import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap"
import logoSm from "../../../assets/images/logo-sm.svg"



export const PrintableComponentStaff = React.forwardRef((props, ref) => {

    const details = props.data;
    const [currentDateTime, setCurrentDateTime] = useState(new Date());


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000); // Update every second

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const formattedDate = currentDateTime.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    const formattedTime = currentDateTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    });


    return (
        <div ref={ref}>
            <div className="modal-content" style={{ display: "flex", justifyContent: "center" }}>
                <div className="ordersummary" style={{ width: "100%", paddingLeft: "15px" }}>
                    <Box className="summary text-center">
                    </Box>

                    <Row>
                        <Col md="12" style={{ textAlign: "center", marginTop: "30px" }}>
                            <p style={{ fontWeight: "700" }}>
                                <h1>{"HARITHA KARMA SENA"}</h1>
                            </p>

                            <p>
                                <h4 style={{ marginTop: "-10px" }}>
                                    {"Staff Complaint"}
                                </h4>
                            </p>

                        </Col>
                    </Row>

                    <Row>
                        <p>
                            <span style={{
                                marginLeft: "41px",
                                marginRight: "38px",
                                marginTop: "20px",
                                marginBottom: "25px",
                                fontWeight: "700"
                            }}>{formattedDate} - {formattedTime}</span>
                        </p>
                    </Row>

                    <table
                        id="table2"
                        style={{
                            marginLeft: "41px",
                            marginRight: "38px",
                            marginTop: "20px",
                            marginBottom: "25px",
                        }}
                    >
                        <thead>
                            <tr>
                                <th style={{ width: "50px" }}>#</th>
                                <th style={{ width: "150px" }}>Date</th>
                                <th style={{ width: "150px" }}>Complainant</th>
                                <th style={{ width: "150px" }}>Complaint ID</th>
                                <th style={{ width: "150px" }}>Defendant</th>
                                <th style={{ width: "150px" }}>Category</th>
                                <th style={{ width: "150px" }}>Ward</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.map((el, indx) => {
                                return (
                                    <tr>
                                        <td style={{ textAlign: "center" }}>{indx + 1}</td>
                                        <td style={{ textAlign: "center" }}>{el?.date}</td>
                                        <td style={{ textAlign: "center" }}>{el?.complainant}</td>
                                        <td style={{ textAlign: "center" }}>{el?.complaint_id}</td>
                                        <td style={{ textAlign: "left" }}>{el?.defendant_firstname} {el?.defendant_lastname}</td>
                                        <td style={{ textAlign: "left" }}>{el?.category}</td>
                                        <td style={{ textAlign: "left" }}>{el?.ward}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    <Row>
                        <h5
                            style={{
                                marginLeft: "41px",
                                marginRight: "38px",
                                marginTop: "20px",
                                marginBottom: "25px",
                                fontWeight: "600"
                            }}
                        >
                            List is Authorised by Computer
                        </h5>
                    </Row>

                    <Row>
                        <Col style={{ textAlign: "center", marginTop: "30px" }}>
                            <p>
                                <h5 style={{ marginTop: "-23px", marginBottom: "-16px" }}>
                                    Powered by Nellikka
                                    <img
                                        src={logoSm}
                                        style={{ width: "35px", marginLeft: "10px" }}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </h5>
                            </p>
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    )

});