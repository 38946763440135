import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import {
  getLocalbody,
  setAssignCust,
  getAssignCust,
} from "../../../helpers/globalFunctions";
import { getCustomerType } from "../../../store/actions";
import $ from "jquery";
import "./AssignPackage.scss";


const AssignPackage = (props) => {
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [errors, setErrors] = useState({
    packageError: "",
  });
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [assignCustomer, setAssignCustomer] = useState([]);
  const [selectedWardSearch, setSelectedWardSearch] = useState(null);
  const [searchGroupOptions, setSearchGroupOptions] = useState([]);
  const [selectedGroupSearch, setSelectedGroupSearch] = useState(null);
  const [selectedCustomerTypeSearch, setSelectedCustomerTypeSearch] =
    useState(null);
  const [selectedPackageSearch, setSelectedPackageSearch] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [wardOptions, setWardOptions] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);
  const [customerType, setCustomerType] = useState([]);
  const [assignPackage, setAssignPackage] = useState(false);
  const { customerTypes } = useSelector((state) => state.customers);
  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL;
  const history = useHistory();
  const dispatch = useDispatch();


  useEffect(() => {
    handleTableData();
    dispatch(getCustomerType());
    fetch_all_packages(currentLocalbody);
    fetch_all_wards(currentLocalbody);
    setAssignCust("");
  }, [currentLocalbody]);


  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }

  function fetch_all_packages(localbody_id) {
    axios
      .get(
        `${API_URL}api/staff/packages?localbody_id=` +
        localbody_id,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          var package_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.package_name,
                value: el._id,
              };
            });
        } else package_data = [];

        setPackageOptions([
          {
            options: package_data,
          },
        ]);
      });
  }
  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);
  const handleTableCheckboxChnage = (e, item, index) => {
    $('input[type="checkbox"][name="headerCheckbox"]')
      .prop("checked", false)
      .trigger("change");
    let dataIndex = null;

    let val = getAssignCust();
    let arr = null;
    if (val) {
      arr = val.split(",");
    }
    let values = [];
    if (arr !== null) {
      values = arr;
    }
    if (e.target.checked === true) {
      values.push(item._id);
    } else {
      values &&
        values.map((value, index) => {
          if (value === item?._id) dataIndex = index;
        });
      values.splice(dataIndex, 1);
    }
    setAssignCustomer(values);
    setAssignCust(values);
  };
  const handleAllCheckboxChange = (e) => {
    let data = [...details];
    const values = [];
    if (e.target.checked === true) {
      $("input[type=checkbox]").prop("checked", true);
      data &&
        data.map((item) => {
          values.push(item._id);
        });
      setAssignCust(values);
    } else {
      $("input[type=checkbox]").prop("checked", false);
      setAssignCust("");
    }
    setAssignCustomer(values);
  };
  function handleTableData(
    ward_id = "",
    group_id = "",
    cust_type_id = "",
    package_id = ""
  ) {
    var url = `${API_URL}customers/list?localbody_id=` + currentLocalbody;

    url =
      url +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&cust_type_id=" +
      cust_type_id +
      "&package_id=" +
      package_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        result.map((item, index) => {
          item.id = index + 1;
          item.localbody_name = getLocalbody();
          item.type = item?.cust_type_name;
          item.district_name = item?.district_name;
          item.ward_name = item?.ward_name;

          item.checkbox = (
            <input
              type="checkbox"
              id="tableCheckbox"
              style={{ cursor: "pointer" }}
              onClick={(e) => handleTableCheckboxChnage(e, item, index)}
            ></input>
          );
          if (item.cust_verification_status === 1) {
            item.status = (
              <h6
                style={{
                  color: "green",
                }}
              >
                {"Verified"}
              </h6>
            );
          } else {
            item.status = (
              <h6
                style={{
                  color: "red",
                }}
              >
                {"Not Verified"}
              </h6>
            );
          }
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/Customer/" + item._id);
                }}
              ></i>
            </div>
          );
        });
        setDetails(result);
        setAssignCust("");
        setAssignCustomer([]);
        $("input[type=checkbox]").prop("checked", false);
      });
  }

  const handleValidSubmit = () => {
    if (selectedPackage === null) {
      setErrors({
        packageError: "Please select package",
      });
      return;
    }
    if (assignCustomer && assignCustomer.length <= 0) {
      toastr.error("Please select customer");
      return;
    }
    setAssignPackage(true);
    let data = {
      package_id: master?.package_id,
      cust_id: assignCustomer,
    };
    axios
      .put(`${API_URL}customers/assign_package`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          toastr.success("Package assigned successfully");
          setSelectedPackage(null);
          setMaster({});
          setAssignCustomer([]);
          setErrors({
            packageError: "",
          });
          handleTableData();
          $("input[type=checkbox]").prop("checked", false);

          setSearchData({});
          setSelectedCustomerTypeSearch(null);
          setSelectedWardSearch(null);
          setSelectedGroupSearch(null);
          setSelectedPackageSearch(null);
          setSearchGroupOptions([]);
          setAssignCust("");
          setAssignPackage(false);
        } else {
          setAssignPackage(false);
          toastr.error("Failed to  assign package");
          return;
        }
      })
      .catch((err) => {
        setAssignPackage(false);
        toastr.error(err.response.data.message);
        return;
      });
  };
  const handleSelectChange = (selected) => {
    setSelectedPackage(selected);
    setErrors({
      packageError: "",
    });
    setMaster({
      ...master,
      package_id: selected.value,
    });
  };

  const data = {
    columns: [
      {
        label: (
          <input
            type="checkbox"
            id="headerCheckbox"
            name="headerCheckbox"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleAllCheckboxChange(e)}
          ></input>
        ),
        field: "checkbox",
        sort: "asc",
        width: 100,
      },
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reg No",
        field: "cust_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "cust_name",
        width: 300,
      },
      {
        label: "Phone",
        field: "cust_phone",
        width: 300,
      },
      {
        label: "Ward",
        field: "ward_name",
        width: 300,
      },
      {
        label: "Status",
        field: "status",
        width: 300,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };

  const handleSearchSelectChange = (selected, name) => {
    let cust_type_id = searchData?.cust_type_id ? searchData.cust_type_id : "";
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let package_id = searchData?.package_id ? searchData.package_id : "";
    switch (name) {
      case "customerType":
        setSelectedCustomerTypeSearch(selected);
        const selectedCustVales = selected.map(option => option.value);
        setSearchData({
          ...searchData,
          cust_type_id: selectedCustVales,
        });
        handleTableData(ward_id, group_id, selectedCustVales, package_id);
        break;
      case "ward":
        setSelectedWardSearch(selected);
        setSelectedGroupSearch(null);
        setSearchData({
          ...searchData,
          ward_id: selected.value,
        });
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
            currentLocalbody +
            "&ward_id=" +
            selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setSearchGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        handleTableData(selected.value, "", cust_type_id, package_id);
        break;
      case "group":
        setSelectedGroupSearch(selected);
        setSearchData({
          ...searchData,
          group_id: selected.value,
        });
        handleTableData(ward_id, selected.value, cust_type_id, package_id);
        break;
      case "package":
        setSelectedPackageSearch(selected);
        setSearchData({
          ...searchData,
          package_id: selected.value,
        });
        handleTableData(ward_id, group_id, cust_type_id, selected.value);
        break;
      default:
        break;
    }
  };
  const reset = () => {
    setSearchData({});
    setSelectedCustomerTypeSearch(null);
    setSelectedWardSearch(null);
    setSelectedGroupSearch(null);
    setSelectedPackageSearch(null);
    setSearchGroupOptions([]);
    setAssignCustomer([]);
    $("input[type=checkbox]").prop("checked", false);
    setAssignCust("");
    handleTableData();
    setErrors({
      packageError: "",
    });
  };


  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Assign Package" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >

                    <Row className="mb-3">
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="ward_id"
                            value={selectedWardSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "ward");
                            }}
                            options={wardOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Customer Type</Label>
                          <Select
                            isMulti
                            name="cust_type_id"
                            value={selectedCustomerTypeSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(
                                selected,
                                "customerType"
                              );
                            }}
                            options={customerType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Group</Label>
                          <Select
                            name="group_id"
                            value={selectedGroupSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "group");
                            }}
                            options={searchGroupOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Package</Label>
                          <Select
                            name="package_id"
                            value={selectedPackageSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "package");
                            }}
                            options={packageOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="1">
                        <div
                          className="mt-4 mb-3"
                          style={{ paddingTop: "5px" }}
                        >
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Package</Label>
                          <Select
                            name="package_id"
                            value={selectedPackage}
                            onChange={(selected) => {
                              handleSelectChange(selected);
                            }}
                            options={packageOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedPackage === null
                              ? errors.packageError
                              : ""}
                          </p>
                        </div>
                      </Col>

                      <Col
                        md="1"
                        className="mt-4"
                        style={{ paddingTop: "5px" }}
                      >
                        <div>
                          {assignPackage === false ? (
                            <Button color="primary" type="submit">
                              Assign Package
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={true}
                            >
                              Processing
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>

                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="assignPackageTableId"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>

    </React.Fragment>
  );
};

export default AssignPackage;
