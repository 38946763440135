import axios from "axios";
import { post, del, get, put } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export const saveCompanyName = (data) => {
  let company = data;
  return company;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Login Method
const login = (data) => post(url.POST_LOGIN, data);

export const checkAuth = (data) => post(url.CHECK_AUTH, data);

// get menu options
export const getMenusOptions = (userId) =>
  get(`${url.GET_Menu_OPTIONS}?id=${userId}`);
// postForgetPwd
// const postJwtForgetPwd = (data) =>
//   post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// get Product detail
// export const getProductDetail = (id) =>
//   get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// export const getUserProfile = (user) => post(url.GET_USERS_PROFILE,user);

// get users
export const getUsers = () => get(url.GET_USERS);
export const getUsersProfile = (user) => post(url.GET_USERS_PROFILE, user);
// add user
export const addUser = (user) => post(url.ADD_USER, user);
export const getUser = (userId) => get(url.GET_USER, userId);
//export const updateUser = (user) => put(`${url.UPDATE_USER}/${user._id}`, user);
export const updateUser = (user) =>
  put(url.UPDATE_USER + "?user_id=" + user._id, user);
//export const deleteUser = (userId) => del(url.DELETE_USER+"?user_id="+userId);
export const deleteUser = (userId) => del(url.DELETE_USER, userId);
export const getUsersStatus = (user_id) =>
  get(url.GET_USERS_STATUS + "?user_id=" + user_id);
// get users
export const getDistricts = () => get(url.GET_DISTRICTS);

// add user
export const addDistrict = (district) => post(url.ADD_DISTRICT, district);
export const getDistrict = (userId) => get(url.GET_DISTRICT, userId);
export const updateDistrict = (district) =>
  put(`${url.UPDATE_DISTRICT}/${district._id}`, district);
export const deleteDistrict = (userId) => del(url.DELETE_DISTRICT, userId);
export const getDistrictOptions = () => get(url.GET_DISTRICT_OPTIONS);
// get wards
export const getWards = () => get(url.GET_WARDS);

// add ward
export const addWard = (ward) => post(url.ADD_WARD, ward);
export const getWard = (wardId) => get(url.GET_WARD, wardId);
export const updateWard = (ward) => put(`${url.UPDATE_WARD}/${ward._id}`, ward);
export const deleteWard = (wardId) => del(url.DELETE_WARD, wardId);

// get localbodies
export const getLocalbodies = (companyId) =>
  get(`${url.GET_LOCALBODIES}?id=${companyId}`);

// add localbodies
export const addLocalbody = (localbody) => post(url.ADD_LOCALBODY, localbody);
export const getLocalBody = (localbodyId) =>
  get(`${url.GET_LOCALBODY}/${localbodyId}`, { localbodyId: localbodyId });
export const updateLocalbody = (localbody) =>
  put(`${url.UPDATE_LOCALBODY}/${localbody._id}`, localbody);
export const deleteLocalbody = (localbodyId) =>
  del(url.DELETE_LOCALBODY, localbodyId);

export const getLocalbodyOptions = (distId) =>
  get(`${url.GET_LOCALBODY_OPTIONS}?id=${distId}`);

export const getLocalbodyTypes = () => get(url.GET_LOCALBODY_TYPES);
export const getTypesLocalbodies = (typeId) =>
  get(`${url.GET_TYPES_LOCALBODIES}?id=${typeId}`);

//Privilege
export const addPrivilege = (privilege) => post(url.ADD_PRIVILAGE, privilege);
export const getAllPrivileges = () => get(url.GET_PRIVILAGE);
export const updatePrivilege = (privilege) =>
  put(url.UPDATE_PRIVILAGE, privilege);
export const deletePrivilege = (privilegeid) =>
  del(url.DELETE_PRIVILAGE, privilegeid);
export const getPrivilagesOptions = () => get(url.GET_PRIVILAGES_OPTIONS);

// get companies options
export const getCompaniesOptions = () => get(url.GET_COMPANIES_OPTIONS);
export const getCompaniesMasterOptions = (company_id) =>
  get(url.GET_COMPANIES_MASTER_OPTIONS + "?id=" + company_id);
export const getLocalbodiesMasterOptions = (company_id) =>
  get(url.GET_LOCALBODIES_MASTER_OPTIONS + "?id=" + company_id);
export const addCompany = (data) => post(url.ADD_COMPANY, data);
export const updateCompany = (data) => put(url.UPDATE_COMPANY, data);
export const deleteCompany = (companyId) => del(url.DELETE_COMPANY, companyId);

// get Branches options
export const getBranchesOptions = (companyId) =>
  get(`${url.GET_BRANCHES_OPTIONS}/${companyId}`, { companyId: companyId });

export { getLoggedInUser, isUserAuthenticated, login };

// get Groups
export const getGroups = () => get(url.GET_GROUPS);

export const addGroup = (group) => post(url.ADD_GROUP, group);
export const getGroup = (groupname) => get(url.GET_GROUP, groupname);
export const updateGroup = (group) =>
  put(`${url.UPDATE_GROUP}/${group._id}`, group);
export const deleteGroup = (groupId) => del(url.DELETE_GROUP, groupId);
export const getGroupOptions = (localbodyId) =>
  get(`${url.GET_GROUP_OPTIONS}?id=${localbodyId}`);

export const getWardsGroupOptions = (wardId) =>
  get(`${url.GET_WARDS_GROUP_OPTIONS}?wid=${wardId}`);

//get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);
export const getCustVisitLog = (custId) =>
  get(`${url.GET_CUST_VISITLOG}?id=${custId}`);
export const getCustReceipts = (custId) =>
  get(`${url.GET_CUST_RECEIPTS}?id=${custId}`);
export const getCustInvoice = (custId) =>
  get(`${url.GET_CUST_INVOICE}?id=${custId}`);
export const getCustStatement = (custId) =>
  get(`${url.GET_CUST_STATEMENT}?id=${custId}`);
export const addCustomers = (data) => post(url.ADD_CUSTOMERS, data);
export const updateCustomers = (data) => put(url.UPDATE_CUSTOMERS, data);

export const getCustomerType = (categoryId) => 
  get(`${url.GET_CUSTOMER_TYPE}?category=${categoryId || ""}`);
export const deleteCustomers = (customerId) =>
  del(url.DELETE_CUSTOMERS, customerId);

export const getWardOptions = (localbodyId) =>
  get(`${url.GET_WARD_OPTIONS}/${localbodyId}`, { localbodyId: localbodyId });

//waste items
export const getWasteItems = () => get(url.GET_WASTEITEMS);
export const getWasteTypes = () => get(url.GET_WASTE_TYPES);
export const getWasteCategories = () => get(url.GET_WASTE_CATEGORIES);
export const addWasteItem = (wasteItem) => post(url.ADD_WASTE_ITEM, wasteItem);
export const updateWasteItem = (wasteItem) =>
  post(url.UPDATE_WASTE_ITEM, wasteItem);
export const deleteWasteItem = (wasteItemId) =>
  del(url.DELETE_WASTE_ITEM, wasteItemId);
export const getWasteItemsOptions = () => get(url.GET_WASTE_ITEMS_OPTIONS);
//customer categories
export const getCustomerCategoryOptions = () =>
  get(url.GET_CUST_CATEGORIES_OPTIONS);
export const getCustomerCategories = () => get(url.GET_CUST_CATEGORIES);
export const addCategory = (category) => post(url.ADD_CUST_CATEGORY, category);
export const getCategory = (catId) => get(url.GET_CUST_CATEGORY, catId);
export const updateCategory = (category) =>
  put(`${url.UPDATE_CUST_CATEGORY}/${category._id}`, category);
export const deleteCategory = (catId) => del(url.DELETE_CUST_CATEGORY, catId);

export const getQrcode = () => get(url.GET_QRCODE);

export const addQrcode = (data) => post(url.ADD_QRCODE, data);

export const getTariff = () => get(url.GET_TARIFF);
export const getTariffOptions = () => get(url.GET_TARIFF_OPTIONS);
export const updateTariffStatus = (tariff) =>
  put(`${url.UPDATE_TARIFF_STATUS}/${tariff._id}`, tariff);

export const getInvoice = () => get(url.GET_INVOICE);
export const getInvoiceList = () => get(url.GET_INVOICE_LIST);

export const getReceipts = () => get(url.GET_RECEIPTS);

//Add Tariff
export const getPackages = (localbody_id) =>
  get(url.GET_PACKAGES + "?localbody_id=" + localbody_id);
export const addPackage = (packageData) => post(url.ADD_PACKAGE, packageData);
export const updatePackage = (packageData) =>
  put(`${url.UPDATE_PACKAGE}/${packageData._id}`, packageData);
export const deletePackage = (packageId) => del(url.DELETE_PACKAGE, packageId);
export const updatePackageStatus = (packageData) =>
  put(`${url.UPDATE_PACKAGE_STATUS}/${packageData._id}`, packageData);
// add account type
export const addAccountType = (acctype) => post(url.ADD_ACCTYPE, acctype);
export const getAllAccountTypes = (localbody_id) =>
  get(url.GET_ACCTYPES + "?localbody_id=" + localbody_id);
export const updateAccountType = (acctype) => put(url.UPDATE_ACCTYPE, acctype);
export const deleteAccountType = (acctypeid, auth_user) =>
  del(url.DELETE_ACCTYPE, acctypeid);
export const accountTypeOptions = () => get(url.GET_ACCTYPE_OPTIONS);

// add account detail
export const addAccountDetail = (accdetail) =>
  post(url.ADD_ACCDETAIL, accdetail);
export const getAllAccountDetails = (localbody_id) =>
  get(url.GET_ACCDETAILS + "?localbody_id=" + localbody_id);
export const updateAccountDetail = (accdetail) =>
  put(url.UPDATE_ACCDETAIL, accdetail);
export const deleteAccountDetail = (accdetailid, auth_user) =>
  del(url.DELETE_ACCDETAIL, accdetailid);

// chart of accounts
export const addChartOfAcc = (data) => post(url.ADD_CHARTOFACC, data);
// export const getAllChartOfAcc = (localbody_id) =>
//   get(url.GET_CHARTOFACC + "?localbody_id=" + localbody_id);

export const getAllChartOfAcc = (localbody_id = "", acc_type_id = "") =>
  get(
    url.GET_CHARTOFACC +
      "?localbody_id=" +
      localbody_id +
      "&acc_type_id=" +
      acc_type_id
  );

export const updateChartOfAcc = (data) => put(url.UPDATE_CHARTOFACC, data);
export const deleteChartOfAcc = (chartDetailId, auth_user) =>
  del(url.DELETE_CHARTOFACC, chartDetailId);

// credit debit fund
export const addCreditDebit = (data) => post(url.ADD_CREDIT_DEBIT, data);
export const getAllCreditDebit = (localbody_id) =>
  get(url.GET_CREDIT_DEBIT + "?localbody_id=" + localbody_id);

// fund transfer
export const getAllFundTransfer = (localbody_id) =>
  get(url.GET_FUND_TRANSFER + "?localbody_id=" + localbody_id);
export const addFundTransfer = (data) => post(url.ADD_FUND_TRANSFER, data);
export const getAllFundSource = (localbody_id) =>
  get(url.GET_FUND_SOURCE + "?localbody_id=" + localbody_id);

// income expenses
export const getIncomeExpenseAccType = () =>
  get(url.GET_INCOME_EXPENSE_ACC_TYPE);
export const addIncomeExpense = (data) => post(url.ADD_INCOME_EXPENSE, data);
export const getAllIncomeExpenses = (localbody_id) =>
  get(url.GET_INCOME_EXPENSE + "?localbody_id=" + localbody_id);

//popup
export const addPopup = (data) => post(url.ADD_POPUP, data);
export const getAllPopup = (localbody_id) =>
  get(url.GET_POPUP + "?localbody_id=" + localbody_id);
export const updatePopup = (data) =>
  put(url.UPDATE_POPUP + "/" + data.popup_id, data);
export const deletePopup = (popupDetailId, auth_user) =>
  del(url.DELETE_POPUP + "/" + auth_user, popupDetailId);

//salary
export const getStaffSalary = (localbody_id, month, days) =>
  get(
    url.GET_STAFF_SALARY + "?localbody_id=" + localbody_id + "&month=" + month
  );

//popup
export const addSlider = (data) => post(url.ADD_SLIDER, data);
export const getAllSlider = (localbody_id) =>
  get(url.GET_SLIDER + "?localbody_id=" + localbody_id);
export const updateSlider = (data) => put(url.UPDATE_SLIDER, data);

export const deleteSlider = (slider_id, auth_user) =>
  del(url.DELETE_SLIDER + "/" + auth_user, slider_id);

//export const menuoption = (data) => post(url.MENU_OPTION, data);
// export const menuoption = (privilege,mainmenu_id,submenu_id) =>
// get(url.MENU_OPTION + "?privilege=" + privilege+"&mainmenu_id="+mainmenu_id+"&submenu_id"+submenu_id);
