import React from "react";
import { Card, CardBody, CardTitle, Row, Col, Progress } from "reactstrap";
// import ReactApexChart from "react-apexcharts";
// import moment from "moment";
import ReactEcharts from "echarts-for-react";
const TopProduct = (props) => {
  function generateRandomColor() {
    // var letters = 'BCDEF'.split('');
    // var color = '#';
    // for (var i = 0; i < 6; i++ ) {
    //     color += letters[Math.floor(Math.random() * letters.length)];
    // }
    // return color;
    let color = "#";
    for (let i = 0; i < 3; i++)
      color += (
        "0" +
        Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)
      ).slice(-2);
    return color;
  }
  const items = props.wasteItems;
  const weight = [];
  const categories = [];
  const colors = [];
  const dataValues = [];
  items &&
    items.map((progressbar, key) => {
      let value = {
        value: progressbar?.weight,
        name: progressbar?.name+" - "+progressbar?.weight+" Kg",
      };
      categories.push(progressbar.name.toLowerCase());
      weight.push(progressbar.weight);
      colors.push(generateRandomColor());
      dataValues.push(value);
    });
  // const series = weight;
  // const options = {
  //   labels: categories,
  //   colors: colors,
  //   legend: {
  //     show: !0,
  //     position: "bottom",
  //     horizontalAlign: "center",
  //     verticalAlign: "middle",
  //     floating: !1,
  //     fontSize: "14px",
  //     color: "black",
  //     offsetX: 0,
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 600,
  //       options: {
  //         chart: {
  //           height: 240,
  //         },
  //         legend: {
  //           show: !1,
  //         },
  //       },
  //     },
  //   ],
  // };

  ///////

  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "",
      },
      legend: {
        orient: "horizontal",
        x: "left",
        data: dataValues,
        textStyle: {
          color: "black",
          fontSize: "10",
        },
      },
      color: colors,
      series: [
        {
          name: "",
          type: "pie",
          radius: ["30%", "60%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "10",
                fontWeight: "bold",
                color: "black",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: dataValues,
        },
      ],
    };
  };

  return (
    <React.Fragment>
      <Card style={{ minHeight:"430px"}}>
        <CardBody>
          <center>
            <CardTitle className="mb-4 h4" style={{ fontSize:"20px"}}>Collected Waste Items</CardTitle>
          </center>
          <br></br>
          {/* <ReactApexChart
            options={options}
            series={series}
            type="donut"
            height="320"
            className="apex-charts"
          /> */}
          <ReactEcharts style={{ height: "334px" }} option={getOption()} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TopProduct;
