import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    // Modal,
    // Table,
} from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
    getLocalbody,
    getDate,
    getFirstday,
    getArraySumByKey,
    formatMoney,
    isSuperAdmin,
} from "../../../helpers/globalFunctions";
import "./style.scss";
import { Pagination } from "@mui/material";

const TotalSummaryReport = (props) => {

    const [wardOptions, setWardOptions] = useState([]);
    const [selectedWard, setSelectedWard] = useState(null);

    const [groupOptions, setGroupOptions] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [searchData, setSearchData] = useState({
        from_date: getFirstday(new Date()),
        to_date: getDate(new Date()),
    });
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

    const [selectedCustomerType, setSelectedCustomerType] = useState(null);
    const [details, setDetails] = useState([]);
    const [dataToBeExported, setDataToBeExported] = useState([]);

    const [selectedVerification, setSelectedVerification] = useState(null);
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [summaryDetails, setSummaryDetails] = useState([]);

    useEffect(() => {
        handleTableData();
        fetch_all_wards(currentLocalbody);
    }, [currentLocalbody]);


    function handleTableData(
        from_date = "",
        to_date = "",
        ward_id = "",
        group_id = "",
        cust_type = "",
        verification = "",
        page = 1,
        limit = 20
    ) {
        let userId = "";
        if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser");
            const obj = JSON.parse(data);
            userId = obj._id;
        }
        var url =
            `${API_URL}customers/customer-summary?localbody=` +
            currentLocalbody +
            "&user_id=" +
            userId;

        if (from_date === "" && to_date === "") {
            from_date = from_date ? from_date : getFirstday(new Date());
            to_date = to_date ? to_date : getDate(new Date());
        }

        url =
            url +
            "&fromdate=" +
            from_date +
            "&todate=" +
            to_date +
            "&filter_ward=" +
            ward_id +
            "&filter_group=" +
            group_id +
            "&sort=" +
            cust_type +
            "&verified=" +
            verification +
            "&page=" +
            page +
            "&limit=" +
            limit;
        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                let result = res.data.customers;
                setTotalPages(res.data.count);

                let Data = [];
                let dataToBeExported = [];
                let id = (page - 1) * limit; // Reset id for each page

                result &&
                    result.map((item, index) => {
                        let exportItem = {};
                        id++;
                        item.id = id;

                        //export data
                        exportItem.Id = item?.id;
                        exportItem.Reg_no = item?.cust_reg_no;
                        exportItem.Customer = item?.cust_name;
                        exportItem.Address = item?.cust_address;
                        exportItem.Phone = item?.cust_phone;
                        exportItem.Designation = item?.designations;
                        exportItem.Ward = item?.ward;
                        exportItem.Group = item?.group;
                        exportItem.Due_amount = item?.cust_due;

                        Data.push(item);
                        dataToBeExported.push(exportItem);

                    });

                setDetails(result);
                setSummaryDetails(Data);
                setDataToBeExported(dataToBeExported);
            });
    }

    const ward_id = searchData?.ward_id;
    const group_id = searchData?.group_id;
    const cust_type = searchData?.cust_type;
    const verification = searchData?.verification;
    const from_date = searchData?.from_date;
    const to_date = searchData?.to_date;

    // pagination
    const [searchValue, setSearchValue] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPageCount, setPerPageCount] = useState(20);
    const [indexOfLastTransfer, setindexOfLastTransfer] = useState(currentPage * PerPageCount);
    const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(indexOfLastTransfer - PerPageCount);

    const paginate = (event, value) => {
        setCurrentPage(value);
        // Call handleTableData with updated page value
        handleTableData(from_date, to_date, ward_id, group_id, cust_type, verification, value, PerPageCount);
    };

    const resetPaginate = () => {
        setCurrentPage(1);
        const indexOfLastTransfer = 20;
        const indexOfFirstTransfer = 0;
        setindexOfFirstTransfer(indexOfFirstTransfer);
        setindexOfLastTransfer(indexOfLastTransfer);
    }

    const debounce = (func, delay) => {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => func(...args), delay);
        };
    }

    useEffect(() => {
        if (details?.length === 0 && indexOfFirstTransfer !== 0) {
            resetPaginate();
            handleTableData(searchValue, currentLocalbody, from_date, to_date, ward_id, group_id, cust_type, verification, currentPage, PerPageCount);
        }
    }, [details]);

    function fetch_all_wards(localbody) {
        axios
            .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var ward_data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el.ward_name,
                            value: el._id,
                        };
                    });

                setWardOptions([
                    {
                        options: ward_data,
                    },
                ]);
            });
    }

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 100,
            },
            {
                label: "Reg No.",
                field: "cust_reg_no",
                sort: "asc",
                width: 150,
            },
            {
                label: "Customer",
                field: "cust_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Address",
                field: "cust_address",
                sort: "asc",
                width: 150,
            },
            {
                label: "Phone",
                field: "cust_phone",
                sort: "asc",
                width: 150,
            },
            {
                label: "Designation",
                field: "designations",
                sort: "asc",
                width: 150,
            },
            {
                label: "Ward",
                field: "ward",
                sort: "asc",
                width: 150,
            },
            {
                label: "Group",
                field: "group",
                sort: "asc",
                width: 150,
            },
            // {
            //     label: "Package",
            //     field: "package",
            //     sort: "asc",
            //     width: 150,
            // },
            {
                label: "Due Amount",
                field: "cust_due",
                sort: "asc",
                width: 150,
            },
        ],
        rows: summaryDetails,
    }


    let handleDate = (e) => {
        var date1 = $("#from_date").val();
        var date2 = $("#to_date").val();


        let toDate = moment(date1);
        let fromDate = moment(date2);
        let result = fromDate.diff(toDate, "days");

        if (result + 1 <= 0) {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
                ["to_date"]: "",
            });
            date2 = "";
        } else {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
            });
        }

        // handleTableData(date1, date2, ward_id, group_id, cust_type, category_id);
    };

    const handleSelectChange = (selected, name) => {
        switch (name) {
            case "ward":
                setSelectedWard(selected);
                setSelectedGroup(null);
                setSearchData({
                    ...searchData,
                    ["ward_id"]: selected.value,
                    ["group_id"]: "",
                });
                axios
                    .get(
                        `${API_URL}api/staff/groups?localbody_id=` +
                        currentLocalbody +
                        "&ward_id=" +
                        selected.value,
                        {
                            headers: {
                                "x-access-token": accessToken,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data && res.data.success === true) {
                            var group_data =
                                res.data.data &&
                                res.data.data.map((el) => {
                                    return {
                                        label: el.group_name,
                                        value: el._id,
                                    };
                                });
                        } else {
                            group_data = [];
                        }

                        setGroupOptions([
                            {
                                options: group_data,
                            },
                        ]);
                    });
                break;

            case "group":
                setSelectedGroup(selected);
                setSearchData({
                    ...searchData,
                    ["group_id"]: selected.value,
                });
                break;

            case "cust_type":
                setSelectedCustomerType(selected);
                setSearchData({
                    ...searchData,
                    ["cust_type"]: selected.value,
                });
                break;

            case "verification":
                setSelectedVerification(selected);
                setSearchData({
                    ...searchData,
                    ["verification"]: selected.value,
                });
                break;

            default:
                break;
        }
    };

    const reset = () => {
        setSearchData({
            from_date: getFirstday(new Date()),
            to_date: getDate(new Date()),
        });
        setSelectedWard(null);
        setSelectedGroup(null);
        setSelectedCustomerType(null);
        setSelectedVerification(null);
        setSelectedCustomerType(null);
        handleTableData(searchValue);
        resetPaginate();
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        resetPaginate();
        let ward_id = searchData?.ward_id ? searchData.ward_id : "";
        let group_id = searchData?.group_id ? searchData.group_id : "";
        let cust_type = searchData?.cust_type ? searchData.cust_type : "";
        let date1 = searchData?.from_date ? searchData.from_date : "";
        let date2 = searchData?.to_date ? searchData.to_date : "";
        let verification = searchData?.verification ? searchData.verification : "";
        handleTableData(date1, date2, ward_id, group_id, cust_type, verification);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Total Summary Report" />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label htmlFor="validationCustom05">From</Label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    id="from_date"
                                                    name="from_date"
                                                    value={searchData?.from_date}
                                                    onChange={handleDate}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label htmlFor="validationCustom05">To</Label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    id="to_date"
                                                    name="to_date"
                                                    value={searchData?.to_date}
                                                    min={searchData?.from_date}
                                                    onChange={handleDate}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Ward</Label>
                                                <Select
                                                    name="ward_id"
                                                    value={selectedWard}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "ward");
                                                    }}
                                                    options={wardOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Group</Label>
                                                <Select
                                                    name="group_id"
                                                    value={selectedGroup}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "group");
                                                    }}
                                                    options={groupOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Customer Type</Label>
                                                <Select
                                                    name="cust_type"
                                                    value={selectedCustomerType}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "cust_type");
                                                    }}
                                                    options={[
                                                        { label: "Due customers", value: 1 },
                                                        { label: "Payment pending", value: 2 },
                                                        { label: "Non co-operative", value: 3 }
                                                    ]}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Status</Label>
                                                <Select
                                                    name="verification"
                                                    value={selectedVerification}
                                                    classNamePrefix="select2-selection"
                                                    options={[
                                                        { label: "Verified", value: 0 },
                                                        { label: "Not verified", value: 1 }
                                                    ]} onChange={(selected) => {
                                                        handleSelectChange(selected, "verification")
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="3">
                                            <div style={{ display: "flex" }}>
                                                <div
                                                    className="mt-1 mb-3 mx-1"
                                                    style={{ paddingTop: "24px" }}
                                                >
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => handleSearch()}
                                                    >
                                                        Search
                                                    </Button>
                                                </div>
                                                {isSuperAdmin() ? (
                                                <div
                                                    className="mt-1 mb-3 mx-1"
                                                    style={{ paddingTop: "24px" }}
                                                >
                                                    <Button color="success" type="submit">
                                                        <CSVLink
                                                            data={dataToBeExported}
                                                            filename={
                                                                "Total_summary_report_" +
                                                                getDate(new Date()) +
                                                                ".xls"
                                                            }
                                                            style={{ color: "white" }}
                                                        >
                                                            Export
                                                        </CSVLink>
                                                    </Button>
                                                </div>
        ) : null}
                                                <div
                                                    className="mt-1 mb-3 mx-1"
                                                    style={{ paddingTop: "24px" }}
                                                >
                                                    <Button
                                                        color="danger"
                                                        type="reset"
                                                        onClick={() => reset()}
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="col-12">
                                            <MDBDataTable
                                                id="TotalTableId"
                                                responsive
                                                bordered
                                                info={false}
                                                searching={true}
                                                disbaleRetreatAfterSorting={true}
                                                paging={false}
                                                data={data}
                                                onSearch={debounce(handleSearch, 500)}
                                                entries={PerPageCount}
                                            />
                                            {totalPages > 20 && (
                                                <div className="pagination-div">
                                                    <div className="page-details">
                                                        Showing {((currentPage - 1) * PerPageCount) + 1} to{" "}
                                                        {Math.min(currentPage * PerPageCount, totalPages)} of{" "}
                                                        {totalPages} entries
                                                    </div>
                                                    <Pagination
                                                        color="standard"
                                                        shape="rounded"
                                                        defaultPage={1}
                                                        count={Math.ceil(totalPages / PerPageCount)}
                                                        page={currentPage}
                                                        onChange={paginate}
                                                        info={true}
                                                        size="large"
                                                    />
                                                </div>
                                            )}

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>
    );
};

export default TotalSummaryReport;
