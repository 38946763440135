import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import axios from "axios";
import moment from "moment";
import MiniWidget from "./mini-widget";
import WasteCollectItems from "./wasteItems";
import { useHistory } from "react-router-dom";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
const options1 = {
  fill: {
    colors: ["#f1734f"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};
const options2 = {
  fill: {
    colors: ["#34c38f"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};
const options3 = {
  fill: {
    colors: ["#e83e8c"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const options4 = {
  fill: {
    colors: ["#50a5f1"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};
const options5 = {
  fill: {
    colors: ["#6f42c1"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};
const options6 = {
  fill: {
    colors: ["#6d92b3"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};
const options7 = {
  fill: {
    colors: ["#18996af5"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};
const options8 = {
  fill: {
    colors: ["#ffdd7c"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};
const options9 = {
  fill: {
    colors: ["#bb2e2e"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};
const Dashboard = (props) => {
  const accessToken = props.match.params.accessToken;
  const currentLocalbody = props.match.params.localbody_id;
  const userId = props.match.params.user_id;

  const [heading, setHeading] = useState(null);
  const [active_customer_count, setActiveCustomerCount] = useState(0);
  const [total_customer_count, setTotalCustomerCount] = useState(0);
  const [custPercentage, setCustPercentage] = useState([0]);
  const [total_non_corparate, setTotalNonCorparate] = useState(0);

  const [total_visit, settotal_visit] = useState(0);
  const [total_pending, settotal_pending] = useState(0);
  const [visitPercentage, setVisitPercentage] = useState([0]);
  const [total_paid_customers, setTotalPaidCustomers] = useState(0);

  const [total_present, settotal_present] = useState(0);
  const [total_staff, settotal_staff] = useState(0);
  const [staffPercentage1, setStaffPercentage1] = useState([0]);
  const [staffPercentage2, setStaffPercentage2] = useState([0]);
  const [staffPercentage3, setStaffPercentage3] = useState([0]);

  const [attendance_present, setattendance_present] = useState(0);
  const [attendance_absent, setattendance_absent] = useState(0);
  const [attendance_half, setattendance_half] = useState(0);

  const [userAttendance, setUserAttendance] = useState({});
  const [wasteItems, setWasteItems] = useState([]);
  const [max_weight, setMaxWeight] = useState(0);
  const history = useHistory()
  
  useEffect(() => {
        fetch_graph_data2(userId);
        handleDashboardData();
  }, [userId]);
  useEffect(() => {
      const interval = setInterval(() => {
       window.location.href=("/tv_screen1/"+accessToken+"/"+userId) 
      }, 19000);
  }, [userId]);
const handleDashboardData = ()=>{
  axios
  .get(
    `${API_URL}api/staff/tv_dashboard?user_id=` +
      userId,
    {
      headers: {
        "x-access-token": accessToken,
      },
    }
  )
  .then((res) => {
    setHeading(res.data.localbody_name);
    setActiveCustomerCount(res.data.verified_customers);
    setTotalCustomerCount(res.data.total_customers);
    setTotalNonCorparate(res.data.total_non_corparate);


    settotal_visit(res.data.total_visit);
    settotal_pending(res.data.total_pending);
    setTotalPaidCustomers(res.data.total_paid_customers);

    settotal_staff(res.data.total_staff);
    settotal_present(res.data.total_present);

    setCustPercentage([
      100,
    ]);
    setVisitPercentage([
      (res.data.total_visit / res.data.verified_customers) * 100,
    ]);
  });
}
const fetch_graph_data2 = (userId) => {
  axios
    .get(
      `${API_URL}api/staff/tv_dashboard/wasteItemgraph?user_id=` +
        userId,
      {
        headers: {
          "x-access-token": accessToken,
        },
      }
    )
    .then((res) => {
      setWasteItems(res.data.waste_items);
      setMaxWeight(res.data.max_weight);
    });
};
  const reports = [
    {
      id: 1,
      icon: "mdi mdi-account",
      title: "Customers",
      value: total_customer_count,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 80,
      chartwidth: 80,
      prefix: "",
      suffix: "",
      badgeValue: total_customer_count,
      desc: "Total customers",
      series: custPercentage,
      color:"warning",
      options: options1,
    },
    {
      id: 2,
      icon: "mdi mdi-account-check",
      title: "Verified Customers",
      value: active_customer_count,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 80,
      chartwidth: 80,
      prefix: "",
      suffix: "",
      color: "success",
      series: [(active_customer_count/total_customer_count)*100],
      options: options2,
    },
    {
      id: 2,
      icon: "mdi mdi-account-alert",
      title: "Non Corp. Customers",
      value: total_non_corparate,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 80,
      chartwidth: 80,
      prefix: "",
      suffix: "",
      color: "danger",
      series: [(total_non_corparate/total_customer_count)*100],
      options: options3,
    },
    {
      id: 3,
      icon: "mdi mdi-transit-transfer",
      title: "Waste Collected",
      value: total_visit,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 80,
      chartwidth: 80,
      color: "info",
      series: visitPercentage,
      options: options4,
    },
    {
      id: 4,
      icon: "mdi mdi-adjust",
      title: "Visit Pending",
      value: total_pending,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 80,
      chartwidth: 80,
      color: "primary",
      series: [(total_pending/active_customer_count)*100],
      options: options5,
    },
    {
      id: 4,
      icon: "mdi mdi-receipt",
      title: "Payment Collected",
      value: total_paid_customers,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 80,
      chartwidth: 80,
      color: "warning",
      series: [(total_paid_customers/active_customer_count)*100],
      options: options6,
    },
    // {
    //   id: 4,
    //   icon: "mdi mdi-human-male-female",
    //   title: "Present",
    //   value: attendance_present,
    //   decimal: 0,
    //   prefix: "",
    //   suffix: "",
    //   charttype: "radialBar",
    //   chartheight: 80,
    //   chartwidth: 80,
    //   color: "success",
    //   series: staffPercentage1,
    //   options: options7,
    // },
    // {
    //   id: 4,
    //   icon: "mdi mdi-human-male-female",
    //   title: "Halfday",
    //   value: attendance_half,
    //   decimal: 0,
    //   prefix: "",
    //   suffix: "",
    //   charttype: "radialBar",
    //   chartheight: 80,
    //   chartwidth: 80,
    //   color: "warning",
    //   series: staffPercentage2,
    //   options: options8,
    // },
    // {
    //   id: 4,
    //   icon: "mdi mdi-human-male-female",
    //   title: "Absent",
    //   value: attendance_absent,
    //   decimal: 0,
    //   prefix: "",
    //   suffix: "",
    //   charttype: "radialBar",
    //   chartheight: 80,
    //   chartwidth: 80,
    //   badgeValue: total_staff,
    //   color: "danger",
    //   desc: "Total staff",
    //   series: staffPercentage3,
    //   options: options9,
    // },
  ];
  return (
    <React.Fragment>
      <div className="page-content" style={{ paddingTop:"30px"}}>
          {/* <center><h2 style={{ fontWeight:"bold"}}>{heading}</h2></center> */}
          <Row style={{ paddingTop:"10px"}}>
            <Col md="6">
              <Row>
              <MiniWidget reports={reports} />
              </Row>
            </Col>
            <Col md="6">
            <WasteCollectItems
                wasteItems={wasteItems}
                maxWeight={max_weight}
              />
            </Col>
            
          </Row>

      </div>
    </React.Fragment>
  );
};

export default Dashboard;
