import React, { useState, useEffect, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Table } from "reactstrap";
// import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import Select from "react-select";
import moment from "moment";
import { getLocalbody } from "../../../helpers/globalFunctions";
import $ from "jquery";
// import SweetAlert from "react-bootstrap-sweetalert";
import "./requestDetails.scss";
const FoodWasteRequest = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedProcessingMethod, setSelectedProcessingMethod] =
    useState(null);
  const [processingMethodOptions, setProcessingMethodOptions] = useState([]);
  //   const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  //   const [errors, setErrors] = useState({
  //     customerError: "",
  //     processingMethodError: "",
  //   });
  //   const [requestIdToBeDeleted, setRequestIdToBeDeleted] = useState(null);
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  //   const formRef = useRef();

  useEffect(() => {
    handleTableData();
    fetch_all_customers(currentLocalbody, "");
    fetch_all_methods();
    fetch_all_wards(currentLocalbody);
  }, []);

  function handleTableData(
    ward_id = "",
    customer_id = "",
    item_id = "",
    from_date = "",
    to_date = ""
  ) {
    let user_id = "";
   

      if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        user_id = obj._id;
      }
    axios
      .get(
        `${API_URL}foodwaste/request?localbody_id=` +
          currentLocalbody +
          "&user_id=" +
          user_id +
          "&ward_id=" +
          ward_id +
          "&customer_id=" +
          customer_id +
          "&item_id=" +
          item_id +
          "&from_date=" +
          from_date +
          "&to_date=" +
          to_date,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;

            item.time = moment(item?.foodwaste_request_time, "hhmmss").format(
              "hh:mm a"
            );
            item.date = moment(item?.foodwaste_request_date).format(
              "DD-MM-YYYY"
            );
            // item.action = (
            //   <div style={{ display: "flex", justifyContent: "center" }}>
            //     {/* <i
            //         className="fas fa-eye"
            //         style={{
            //           fontSize: "1em",
            //           cursor: "pointer",
            //           marginLeft: "0.2em",
            //           marginRight: "0.5em",
            //         }}
            //         // onClick={() => {
            //         // }}
            //       ></i> */}
            //     <i
            //       className="far fa-trash-alt"
            //       style={{ fontSize: "1em", cursor: "pointer" }}
            //       onClick={() => {
            //         // setWorkRequestIdToBeDeleted(item._id);
            //         // setConfirmDeleteAlert(true);
            //       }}
            //     ></i>
            //   </div>
            // );
            let statusOptions = [];
            let selectedRequestStatus = {};
            item.ward &&
            item.ward.map((ward) => {
              item.ward_name = ward?.ward_name;
            });
            item.customer &&
            item.customer.map((customer) => {
              item.customer_name = customer?.cust_name;
            });
            item.user &&
            item.user.map((user) => {
              item.staff_name = user?.firstName;
            });
            item.require = (
              <div>
                <ul>
                  {item.require &&
                    item.require.map((element, index) => {
                      return <li>{element.require_name}</li>;
                    })}
                </ul>
              </div>
            );
  
            if (item.foodwaste_request_activestatus === 0) {
              item.status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => {
                      updateStatus(item);
                    }}
                  >
                    Processing
                  </Button>
                </div>
              );
            }
            else if (item.foodwaste_request_activestatus === 1) {
              item.status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      updateStatus(item);
                    }}
                  >
                    Approved
                  </Button>
                </div>
              );
            }
            
            
            
            else {
              item.status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="warning"
                    size="sm"
                    onClick={() => {
                      updateStatus(item);
                    }}
                  >
                    Delivered
                  </Button>
                </div>
              );
            }



            // } else {
            //   if (item?.foodwaste_request_activestatus === 2)
            //     item.status = "Delivered";
            //   else if (item?.foodwaste_request_activestatus === 3)
            //     item.status = "Cancelled";
            // }
          });
        setDetails(result);
      });
  }
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };
  const updateStatus = (item) => {
    axios
      .put(`${API_URL}foodwaste/follow_ups/` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          handleTableData();
        }
        // } else {
        //   toastr.error(res.data.message);
        //   return;
        // }
      });
  };
  // const updateStatus = (selected, item) => {
  //   let data = {
  //     status: selected.value,
  //     customer_id: item?.customer_id,
  //     foodwaste_request_id: item?._id,
  //   };
  //   axios
  //     .post(`${API_URL}foodwaste/requeststatus_update`, data, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         toastr.success("Status has been updated");
  //         handleTableData();
  //       }
  //     })
  //     .catch((err) => {
  //       toastr.error(err.response.data.message);
  //       return;
  //     });
  // };
  const fetch_all_customers = (localbody, query) => {
    let data = {
      keyword: query,
      localbody_id: [localbody],
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const handleCustomerSearch = (query) => {
    let data = {
      keyword: query,
      localbody_id: currentLocalbody,
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const fetch_all_methods = () => {
    axios
      .get(`${API_URL}foodwaste/requirelist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let method_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.require_name,
              value: el?._id,
            };
          });
        setProcessingMethodOptions([
          {
            options: method_data,
          },
        ]);
      });
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }

  const handleSelectChange = (selected, name) => {
    let ward_id = master?.ward_id ? master.ward_id : "";
    let customer_id = master?.customer_id ? master.customer_id : "";
    let item_id = master?.foodwaste_request_require
      ? master.foodwaste_request_require
      : "";
    let date1 = master?.from_date ? master.from_date : "";
    let date2 = master?.to_date ? master.to_date : "";
    switch (name) {
      case "customer":
        setSelectedCustomer(selected);
        setMaster({
          ...master,
          customer_id: selected.value,
        });
        handleTableData(ward_id, selected.value, item_id, date1, date2);
        break;
      case "processingMethod":
        setSelectedProcessingMethod(selected);
        setMaster({
          ...master,
          foodwaste_request_require: selected.value,
        });
        handleTableData(ward_id, customer_id, selected.value, date1, date2);
        break;
      case "ward":
        setSelectedWard(selected);
        setMaster({
          ...master,
          ward_id: selected.value,
        });
        handleTableData(selected.value, customer_id, item_id, date1, date2);
        break;
      default:
        break;
    }
  };
  //   const handleValidSubmit = () => {
  //     if (selectedCustomer === null || selectedProcessingMethod === null) {
  //       let errorValue = errors;
  //       if (selectedCustomer === null) {
  //         errorValue.customerError = "Please select customer";
  //       }
  //       if (selectedProcessingMethod === null) {
  //         errorValue.processingMethodError = "Please select processing method";
  //       }
  //       setErrors({
  //         ...errorValue,
  //       });
  //     } else {
  //       axios
  //         .post(`${API_URL}foodwaste/request`, master, {
  //           headers: {
  //             "x-access-token": accessToken,
  //           },
  //         })
  //         .then((res) => {
  //           if (res.data.success === true) {
  //             toastr.success("Food waste request addedd successfully");
  //             formRef.current.reset();
  //             handleTableData();
  //             setErrors({
  //               categoryError: "",
  //               requestToError: "",
  //             });
  //             setMaster({
  //               localbody_id: getLocalbody(),
  //               foodwaste_request_remarks: "",
  //             });
  //             setSelectedCustomer(null);
  //             setSelectedProcessingMethod(null);
  //           } else {
  //             toastr.error(res.data.message, "Failed to add food waste request");
  //             return;
  //           }
  //         })
  //         .catch((err) => {
  //           toastr.error(err.response.data.message);
  //           return;
  //         });
  //     }
  //   };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Customer",
        field: "customer_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ward",
        field: "ward_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Item",
        field: "require",
        sort: "asc",
        width: 200,
      },
    
      {
        label: "Staff",
        field: "staff_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      //   {
      //     label: "Action",
      //     field: "action",
      //     sort: "asc",
      //     width: 200,
      //   },
    ],
    rows: details,
  };
  //   const handleInputChange = (e) => {
  //     setMaster({
  //       ...master,
  //       [e.target.name]: e.target.value,
  //     });
  //   };
  const reset = () => {
    setSelectedCustomer(null);
    setSelectedProcessingMethod(null);
    setSelectedWard(null);
    setMaster({
      from_date: "",
      to_date: "",
    });
    handleTableData();
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let ward_id = master?.ward_id ? master.ward_id : "";
    let customer_id = master?.customer_id ? master.customer_id : "";
    let item_id = master?.foodwaste_request_require
      ? master.foodwaste_request_require
      : "";

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(ward_id, customer_id, item_id, date1, date2);
  };
  const handleSearch = () => {
    let ward_id = master?.ward_id ? master.ward_id : "";
    let group_id = master?.group_id ? master.group_id : "";
    let date1 = master?.from_date ? master.from_date : "";
    let date2 = master?.to_date ? master.to_date : "";
    let category_id = master?.category_id ? master.category_id : "";
    handleTableData(date1, date2, ward_id, group_id, category_id);
  };
  const customStyles = {
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
    }),
  };
  return (
    <React.Fragment>
      {/* {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}workrequest?workrequest_id=` + requestIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Request deleted successfully");
                  if (master && master._id === requestIdToBeDeleted) {
                    formRef.current.reset();
                    setErrors({
                      categoryError: "",
                      requestToError: "",
                    });
                    setMaster({
                      workrequest_localbody: getLocalbody(),
                      workrequest_remarks: "",
                    });
                    setSelectedCustomer(null);
                    setSelectedProcessingMethod(null);
                  }
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete request");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null} */}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Food Waste Request" />
          {/* <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Customer</Label>
                          <Select
                            name="customer_id"
                            value={selectedCustomer}
                            onChange={(selected) => {
                              handleSelectChange(selected, "customer");
                            }}
                            options={customerOptions}
                            onInputChange={(input) =>
                              handleCustomerSearch(input)
                            }
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedCustomer === null
                              ? errors.customerError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Processing Method</Label>
                          <Select
                            name="foodwaste_request_require"
                            value={selectedProcessingMethod}
                            onChange={(selected) => {
                              handleSelectChange(selected, "processingMethod");
                            }}
                            options={processingMethodOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedProcessingMethod === null
                              ? errors.processingMethodError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            name="foodwaste_request_remarks"
                            label="Remarks"
                            type="textarea"
                            // errorMessage="Enter Remarks"
                            className="form-control"
                            id="validationCustom02"
                            value={master?.foodwaste_request_remarks}
                            // validate={{ required: { value: true } }}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="mb-3 mt-3"
                          style={{ paddingTop: "10px" }}
                        >
                          <Button color="primary" type="submit">
                            {"Submit"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={master?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={master?.to_date}
                          min={master?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Customer</Label>
                        <Select
                          styles={customStyles}
                          name="customer_id"
                          value={selectedCustomer}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customer");
                          }}
                          options={customerOptions}
                          onInputChange={(input) => handleCustomerSearch(input)}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          styles={customStyles}
                          name="ward"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Item</Label>
                        <Select
                          name="foodwaste_status_method"
                          value={selectedProcessingMethod}
                          onChange={(selected) => {
                            handleSelectChange(selected, "processingMethod");
                          }}
                          options={processingMethodOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="1" style={{ paddingTop: "20px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="primary"
                          type="submit"
                          //onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </div>
                    </Col>

                    <Col md="1" style={{ paddingTop: "15px" }}>
                      <div className="mb-2 mt-3" >
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          {"Reset"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <MDBDataTable
                      id="foodWasteRequestID"
                      responsive
                      bordered
                      data={data}
                      info={true}
                      searching={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default FoodWasteRequest;
