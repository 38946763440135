import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import {
  getLocalbody,
  setAssignCust,
  getAssignCust,
} from "../../../helpers/globalFunctions";
import { getCustomerType } from "../../../store/actions";
import $ from "jquery";
import "./assignGroup.scss";
import SweetAlert from "react-bootstrap-sweetalert";

const AssignGroup = (props) => {
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [errors, setErrors] = useState({
    groupError: "",
  });
  // const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [assignCustomer, setAssignCustomer] = useState([]);
  const [selectedWardSearch, setSelectedWardSearch] = useState(null);
  const [selectedTypeSearch, setSelectedTypeSearch] = useState(null);
  // const [selectedWard, setSelectedWard] = useState(null);
  const [searchGroupOptions, setSearchGroupOptions] = useState([]);
  const [selectedGroupSearch, setSelectedGroupSearch] = useState(null);
  const [selectedCustomerTypeSearch, setSelectedCustomerTypeSearch] =
    useState(null);
  const [selectedPackageSearch, setSelectedPackageSearch] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [wardOptions, setWardOptions] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);
  const [customerType, setCustomerType] = useState([]);
  const [assignGroup, setAssignGroup] = useState(false);
  const { customerTypes } = useSelector((state) => state.customers);
  const [selectedStaff, setselectedStaff] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [isGetData, setIsGetData] = useState(true);
  const formRef = useRef();
  const [confirm, setConfirm] = useState(false);
  const API_URL = process.env.REACT_APP_APIURL;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    handleTableData();
    // fetch_all_group(currentLocalbody);
    dispatch(getCustomerType());
    fetch_all_packages(currentLocalbody);
    fetch_all_wards(currentLocalbody);
    setAssignCust("");
    fetch_all_staff(currentLocalbody);
  }, [currentLocalbody]);


  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }

  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/operation-base?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  }

  function fetch_all_packages(localbody_id) {
    axios
      .get(
        `${API_URL}api/staff/packages?localbody_id=` +
        localbody_id,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          var package_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.package_name,
                value: el._id,
              };
            });
        } else package_data = [];

        setPackageOptions([
          {
            options: package_data,
          },
        ]);
      });
  }
  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);
  const handleAllCheckboxChange = (e) => {
    let data = [...details];
    let values = [];
    if (e.target.checked === true) {
      $("input[type=checkbox]").prop("checked", true);
      data &&
        data.map((item) => {
          values.push(item._id);
        });
      setAssignCust(values);
    } else {
      $("input[type=checkbox]").prop("checked", false);
      values = [];
      setAssignCust("");
    }
    setAssignCustomer(values);
  };
  const handleTableCheckboxChange = (e, item, index) => {
    $('input[type="checkbox"][name="headerCheckbox"]').prop("checked", false).trigger("change");

    let dataIndex = null;
    let val = getAssignCust();
    let arr = null;
    if (val) {
      arr = val.split(",");
    }
    let values = [];
    if (arr !== null) {
      values = arr;
    }

    if (e.target.checked === true) {
      values.push(item._id);
    } else {
      values &&
        values.map((value, index) => {
          if (value === item?._id) dataIndex = index;
        });
      values.splice(dataIndex, 1);
    }
    setAssignCustomer(values);
    setAssignCust(values);
  };

  function handleTableData(
    ward_id = "",
    group_id = "",
    cust_type_id = "",
    staff_id = "",
    package_id = "",
    withGroup = ""
  ) {
    setIsGetData(true);
    var url = `${API_URL}customers/list?localbody_id=` + currentLocalbody;

    url =
      url +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&cust_type_id=" +
      cust_type_id +
      "&staff_id=" +
      staff_id +
      "&package_id=" +
      package_id +
      "&withGroup=" +
      withGroup;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setIsGetData(false);
        var result = res.data.data;
        result.map((item, index) => {
          item.id = index + 1;
          item.localbody_name = getLocalbody();
          item.type = item?.cust_type_name;
          item.district_name = item?.district_name;
          item.ward_name = item?.ward_name;

          item.checkbox = (
            <input
              type="checkbox"
              id="tableCheckbox"
              style={{ cursor: "pointer" }}
              onClick={(e) => handleTableCheckboxChange(e, item, index)}
            ></input>
          );
          if (item.cust_verification_status === 1) {
            item.status = (
              <h6
                style={{
                  color: "green",
                }}
              >
                {"Verified"}
              </h6>
            );
          } else {
            item.status = (
              <h6
                style={{
                  color: "red",
                }}
              >
                {"Not Verified"}
              </h6>
            );
          }
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/Customer/" + item._id);
                }}
              ></i>
            </div>
          );
        });
        setDetails(result);
        setAssignCust("");
        setAssignCustomer([]);
        $("input[type=checkbox]").prop("checked", false);
      });
  }

  // const fetch_all_group = (localbody) => {
  //   axios
  //     .get(`${API_URL}user/local_group?localbody_id=` + localbody, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data && res.data.success === true) {
  //         var group_data =
  //           res.data.data &&
  //           res.data.data.map((el) => {
  //             return {
  //               label: el.group_name,
  //               value: el._id,
  //               ward: el.group_ward,
  //             };
  //           });
  //       } else {
  //         group_data = [];
  //       }

  //       setGroupOptions([
  //         {
  //           options: group_data,
  //         },
  //       ]);
  //     });
  // };

  const handleValidSubmit = () => {
    setConfirm(false);
    setAssignGroup(true);
    let data = {
      group_id: master?.group_id,
      cust_id: assignCustomer,
    };
    axios
      .put(`${API_URL}customers/assign_group`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          toastr.success("Group assigned successfully");
          setSelectedGroup(null);
          // setSelectedWard(null);
          setMaster({});
          setAssignCustomer([]);
          setErrors({
            groupError: "",
          });
          handleTableData();
          $("input[type=checkbox]").prop("checked", false);

          setSearchData({});
          setSelectedCustomerTypeSearch(null);
          setSelectedWardSearch(null);
          setSelectedGroupSearch(null);
          setSelectedPackageSearch(null);
          setSearchGroupOptions([]);
          setAssignCust("");
          setAssignGroup(false);
        } else {
          setAssignGroup(false);
          toastr.error("Failed to assign group");
          return;
        }
      })
      .catch((err) => {
        setAssignGroup(false);
        toastr.error(err.response.data.message);
        return;
      });
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      // case "ward":
      //   setSelectedWard(selected);
      //   axios
      //     .get(
      //       `${API_URL}api/staff/groups?localbody_id=` +
      //         currentLocalbody +
      //         "&ward_id=" +
      //         selected.value,
      //       {
      //         headers: {
      //           "x-access-token": accessToken,
      //         },
      //       }
      //     )
      //     .then((res) => {
      //       if (res.data && res.data.success === true) {
      //         var group_data =
      //           res.data.data &&
      //           res.data.data.map((el) => {
      //             return {
      //               label: el.group_name,
      //               value: el._id,
      //             };
      //           });
      //       } else {
      //         group_data = [];
      //       }

      //       setGroupOptions([
      //         {
      //           options: group_data,
      //         },
      //       ]);
      //     });
      //   setMaster({
      //     ...master,
      //     ward_id: selected.value,
      //   });
      //   break;
      case "group":
        setSelectedGroup(selected);
        setErrors({
          groupError: "",
        });
        setMaster({
          ...master,
          group_id: selected.value,
        });
        break;
      default:
        break;
    }
  };

  const data = {
    columns: [
      {
        label: (
          <input
            type="checkbox"
            name="headerCheckbox"
            id="headerCheckbox"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleAllCheckboxChange(e)}
          ></input>
        ),
        field: "checkbox",
        sort: "asc",
        width: 100,
      },
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reg No",
        field: "cust_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "cust_name",
        width: 300,
      },
      {
        label: "Phone",
        field: "cust_phone",
        width: 300,
      },
      {
        label: "Ward",
        field: "ward_name",
        width: 300,
      },
      {
        label: "Status",
        field: "status",
        width: 300,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };

  const handleSearchSelectChange = (selected, name) => {
    let cust_type_id = searchData?.cust_type_id ? searchData.cust_type_id : "";
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let package_id = searchData?.package_id ? searchData.package_id : "";
    let withGroup = searchData?.withGroup ? searchData.withGroup : "";
    switch (name) {
      case "customerType":
        setSelectedCustomerTypeSearch(selected);
        const selectedValues = selected.map(option => option.value)
        setSearchData({
          ...searchData,
          cust_type_id: selectedValues,
        });
        handleTableData(ward_id, group_id, selectedValues, staff_id, package_id, withGroup);
        break;
      case "ward":
        setSelectedWardSearch(selected);
        setSelectedGroup(null);
        setSelectedGroupSearch(null);
        setSearchData({
          ...searchData,
          ward_id: selected.value,
        });
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
            currentLocalbody +
            "&ward_id=" +
            selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setSearchGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        handleTableData(selected.value, group_id, cust_type_id, staff_id, package_id, withGroup);
        break;
      case "group":
        setSelectedGroupSearch(selected);
        setSearchData({
          ...searchData,
          group_id: selected.value,
        });
        handleTableData(ward_id, selected.value, cust_type_id, staff_id, package_id, withGroup);
        break;
      case "staff":
        setselectedStaff(selected);
        setSearchData({
          ...searchData,
          staff_id: selected.value,
        });
        handleTableData(ward_id, group_id, cust_type_id, selected.value, package_id, withGroup);
        break;
      case "package":
        setSelectedPackageSearch(selected);
        setSearchData({
          ...searchData,
          package_id: selected.value,
        });
        handleTableData(ward_id, group_id, cust_type_id, staff_id, selected.value, withGroup);
        break;
      case "withGroup":
        setSelectedTypeSearch(selected)
        setSearchData({
          ...searchData,
          withGroup: selected.value
        })
        handleTableData(ward_id, group_id, cust_type_id, staff_id, package_id, selected.value)
        break;

      default:
        break;
    }
  };
  const reset = () => {
    setSearchData({});
    setSelectedCustomerTypeSearch(null);
    setSelectedGroup(null);
    setSelectedWardSearch(null);
    setSelectedGroupSearch(null);
    setselectedStaff(null)
    setSelectedPackageSearch(null);
    setSelectedTypeSearch(null)
    setSearchGroupOptions([]);
    setAssignCustomer([]);
    $("input[type=checkbox]").prop("checked", false);
    handleTableData();
    setErrors({
      groupError: "",
    });
    setAssignCust("");
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Assign Group" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      if (selectedGroup === null) {
                        setErrors({
                          groupError: "Please select group",
                        });
                        return;
                      }
                      if (assignCustomer && assignCustomer.length <= 0) {
                        toastr.error("Please select customer");
                        return;
                      }
                      setConfirm(true);
                    }}
                  >
                    <Row className="mb-3">
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="ward_id"
                            value={selectedWardSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "ward");
                            }}
                            options={wardOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Group Type</Label>
                          <Select
                            name="withGroup "
                            value={selectedTypeSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "withGroup")
                            }}
                            options={[
                              { label: "Assigned Group", value: 2 },
                              { label: "UnAssigned Group", value: 1 }
                            ]}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Customer Type</Label>
                          <Select
                            isMulti
                            name="cust_type_id"
                            value={selectedCustomerTypeSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(
                                selected,
                                "customerType"
                              );
                            }}
                            options={customerType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Group</Label>
                          <Select
                            name="group_id"
                            value={selectedGroupSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "group");
                            }}
                            options={searchGroupOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            name="staff_id"
                            value={selectedStaff}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "staff");
                            }}
                            options={staffOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Package</Label>
                          <Select
                            name="package_id"
                            value={selectedPackageSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "package");
                            }}
                            options={packageOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="1">
                        <div
                          className="mt-4 mb-3"
                        >
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="ward_id"
                            value={selectedWard}
                            onChange={(selected) => {
                              handleSelectChange(selected, "ward");
                            }}
                            options={wardOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Group</Label>
                          <Select
                            name="cust_type"
                            value={selectedGroup}
                            onChange={(selected) => {
                              handleSelectChange(selected, "group");
                            }}
                            // options={groupOptions}
                            options={searchGroupOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedGroup === null ? errors.groupError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col
                        md="1"
                        className="mt-4"
                        style={{ paddingTop: "5px" }}
                      >
                        <div>
                          {assignGroup === false ? (
                            <Button color="primary" type="submit">
                              Assign Group
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={true}
                            >
                              Processing
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="assignGroupTableId"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {confirm && (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleValidSubmit();
          }}
          onCancel={() => setConfirm(false)}>
          Are you sure you want to assign {assignCustomer.length} customers in group {selectedGroup?.label} ?
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

export default AssignGroup;
