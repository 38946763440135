import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";

const AddSurvey = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const formRef = useRef();

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Survey",
        field: "survey",
        sort: "asc",
        width: 200,
      },
      {
        label: "Sub Title",
        field: "sub_title",
        sort: "asc",
        width: 200,
      },
      {
        label: "Survey Link",
        field: "survey_link",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        width: 300,
      },
    //   {
    //     label: "Change Status",
    //     field: "change_status",
    //     width: 300,
    //   },
      {
        label: "Status",
        field: "status",
        width: 300,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const reset = () => {
    formRef.current.reset();
    setMaster({});
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Add Survey" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    // onValidSubmit={(e, v) => {
                    //   handleValidSubmit(e, v);
                    // }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            name="survey_name"
                            label="Survey Name"
                            type="text"
                            errorMessage="Enter survey name"
                            className="form-control"
                            id="validationCustom02"
                            value={master?.survey_name}
                            validate={{ required: { value: true } }}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            name="sub_title"
                            label="Sub Title"
                            type="text"
                            errorMessage="Enter sub title"
                            className="form-control"
                            id="validationCustom02"
                            value={master?.sub_title}
                            validate={{ required: { value: true } }}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col
                        md="3"
                        className="mt-4"
                        style={{ paddingTop: "4px" }}
                      >
                        <div>
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                          <Button
                            color="danger"
                            type="reset"
                            style={{ marginLeft: "14px" }}
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="addSurveyTableID"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AddSurvey;
