import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

import {
  apiError,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody } from "../../../helpers/globalFunctions";
import "./customerCategory.scss";

const CustomerCategory = (props) => {
  const [categoryObject, setCategoryObject] = useState({});
  const [categoryIdTobeUpdated, setCategoryIdToBeUpdated] = useState(null);
  const [categoryIdToBeDeleted, setCategoryIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [categoriesForTable, setCategoriesForTable] = useState([]);
  const [categoryValue, setcategoryValue] = useState("");

  const [type, setType] = React.useState("");
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const {
  } = useSelector((state) => state.districts);
  const {
    addingCategory,
  } = useSelector((state) => state.customerCategory);

  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    handleTableData(currentLocalbody);
  }, [currentLocalbody]);


  let preUpdateCategory = (item) => {
    if (item.customer_type_name) {
      setcategoryValue(item.customer_type_name);
      item.category_name = item.customer_type_name;
    }
    if(item.type==1)
    {
      var type = {label:"Residential",value:1}
    }
    else
    {
      var type = {label:"Commercial",value:2}
    }
    setType(type)
    setCategoryIdToBeUpdated(item._id);
    setCategoryObject({ ...item, password: null });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  function handleTableData() {
    var url =
      `${API_URL}customerCategory/list?localbody_id=` + currentLocalbody;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let categoryData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  preUpdateCategory(item);
                  toTop();
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setCategoryIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          item.date = moment(item.customer_type_date).format("DD-MM-YYYY");
          item.time = moment(item.customer_type_time, "HHmmss").format(
            "hh:mm a"
          ); // 24hours
          if(item.type==1)
          {
            item.type_name="Residential";
          }
          else
          {
            item.type_name="Commercial";
          }

          if(item?.customer_type_updatedby){
            item.staff = item.customer_type_updatedby.firstName;
          }
          else{
            if (item.customer_type_addedby != null) {
              item.staff = item.customer_type_addedby.firstName;
            }
          }
          // if (item.customer_type_addedby != null) {
          //   item.staff = item.customer_type_addedby.firstName;
          // }
          categoryData.push(item);
        });
        setCategoriesForTable(categoryData);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        //sort: "desc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        //sort: "desc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        //sort: "desc",
        width: 150,
      },

      {
        label: "Category",
        field: "customer_type_name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Type",
        field: "type_name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },

      {
        label: "Action",
        field: "action",
        width: 50,
      },
    ],
    rows: categoriesForTable,
  };

  function handleChangeCategory(e) {
    let name = e.target.name;
    let value = e.target.value;
    setcategoryValue(value);
    setCategoryObject({ ...categoryObject, [name]: value });
  }
  const handleChange = (selected) => {
    setType(selected);
    setCategoryObject({ ...categoryObject, ["type"]: selected.value });
  };

  const handleValidSubmit = () => {
    //return;
    if (categoryIdTobeUpdated) {
      axios
        .put(
          `${API_URL}customerCategory` + "/" + categoryIdTobeUpdated,
          categoryObject,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Customer category updated successfully");
            setcategoryValue("");
            formRef.current.reset();
            handleTableData();
            setCategoryObject({});
            setType("");
            setCategoryIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update Customer category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}customerCategory`, categoryObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Customer category added successfully");
            setcategoryValue("");
            handleTableData();
            setCategoryObject({});
            setType("");
            formRef.current.reset();
          } else {
            toastr.error("Failed to add Customer category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          // onConfirm={() => {
          //   dispatch(deleteCategory(categoryIdToBeDeleted));
          //   setConfirmDeleteAlert(false);
          // }}
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}customerCategory` + "/" + categoryIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Customer category deleted successfully");

                  if (
                    categoryObject &&
                    categoryObject._id === categoryIdToBeDeleted
                  ) {
                    setCategoryIdToBeUpdated(null);
                    formRef.current.reset();
                    setcategoryValue("");
                    setCategoryObject({});
                  }
                  handleTableData();
                } else {
                  toastr.error(res.data.message,"Failed to delete Customer category");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Customer Category" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Category Name
                          </Label>
                          <AvField
                            name="category_name"
                            placeholder="Category name"
                            type="text"
                            errorMessage="Enter Category Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={categoryValue}
                            onChange={handleChangeCategory}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Category Type
                          </Label>
                          <Select
                              name="type"
                              value={type}
                              onChange={(selected) => {
                                handleChange(selected);
                              }}
                              options={[
                                {
                                  label: "Residential",
                                  value: 1,
                                },
                                { label: "Commercial", value: 2 },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "26px" }}>
                          {categoryIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingCategory ? true : false}
                            >
                              {addingCategory ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingCategory ? true : false}
                            >
                              {addingCategory ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="customerCatTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(
  connect(mapStateToProps, { apiError })(CustomerCategory)
);

CustomerCategory.propTypes = {
  error: PropTypes.any,
  categories: PropTypes.array,
};
