import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Table } from "reactstrap";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";
import { apiError } from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody, getDaysInMonth } from "../../../helpers/globalFunctions";
import "./Salary_Calculation.scss";
import { getStaffSalary, getAllFundSource } from "../../../store/actions";
import CreateIcon from "@mui/icons-material/Create";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import { AccessTimeSharp, DetailsSharp } from "@mui/icons-material";

const Salary = (props) => {
  const [show, setShow] = useState(false);
  const [selectedFundSource, setSelectedFundSource] = useState(null);
  const [salaryToBeDeleted, setsalaryToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [details, setDetails] = useState([]);
  const [master, setMaster] = useState([]);
  const [salaryList, setsalaryList] = useState([]);
  const [salaryslipList, setsalaryslipList] = useState([]);
  const [value, setValue] = useState("");
  const [days, setDays] = useState("");
  const [work_days, setWorkDays] = useState("");
  const [sunday_days, setSundayDays] = useState("");
  const [singleObject, setSingleObject] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [payOption, setpayOption] = useState("none");
  const [isAllCheckbox, setIsAllCheckbox] = useState(false);
  const [fundSourceOptions, setFundSourceOptions] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [selectedSalary, setselectedSalary] = useState({});
  const { staffsalaries, error } = useSelector((state) => state.staffSalaries);
  const { fundSource } = useSelector((state) => state.fundTransfers);
  const [editedExtraPayment, setEditedExtraPayment] = useState("");
  const dispatch = useDispatch();
  const [extraPaymentVisible, setExtraPaymentVisible] = useState(false);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const onFocus = (e) => {
    e.target.select();
  };
  useEffect(() => {
    handleTableData();
    let data = JSON.parse(JSON.stringify(staffsalaries));
    var staffData = [];
    data.map((item, index) => {
      item.id = index + 1;
      var halfday = item.total_half / 2;
      item.total_present = item.total_present + halfday;
      var holiday_total_halfday = item.holiday_halfday / 2;
      item.holiday_total_present = item.holiday_present + holiday_total_halfday;
      item.grand_present = item.total_present + item.holiday_total_present;
      item.extra_days = item.holiday_total_present;
      item.total_absent = item.total_absent + halfday;

      var totalday = parseFloat(item.total_present) + parseFloat(item.total_absent);
      if (totalday < work_days) {
        var diffrence = work_days - totalday;
        item.total_absent = item.total_absent + diffrence;
      }

      // item.total_absent = item.total_absent+halfday;
      if (item.salary_type == "Day") {
        var day_salary = item.salary;
        var gross_salary = work_days * day_salary;
      } else {
        var day_salary = item.salary / days;
        var gross_salary = days * day_salary;
      }
      // var temp_absent=item.total_absent;
      // item.total_absent=item.total_absent-item.extra_days;
      // if(item.total_absent<0)
      // {
      //   item.total_absent=0
      // }

      if (parseFloat(item.total_absent) >= parseFloat(item.total_present)) {
        item.calc_salary = parseFloat(item.total_present) * day_salary;
        item.lop = (gross_salary - parseFloat(item.calc_salary)).toFixed(2);
        item.lops = item.lop;
        item.calc_salarys = item.calc_salary.toFixed(2);
      } else {
        if (item.total_present >= work_days) {
          item.calc_salary = gross_salary;
          //item.lop = (day_salary*item.total_absent).toFixed(2);
          item.lop = (gross_salary - parseFloat(item.calc_salary)).toFixed(2);
          item.lops = item.lop;
          item.calc_salarys = item.calc_salary.toFixed(2);
        } else {
          // item.total_absent=item.total_absent-item.extra_days;
          item.lop = (day_salary * item.total_absent).toFixed(2);
          //item.calc_salary = (item.total_present*day_salary).toFixed(2);

          item.calc_salary = gross_salary - parseFloat(item.lop);

          item.lops = (day_salary * (item.total_absent - item.extra_days)).toFixed(2);
          item.lops = Math.abs(item.lops);
          item.calc_salarys = (gross_salary - parseFloat(item.lops)).toFixed(2);
        }
      }
      // item.total_absent=temp_absent;
      item.actual_extra_payment = (item.holiday_total_present * day_salary).toFixed(2);
      item.extra_payment = item.actual_extra_payment;
      // item.extraPayment = (
      //   <>
      //     <input
      //       name="extra_pay[]"
      //       value={item?.extra_payment}
      //       style={{ textAlign: "right" }}
      //       className="form-control"
      //       onChange={(e) =>
      //         handleExtraPayment(e, index)
      //       }
      //       type="text"
      //     />
      //   </>
      // );
      if (item.monthly_incentive_amount > 0) {
        item.actual_incentive = item.monthly_incentive_amount.toFixed(2);
        item.incentive = item.actual_incentive;
      } else {
        item.actual_incentive = item.daily_incentive_amount.toFixed(2);
        item.incentive = item.actual_incentive;
      }
      // item.incentiveAmount= (
      //   <>
      //     <input
      //       value={item.incentive}
      //       style={{ textAlign: "right" }}
      //       className="form-control"
      //       type="text"
      //     />
      //   </>
      // )
      item.net_salary = parseFloat(item.calc_salary) + parseFloat(item.extra_payment) + parseFloat(item.incentive);
      item.net_salary = parseFloat(item.net_salary);
      if (item.net_salary >= parseFloat(item.advance_balance)) {
        item.advance_due = 0;
        item.to_pay = parseFloat(item.net_salary) - parseFloat(item.advance_balance);
      } else {
        item.to_pay = 0;
        item.advance_due = parseFloat(item.advance_balance) - parseFloat(item.net_salary);
      }
      // item.checkbox = (
      //   <>
      //     <input
      //       type="checkbox"
      //       id="tableCheckbox"
      //       style={{ cursor: "pointer" }}
      //     ></input>
      //   </>
      // );
      // item.attendance = (
      //   <>
      //   <Row>
      //     <Col xl="12">
      //       <Row>
      //         <table>
      //           <thead>
      //             <tr>
      //               {/* <td>LOP</td> */}
      //               <td style={tableStyle}>{item.grand_present}</td>
      //               <td style={tableStyle}>{item.total_absent}</td>
      //             </tr>
      //           </thead>
      //         </table>
      //       </Row>
      //     </Col>
      //   </Row>
      // </>
      // )

      item.salary_name = item.salary + "/" + item.salary_type;

      item.calc_salary = item.calc_salary.toFixed(2);
      item.advance_balance = item.advance_balance.toFixed(2);
      item.to_pay = Math.round(item.to_pay);

      if (item.emi_hold_status == 0) {
        item.total_payable = Math.ceil(item.to_pay) - Math.ceil(item.emi_amount_permonth);
      } else if (item.emi_hold_status == 1) {
        item.total_payable = Math.ceil(item?.to_pay);
      }

      item.isChecked = "";
      item.extraPayment = (
        <>
          <input
            name="extra_pay[]"
            onFocus={onFocus}
            defaultValue={Math.round(item?.extra_payment)}
            style={{ textAlign: "right" }}
            className="form-control"
            onBlur={(e) => handleExtraPayment(e, index, item)}
            type="text"
            disabled={item.isChecked ? "" : "disabled"}
            key={`extra_pay-${index}`}
          />
        </>
      );
      item.incentiveAmount = (
        <>
          <input
            name="incentives[]"
            onFocus={onFocus}
            defaultValue={Math.round(item.incentive)}
            style={{ textAlign: "right" }}
            className="form-control"
            onBlur={(e) => handleExtraPayment(e, index, item)}
            type="text"
            disabled={item.isChecked ? "" : "disabled"}
            key={`incentives-${index}`}
          />
        </>
      );
      item.checkbox = (
        <>
          <input
            type="checkbox"
            id="tableCheckbox"
            style={{ cursor: "pointer" }}
            checked={item.isChecked}
            onClick={(e) => handleSingleCheckboxChange(e, item)}></input>
        </>
      );
      item.attendance = (
        <>
          <Row>
            <Col xl="12">
              <Row>
                <table>
                  <thead>
                    <tr>
                      {/* <td>LOP</td> */}
                      <td style={tableStyle}>{item.grand_present}</td>
                      <td style={tableStyle}>{item.total_absent}</td>
                    </tr>
                  </thead>
                </table>
              </Row>
            </Col>
          </Row>
        </>
      );
      staffData.push(item);
    });
    setDetails(staffData);
  }, [staffsalaries]);

  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the input field
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        handleClickOpenModal();
      }
    };

    // Attach the event listener when the input is visible
    if (extraPaymentVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remove the event listener when the component is unmounted or input is not visible
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [extraPaymentVisible]);

  // useEffect(() => {
  //   handleTableData();
  //   let data = JSON.parse(JSON.stringify(staffsalaries));
  //   var staffData = [];
  //   data.map((item, index) => {
  //     if (item.emi_hold_status == 0) {
  //       item.total_payable =
  //         Math.ceil(item.to_pay) - Math.ceil(item.emi_amount_permonth);
  //     } else if (item.emi_hold_status == 1) {
  //       item.total_payable = Math.ceil(item?.to_pay);
  //     }

  //     // item.isChecked = "";
  //     item.extraPayment = (
  //       <>
  //         <input
  //           name="extra_pay[]"
  //           onFocus={onFocus}
  //           value={Math.round(item?.extra_payment)}
  //           style={{ textAlign: "right" }}
  //           className="form-control"
  //           onChange={(e) => handleCreateClick(e, index, item)}
  //           type="text"
  //           disabled={item.isChecked ? "" : "disabled"}
  //         />
  //       </>
  //     );
  //     item.incentiveAmount = (
  //       <>
  //         <input
  //           name="incentives[]"
  //           onFocus={onFocus}
  //           value={Math.round(item.incentive)}
  //           style={{ textAlign: "right" }}
  //           className="form-control"
  //           onChange={(e) => handleCreateClick(e, index, item)}
  //           type="text"
  //         // disabled={item.isChecked ? "" : "disabled"}
  //         />
  //       </>
  //     );
  //     // item.checkbox = (
  //     //   <>
  //     //     <input
  //     //       type="checkbox"
  //     //       id="tableCheckbox"
  //     //       style={{ cursor: "pointer" }}
  //     //       checked={item.isChecked}
  //     //       onClick={(e) => handleSingleCheckboxChange(e, item)}
  //     //     ></input>
  //     //   </>
  //     // );
  //     item.attendance = (
  //       <>
  //         <Row>
  //           <Col xl="12">
  //             <Row>
  //               <table>
  //                 <thead>
  //                   <tr>
  //                     {/* <td>LOP</td> */}
  //                     <td style={tableStyle}>{item.grand_present}</td>
  //                     <td style={tableStyle}>{item.total_absent}</td>
  //                   </tr>
  //                 </thead>
  //               </table>
  //             </Row>
  //           </Col>
  //         </Row>
  //       </>
  //     );
  //     staffData.push(item);
  //   });
  //   setDetails(staffData);
  // }, [staffsalaries]);

  useEffect(() => {
    dispatch(getAllFundSource(currentLocalbody));
    fetch_all_staff(currentLocalbody);
  }, [currentLocalbody]);
  useEffect(() => {
    const updatedDetails = details.map((item, index) => {
      item.isChecked = "";
      if (master.find((row) => row.staff_id.toString() == item.staff_id.toString()) !== undefined) {
        item.isChecked = "checked";
      }
      item.extraPayment = (
        <>
          <input
            name="extra_pay[]"
            onFocus={onFocus}
            defaultValue={Math.round(item?.extra_payment)}
            style={{ textAlign: "right" }}
            className="form-control"
            onBlur={(e) => handleExtraPayment(e, index, item)}
            type="text"
            disabled={item.isChecked ? "" : "disabled"}
            key={`extra_pay-${index}`}
          />
        </>
      );
      item.incentiveAmount = (
        <>
          <input
            name="incentives[]"
            onFocus={onFocus}
            defaultValue={Math.round(item.incentive)}
            style={{ textAlign: "right" }}
            className="form-control"
            disabled={item.isChecked ? "" : "disabled"}
            onBlur={(e) => handleExtraPayment(e, index, item)}
            type="text"
            key={`incentives-${index}`}
          />
        </>
      );
      item.checkbox = (
        <>
          <input
            type="checkbox"
            id="tableCheckbox"
            style={{ cursor: "pointer" }}
            checked={item.isChecked}
            onClick={(e) => handleSingleCheckboxChange(e, item)}></input>
        </>
      );

      return item;
    });
    setDetails(updatedDetails);
  }, [master]);
  // useEffect(() => {
  //   var data =
  //     fundSource &&
  //     fundSource.map((item) => {
  //       return {
  //         label: item.acc_chart_name + " - " + item.acc_type_id.acc_type_name,
  //         value: item._id,
  //       };
  //     });

  //   setFundSourceOptions([
  //     {
  //       options: data,
  //     },
  //   ]);
  // }, [fundSource]);

  const fetch_all_staff = (currentLocalbody) => {
    axios
      .get(`${API_URL}user/priv_options?localbody_id=` + currentLocalbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setFundSourceOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };

  const tableStyle = {
    textAlign: "center",
  };
  const reset = () => {
    setValue("");
    setDays("");
    setWorkDays("");
    setSundayDays("");
    setShow(false);
    setDetails([]);
    formRef.current.reset();
  };
  const handleChangeDate = (e) => {
    setValue(e.target.value);
    const date = new Date(e.target.value + "-01");
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    var total_days = getDaysInMonth(currentYear, currentMonth);

    //var sundays = getDaysInMonthWithoutSunday(currentYear,currentMonth-1);

    setDays(total_days);
    // setWorkDays(work_days);
    // setSundayDays(sundays);
    axios
      .get(`${API_URL}holiday/count?month=` + e.target.value + "&localbody_id=" + currentLocalbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var sundays = res.data.data;
        setSundayDays(sundays);
        var work_days = total_days - sundays;
        setWorkDays(work_days);
      });
    //var work_days = total_days-sundays;
  };

  const handleChangeDays = (e) => {
    setWorkDays(e.target.value);
  };

  const handleCalculate = (e, v) => {
    setIsAllCheckbox(false);
    setMaster([]);
    $("input[type=checkbox]").prop("checked", false);
    dispatch(getStaffSalary(currentLocalbody, value, days, work_days));
    setShow(true);
  };

  const handleExtraPayment = (e, index, item) => {
    var all_data = master;
    if (e.target.name == "extra_pay[]") {
      // all_data.map(element => {
      //   if (element.staff_id === item.staff_id) {
      //     element.extra_payment = e.target.value;
      //     element.to_pay = parseFloat(element.to_pay) + parseFloat(element.extra_payment);
      //   }
      // });
      // setMaster(all_data);
      var type = "extra payment";
    } else {
      // all_data.map(element => {
      //   if (element.staff_id === item.staff_id) {
      //     element.incentive = e.target.value;
      //     element.to_pay = parseFloat(element.to_pay) + parseFloat(element.incentive);
      //   }
      // });
      // setMaster(all_data);
      var type = "extra incentive";
    }
    var staff_id = staffsalaries[index].staff_id;
    var month = value;
    var localbody = currentLocalbody;
    var amount = e.target.value;
    var staffData = [];
    axios
      .post(
        `${API_URL}salary/apply_extra_payment`,
        {
          staff_id: staff_id,
          month: month,
          localbody_id: localbody,
          amount: amount,
          type: type,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var data = res.data.data;
        data.map((item, index) => {
          item.id = index + 1;
          var halfday = item.total_half / 2;
          item.total_present = item.total_present + halfday;
          var holiday_total_halfday = item.holiday_halfday / 2;
          item.holiday_total_present = item.holiday_present + holiday_total_halfday;
          item.grand_present = item.total_present + item.holiday_total_present;
          item.extra_days = item.holiday_total_present;
          item.total_absent = item.total_absent + halfday;

          var totalday = parseFloat(item.total_present) + parseFloat(item.total_absent);
          if (totalday < work_days) {
            var diffrence = work_days - totalday;
            item.total_absent = item.total_absent + diffrence;
          }

          // item.total_absent = item.total_absent+halfday;
          if (item.salary_type == "Day") {
            var day_salary = item.salary;
            var gross_salary = work_days * day_salary;
          } else {
            var day_salary = item.salary / days;
            var gross_salary = days * day_salary;
          }
          // var temp_absent=item.total_absent;
          // item.total_absent=item.total_absent-item.extra_days;
          // if(item.total_absent<0)
          // {
          //   item.total_absent=0
          // }

          if (parseFloat(item.total_absent) >= parseFloat(item.total_present)) {
            item.calc_salary = parseFloat(item.total_present) * day_salary;
            item.lop = (gross_salary - parseFloat(item.calc_salary)).toFixed(2);
            item.lops = item.lop;
            item.calc_salarys = item.calc_salary.toFixed(2);
          } else {
            if (item.total_present >= work_days) {
              item.calc_salary = gross_salary;
              //item.lop = (day_salary*item.total_absent).toFixed(2);
              item.lop = (gross_salary - parseFloat(item.calc_salary)).toFixed(2);
              item.lops = item.lop;
              item.calc_salarys = item.calc_salary.toFixed(2);
            } else {
              // item.total_absent=item.total_absent-item.extra_days;
              item.lop = (day_salary * item.total_absent).toFixed(2);
              //item.calc_salary = (item.total_present*day_salary).toFixed(2);

              item.calc_salary = gross_salary - parseFloat(item.lop);

              item.lops = (day_salary * (item.total_absent - item.extra_days)).toFixed(2);
              item.lops = Math.abs(item.lops);
              item.calc_salarys = (gross_salary - parseFloat(item.lops)).toFixed(2);
            }
          }
          // item.total_absent=temp_absent;
          item.actual_extra_payment = (item.holiday_total_present * day_salary).toFixed(2);
          item.extra_payment = item.actual_extra_payment;

          if (typeof item.extra_given !== "undefined") {
            item.extra_payment = item.extra_given || 0;
          }

          if (item.monthly_incentive_amount > 0) {
            item.actual_incentive = item.monthly_incentive_amount.toFixed(2);
            item.incentive = item.actual_incentive;
          } else {
            item.actual_incentive = item.daily_incentive_amount.toFixed(2);
            item.incentive = item.actual_incentive;
          }
          if (typeof item.incentive_given !== "undefined") {
            item.incentive = item.incentive_given;
          }
          // item.incentiveAmount= (
          //   <>
          //     <input
          //       value={item.incentive}
          //       style={{ textAlign: "right" }}
          //       className="form-control"
          //       type="text"
          //     />
          //   </>
          // )
          item.net_salary = parseFloat(item.calc_salary) + parseFloat(item.extra_payment) + parseFloat(item.incentive);
          item.net_salary = parseFloat(item.net_salary);
          if (item.net_salary >= parseFloat(item.advance_balance)) {
            item.advance_due = 0;
            item.to_pay = parseFloat(item.net_salary) - parseFloat(item.advance_balance);
          } else {
            item.to_pay = 0;
            item.advance_due = parseFloat(item.advance_balance) - parseFloat(item.net_salary);
          }
          // item.checkbox = (
          //   <>
          //     <input
          //       type="checkbox"
          //       id="tableCheckbox"
          //       style={{ cursor: "pointer" }}
          //     ></input>
          //   </>
          // );
          // item.attendance = (
          //   <>
          //   <Row>
          //     <Col xl="12">
          //       <Row>
          //         <table>
          //           <thead>
          //             <tr>
          //               {/* <td>LOP</td> */}
          //               <td style={tableStyle}>{item.grand_present}</td>
          //               <td style={tableStyle}>{item.total_absent}</td>
          //             </tr>
          //           </thead>
          //         </table>
          //       </Row>
          //     </Col>
          //   </Row>
          // </>
          // )

          item.salary_name = item.salary + "/" + item.salary_type;

          item.calc_salary = item.calc_salary.toFixed(2);
          item.advance_balance = item.advance_balance.toFixed(2);
          item.to_pay = Math.round(item.to_pay);

          if (item.emi_hold_status == 0) {
            item.total_payable = Math.ceil(item.to_pay) - Math.ceil(item.emi_amount_permonth);
          } else if (item.emi_hold_status == 1) {
            item.total_payable = Math.ceil(item?.to_pay);
          }

          if (e.target.name == "extra_pay[]") {
            all_data.map((element) => {
              if (element.staff_id.toString() == staff_id.toString()) {
                element.extra_payment = amount;
              }
              if (element.staff_id.toString() == item.staff_id.toString()) {
                element.to_pay = item.to_pay;
              }
            });
          } else {
            all_data.map((element) => {
              if (element.staff_id.toString() == staff_id.toString()) {
                element.incentive = amount;
              }
              if (element.staff_id.toString() == item.staff_id.toString()) {
                element.to_pay = item.to_pay;
              }
            });
          }

          item.isChecked = "";

          if (master.find((row) => row.staff_id.toString() == item.staff_id.toString()) !== undefined) {
            item.isChecked = "checked";
          }

          item.extraPayment = (
            <>
              <input
                name="extra_pay[]"
                onFocus={onFocus}
                defaultValue={Math.round(item?.extra_payment)}
                style={{ textAlign: "right" }}
                className="form-control"
                onBlur={(e) => handleExtraPayment(e, index, item)}
                type="text"
                disabled={item.isChecked ? "" : "disabled"}
                key={`extra_pay-${index}`}
              />
            </>
          );
          item.incentiveAmount = (
            <>
              <input
                name="incentives[]"
                onFocus={onFocus}
                defaultValue={Math.round(item.incentive)}
                style={{ textAlign: "right" }}
                className="form-control"
                onBlur={(e) => handleExtraPayment(e, index, item)}
                type="text"
                disabled={item.isChecked ? "" : "disabled"}
                key={`incentives-${index}`}
              />
            </>
          );
          item.checkbox = (
            <>
              <input
                type="checkbox"
                id="tableCheckbox"
                style={{ cursor: "pointer" }}
                checked={item.isChecked}
                onClick={(e) => handleSingleCheckboxChange(e, item)}></input>
            </>
          );
          item.attendance = (
            <>
              <Row>
                <Col xl="12">
                  <Row>
                    <table>
                      <thead>
                        <tr>
                          {/* <td>LOP</td> */}
                          <td style={tableStyle}>{item.grand_present}</td>
                          <td style={tableStyle}>{item.total_absent}</td>
                        </tr>
                      </thead>
                    </table>
                  </Row>
                </Col>
              </Row>
            </>
          );
          staffData.push(item);
        });
        setMaster(all_data);
        setDetails(staffData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAllCheckboxChange = (e) => {
    if (e.target.checked === true) {
      setIsAllCheckbox(true);
      setMaster(details);
      $("input[type=checkbox]").prop("checked", true);
    } else {
      setIsAllCheckbox(false);
      setMaster([]);
      $("input[type=checkbox]").prop("checked", false);
    }
  };
  const handleSingleCheckboxChange = (e, item) => {
    if (e.target.checked === true) {
      // var data = master;
      // data.push(item);
      // setMaster(data);
      setMaster((prevData) => [...prevData, item]);
    } else {
      // var data = master;
      // data = removeByAttr(data, "staff_id", item.staff_id)
      // setMaster(data);
      setMaster((prevData) => prevData.filter((row) => row.staff_id.toString() !== item.staff_id.toString()));
    }
  };
  const handleClickOpenModal = (item) => {
    setselectedSalary({ id: item._id, amount: item.total_amount });
    axios
      .get(`${API_URL}salary/single_salary?id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          var data = res.data.data[0];
          if (data.total_amount) {
            data.total_amount = data.total_amount.toFixed(2);
          }
          data.date = moment(data.date).format("DD-MM-YYYY");
          data.time = moment(data.time, "HH:mm:ss").format("hh:mm a");
          if (data.paid_status == 0) {
            setpayOption("block");
            data.status = (
              <span class="badge rounded-pill bg-danger" style={{ padding: "3px 8px 3px 8px" }}>
                Unpaid
              </span>
            );
          } else {
            setpayOption("none");
            data.status = (
              <span class="badge rounded-pill bg-success" style={{ padding: "3px 8px 3px 8px" }}>
                Paid
              </span>
            );
          }

          data.addedBy = data.staff_info[0]?.firstName + " " + data.staff_info[0]?.lastName;
          if (data.paid_status == 0) {
            data.paidBy = "";
            data.paid_date = "";
            data.paid_time = "";
            data.found_source = "";
          } else {
            data.paidBy = data.paid_staff_info[0]?.firstName + " " + data.paid_staff_info[0]?.lastName;
            data.paid_date = moment(data.paid_date).format("DD-MM-YYYY");
            data.paid_time = moment(data.paid_time, "HH:mm:ss").format("hh:mm a");
            data.found_source = data.fund_source_info[0].acc_chart_name;
          }
          data.salary_slip.map((element, index) => {
            element.id = index + 1;
            if (element.salary_type != "" && typeof element.salary_type != "undefined") {
              element.salary_name = element.basic_salary + "/" + element.salary_type;
            } else {
              element.salary_name = element.basic_salary;
            }
          });

          setsalaryslipList(data.salary_slip);

          setSingleObject(data);
          setOpenModal(true);
        }
      });
  };

  const handleSubmitSalary = () => {
    let data = {
      localbody_id: currentLocalbody,
      month: value,
      days: work_days,
      total_staff: details.length,
      salary: {
        actual_extra_payment: master.actual_extra_payment,
        actual_incentive: master.actual_incentive,
        advance_balance: master.advance_balance,
        advance_due: master.advance_due,
        calc_salary: master.calc_salary,
        calc_salarys: master.calc_salarys,
        daily_incentive_amount: master.daily_incentive_amount,
        emi_amount_permonth: master.emi_amount_permonth,
        emi_hold_status: master.emi_hold_status,
        extra_days: master.extra_days,
        extra_payment: master.extra_payment,
        grand_present: master.grand_present,
        holiday_halfday: master.holiday_halfday,
        holiday_present: master.holiday_present,
        holiday_total_present: master.holiday_total_present,
        id: master.id,
        incentive: master.incentive,
        lop: master.lop,
        lops: master.lops,
        monthly_incentive_amount: master.monthly_incentive_amount,
        name: master.name,
        net_salary: master.net_salary,
        salary: master.salary,
        salary_name: master.salary_name,
        salary_type: master.salary_type,
        salary_type_id: master.salary_type_id,
        staff_id: master.staff_id,
        to_pay: master.to_pay,
        total_absent: master.total_absent,
        total_half: master.total_half,
        total_payable: master.total_payable,
        total_present: master.total_present,
        _id: master.id,
      },
    };
    if (master.length == 0) {
      toastr.error("Select atlease one checkbox before submit");
      return;
    } else {
      axios
        .post(
          `${API_URL}salary/save_salary`,
          { salary: master, localbody_id: currentLocalbody, month: value, days: work_days, total_staff: details.length },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )

        // axios
        //   .post(`${API_URL}salary/save_salary`, data, {
        //     headers: {
        //       "x-access-token": accessToken,
        //     },
        //   })
        .then((res) => {
          toastr.success("Submitted successfully");
          setValue("");
          setDays("");
          setWorkDays("");
          setSundayDays("");
          setShow(false);
          setDetails([]);
          formRef.current.reset();
          handleTableData();
          setMaster([]);
          setIsAllCheckbox(false);
        });
    }
  };
  const handleTableData = () => {
    let url = `${API_URL}salary/salary_list?localbody_id=` + currentLocalbody;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((element, index) => {
            element.id = index + 1;
            element.total_amount = element?.total_amount?.toFixed(2);
            element.date = moment(element.date).format("DD-MM-YYYY");
            element.time = moment(element.time, "HH:mm:ss").format("hh:mm a");
            element.staff = element.staff_info[0]?.firstName + " " + element.staff_info[0]?.lastName;
            if (element.paid_status == 0) {
              var class_name = "fa fa-credit-card";
              element.status = <span class="badge rounded-pill bg-danger">Unpaid</span>;
              element.action = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <i
                    className={class_name}
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.5em",
                    }}
                    onClick={() => handleClickOpenModal(element)}></i>
                  <i
                    className="far fa-trash-alt"
                    style={{ fontSize: "1em", cursor: "pointer" }}
                    onClick={() => {
                      setsalaryToBeDeleted(element._id);
                      setConfirmDeleteAlert(true);
                    }}></i>
                </div>
              );
            } else {
              var class_name = "fas fa-eye";
              element.status = <span class="badge rounded-pill bg-success">Paid</span>;
              element.action = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <i
                    className={class_name}
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.5em",
                    }}
                    onClick={() => handleClickOpenModal(element)}></i>
                </div>
              );
            }
          });
        setsalaryList(result);
      });
  };
  const handleSelectChange = (e, name) => {
    setSelectedFundSource(e);
  };
  const handlePayment = () => {
    if (selectedFundSource) {
      axios
        .post(
          `${API_URL}salary/salary_pay`,
          {
            id: selectedSalary.id,
            amount: selectedSalary.amount,
            fundsource: selectedFundSource.value,
            localbody_id: currentLocalbody,
          },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          toastr.success("Submitted successfully");
          setValue("");
          setDays("");
          setWorkDays("");
          setSundayDays("");
          setShow(false);
          setDetails([]);
          setMaster([]);
          setFundSourceOptions(null);
          setSingleObject({});
          setpayOption("none");
          formRef.current.reset();
          setOpenModal(false);
          handleTableData();
        });
    } else {
      toastr.error("Select Fundsource before payment");
    }
  };

  const calculated_data = {
    columns: [
      {
        label: (
          <input type="checkbox" name="headerCheckbox" id="headerCheckbox" style={{ cursor: "pointer" }} onClick={(e) => handleAllCheckboxChange(e)}></input>
        ),
        field: "checkbox",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "#",
      //   field: "id",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Basic",
        field: "salary_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "P",
        field: "grand_present",
        sort: "asc",
        width: 150,
      },
      {
        label: "L",
        field: "total_absent",
        sort: "asc",
        width: 150,
      },
      {
        label: "E",
        field: "extra_days",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: (
      //     <>
      //       <Row>
      //         <Col xl="12">
      //           <Row>
      //             <table>
      //               <thead>
      //                 <tr>
      //                   <th colSpan="3">Attendance</th>
      //                 </tr>
      //                 <tr>
      //                   {/* <td>LOP</td> */}
      //                   <td>P</td>
      //                   <td>L</td>
      //                 </tr>
      //               </thead>
      //             </table>
      //           </Row>
      //         </Col>
      //       </Row>
      //     </>
      //   ),
      //   field: "attendance",
      //   sort: "asc",
      //   width: 400,
      // },
      {
        label: "LOP",
        field: "lops",
        sort: "asc",
        width: 150,
      },
      {
        label: "Calculated",
        field: "calc_salarys",
        sort: "asc",
        width: 150,
      },

      {
        label: "Advance",
        field: "advance_balance",
        sort: "asc",
        width: 150,
      },
      {
        label: "Extra Payment",
        field: "extraPayment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Incentive",
        field: "incentiveAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pay",
        field: "to_pay",
        sort: "asc",
        width: 150,
      },
      {
        label: "EMI",
        field: "emi_amount_permonth",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Payable",
        field: "total_payable",
        sort: "asc",
        width: 150,
      },
    ],
    rows: details,
  };
  const salarySlip = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "staff_name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Basic",
        field: "salary_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Present",
        field: "total_present",
        sort: "asc",
        width: 150,
      },
      {
        label: "Absent",
        field: "total_leave",
        sort: "asc",
        width: 150,
      },
      {
        label: "Extra Days",
        field: "extra_days",
        sort: "asc",
        width: 150,
      },
      {
        label: "LOP",
        field: "lop",
        sort: "asc",
        width: 150,
      },
      {
        label: "Calculated",
        field: "calculated_salary",
        sort: "asc",
        width: 150,
      },

      {
        label: "Advance",
        field: "advance_balance",
        sort: "asc",
        width: 150,
      },
      {
        label: "Extra Payment",
        field: "extraPayment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Incentive",
        field: "incentiveAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pay",
        field: "to_pay",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: salaryslipList?.map((salary) => ({
      id: salary.id,
      staff_name: salary.staff_name,
      salary_name: salary.basic_salary,
      total_present: salary.total_present,
      total_leave: salary.total_leave,
      extra_days: salary.extra_days,
      lop: salary.lop,
      calculated_salary: salary.calculated_salary,
      advance_balance: salary.advance_balance,
      extraPayment: salary.extra_payment_given,
      incentiveAmount: salary.incentive_given,
      to_pay: salary.to_pay,
      action: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CreateIcon style={{ fontSize: "1em", cursor: "pointer" }} />
        </div>
      ),
    })),
  };

  const salary_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },

      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Month",
        field: "month",
        sort: "asc",
        width: 150,
      },
      {
        label: "No.of Staff",
        field: "calculated_staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Amount",
        field: "total_amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Created By",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: salaryList,
  };

  function handlePaymentGiven(e) {
    setEditedExtraPayment(e.target.value);
  }

  function handleExtraIncentive(e, indx, item) {
    var ince_data = master;
  }

  const focusChange = (item, index) => {
    // Handle the onBlur event here
    const { incentive_given, extra_payment_given } = item;

    let data = {
      staff_id: item.staff_id,
      localbody_id: currentLocalbody,
      month: item.month,
      incentive_given,
      extra_payment_given,
    };

    axios
      .post(`${API_URL}salary/edit/beforepay`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        const data = [...salaryslipList];
        data[index].to_pay = result.to_pay;
        setsalaryslipList(data);
        setExtraPaymentVisible(-1);
      })
      .catch((err) => {
        toastr.error(err?.response?.data?.message);
      });

    // setMaster({ ...master, [name]: value });
    // handleClickOpenModal();
  };

  const handleKey = (e) => {
    if (e.keyCode === 40 || e.keyCode === 38) {
      e.preventDefault();
    }

    if (e.keyCode === 13) {
      e.target.blur();
    }
  };
  const handleChange = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;

    const data = [...salaryslipList];

    data[index][name] = value;

    setsalaryslipList(data);

    // // Create a new array with updated values for the specified index
    // const updatedMaster = [...master];
    // updatedMaster[index] = {
    //   ...updatedMaster[index],
    //   [name]: value,
    // };

    // // Update state with the new array
    // setMaster(updatedMaster);
  };

  function handleInceChange(item, index) {
    let data = {
      type: salaryList.type,
      staff_id: item.staff_id,
      localbody_id: currentLocalbody,
      month: item.month,
      amount: item.to_pay,
    };

    axios
      .post(`${API_URL}salary/edit/beforepay`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        // Assuming 'master' is a state variable
        setMaster({
          ...master,
          extra_payment_given: result.extra_payment_given, // Set initial value here
        });

        setExtraPaymentVisible(index);
      });
  }

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}salary` + "/" + salaryToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Deleted successfully");
                  setsalaryToBeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete advance");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <Modal show={openModal} size="xl" centered={true}>
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setOpenModal(false);
              handleTableData()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <table className="table table-borderless">
            <tr>
              <td style={{ fontWeight: "bold" }}>Date & Time</td>
              <td>
                : {singleObject?.date} {singleObject?.time}
              </td>
              <td style={{ fontWeight: "bold" }}>ID</td>
              <td>: {singleObject?.reference_id}</td>
              <td style={{ fontWeight: "bold" }}>Month</td>
              <td>: {singleObject?.month}</td>
              <td style={{ fontWeight: "bold" }}>No.of Working Days</td>
              <td>: {singleObject?.work_days}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Total Amount</td>
              <td>: Rs. {singleObject?.total_amount}</td>
              <td style={{ fontWeight: "bold" }}>Added By</td>
              <td>: {singleObject?.addedBy}</td>
              <td style={{ fontWeight: "bold" }}>Status</td>
              <td>: {singleObject?.status}</td>
              <td style={{ fontWeight: "bold" }}>Paid Date</td>
              <td>
                : {singleObject?.paid_date} {singleObject?.paid_time}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Paid By</td>
              <td>: {singleObject?.paidBy}</td>
              <td style={{ fontWeight: "bold" }}>Fund Source</td>
              <td>: {singleObject?.found_source}</td>
            </tr>
          </table>
          {/* <MDBDataTable
            id="salarySlipTable"
            responsive
            bordered
            data={salarySlip}
            searching={false}
            info={false}
            disableRetreatAfterSorting={true}
            paging={false}
          /> */}

          <Row>
            <div className="table-responsive">
              <Table style={{ border: "1px solid #dadada" }} className="table table-bordered mb-0 table-hover">
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th className="vertical-middle" scope="col" rowSpan={2}>
                      No.
                    </th>
                    <th className="vertical-middle" rowSpan={2}>
                      Name
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      Basic
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      Present
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      Absent
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      Extra Days
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      LOP
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      Calculated
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      Advance
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      Extra Payment
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      Incentive
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      Pay
                    </th>
                    <th className="vertical-middle" style={{ winWidth: "10rem" }} rowSpan={2}>
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody style={{ border: "1px solid #dadada" }}>
                  {salaryslipList?.map((item, index) => {
                    const rowMaster = master[index] || {}; // Get the state for the current row

                    return (
                      <tr className="vertical-middle" key={index}>
                        <th className="vertical-middle" scope="row">
                          {index + 1}
                        </th>
                        <td className="vertical-middle">{item.staff_name}</td>
                        <td className="vertical-middle">{item.basic_salary}</td>
                        <td className="vertical-middle">{item.total_present}</td>
                        <td className="vertical-middle">{item.total_leave}</td>
                        <td className="vertical-middle">{item.extra_days}</td>
                        <td className="vertical-middle">{item.lop}</td>
                        <td className="vertical-middle">{item.calculated_salary}</td>
                        <td className="vertical-middle">{item.advance_balance}</td>
                        <td className="p-2">
                          {extraPaymentVisible === index ? (
                            <input
                              // onFocus={onFocus}
                              placeholder="Extra payment"
                              // onWheel={(e) => e.target.blur()}
                              onChange={(e) => handleChange(e, index)}
                              onKeyDown={(e) => handleKey(e)}
                              className="w-100 table-input mt-2"
                              style={{ cursor: "pointer" }}
                              type="text"
                              value={item.extra_payment_given}
                              name="extra_payment_given"
                            />
                          ) : (
                            <span
                              onClick={() => {
                                setExtraPaymentVisible(index);
                                setMaster(item);
                              }}>
                              {item.extra_payment_given}
                            </span>
                          )}
                        </td>
                        <td className="vertical-middle">
                          {extraPaymentVisible === index ? (
                            <input
                              // onFocus={onFocus}
                              placeholder="Extra payment"
                              // onWheel={(e) => e.target.blur()}
                              onChange={(e) => handleChange(e, index)}
                              // onKeyDown={(e) => handleKey(e)}
                              className="w-100 table-input mt-2"
                              style={{ cursor: "pointer" }}
                              type="text"
                              value={item.incentive_given}
                              name="incentive_given"
                            />
                          ) : (
                            <span
                              onClick={() => {
                                setExtraPaymentVisible(index);
                                setMaster(item);
                              }}>
                              {item.incentive_given}
                            </span>
                          )}
                        </td>
                        <td className="vertical-middle">{item.to_pay}</td>
                        <td className="vertical-middle">
                          {extraPaymentVisible === index ? (
                            <CheckCircleOutlineIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                focusChange(item, index);
                              }}
                            />
                          ) : (
                            <CreateIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setExtraPaymentVisible(index);
                                setMaster(item);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Row>

          <Row>
            <Col
              md="9"
              style={{
                textAlign: "right",
                paddingBotton: "10px",
                display: payOption,
              }}>
              <b>Total Amount</b>
            </Col>
            <Col md="3" style={{ display: payOption }}>
              <b>Rs. {salaryslipList.reduce((accumulator, currentValue) => accumulator + currentValue.to_pay || 0, 0)}</b>
              {/* <b>Rs. {singleObject?.total_amount}</b> */}
            </Col>
            <Col
              md="9"
              style={{
                textAlign: "right",
                padding: "10px",
                display: payOption,
              }}>
              <b>Fund Source</b>
            </Col>
            <Col md="3" style={{ display: payOption }}>
              <Select
                name="fundsource"
                id="fundsource"
                value={selectedFundSource}
                onChange={(value) => {
                  handleSelectChange(value, "fundsource");
                }}
                menuPosition="fixed"
                options={fundSourceOptions}
                classNamePrefix="select2-selection"
              />
            </Col>
            <Col
              md="9"
              style={{
                textAlign: "right",
                paddingBotton: "10px",
                display: payOption,
              }}></Col>
            <Col md="3" style={{ padding: "10px", display: payOption }}>
              <Button color="primary" type="button" onClick={() => handlePayment()}>
                Pay Now
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Calculate Salary" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleCalculate(e, v);
                    }}>
                    <Col xl="12">
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Month</Label>
                            <AvField
                              name="month"
                              type="month"
                              errorMessage="Select Month"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={value}
                              onChange={handleChangeDate}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Working Days</Label>
                            <AvField
                              name="working_days"
                              type="text"
                              errorMessage="Enter no.of working days"
                              className="form-control"
                              placeholder="Working Days"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={work_days}
                              onChange={handleChangeDays}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Sundays</Label>
                            <AvField
                              readOnly="readOnly"
                              name="no_sundays"
                              type="text"
                              placeholder="Sundays"
                              errorMessage="Enter no.of sunday days"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={sunday_days}
                              onChange={handleChangeDays}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div style={{ paddingTop: "25px" }}>
                            <Button color="primary" type="submit" style={{ marginRight: "5%" }}>
                              {/* <Save></Save> */}
                              {"  "}
                              Calculate
                            </Button>
                            <Button color="danger" type="button" onClick={() => reset()}>
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {show ? (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {/* <CardTitle>Staff & their Calculated Salary</CardTitle>
                    <hr /> */}
                    <MDBDataTable
                      id="salaryCalcTableId"
                      responsive
                      bordered
                      data={calculated_data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />

                    <Row>
                      <Col
                        className="col-12"
                        style={{
                          paddingTop: "4px",
                          textAlign: "-webkit-right",
                        }}>
                        <div className="mb-2">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => {
                              handleSubmitSalary();
                            }}>
                            {"  "}
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="salaryTable"
                    responsive
                    bordered
                    data={salary_data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(Salary));

Salary.propTypes = {
  error: PropTypes.any,
  categories: PropTypes.array,
};
