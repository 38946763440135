import React, { useState, useEffect, useRef } from "react";
import { Row,Col,Card,CardBody } from "reactstrap";
import axios from "axios";
import "./style.scss";
import CountUp from 'react-countup';
import _ from 'lodash';
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
const DashboardScreen1 = (props)=>{
    const accessToken = props.match.params.accessToken;
    const userId = props.match.params.user_id;
    const [wardCount, setWardCount] = useState(0);
    const [readonly, setreadonly] = useState(0);
    const [tot_customers,settot_customers]=useState(0);
    const [tot_visit,settot_visit]=useState(0);
    const [wardList,setwardList]=useState([]);
    const start = 0;
    useEffect(()=>{
        handleData(userId);
    },[])
    useEffect(() => {
          const interval = setInterval(() => {
            window.location.href=("/tv_dashboard/"+accessToken+"/"+userId) 
          }, 19000);
      }, [userId]);
    const handleData = (userId) => {
        axios
          .get(
            `${API_URL}api/staff/tv_screen1?user_id=` +
              userId,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {

            var list = _.chunk(res.data.ward_list, 15);
            setwardList(list);
            setWardCount(res.data.wards_count);
            settot_customers(res.data.tot_customers);
            settot_visit(res.data.total_visit);
            setreadonly(Math.round((res.data.total_visit/res.data.tot_customers)*100));
          });
      };

    return (
        <React.Fragment>
            <div className="page-content" style={{ paddingTop:"10px", paddingBottom:"0px"}}>
                <Row>
                  <Col md="3">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                          <h4 className="mb-1 mt-1"><span style={{ fontSize:"20px"}}>{wardCount}</span></h4>
                          <p className="text-dark mb-0" style={{ fontSize:"15px", fontWeight:"bold"}}>Total Wards</p>
                          </Col>
                          <Col>
                          <p className="text-dark mb-0" style={{ fontSize:"25px", textAlign:"right"}}><span className={"text-primary me-1"}><i className={"fas fa-map me-1"}></i></span></p>
                          </Col>
                        </Row>                
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                          <h4 className="mb-1 mt-1"><span style={{ fontSize:"20px"}}>{tot_customers}</span></h4>
                          <p className="text-dark mb-0" style={{ fontSize:"15px", fontWeight:"bold"}}>Customers</p>
                          </Col>
                          <Col>
                          <p className="text-dark mb-0" style={{ fontSize:"25px", textAlign:"right"}}><span className={"text-info me-1"}><i className={"fa fa-user me-1"}></i></span></p>
                          </Col>
                        </Row>                
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                          <h4 className="mb-1 mt-1"><span style={{ fontSize:"20px"}}>{tot_visit}</span></h4>
                          <p className="text-dark mb-0" style={{ fontSize:"15px", fontWeight:"bold"}}>Visits</p>
                          </Col>
                          <Col>
                          <p className="text-dark mb-0" style={{ fontSize:"25px", textAlign:"right"}}><span className={"me-1"} style={{ color:"#6f42c1"}}><i className={"mdi mdi-transit-transfer me-1"}></i></span></p>
                          </Col>
                        </Row>                
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                          <h4 className="mb-1 mt-1"><span style={{ fontSize:"20px"}}>{readonly} %</span></h4>
                          <p className="text-dark mb-0" style={{ fontSize:"15px", fontWeight:"bold"}}>Complete</p>
                          </Col>
                          <Col>
                          <p className="text-dark mb-0" style={{ fontSize:"25px", textAlign:"right"}}><span className={"text-success me-1"}><i className={"fas fa-percentage me-1"}></i></span></p>
                          </Col>
                        </Row>                
                      </CardBody>
                    </Card>
                  </Col>
                   
                    <Col md="12">
                        <Card style={{ minHeight:"522px"}}>
                            <CardBody>
                                <Row>
                                  
                                    {
                                    wardList.map((item,index)=>{
                                      
                                        return (
                                         <>
                                          <Col md="3">
                                          <table className="table table-bordered" id="tvwardListId">
                                            <thead>
                                              <tr>
                                              <th>Ward</th>
                                              <th>Customers</th>
                                              <th>%</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                item.map((subItem,index1)=>{
                                                  var name=subItem.ward_name.split("/");
                                                  name=name[1];
                                                  return(
                                                    <tr>
                                                      <td style={{width:"90%"}}>{name}</td>
                                                      <td>{subItem.visited_count}/{subItem.count}</td>
                                                      <td>{Math.round((subItem.visited_count/subItem.count)*100)}</td>
                                                    </tr>
                                                  )
                                                })
                                              }
                                            </tbody>
                                          </table>                                         
                                          </Col>
                                         </>
                                        )
                                     
                                    })
                                  }
                                  
                                 
                                
                                </Row>
                            
                            </CardBody>
                        </Card>

                    </Col>
                    <Col>
                   
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}
export default DashboardScreen1