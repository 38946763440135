import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import toastr from "toastr";
// users
import user4 from "../../../assets/images/users/avatar-4.jpg";
import { Modal } from "react-bootstrap";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
//import { setCompany, setLocalbody,getProfileImage} from "../../../helpers/globalFunctions";
import { getUsersProfile } from "../../../store/actions";
import defaultProfile from "../../../assets/images/person-icon.png";
const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const [userId, setUserId] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [master, setMaster] = useState({});
  const [userImage, setUserImage] = useState("");
  const formRef = useRef();
  // const [image,SetImage] = useState(getProfileImage());
  const {
    usersProfile
  } = useSelector((state) => state.users);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setusername(obj.firstName);
      setUserId(obj._id);
      setMaster({
        user_id: obj._id,
      });
      fetch_user_image(obj._id);
      let data = {
        user_id: obj._id
      }
    }
  }, [props.success]);

  useEffect(() => {
    usersProfile && usersProfile.map((element) => {
      fetch_user_image(element._id);
    });
  }, [usersProfile])
  // useEffect(()=>{
  //   if(image !== null ||image !== ""){
  //     if (localStorage.getItem("authUser")) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       fetch_user_image(obj._id);
  //     }
  //   }
  // },[usersProfile])
  const fetch_user_image = (id) => {
    let user = {
      user_id: id,
    };
    axios
      .post(`${API_URL}api/staff/user_profile`, user, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        res.data.result &&
          res.data.result.map((item, index) => {
            setUserImage(item.user_image);
          });
      });
  };
  // const { usersProfile } = useSelector((state) => state.users);

  let logout = () => {
    localStorage.removeItem("authUser");
    props.history.push("/login");
    // setCompany("");
    // setLocalbody("");
  };
  const goToProfile = () => {
    history.push("/profile/" + userId);
  };
  const handleClick = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setMaster({
      user_id: userId,
    });
    formRef.current.reset();
    setShowPopup(false);
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmitPassword = () => {
    if (master?.password !== master?.new_password) {
      toastr.error("Password don't match");
      return;
    } else {
      axios
        .post(`${API_URL}user/change_password`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            setMaster({
              user_id: userId,
            });
            closePopup();
            formRef.current.reset();
          } else {
            toastr.error(res.data.message);
            return;
          }
        });
    }
  };
  return (
    <React.Fragment>
      <Modal show={showPopup} centered={true} size="md">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Change Password</h5>
          <button
            type="button"
            onClick={() => closePopup()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          ref={formRef}
          className="needs-validation"
          onValidSubmit={(e, v) => {
            handleValidSubmitPassword(e, v);
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Current Password</Label>
                  <AvField
                    name="old_password"
                    type="password"
                    errorMessage="Enter current password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={master?.old_password}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">New Password</Label>
                  <AvField
                    name="password"
                    type="password"
                    errorMessage=" Please provide a valid password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={master?.password}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">
                    Confirm New Password
                  </Label>
                  <AvField
                    name="new_password"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage=" Please confirm the password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={master?.new_password}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-dark"
              style={{ marginRight: "1rem" }}
              onClick={() => closePopup()}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src={`${API_URL}uploads/user_images/${userImage}`}
            // alt="Header Avatar"
          /> */}
          {userImage !== "" ? (
            <img
              className="rounded-circle header-profile-user"
              src={`${API_URL}uploads/user_images/${userImage}`}
            //alt="Header Avatar"
            />
          ) : (
            <img
              className="rounded-circle header-profile-user"
              src={defaultProfile}
              alt="Header Avatar"
            />
          )}
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
            {username}
          </span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => goToProfile()}>
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {props.t("View Profile")}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/">
            <i className="uil uil-history font-size-18 align-middle me-1 text-muted"></i>
            {props.t("History")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Settings")}
            <span className="badge bg-soft-success rounded-pill mt-1 ms-2">
              03
            </span>
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <div
            className="dropdown-item"
            onClick={() => handleClick()}
            style={{ cursor: "pointer" }}
          >
            <i className="fas fa-key font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Change Password")}</span>
          </div>
          <div className="dropdown-divider" />
          <div
            className="dropdown-item"
            onClick={logout}
            style={{ cursor: "pointer" }}
          >
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Logout")}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
