import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getLocalbody, getDate } from "../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import "./feedback.scss";
import Pagination from "@mui/material/Pagination";
import Rating from '@mui/material/Rating';
const Feedback = (props) => {
    const [currentLocalbody] = useState(getLocalbody());
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [details, setDetails] = useState([]);
    const [searchData, setSearchData] = useState({});
    const [wardOptions, setWardOptions] = useState([]);
    const [selectedWardSearch, setSelectedWardSearch] = useState(null);
    const [searchGroupOptions, setSearchGroupOptions] = useState([]);
    const [selectedGroupSearch, setSelectedGroupSearch] = useState(null);
    const [statusOptions] = useState([{ label: "No Reply", value: "false" }, { label: "Replied", value: "true" }]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedCustomer, setselectedCustomer] = useState(null);
    const [customerOptions, setcustomerOptions] = useState([]);
    const [masterObject, setMasterObject] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    /** pagination */
    //   const [searchValue, setSearchValue] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPageCount, setPerPageCount] = useState(20);
    const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
        currentPage * PerPageCount
    );

    const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
        indexOfLastTransfer - PerPageCount
    );
    const paginate = (event, value) => {
        setCurrentPage(value);
        const indexOfLastTransfer = value * PerPageCount;
        const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
        setindexOfFirstTransfer(indexOfFirstTransfer);
        setindexOfLastTransfer(indexOfLastTransfer);

        handleTableData(searchData?.customer_id, searchData?.ward, searchData?.group, searchData?.from_date, searchData?.to_date, searchData?.reply_status, searchData?.localbody_id, indexOfFirstTransfer, indexOfLastTransfer);
    };
    const resetPaginate = () => {
        setCurrentPage(1);
        const indexOfLastTransfer = PerPageCount;
        const indexOfFirstTransfer = 0;
        setindexOfFirstTransfer(indexOfFirstTransfer);
        setindexOfLastTransfer(indexOfLastTransfer);
    }
    // const debounce = (func, delay) => {
    //     let timerId;
    //     return (...args) => {
    //         clearTimeout(timerId);
    //         timerId = setTimeout(() => func(...args), delay);
    //     };
    // };
    // const handleSearch = (value) => {
    //     setSearchLoader(true);
    //     setSearchValue(value)
    //     resetPaginate();
    //     handleTableData();
    // }
    useEffect(() => {
        if (details.length == 0 && indexOfFirstTransfer != 0) {
            resetPaginate();
            handleTableData(searchData?.customer_id, searchData?.ward, searchData?.group, searchData?.from_date, searchData?.to_date, searchData?.reply_status, searchData?.localbody_id);
        }

    }, [details])
    /** end pagination */
    useEffect(() => {
        handleTableData();
        fetch_all_wards(currentLocalbody);
    }, []);
    const handleTableData = (customer_id = "", ward = "", group = "", from_date = "", to_date = "", reply_status = "", localbody_id = currentLocalbody, start = 0, end = PerPageCount) => {
        var url = `${API_URL}feedback/all-feedbacks?customer_id=${customer_id}&ward=${ward}&group=${group}&from_date=${from_date}&to_date=${to_date}&reply_status=${reply_status}&localbody_id=${localbody_id}&start=${start}&end=${end}`;
        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {

                setTotalPages(res.data.limit);
                let id = start;
                if (res.data.success) {
                    var result = res.data.data;
                    result.map((item, index) => {
                        id++;
                        item.id = id;
                        item.feedback_date = moment(item.feedback_date + " " + item.feedback_time, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY hh:mm a");
                        if (item.has_reply == "true")
                            item.feedback_reply_date = moment(item.feedback_reply_date + " " + item.feedback_reply_time, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY hh:mm a");
                        if (item.feedback_star) {
                            item.star = (
                                <>
                                    <Rating
                                        name="simple-controlled"
                                        value={parseFloat(item.feedback_star)}
                                        readOnly
                                    /></>
                            )

                        }
                        item.action = (
                            <i className="fas fa-reply"
                                title="Send Reply"
                                onClick={() => {
                                    setMasterObject({
                                        ...masterObject,
                                        "feedback_id": item?._id,
                                        "old_reply": item?.feedback_reply,
                                        "message": ""
                                    });
                                    setOpenModal(true);
                                }}
                            >

                            </i>
                        )
                    })
                }
                else {
                    var result = [];
                }

                setDetails(result)
            });
    }
    const handleChangeSearch = (e, type) => {
        switch (type) {
            case "from_date":
                setSearchData({
                    ...searchData,
                    "from_date": e.target.value
                })
                break;
            case "to_date":
                setSearchData({
                    ...searchData,
                    "to_date": e.target.value
                })
                break;
            case "ward":
                setSelectedWardSearch(e);
                setSelectedGroupSearch(null);
                setselectedCustomer(null)
                setSearchData({
                    ...searchData,
                    ward: e.value,
                    group: "",
                    customer_id: ""
                });
                fetch_all_customers(currentLocalbody, e.value, "");
                axios
                    .get(
                        `${API_URL}api/staff/groups?localbody_id=` +
                        currentLocalbody +
                        "&ward_id=" +
                        e.value,
                        {
                            headers: {
                                "x-access-token": accessToken,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data && res.data.success === true) {
                            var group_data =
                                res.data.data &&
                                res.data.data.map((el) => {
                                    return {
                                        label: el.group_name,
                                        value: el._id,
                                    };
                                });
                        } else {
                            group_data = [];
                        }

                        setSearchGroupOptions([
                            {
                                options: group_data,
                            },
                        ]);
                    });
                break;
            case "group":
                setSelectedGroupSearch(e);
                setselectedCustomer(null);
                setSearchData({
                    ...searchData,
                    group: e.value,
                    customer_id: ""
                });
                fetch_all_customers(currentLocalbody, searchData?.ward, e.value);
                break;
            case "customer_id":
                setselectedCustomer(e);
                setSearchData({
                    ...searchData,
                    customer_id: e.value,
                });
                break;
            case "reply_status":
                setSelectedStatus(e);
                setSearchData({
                    ...searchData,
                    reply_status: e.value,
                });
                break;

        }
    }
    const handleTableSearch = () => {
        resetPaginate();
        handleTableData(searchData?.customer_id, searchData?.ward, searchData?.group, searchData?.from_date, searchData?.to_date, searchData?.reply_status, searchData?.localbody_id);
    }
    function fetch_all_wards(localbody) {
        axios
            .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var ward_data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el.ward_name,
                            value: el._id,
                        };
                    });

                setWardOptions([
                    {
                        options: ward_data,
                    },
                ]);
            });
    }
    function fetch_all_customers(localbody = currentLocalbody, ward = "", group = "") {
        axios
            .get(`${API_URL}feedback/customers?localbody_id=` + localbody + "&ward=" + ward + "&group=" + group, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el.cust_name,
                            value: el._id,
                        };
                    });

                setcustomerOptions([
                    {
                        options: data,
                    },
                ]);
            });
    }
    const handleValidSubmit = (event, values) => {
        axios
            .post(`${API_URL}feedback/add-reply`, masterObject, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                setMasterObject(null);
                setOpenModal(false);
                toastr.success("Submitted successfully");
                handleTableData(searchData?.customer_id, searchData?.ward, searchData?.group, searchData?.from_date, searchData?.to_date, searchData?.reply_status, searchData?.localbody_id, indexOfFirstTransfer, indexOfLastTransfer);
            });
    }
    const reset = () => {
        setSearchData({
            from_date: "",
            to_date: ""
        });
        resetPaginate();
        handleTableData();
        setSelectedWardSearch(null)
        setSelectedGroupSearch(null)
        setselectedCustomer(null)
        setSelectedStatus(null)
    };

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "feedback_date",
                sort: "asc",
                width: 400,
            },
            {
                label: "Customer",
                field: "feedback_customer_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Category",
                field: "feedback_category_name",
                sort: "asc",
                width: 100,
            },
            {
                label: "Rate",
                field: "star",
                sort: "asc",
                width: 100,
            },
            {
                label: "Feedback",
                field: "feedback",
                sort: "asc",
                width: 100,
            },
            {
                label: "Reply",
                field: "feedback_reply",
                sort: "asc",
                width: 100,
            },
            {
                label: "Replied By",
                field: "feedback_reply_staff",
                width: 300,
            },
            {
                label: "Replied On",
                field: "feedback_reply_date",
                width: 300,
            },
            {
                label: "Action",
                field: "action",
                width: 300,
            },
        ],
        rows: details,
    };
    return (
        <React.Fragment>
            <Modal
                isOpen={openModal}
                toggle={() => setOpenModal(!openModal)}
                size="md"
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Send Your Reply </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setOpenModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <AvForm className="needs-validation" onValidSubmit={(e, v) => { handleValidSubmit(e, v); }}>
                    <div className="modal-body">
                        <Row>
                            <Col md="12">

                                <AvField
                                    name="message"
                                    placeholder="Message"
                                    type="textarea"
                                    errorMessage="Message required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                    onChange={(e) => {
                                        setMasterObject({
                                            ...masterObject,
                                            "message": e.target.value,
                                            "old_reply": e.target.value,
                                        })
                                    }}
                                    value={masterObject?.old_reply}
                                />
                            </Col>
                            <Col md="12" className="mt-2">
                                <Button
                                    color="primary"
                                    type="submit"
                                >
                                    Send
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </AvForm>
            </Modal>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Customer Feedbacks" />
                    <Row>


                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label >From</Label>
                                                {/* <div className="col-md-10"> */}
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    id="from_date"
                                                    name="from_date"
                                                    value={searchData?.from_date}

                                                    onChange={(e) => { handleChangeSearch(e, "from_date") }}
                                                    max={getDate(new Date())}
                                                />
                                                {/* </div> */}
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label >To</Label>
                                                {/* <div className="col-md-10"> */}
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    id="to_date"
                                                    name="to_date"
                                                    value={searchData?.to_date}
                                                    onChange={(e) => { handleChangeSearch(e, "to_date") }}
                                                    min={searchData?.from_date}
                                                />
                                                {/* </div> */}
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Ward</Label>
                                                <Select
                                                    name="ward"
                                                    value={selectedWardSearch}
                                                    onChange={(selected) => {
                                                        handleChangeSearch(selected, "ward");
                                                    }}
                                                    options={wardOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Group</Label>
                                                <Select
                                                    name="group"
                                                    value={selectedGroupSearch}
                                                    onChange={(selected) => {
                                                        handleChangeSearch(selected, "group");
                                                    }}
                                                    options={searchGroupOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Customer</Label>
                                                <Select
                                                    name="customer"
                                                    value={selectedCustomer}
                                                    onChange={(selected) => {
                                                        handleChangeSearch(selected, "customer_id");
                                                    }}
                                                    options={customerOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Status</Label>
                                                <Select
                                                    name="reply_status"
                                                    value={selectedStatus}
                                                    onChange={(selected) => {
                                                        handleChangeSearch(selected, "reply_status");
                                                    }}
                                                    options={statusOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div style={{ display: "flex" }} >

                                                <div className="mt-1 mb-3 mx-1">
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => handleTableSearch()}
                                                    >
                                                        Search
                                                    </Button>
                                                </div>

                                                <div className="mt-1 mb-3 mx-1" >
                                                    <Button
                                                        color="danger"
                                                        type="reset"
                                                        onClick={() => reset()}
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                    <MDBDataTable
                                        id="feedbackTableId"
                                        responsive
                                        bordered
                                        data={data}
                                        searching={false}
                                        info={false}
                                        disableRetreatAfterSorting={true}
                                        paging={false}
                                        //onSearch={(value) => { handleSearch(value) }}
                                        //onSearch={debounce(handleSearch, 500)}
                                        entries={PerPageCount}
                                    />
                                    {totalPages > PerPageCount && (
                                        <div className="pagination-div">
                                            {/* Display the page details */}
                                            <div className="page-details">
                                                Showing {indexOfFirstTransfer + 1} to {indexOfFirstTransfer + details.length} of {totalPages} entries
                                            </div>
                                            <Pagination
                                                color="standard"
                                                shape="rounded"
                                                defaultPage={1}
                                                count={Math.ceil(totalPages / PerPageCount)}
                                                page={currentPage}
                                                onChange={paginate}
                                                info={true}
                                                size="large"
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Feedback;