import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Row, Col, Button, Card, CardBody, Label } from "reactstrap"
import axios from "axios"
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { getLocalbody } from "../../helpers/globalFunctions";
import { MDBDataTable } from "mdbreact"
import moment from "moment";
import Select from "react-select"
import "./style.scss"


function WarehouseManage() {

    const API_URL = process.env.REACT_APP_APIURL || "https://localhost:3099/";
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
    const [masterObj, setmasterObj] = useState({})
    const [tableData, setTableData] = useState([])
    const [selectedWard, setSelectedWard] = useState(null)
    const [wardOptions, setWardOptions] = useState([])
    const [selectedGroup, setSelecteGroup] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [groupOptions, setGroupOptions] = useState([])



    useEffect(() => {
        handleTableData()
        fetch_all_ward()
    }, []);



    function handleTableData(ward = "", group = "", status = "") {
        axios
            .get(`${API_URL}workrequest/workrequest-list?localbodyId=${currentLocalbody}&wardId=${ward}&groupId=${group}&filter=${status}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {
                let Data = []
                const result = res.data.workRequest;

                result.map((item, indx) => {

                    item.id = indx + 1
                    item.time = moment(item.workrequest_time, "HH:mm:ss").format("hh:mm a");

                    item.group &&
                        item.group.map((el) => {
                            item.groupName = el.name;
                        })

                    if (item.workrequest_activestatus === 0) {
                        item.status = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button color="success" size="sm"
                                    onClick={() => updateStatus(item)}
                                >Processing</Button>
                            </div>
                        )
                    } else if (item.workrequest_activestatus === 1) {
                        item.status = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button color="primary" size="sm" style={{ paddingRight: '13px' }} >Approved</Button>
                            </div>
                        )
                    } else if (item.workrequest_activestatus === 2) {
                        item.status = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button color="danger" size="sm" style={{ paddingRight: '17px' }}>Rejected</Button>
                            </div>
                        )
                    } else if (item.workrequest_activestatus === 3) {
                        item.status = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button color="warning" size="sm">Forwarded</Button>
                            </div>
                        )
                    } else if (item.workrequest_activestatus === 4) {
                        item.status = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button color="orange" size="sm">Accepted</Button>
                            </div>
                        )
                    } else {
                        item.status = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button color="brown" size="sm">Completed</Button>
                            </div>
                        )
                    }

                    Data.push(item)
                })
                setTableData(Data)
            })
    }

    function fetch_all_ward() {
        axios
            .get(`${API_URL}api/staff/wards?localbody_id=${currentLocalbody}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {
                const ward_data =
                    res.data.data &&
                    res.data.data.map((item, indx) => {

                        return {
                            label: item?.ward_name,
                            value: item?._id
                        }
                    })
                setWardOptions([{ options: ward_data }])

            })
    }

    const data = {
        columns: [
            {
                label: "#",
                field: 'id',
                width: 50,
            },
            {
                label: "Date",
                field: 'workrequest_date',
                width: 50,
            },
            {
                label: "Time",
                field: 'time',
                width: 50,
            },
            {
                label: "Category",
                field: 'category',
                width: 50,
            },
            {
                label: "Quantity",
                field: 'workrequest_category_quantity',
                width: 50,
            },
            {
                label: "Available Quantity",
                field: 'availableQuantity',
                width: 50,
            },
            {
                label: "Ward",
                field: 'ward',
                width: 50,
            },
            {
                label: "Group",
                field: 'groupName',
                width: 50,
            },
            {
                label: "Status",
                field: 'status',
                width: 50,
            },
        ],
        rows: tableData,
    }

    function updateStatus(item) {

        let data = item.workrequest_activestatus === 0 ? 1 : 2
        axios
            .put(`${API_URL}workrequest/assign-driver?workRequestId=${item._id}&approvalStatus=${data}`,
                {},
                {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }).then(res => {
                    if (res.data.success === true) {
                        handleTableData()
                    }
                })
    }

    function handleSelect(selected, name) {
        switch (name) {
            case "ward":
                setSelectedWard(selected)
                setmasterObj({ ...masterObj, ward: selected.value })
                setSelecteGroup(null)

                axios
                    .get(`${API_URL}api/staff/groups?localbody_id=${currentLocalbody}&ward_id=${selected.value}`, {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    }).then(res => {
                        var ward_data =

                            res.data.data &&
                            res.data.data.map((item, indx) => {
                                return {
                                    label: item?.group_name,
                                    value: item?._id
                                }
                            })
                        setGroupOptions([{ options: ward_data }])
                    })
                break;

            case "group":
                setSelecteGroup(selected)
                setmasterObj({ ...masterObj, group: selected.value })
                break;

            case "status":
                setSelectedStatus(selected)
                setmasterObj({ ...masterObj, status: selected.value })
                break;

            default:
                break;
        }
    }

    function reset() {
        setSelecteGroup(null)
        setSelectedWard(null)
        setSelectedStatus(null)
        setmasterObj({})
        handleTableData()
    }

    function handleSearch() {
        let ward = masterObj?.ward ? masterObj?.ward : "";
        let group = masterObj?.group ? masterObj?.group : "";
        let status = masterObj?.status ? masterObj?.status : "";

        handleTableData(ward, group, status)
    }



    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="WorkRequest" />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Ward</Label>
                                                <Select
                                                    name="ward"
                                                    value={selectedWard}
                                                    options={wardOptions}
                                                    classNamePrefix="select2-selection"
                                                    onChange={(selected) => {
                                                        handleSelect(selected, "ward")
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Group</Label>
                                                <Select
                                                    name="group"
                                                    value={selectedGroup}
                                                    classNamePrefix="select2-selection"
                                                    options={groupOptions}
                                                    onChange={(selected) => {
                                                        handleSelect(selected, "group")
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Status</Label>
                                                <Select
                                                    name="status"
                                                    value={selectedStatus}
                                                    classNamePrefix="select2-selection"
                                                    options={[
                                                        { label: "Processing", value: 0 },
                                                        { label: "Approved", value: 1 },
                                                        { label: "Rejected", value: 2 },
                                                        { label: "Forwarded", value: 3 },
                                                        { label: "Accepted", value: 4 },
                                                        { label: "Completed", value: 5 },
                                                    ]}
                                                    onChange={(selected) => {
                                                        handleSelect(selected, 'status')
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="3">
                                            <div style={{ display: "flex", marginTop: "25px" }}>
                                                <Button color="primary" type="search" onClick={() => handleSearch()}>Search</Button>

                                                <Button color="danger" type="reset" onClick={() => reset()} style={{ marginLeft: "15px" }}>Reset</Button>
                                            </div>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            <MDBDataTable
                                id="warehouseId"
                                responsive
                                bordered
                                data={data}
                            />
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )


}

export default WarehouseManage;