import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import toastr from "toastr";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { getAllPopup, addPopup } from "../../store/actions";
import {
  getCompany,
  getLocalbody,
  getDateAndTime,
} from "../../helpers/globalFunctions";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import "./imagePopup.scss";

const ImagePopupView = (props) => {
  const [selectedVisibleTo, setSelectedVisibleTo] = useState(null);
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [popupView, setPopupView] = useState(false);
  const [tableData, setTableData] = useState({});
  const [uploadProgress, setUploadProgress] = useState();
  const [popupIdToBeDeleted, setPopupIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [popupIdToBeUpdated, setPopupIdToBeUpdated] = useState(null);
  const [localbodyOptions, setLoacalbodyOptions] = useState([]);
  const [selectedLocalbody, setSelectedLocalbody] = useState(null);
  const [errors, setErrors] = useState({
    imageError: "",
    visibleToError: "",
    localbodyError: "",
    fromDateError: "",
    toDateError: "",
  });
  const [date, setDate] = useState(getDateAndTime(new Date()));
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const dispatch = useDispatch();
  const formRef = useRef();

  const { error, popups, addPopupResponse } = useSelector(
    (state) => state.popups
  );
  const [currentCompany, setCurrectCompany] = useState(getCompany());

  useEffect(() => {
    dispatch(getAllPopup(currentLocalbody));
    fetch_all_localbodies();
  }, [currentLocalbody]);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const fetch_all_localbodies = () => {
    axios
      .get(`${API_URL}localbody/alllist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let localbody_data =
          res.data.data &&
          res.data.data.map((item, index) => {
            return {
              label: item.localbody_name,
              value: item._id,
            };
          });
        setLoacalbodyOptions([
          {
            options: localbody_data,
          },
        ]);
      });
  };
  const toggleWasteActiveStatus = (id) => {
    let data = {
      id: id,
    };
    axios
      .put(`${API_URL}popup/toggle?id=` + id, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          dispatch(getAllPopup(currentLocalbody));
        }
      });
  };
  useEffect(() => {
    let popupData = JSON.parse(JSON.stringify(popups));
    popupData.map((item, index) => {
      item.id = index + 1;
      if (item.popup_visible === 0) item.visible_to = "All";
      else if (item.popup_visible === 1) item.visible_to = "Staff";
      else item.visible_to = "Customer";

      // item.from = moment(item?.popup_frmdatetime).format("DD-MM-YYYY,hh:mm a");
      // item.to = moment(item?.popup_todatetime).format("DD-MM-YYYY,hh:mm a");

      let fromDate = moment(item?.from_date).format("DD-MM-YYYY");
      let fromTime = moment(item.from_time, "HHmm").format("hh:mm a");
      item.from = fromDate + "," + fromTime;

      let toTime = moment(item.to_time, "HHmm").format("hh:mm a");
      item.to = item?.to_date + "," + toTime;

      item.image = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={`${API_URL}uploads/popup_images/${item.popup_image}`}
            height="50"
            width="50"
          />
        </div>
      );
      item.status = (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            className="form-check form-switch mb-3"
            style={{ textAlign: "center" }}
          >
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitch2"
              checked={item.popup_active_status === 0 ? true : false}
              onChange={(e) => {
                toggleWasteActiveStatus(item._id);
                // settoggleSwitch(!toggleSwitch);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      );
      item.action = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="fas fa-eye"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.2em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              handleClickOpenForm(item);
            }}
          ></i>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.1em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              toTop();
              preUpdatePopup(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: "1em", cursor: "pointer" }}
            onClick={() => {
              setPopupIdToBeDeleted(item._id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
    });

    setDetails(popupData);
  }, [popups]);

  const handleClickOpenForm = (item) => {
    item.localbodyName = "";
    item.popup_local_body &&
      item.popup_local_body.map((el) => {
        if (item.localbodyName === "") item.localbodyName = el;
        else item.localbodyName = item.localbodyName + "," + "\n" + el;
      });
    // item.createdAt = moment(item?.popup_date).format("MM-DD-YYYY");
    let fromDate = moment(item?.from_date).format("DD-MM-YYYY");
    let fromTime = moment(item.from_time, "HHmm").format("hh:mm a");
    item.from = fromDate + "," + fromTime;

    let toTime = moment(item.to_time, "HHmm").format("hh:mm a");
    item.to = item?.to_date + "," + toTime;
    setTableData(item);
    setPopupView(true);
  };

  let preUpdatePopup = (item) => {
    setPopupIdToBeUpdated(item._id);
    axios
      .get(`${API_URL}popup/?id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((element, index) => {
            element.id = index + 1;
            let options = {};
            if (element?.popup_visible === 0) {
              options = {
                label: "All",
                value: 0,
              };
            } else if (element?.popup_visible === 1) {
              options = {
                label: "Staff",
                value: 1,
              };
            } else if (element?.popup_visible === 2) {
              options = {
                label: "Customer",
                value: 2,
              };
            }
            // element.popup_localbody = [getLocalbody()];
            let localbodyOptions = [];
            let popupLocalbody = [];
            element.popup_local_body &&
              element.popup_local_body.map((el) => {
                let val = {};
                val.label = el.localbody_name;
                val.value = el._id;
                localbodyOptions.push(val);
                popupLocalbody.push(el._id);
              });
            setSelectedLocalbody(localbodyOptions);
            element.popup_localbody = popupLocalbody;
            setSelectedVisibleTo(options);
          });
        setMaster(...result);
      });
  };
  const uploadImage = (e) => {
    let val = e.target.files[0];

    if (val?.size > 102400)
      toastr.error("Image size should be less than or equal to 100 Kb");
    else {
      const fd = new FormData();
      fd.append("popup_image", e.target.files[0]);
      axios
        .post(`${API_URL}popup/image`, fd, {
          headers: {
            // "Content-Type": "multipart/form-data",
            "x-access-token": accessToken,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setUploadProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            setMaster({
              ...master,
              [`popup_image`]: response.data.file.filename,
            });
            setErrors({
              ...errors,
              imageError: "",
            });
            // toastr.success("Image Uploaded");
          } else {
            toastr.error(response.data.message);
          }
        });
    }
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },

      {
        label: "Name",
        field: "popup_name",
        sort: "asc",
        width: 70,
      },
      {
        label: "Visible To",
        field: "visible_to",
        sort: "asc",
        width: 70,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 70,
      },
      {
        label: "From",
        field: "from",
        sort: "asc",
        width: 70,
      },
      {
        label: "To",
        field: "to",
        sort: "asc",
        width: 70,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: details,
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "visibleTo":
        setSelectedVisibleTo(selected);
        setMaster({
          ...master,
          ["popup_visible"]: selected.value,
        });
        setErrors({
          ...errors,
          visibleToError: "",
        });
        break;
      case "localbody":
        let arrValue = [];
        selected &&
          selected.forEach((element) => {
            arrValue.push(element.value);
          });
        setSelectedLocalbody(selected);
        setMaster({
          ...master,
          ["popup_localbody"]: arrValue,
        });
        setErrors({
          ...errors,
          localbodyError: "",
        });
        break;
      default:
        break;
    }
  };
  const handleDate = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "popup_frmdatetime") {
      setErrors({
        ...errors,
        fromDateError: "",
      });
    } else {
      setErrors({
        ...errors,
        toDateError: "",
      });
    }
  };
  const handleChangeInput = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {
    if (
      selectedVisibleTo === null ||
      selectedLocalbody === null ||
      !master?.popup_image ||
      !master?.popup_frmdatetime ||
      !master?.popup_todatetime
    ) {
      let errorValue = errors;
      if (selectedVisibleTo === null) {
        errorValue.visibleToError = "Please select visible to";
      }
      if (selectedLocalbody === null) {
        errorValue.localbodyError = "Please select localbody";
      }
      if (!master?.popup_image) {
        errorValue.imageError = "Please select image";
      }
      if (!master?.popup_frmdatetime) {
        errorValue.fromDateError = "Please choose from";
      }
      if (!master?.popup_todatetime) {
        errorValue.toDateError = "Please choose to";
      }
      setErrors({
        ...errorValue,
      });
      return;
    }

    if (popupIdToBeUpdated) {
      axios
        .put(`${API_URL}popup?id=` + popupIdToBeUpdated, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Popup updated successfully");
            dispatch(getAllPopup(currentLocalbody));
            setPopupIdToBeUpdated(null);
            setSelectedVisibleTo(null);
            setSelectedLocalbody(null);
            formRef.current.reset();
            setMaster({
              popup_frmdatetime: "",
              popup_todatetime: "",
            });
            setErrors({
              imageError: "",
              visibleToError: "",
              localbodyError: "",
              fromDateError: "",
              toDateError: "",
            });
          } else {
            toastr.error("Failed to update popup");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}popup`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Popup created successfully");
            dispatch(getAllPopup(currentLocalbody));
            setSelectedVisibleTo(null);
            setSelectedLocalbody(null);
            formRef.current.reset();
            setMaster({
              popup_frmdatetime: "",
              popup_todatetime: "",
            });
            setErrors({
              imageError: "",
              visibleToError: "",
              localbodyError: "",
              fromDateError: "",
              toDateError: "",
            });
          } else {
            toastr.error("Failed to create popup");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }

    // dispatch(addPopup(master));
  };
  const deletePopupImage = () => {
    setMaster({
      ...master,
      [`popup_image`]: "",
    });
    setErrors({
      ...errors,
      imageError: "",
    });
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}popup?id=` + popupIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Popup deleted successfully");
                  if (master && master?._id === popupIdToBeDeleted) {
                    setPopupIdToBeUpdated(null);
                    setSelectedVisibleTo(null);
                    setSelectedLocalbody(null);
                    formRef.current.reset();
                    setMaster({
                      popup_frmdatetime: "",
                      popup_todatetime: "",
                    });
                    setErrors({
                      imageError: "",
                      visibleToError: "",
                      localbodyError: "",
                      fromDateError: "",
                      toDateError: "",
                    });
                  }
                  dispatch(getAllPopup(currentLocalbody));
                } else {
                  toastr.error("Failed to delete popup");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Popup Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px" }}>Name :</td>
                <td>{tableData?.popup_name}</td>
                <td>Visible To :</td>
                <td style={{ textAlign: "left" }}>{tableData?.visible_to}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Localbody :</td>
                <td style={{ width: "296.59px" }}>
                  {tableData?.localbodyName}
                </td>
                <td>Created At :</td>
                <td style={{ textAlign: "left" }}>{tableData?.popup_date}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>From :</td>
                <td>{tableData?.from}</td>
                <td>To :</td>
                <td style={{ textAlign: "left" }}>{tableData?.to}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Image :</td>
                <td style={{ textAlign: "left" }}>
                  <img
                    style={{
                      width: "180px",
                      height: "180px",
                      paddingLeft: "0px",
                    }}
                    src={`${API_URL}uploads/popup_images/${tableData?.popup_image}`}
                  ></img>
                </td>
                <td>Status :</td>
                <td style={{ textAlign: "left" }}>
                  {tableData?.popup_active_status === 0 ? (
                    <Label style={{ color: "green", paddingTop: "18px" }}>
                      Active
                    </Label>
                  ) : (
                    <Label style={{ color: "red", paddingTop: "18px" }}>
                      Inactive
                    </Label>
                  )}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Popup" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Name</Label>
                          <AvField
                            name="popup_name"
                            type="text"
                            errorMessage="Enter Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.popup_name}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Visible To</Label>
                          <Select
                            name="popup_visible"
                            value={selectedVisibleTo}
                            onChange={(selected) => {
                              handleSelectChange(selected, "visibleTo");
                            }}
                            options={[
                              { label: "All", value: 0 },
                              { label: "Staff", value: 1 },
                              { label: "Customer", value: 2 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedVisibleTo === null
                              ? errors.visibleToError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Localbody</Label>
                          <Select
                            isMulti
                            name="notification_localbody"
                            value={selectedLocalbody}
                            onChange={(selected) => {
                              handleSelectChange(selected, "localbody");
                            }}
                            options={localbodyOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedLocalbody === null
                              ? errors.localbodyError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>

                          <input
                            className="form-control"
                            type="datetime-local"
                            value={master?.popup_frmdatetime}
                            id="popup_frmdatetime"
                            name="popup_frmdatetime"
                            onChange={handleDate}
                            min={date}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master?.popup_frmdatetime
                              ? errors.fromDateError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          <input
                            className="form-control"
                            type="datetime-local"
                            value={master?.popup_todatetime}
                            id="popup_todatetime"
                            name="popup_todatetime"
                            onChange={handleDate}
                            min={
                              master?.popup_frmdatetime
                                ? master.popup_frmdatetime
                                : date
                            }
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master?.popup_todatetime
                              ? errors.toDateError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3" style={{ display: "block" }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Choose Image
                          </Label>
                          {master?.popup_image ? (
                            <div div className="img-wraps">
                              {master.popup_image &&
                                master.popup_image.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${master.popup_image}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/popup_images/${master.popup_image}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deletePopupImage}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="popup_image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master?.popup_image ? errors.imageError : ""}
                          </p>
                        </div>

                        {/* <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Choose Image
                          </Label>
                          <AvField
                            name="image_upload"
                            // value={productObject.product_seo_keys}
                            // placeholder="Product Image 1"
                            type="file"
                            errorMessage="Choose image"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={uploadImage}
                            rows="1"
                          />
                        </div> */}
                      </Col>
                      <Col md="1" style={{ paddingTop: "5px" }}>
                        <div className="mt-4">
                          {popupIdToBeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="popupTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ImagePopupView;
