import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../../components/Common/Breadcrumb';
import { CardBody, Card, Row, Col, Label, Button, ToastBody } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";
import axios from 'axios';
import { getLocalbody } from '../../../../helpers/globalFunctions';
import accessToken from '../../../../helpers/jwt-token-access/accessToken';
import { MDBDataTable } from 'mdbreact';
import toastr from 'toastr';
import "./style.scss";
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal } from 'react-bootstrap';



function Purchase() {

    const formRef = useRef();
    const [masterObject, setmasterObject] = useState({});
    const API_URL = process.env.REACT_APP_APIURL || "https://localhost:3099//";
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

    const [selectedPayment, setSelectedPayment] = useState(null);

    const [errors, setErrors] = useState({ paymentError: "", productError: "" });
    const [qntyy, setQntyy] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productOptions, setProductOptions] = useState([]);

    const [purchaseIdToBeUpdated, setPurchaseIdToBeUpdated] = useState(null);
    const [purchaseDetails, setPurchaseDetails] = useState([]);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
    const [purchaseIdToBeDeleted, setPurchaseIdToBeDeleted] = useState(null);

    const [popupView, setPopupView] = useState(false);
    const [popupData, setPopupData] = useState([]);
    const [stockDetails, setStockDetails] = useState([]);
    const [followUp, setFollowUp] = useState({});



    useEffect(() => {
        fetch_products();
        handleTableData();
        handleStockTable();
    }, []);


    function fetch_products() {
        axios
            .get(`${API_URL}workrequestcategory/workrequest-category-list?localbody=${currentLocalbody}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                var product_data =
                    res.data.data &&
                    res.data.data.map((el) => {

                        return {
                            label: el?.category_name,
                            value: el?._id,
                            unit: el?.unit,
                            rate: el?.rate,
                            amount: el?.amount,
                        }
                    })

                setProductOptions([{ options: product_data }])
            })
    }


    function handleDate(e) {
        setmasterObject({ ...masterObject, [e.target.name]: e.target.value });
    }


    function handleChangeInput(e) {
        let name = e.target.name;
        let value = e.target.value;
        setmasterObject({ ...masterObject, [name]: value })
    }

    function handleSelectChange(selected, name) {
        switch (name) {
            case "paymentMode":
                setSelectedPayment(selected);
                setmasterObject({
                    ...masterObject,
                    paymentMode: selected.value,
                });
                break;

            case "products":
                setSelectedProduct(selected);
                setmasterObject({
                    ...masterObject,
                    products: selected.value,
                    unit: selected.unit,
                    rate: selected.rate,
                    amount: selected.amount,
                });
                break;

            default:
                break;
        }
    }


    function handleTableData() {
        axios
            .get(`${API_URL}workrequest/purchase?localbody=${currentLocalbody}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let Data = [];
                let result = res.data.data;

                result?.map((item, indx) => {
                    item.id = indx + 1;
                    item.date1 = moment(item.date).format("DD-MM-YYYY");

                    item.action = (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <i className="fas fa-eye"
                                style={{
                                    cursor: "pointer",
                                    fontSize: "1em",
                                    marginLeft: "0.5em",
                                    marginRight: "0.1em"
                                }}
                                onClick={() => {
                                    setPopupView(true);
                                    handleClickOpenModal(item);
                                }}
                            ></i>

                            <i className="far fa-edit"
                                style={{
                                    cursor: "pointer",
                                    fontSize: "1em",
                                    marginLeft: "0.5em",
                                    marginRight: "0.1em"
                                }}
                                onClick={() => {
                                    toTop();
                                    preUpdateItem(item);
                                }}
                            ></i>

                            <i className="far fa-trash-alt"
                                style={{ cursor: "pointer", fontSize: "1em" }}
                                onClick={() => {
                                    setConfirmDeleteAlert(true);
                                    setPurchaseIdToBeDeleted(item?._id);
                                }}
                            ></i>
                        </div>
                    )

                    Data.push(item);
                })

                setPurchaseDetails(Data);

            })
    }


    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date1",
                sort: "asc",
                width: 150,
            },
            {
                label: "PurchaseID",
                field: "purchaseID",
                sort: "asc",
                width: 150,
            },
            {
                label: "Bill Number",
                field: "billNumber",
                sort: "asc",
                width: 150,
            },
            {
                label: "Supplier Name",
                field: "supplierName",
                sort: "asc",
                width: 150,
            },
            {
                label: "Quantity",
                field: "Quantity",
                sort: "asc",
                width: 150,
            },
            {
                label: "Remarks",
                field: "remarks",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: purchaseDetails,
    }


    function handleValidSubmit() {

        let data = {
            billNumber: masterObject.billNumber,
            date: masterObject.date,
            grandTotal: masterObject.amount,
            localbody: currentLocalbody,
            orderNumber: masterObject.orderNumber,
            paymentMode: masterObject.paymentMode,
            product: masterObject.products,
            rate: masterObject.rate,
            supplierName: masterObject.supplierName,
            supplierNumber: masterObject.supplierNumber,
            unit: masterObject.unit,
            remarks: masterObject.remarks
        }

        if (selectedPayment === null || selectedProduct === null) {
            let errorVal = errors;
            if (selectedPayment === null) {
                errorVal.paymentError = "Please select payment";
            }
            if (selectedProduct === null) {
                errorVal.productError = "Please select product";
            }
            setErrors({
                ...errorVal
            })
        } else {
            if (purchaseIdToBeUpdated) {
                axios
                    .put(`${API_URL}workrequest/purchase/${purchaseIdToBeUpdated}`, data, {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    }).then((res) => {
                        if (res.data.success === true) {
                            toastr.success("Purchase updated successfully");
                            formRef.current.reset();
                            setmasterObject({
                                ...masterObject,
                                billNumber: "",
                                date: "",
                                grandTotal: "",
                                orderNumber: "",
                                rate: "",
                                supplierName: "",
                                supplierNumber: '',
                                unit: "",
                                remarks: ""
                            });
                            setPurchaseIdToBeUpdated(null);
                            setSelectedPayment(null);
                            setSelectedProduct(null);
                            setErrors({
                                ...errors,
                                paymentError: "",
                                productError: ""
                            })
                        } else {
                            toastr.error("Failed to update");
                            return;
                        }
                    }).catch((err) => {
                        toastr.error(err.response.data.message);
                        return;
                    })
            } else {
                axios
                    .post(`${API_URL}workrequest/purchase`, data, {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    }).then((res) => {

                        if (res.data.success === true) {
                            toastr.success("Purchase added successfully");
                            formRef.current.reset();
                            setmasterObject({
                                ...masterObject,
                                billNumber: "",
                                date: "",
                                grandTotal: "",
                                orderNumber: "",
                                rate: "",
                                supplierName: "",
                                supplierNumber: '',
                                unit: "",
                                remarks: ""
                            });
                            handleTableData();
                            setSelectedPayment(null);
                            setSelectedProduct(null);
                            setErrors({
                                ...errors,
                                paymentError: "",
                                productError: ""
                            })
                        } else {
                            toastr.error("Failed to add");
                            return;
                        }
                    }).catch((err) => {
                        toastr.error(err.response.data.message);
                        return;
                    })
            }
        }
    }

    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }

    let preUpdateItem = (item) => {
        setPurchaseIdToBeUpdated(item?._id);

        axios
            .get(`${API_URL}workrequest/purchase/single/${item?._id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data.data;

                let mode = {
                    label: result?.paymentMode,
                    value: result?.paymentMode,
                };
                setSelectedPayment(mode);

                let product1 = {
                    label: result?.product?.category_name,
                    value: result?.product?._id,
                };
                setSelectedProduct(product1);

                setmasterObject(result);
            })

    }


    function handleClickOpenModal(item) {
        axios
            .get(`${API_URL}workrequest/purchase/single/${item?._id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {
                let result = res.data.data;

                setPopupData(result);
            })
    }

    function handleValidStock() {
        let data = {
            purchase_id: popupData?._id,
            quantity: followUp?.Quantity,
            amount: popupData?.amount
        };

        axios.put(`${API_URL}workrequest/stock/quantity`, data, {
            headers: {
                "x-access-token": accessToken,
            },
        }).then((res) => {
            if (res.data.success === true) {
                toastr.success("Quantity updated successfully");
                formRef.current.reset();
                handleStockTable();
                handleTableData();
                setmasterObject({});
                setFollowUp({
                    quantity: "",
                });
            } else {
                toastr.error("Failed to update");
            }
        }).catch((err) => {
            toastr.error(err.response.data.message);
        });
    }

    function handleCloseModal() {
        setPopupView(false);
    }

    function handleStockTable() {
        axios.get(`${API_URL}workrequest/stock/quantity/previousList?purchase_id=${popupData._id}&localbody=${currentLocalbody}`, {
            headers: {
                "x-access-token": accessToken,
            },
        }).then((res) => {
            let result = res.data.data;
            let Data = [];

            result.forEach((item, indx) => {
                item.id = indx + 1;
                item.date2 = moment(item.date).format("DD-MM-YYYY");
                Data.push(item);
            });

            setStockDetails(Data);
        });
    }


    const stockData = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: 'date2',
                sort: 'asc',
                width: 150,
            },
            {
                label: 'Purchased Quantity',
                field: "purchaseQuantity",
                sort: "asc",
                width: 150,
            },
            {
                label: "In",
                field: "stock_in",
                sort: "asc",
                width: 150,
            },
            {
                label: "Out",
                field: "stock_out",
                sort: "asc",
                width: 150,
            },
            {
                label: "Balance",
                field: "categoryQuantity",
                sort: "asc",
                width: 150,
            },
            {
                label: "Remarks",
                field: "remarks",
                sort: "asc",
                width: 150,
            },
        ],
        rows: stockDetails,
    }


    function handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        setFollowUp({ ...followUp, [name]: value })
    }


    return (

        <React.Fragment>
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle='success'
                    cancelBtnBsStyle='danger'
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}workrequest/purchase/${purchaseIdToBeDeleted}`, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            }).then((res) => {
                                if (res.data.success === true) {
                                    toastr.success("Purchase deleted successfully");
                                    formRef.current.reset();
                                    setmasterObject({});
                                    handleTableData();
                                    setSelectedPayment(null);
                                    setSelectedProduct(null);
                                    setPurchaseIdToBeUpdated(null);
                                    setErrors({
                                        ...errors,
                                        paymentError: "",
                                        productError: ""
                                    })
                                } else {
                                    toastr.error("Failed to delete");
                                    return;
                                }
                            }).catch((err) => {
                                toastr.error(err.response.data.message);
                                return;
                            })
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : ""}

            {/* <Modal show={popupView} size="xl" centered="true" toggle={handleCloseModal}>
                <div className='modal-header'>
                    <h5 className='modal-title mt-0'>Purchase Details</h5>
                    <button type='button'
                        onClick={() => setPopupView(false)}
                        className='close'
                        data-dismiss="modal"
                        aria-label='close'
                    ></button>
                </div>

                <AvForm className="needs-validation" ref={formRef}
                    onValidSubmit={(e, v) => {
                        handleValidStock(e, v)
                    }}
                >
                    <div className='modal-body'>
                        <table id="product_available_day" className='table table-bordered dataTable'>
                            <tr>
                                <td style={{ padding: "10px" }}>Product:</td>
                                <td>{popupData?.product?.category_name}</td>
                                <td style={{ paddingLeft: "25px" }}>Order Number:</td>
                                <td style={{ textAlign: "left" }}>{popupData?.orderNumber}</td>
                                <td style={{ padding: "25px" }}>Bill Number:</td>
                                <td style={{ textAlign: "left" }}>{popupData?.billNumber}</td>
                            </tr>

                            <tr>
                                <td style={{ paddingLeft: "10px" }}>Amount:</td>
                                <td>{popupData?.amount}</td>
                                <td style={{ padding: "25px" }}>Supplier Name:</td>
                                <td style={{ textAlign: "left" }}>{popupData?.supplierName}</td>
                                <td style={{ paddingLeft: "25px" }}>Supplier Number:</td>
                                <td style={{ textAlign: "left" }}>{popupData?.supplierNumber}</td>
                            </tr>

                        </table>
                    </div>

                    <Row classnam>
                        <Col className='col-12'>

                            <Row>
                                <Col md="3" style={{ marginLeft: "10px" }}>
                                    <div className='mb-3'>
                                        <Label>Quantity</Label>
                                        <AvField
                                            name="Quantity"
                                            id="validationCustom02"
                                            placeholder="Quantity"
                                            errorMessage="Enter quantity"
                                            validate={{ required: { value: true } }}
                                            value={followUp?.Quantity}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>

                                <Col md="3" style={{ marginTop: "26px" }}>
                                    <Button type='submit' color='primary'>Submit</Button>
                                </Col>
                            </Row>

                            <MDBDataTable
                                id="stockDataTableId"
                                responsive
                                bordered
                                info={true}
                                searching={true}
                                entries={20}
                                disableRetreatAfterSorting={true}
                                data={stockData}
                            />

                        </Col>
                    </Row>
                </AvForm>
            </Modal> */}

            <Modal show={popupView} size="small" centered="true" toggle={() => setPopupView(!popupView)}>
                <div className='modal-header'>
                    <h5 className='modal-title mt-0'>Purchase Details</h5>
                    <button type='button'
                        onClick={() => setPopupView(false)}
                        className='close'
                        data-dismiss="modal"
                        aria-label='close'
                    ></button>
                </div>

                <AvForm className="needs-validation" ref={formRef}
                    onValidSubmit={(e, v) => {
                        handleValidStock(e, v)
                    }}
                >
                    <div className='modal-body'>
                        <table id="product_available_day" className='table table-bordered dataTable'>
                            <tr>
                                <td style={{ padding: "10px" }}>Product:</td>
                                <td>{popupData?.product?.category_name}</td>
                                <td style={{ paddingLeft: "25px" }}>Order Number:</td>
                                <td style={{ textAlign: "left" }}>{popupData?.orderNumber}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px" }}>Bill Number:</td>
                                <td>{popupData?.billNumber}</td>
                                <td style={{ paddingLeft: "25px" }}>Amount:</td>
                                <td style={{ textAlign: "left" }}>{popupData?.amount}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px" }}>Supplier Name:</td>
                                <td>{popupData?.supplierName}</td>
                                <td style={{ paddingLeft: "25px" }}>Supplier Number:</td>
                                <td style={{ textAlign: "left" }}>{popupData?.supplierNumber}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px" }}>Date:</td>
                                <td>{moment(popupData?.date).format("DD-MM-YYYY")}</td>
                                <td style={{ paddingLeft: "25px" }}>Payment Mode:</td>
                                <td style={{ textAlign: "left" }}>{popupData?.paymentMode}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px" }}>Rate:</td>
                                <td>{popupData?.rate}</td>
                                <td style={{ paddingLeft: "25px" }}>Unit:</td>
                                <td style={{ textAlign: "left" }}>{popupData?.unit}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px" }}>Quantity:</td>
                                <td>{popupData?.Quantity}</td>
                                <td style={{ paddingLeft: "25px" }}>Remarks:</td>
                                <td style={{ textAlign: "left" }}>{popupData?.remarks}</td>
                            </tr>
                        </table>
                    </div>

                    {/* <Row classnam>
                        <Col className='col-12'>
                            <Col style={{ marginLeft: "3px" }}>
                                <MDBDataTable
                                    id="stockDataTableId"
                                    responsive
                                    bordered
                                    info={true}
                                    searching={false}
                                    entries={20}
                                    disableRetreatAfterSorting={true}
                                    data={stockData}
                                />
                            </Col>
                        </Col>
                    </Row> */}
                </AvForm>
            </Modal>


            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Purchase" />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <AvForm className="needs-validation"
                                        ref={formRef}
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v)
                                        }}
                                    >

                                        <Row>
                                            <Col md="3">
                                                <div className='mb-3'>
                                                    <Label>Date</Label>
                                                    <AvField
                                                        name="date"
                                                        type="date"
                                                        className="form-control"
                                                        value={masterObject?.date}
                                                        onChange={handleDate}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className='mb-3'>
                                                    <Label>Bill Number</Label>
                                                    <AvField
                                                        name="billNumber"
                                                        type="text"
                                                        placeholder="Bill number"
                                                        errorMessage="Enter billnumber"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: /^[a-zA-Z0-9\s]+$/, errorMessage: "Enter valid bill number" }
                                                        }}
                                                        value={masterObject?.billNumber}
                                                        onChange={handleChangeInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className='mb-3'>
                                                    <Label>Order Number</Label>
                                                    <AvField
                                                        name="orderNumber"
                                                        type="text"
                                                        placeholder="Order Number"
                                                        errorMessage="Enter order number"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: /^[a-zA-Z0-9\s]+$/, errorMessage: "Enter valid order number" }
                                                        }}
                                                        value={masterObject?.orderNumber}
                                                        onChange={handleChangeInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className='mb-3'>
                                                    <Label>Payment Mode</Label>
                                                    <Select
                                                        name="paymentMode"
                                                        classNamePrefix="select2-selection"
                                                        value={selectedPayment}
                                                        options={[
                                                            { label: "Cash", value: "Cash" },
                                                            { label: "Google Pay", value: "Google Pay" }
                                                        ]}
                                                        onChange={(selected) => {
                                                            handleSelectChange(selected, "paymentMode")
                                                        }}
                                                    />
                                                    <p className='text-danger' style={{ fontSize: "11px" }}>
                                                        {selectedPayment === null ? errors.paymentError : ""}
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className='mb-3'>
                                                    <Label>Supplier Name</Label>
                                                    <AvField
                                                        name="supplierName"
                                                        placeholder="Supplier Name"
                                                        className="form-control"
                                                        errorMessage="Enter supplier name"
                                                        validate={{ required: { value: true } }}
                                                        value={masterObject?.supplierName}
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className='mb-3'>
                                                    <Label>Supplier Number</Label>
                                                    <AvField
                                                        name="supplierNumber"
                                                        className="form-control"
                                                        placeholder="Supplier number"
                                                        errorMessage="Enter number"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: /^[0-9]{10}$/, errorMessage: "Enter a valid number" }
                                                        }}
                                                        value={masterObject?.supplierNumber}
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <p><b>Product Details</b></p>
                                            <Col md="3">
                                                <div className='mb-3'>
                                                    <Label>Product</Label>
                                                    <Select
                                                        name="products"
                                                        classNamePrefix="select2-selection"
                                                        validate={{ required: { value: true } }}
                                                        options={productOptions}
                                                        onChange={(selected) => {
                                                            handleSelectChange(selected, "products")
                                                        }}
                                                        value={selectedProduct}
                                                    />
                                                    <p className='text-danger' style={{ fontSize: "11px" }}>
                                                        {selectedProduct === null ? errors.productError : ""}
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className='mb-3'>
                                                    <Label>Unit</Label>
                                                    <AvField
                                                        name="unit"
                                                        placeholder="Unit"
                                                        className="form-control"
                                                        value={masterObject?.unit}
                                                        onChange={handleChangeInput}
                                                        validate={{ required: { value: true } }}
                                                        disabled={purchaseIdToBeUpdated ? true : false}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className='mb-3'>
                                                    <Label>Quantity</Label>
                                                    <AvField
                                                        name="quantity"
                                                        placeholder="Quantity"
                                                        value={masterObject?.quantity}
                                                        onChange={handleChangeInput}
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        disabled={purchaseIdToBeUpdated ? true : false}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className='mb-3'>
                                                    <Label>Rate</Label>
                                                    <AvField
                                                        name="rate"
                                                        placeholder="Rate"
                                                        value={masterObject?.rate}
                                                        onChange={handleChangeInput}
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        disabled={purchaseIdToBeUpdated ? true : false}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className='mb-3'>
                                                    <Label>Amount</Label>
                                                    <AvField
                                                        name="amount"
                                                        placeholder="Amount"
                                                        value={masterObject?.amount}
                                                        className="form-control"
                                                        onChange={handleChangeInput}
                                                        validate={{ required: { value: true } }}
                                                        disabled={purchaseIdToBeUpdated ? true : false}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className='mb-3'>
                                                    <Label>Remarks</Label>
                                                    <AvField
                                                        name="remarks"
                                                        placeholder="Remarks"
                                                        className="form-control"
                                                        errorMessage="Enter remarks"
                                                        validate={{ required: { value: true } }}
                                                        value={masterObject?.remarks}
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3" style={{ marginTop: "25px" }}>
                                                {purchaseIdToBeUpdated ? (
                                                    <Button type="update" color="primary">Update</Button>
                                                ) : (
                                                    <Button type="submit" color="primary">Submit</Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="purchaseTableId"
                                        responsive
                                        bordered
                                        info={true}
                                        searching={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                        data={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

        </React.Fragment>

    )
}


export default Purchase;