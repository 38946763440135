import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label} from "reactstrap";
import { Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { apiError } from "../../../store/actions";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import moment from "moment";
import "./driverDrop.scss";
const DriverDrop = (props) => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [details, setDetails] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [tableData, setTableData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const [driverDropItemDetails, setDriverDropItemDetails] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
    fetch_all_staff(currentLocalbody);
    fetch_all_warehouse(currentLocalbody);
  }, [currentLocalbody]);

  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}waste/driver_drop_view?driver_drop_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        res.data.data &&
          res.data.data.map((item) => {
            if (item?.driver_drop_addedby_lastname)
              item.staff =
                item.driver_drop_addedby_firstname +
                " " +
                item.driver_drop_addedby_lastname;
            else item.staff = item.driver_drop_addedby_firstname;

            item.dropTime = moment(item.driver_drop_time, "HHmmss").format(
              "hh:mm a"
            );



            let dropItems = item.drop_items;
            dropItems &&
              dropItems.map((itm, index) => {
                itm.id = index + 1;
                if (itm.driver_drop_item_paid_status === 0) itm.status = "Paid";
                else itm.status = "Unpaid";
              });
           
            setDriverDropItemDetails(dropItems);
            setTableData(item);
            setPopupView(true);
          });
      });
  };
  function handleTableData(
    from_date = "",
    to_date = "",
    warehouse_id = "",
    staff_id = ""
  ) {
    var url = `${API_URL}waste/driver_drop?localbody_id=` + currentLocalbody;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
      }
    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&warehouse_id=" +
      warehouse_id +
      "&staff_id=" +
      staff_id;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let dataToBeExported = [];
        result &&
          result.map((item, index) => {
            let exportItem = {};
            item.id = index + 1;

            item.time = moment(item.driver_drop_time, "HHmmss").format(
              "hh:mm a"
            );
            if (item?.staff_lastname)
              item.staff = item.staff_firstname + " " + item.staff_lastname;
            else item.staff = item.staff_firstname;

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.5em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                ></i>
              </div>
            );
            //export data
            // exportItem.id = item.id;
            // exportItem.Date = item.date;
            // exportItem.Visited_Houses = item.visited_houses;
            // exportItem.Paid_Hosues = item.paid_houses;
            // exportItem.House_Amt = item.house_amt;
            // exportItem.Visited_Shops = item.visited_shops;
            // exportItem.Paid_Shops = item.paid_shops;
            // exportItem.Shop_Amt = item.shop_amt;
            // exportItem.Total = item.total_amt;
            // exportItem.Weight = item.total_weight;

            dataToBeExported.push(exportItem);
          });
        setDetails(result);
        // setDataToBeExported(dataToBeExported);
      });
  }
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=`+id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  const fetch_all_warehouse = (id) => {
    axios
      .get(`${API_URL}warehouse/list/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var warehouse_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.warehouse_name,
              value: el._id,
            };
          });
        setWarehouseOptions([
          {
            options: warehouse_data,
          },
        ]);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "driver_drop_date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Spot",
        field: "driver_drop_warehouse",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Bags",
        field: "driver_drop_total_bags",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Weight",
        field: "driver_drop_total_weight",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  const driverItemDetails = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Item Name",
        field: "driver_drop_items_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Bags",
        field: "driver_drop_items_bags",
        sort: "asc",
        width: 150,
      },
      {
        label: "Weight",
        field: "driver_drop_items_weight",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
    ],
    rows: driverDropItemDetails,
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedStaff(null);
    setSelectedWarehouse(null);
    handleTableData();
  };
  const handleSelectChange = (selected, name) => {
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let warehouse_id = searchData?.warehouse_id ? searchData.warehouse_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    switch (name) {
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        handleTableData(date1, date2, warehouse_id, selected.value);
        break;
      case "warehouse":
        setSelectedWarehouse(selected);
        setSearchData({
          ...searchData,
          ["warehouse_id"]: selected.value,
        });
        handleTableData(date1, date2, selected.value, staff_id);
        break;
      default:
        break;
    }
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let warehouse_id = searchData?.warehouse_id ? searchData.warehouse_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(date1, date2, warehouse_id, staff_id);
  };
  return (
    <React.Fragment>
      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Driver drop Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px" }}>Date :</td>
                <td>{tableData?.driver_drop_date}</td>
                <td>Time :</td>
                <td style={{ textAlign: "left" }}>{tableData?.dropTime}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Drop ID :</td>
                <td>{tableData?.driver_drop_id}</td>
                <td>Warehouse :</td>
                <td style={{ textAlign: "left" }}>{tableData?.driver_drop_warehouse}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Total Bags :</td>
                <td>{tableData?.driver_drop_total_bags}</td>
                <td>Total Weight :</td>
                <td style={{ textAlign: "left" }}>{tableData?.driver_drop_total_weight}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Latitude :</td>
                <td>{tableData?.driver_drop_warehouse_latitude}</td>
                <td>Longitude :</td>
                <td style={{ textAlign: "left" }}>{tableData?.driver_drop_warehouse_longitude}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Staff :</td>
                <td>{tableData?.staff}</td>
                <td></td>
                <td style={{ textAlign: "left" }}>{}</td>
              </tr>
            </table>
            {driverDropItemDetails && driverDropItemDetails.length > 0 ? (
              <Row className="mt-3 mb-2">
                <h6
                  className="modal-title mt-0"
                  style={{ textAlign: "center" }}
                >
                  Driver drop Item Details
                </h6>
              </Row>
            ):null}
            {driverDropItemDetails && driverDropItemDetails.length > 0 ? (
              <Row>
                <MDBDataTable
                  id="driverDropItemDetailId"
                  responsive
                  bordered
                  data={driverItemDetails}
                  searching={false}
                  info={false}
                  paging={false}
                  disableRetreatAfterSorting={true}
                  //entries={10}
                />
              </Row>
            ):null}
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Driver Drop" />
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      <input
                        className="form-control"
                        type="date"
                        id="from_date"
                        name="from_date"
                        value={searchData?.from_date}
                        onChange={handleDate}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        value={searchData?.to_date}
                        min={searchData?.from_date}
                        onChange={handleDate}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Warehouse</Label>
                      <Select
                        name="warehouse_id"
                        value={selectedWarehouse}
                        onChange={(selected) => {
                          handleSelectChange(selected, "warehouse");
                        }}
                        options={warehouseOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Staff</Label>
                      <Select
                        name="staff_id"
                        value={selectedStaff}
                        onChange={(selected) => {
                          handleSelectChange(selected, "staff");
                        }}
                        options={staffOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="1" style={{ paddingTop: "4px" }}>
                    <div className="mt-4 mb-3">
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
                <MDBDataTable
                  id="driverDropTableId"
                  responsive
                  striped
                  bordered
                  data={data}
                  info={true}
                  searching={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(DriverDrop));

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
