import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  Container,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import LoopIcon from "@mui/icons-material/Loop";
import moment from "moment";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  getInvoiceList,
  getGroupOptions,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logoDark from "../../../assets/images/logo-dark.svg";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getLocalbody,
  getCompany,
  getDate,
  getFirstday,
  formatMoney,
} from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { CSVLink } from "react-csv";
import "./autoInvoices.scss";

const AutoInvoice = (props) => {
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [currentCompany, serCurrentCompany] = useState(getCompany());
  const [details, setDetails] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    handleTableData();
    fetch_all_staff(currentLocalbody);
    fetch_all_wards(currentLocalbody);
  }, [currentLocalbody]);

  function handleTableData(
    from_date = "",
    to_date = "",
    ward_id = "",
    group_id = "",
    staff_id = ""
  ) {
    var url =
      `${API_URL}invoice/auto?localbody_id=` +
      currentLocalbody +
      "&company_id=" +
      currentCompany;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url =
      url +
      "&fromdate=" +
      from_date +
      "&todate=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&staff_id=" +
      staff_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let dataToBeExported = [];
        result.map((item, index) => {
          let exportItem = {};
          item.id = index + 1;

          item.invoicedate = item.invoice_date;
          item.invoiceNo = item.invoice_no;
          item.custName = item.invoice_customer_name;
          item.group.forEach((element) => {
            item.group = element;
          });
          item.amount = item.invoice_total_amt;
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  handleClickOpenForm(item);
                }}
              ></i>
              <i
                className="fas fa-print"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  downloadPDF(item);
                }}
              ></i>
            </div>
          );

          //export data
          exportItem.id = item.id;
          exportItem.Date = item.invoice_date;
          exportItem.Invoice_No = item.invoice_no;
          exportItem.ID = item.customerId;
          exportItem.Customer_Name = item.custName;
          exportItem.Mobile = item.cust_phone;
          exportItem.Group = item.group;
          exportItem.Ward = item.ward;
          exportItem.Amount = item.invoice_total_amt;
          exportItem.Staff = item.staff;

          dataToBeExported.push(exportItem);
        });

        setDetails(result);
        setDataToBeExported(dataToBeExported);
      });
  }
  const downloadPDF = (item) => {
    window.open(`${API_URL}invoice/view/` + item?._id);
    // saveAs(`${API_URL}` + item?.pdf_link, item?.receipt_no + ".pdf");
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  //   let preUpdateUser = (item) => {
  //     if (item.privilage) {
  //       let privilage = {
  //         label: item.privilage.name,
  //         value: item.privilage._id,
  //       };
  //       handleSelectedPrivilage(privilage);
  //     }
  //     if (item.company) {
  //       let company = {
  //         label: item.company.name,
  //         value: item.company._id,
  //       };
  //       handleSelectedCompany(company);
  //     }
  //     if (item.branch) {
  //       let branch = {
  //         label: item.branch.name,
  //         value: item.branch._id,
  //       };
  //       handleSelectedBranch(branch);
  //     }

  //     setUserIdToBeUpdated(item._id);
  //     setUserObject({ ...item, password: null });
  //   };

  //   let preUpdateUserPassword = (item) => {
  //     setUserIdToBeUpdated(item._id);
  //     setShowModal(true);
  //   };

  // useEffect(() => {
  //   let invoiceData = [];

  //   invoiceList?.map((item, index) => {
  //     item.action = (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         {/* <i
  //           className="uil-key-skeleton"
  //           style={{ fontSize: "1.3em", cursor: "pointer" }}
  //           onClick={() => {
  //             preUpdateUserPassword(item);
  //           }}
  //         ></i> */}
  //         <i
  //           className="fas fa-eye"
  //           style={{
  //             fontSize: "1em",
  //             cursor: "pointer",
  //             marginLeft: "0.2rem",
  //             marginRight: "0.5rem",
  //           }}
  //           onClick={() => {
  //             //   preUpdateUser(item);
  //           }}
  //         ></i>
  //       </div>
  //     );
  //     item.id = index + 1;
  //     if (item.invoiceDate) {
  //       const format2 = "DD-MM-YYYY";

  //       item.invoicedate = moment(item.invoiceDate).format(format2);
  //     }
  //     //   item.name1 = `${item.firstName} ${item.lastName}`;

  //     //   item.privilage1 = item.privilage && item.privilage.name;
  //     //   item.company1 = item.company && item.company.name;
  //     //   item.branch1 = item.branch && item.branch.name;
  //     invoiceData.push(item);
  //   });
  //   setInvoiceForTable(invoiceData);
  // }, [invoiceList]);

  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}invoice/autoview?invoice_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data.invoice;
        result &&
          result.map((el, index) => {
            el.company &&
              el.company.map((company) => {
                el.companyMob = company?.company_mob;
                el.companyEmail = company?.company_email;
                el.companyWeb = company?.company_web;
                el.companyAddress = company?.company_address;
              });
            el.groupName = "";
            el.group &&
              el.group.map((group) => {
                if (el.groupName === "") el.groupName = group;
                else el.groupName = el.groupName + group;
              });
            setPopupData(el);
            setPopupView(true);
          });
      });
  };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    // handleTableData(date1, date2, ward_id, group_id, staff_id);
  };

  const handleSelectChange = (selected, name) => {
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let date1 = searchData?.from_date ? searchData.from_date : "";
    // let date2 = searchData?.to_date ? searchData.to_date : "";
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
          ["group_id"]: "",
        });
        setSelectedGroup(null);
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
            currentLocalbody +
            "&ward_id=" +
            selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        // handleTableData(date1, date2, selected.value, "", staff_id);
        break;
      case "group":
        setSelectedGroup(selected);
        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });
        // handleTableData(date1, date2, ward_id, selected.value, staff_id);
        break;
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        // handleTableData(date1, date2, ward_id, group_id, selected.value);
        break;
      default:
        break;
    }
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "invoicedate",
        sort: "asc",
        width: 400,
      },
      {
        label: "Invoice No",
        field: "invoiceNo",
        sort: "asc",
        width: 200,
      },
      {
        label: "ID	",
        field: "customerId",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "custName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "cust_phone",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "State	",
      //   field: "state",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Group	",
        field: "group",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ward	",
        field: "ward",
        sort: "asc",
        width: 200,
      },
      {
        label: "	Amount	",
        field: "amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: details,
  };

  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedStaff(null);
    setGroupOptions([]);
    handleTableData();
  };
  const handleSearch = () => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    handleTableData(date1, date2, ward_id, group_id, staff_id);
  };
  return (
    <React.Fragment>
      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Invoice Details</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <Container fluid>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <h4 className="float-end font-size-16">
                          <span>
                            {" INVOICE "}
                            &nbsp;
                          </span>
                          <span>#</span>
                          <span style={{ color: "blue" }}>
                            {popupData?.invoice_no}
                          </span>
                        </h4>
                        <div className="mb-0">
                          <img src={logoDark} alt="logo" height="42" />
                        </div>
                        <div className="text-muted">
                          <h4 className="mb-1">
                            {/* {invoiceDetails.branch?.branch_name} */}
                          </h4>
                          <p className="mb-1">{popupData?.companyAddress}</p>
                          <p className="mb-1" hidden={!popupData?.companyEmail}>
                            <i className="uil uil-envelope-alt me-1"></i>{" "}
                            {popupData?.companyEmail}
                          </p>
                          <p className="mb-1" hidden={!popupData?.companyMob}>
                            <i className="uil uil-phone me-1"></i>{" "}
                            {popupData?.companyMob}
                          </p>
                          <p className="mb-1" hidden={!popupData?.companyWeb}>
                            <i className="uil uil-google me-1"></i>{" "}
                            {popupData?.companyWeb}
                          </p>
                          <p className="mb-1">
                            {/* {invoiceDetails.branch?.branch_gstin + "(GSTIN)"} */}
                          </p>
                        </div>
                      </div>
                      <hr className="my-4" />
                      <Row>
                        <Col sm="6">
                          <div className="text-muted">
                            {/* <h5 className="font-size-16 mb-3">Billed To:</h5> */}
                            <h5 className="font-size-15 mb-2">
                              {popupData?.invoice_customer_name}
                            </h5>
                            <p className="mb-1">{popupData?.customerId}</p>
                            <p className="mb-1" hidden={!popupData?.cust_phone}>
                              {" "}
                              <i className="uil uil-phone me-1"></i>
                              {popupData?.cust_phone}
                            </p>
                            <p className="mb-1">{popupData?.ward}</p>
                            <p className="mb-1">{popupData?.groupName}</p>
                            <p className="mb-1">
                              {/* <i className="uil uil-envelope-alt me-1"></i>{" "} */}
                              {/* {} */}
                            </p>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="text-muted text-sm-end">
                            <div>
                              <h5 className="font-size-16 mb-1">
                                Invoice Date:
                              </h5>
                              <p>{popupData?.invoice_date}</p>
                            </div>
                            <div className="mt-4">
                              <h5 className="font-size-16 mb-1">
                                Invoice Time:
                              </h5>
                              <p>
                                {moment(
                                  popupData?.invoice_time,
                                  "h:mm:ss"
                                ).format("hh:mm a")}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="py-2">
                        {/* <h5 className="font-size-15"></h5> */}
                        <div className="table-responsive mb-3">
                          <Table className="table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>#</th>
                                <th>Item Name</th>
                                <th>Quantity</th>
                                <th>Amount</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {popupData.invoice_items &&
                                popupData.invoice_items.map((item, key) => (
                                  <tr key={key}>
                                    <td className="text-center">{key + 1}</td>
                                    <td>{item?.inovice_item_name}</td>
                                    <td className="text-center">
                                      {item.invoice_item_quanty}
                                    </td>
                                    <td className="text-end">
                                      {item?.invoice_item_amt}
                                    </td>
                                    <td className="text-end">
                                      {item.invoice_item_price}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>

                        <Row className="pt-4">
                          <Col lg={7}>
                            <Row>
                              <Col md={12}></Col>
                            </Row>
                          </Col>
                          <Col md={1}></Col>
                          <Col>
                            <Table className="table-nowrap table-centered mb-0">
                              <tbody>
                                <tr>
                                  <th colSpan="4" className="text-end">
                                    Total Amount :
                                  </th>
                                  <td className="text-end">
                                    {formatMoney(
                                      popupData?.invoice_total_payable
                                    )}
                                  </td>
                                </tr>
                                {popupData &&
                                  popupData?.invoice_receipt_amt !== 0 ? (
                                  <tr>
                                    <th colSpan="4" className="text-end">
                                      Total Amount :
                                    </th>
                                    <td className="text-end">
                                      {formatMoney(
                                        popupData?.invoice_receipt_amt
                                      )}
                                    </td>
                                  </tr>
                                ) : null}
                                <tr>
                                  <th colSpan="4" className="border-0 text-end">
                                    Taxable Amount :
                                  </th>
                                  <td className="border-0 text-end">
                                    {formatMoney(popupData?.invoice_gst_amt)}
                                  </td>
                                </tr>
                                <tr>
                                  {/* <th colSpan="4" className="border-0 text-end">
                                  CGST :
                                </th>
                                <td className="border-0 text-end">
                                  {formatMoney(
                                    Number(
                                      parseFloat(
                                        invoiceDetails.invoice_gst_amount / 2
                                      )
                                    )
                                  )}
                                </td> */}
                                </tr>
                                <tr>
                                  {/* <th colSpan="4" className="border-0 text-end">
                                  SGST :
                                </th>
                                <td className="border-0 text-end">
                                  {formatMoney(
                                    Number(
                                      parseFloat(
                                        invoiceDetails.invoice_gst_amount / 2
                                      )
                                    )
                                  )}
                                </td> */}
                                </tr>
                                {/* {invoiceDetails.invoice_shipping_charge > 0 ? (
                                <tr>
                                  <th colSpan="4" className="border-0 text-end">
                                    Delivery Charge :
                                  </th>
                                  <td className="border-0 text-end">
                                    {formatMoney(
                                      invoiceDetails?.invoice_shipping_charge
                                    )}
                                  </td>
                                </tr>
                              ) : null} */}
                                {/* <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  Return Amount :
                                </th>
                                <td className="border-0 text-end"></td>
                              </tr> */}
                                <tr>
                                  <th colSpan="4" className="border-0 text-end">
                                    Payable Amount
                                  </th>
                                  <td className="border-0 text-end">
                                    <h4 className="m-0">
                                      {formatMoney(popupData?.totalAmount)}
                                    </h4>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                        {/* <div className="d-print-none mt-4">
                        <div className="float-end">
                          <Link to="#" onClick={printOrder} className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print"></i></Link>{" "}
                          <Link to="#" className="btn btn-primary w-md waves-effect waves-light">Close</Link>
                        </div>
                      </div> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            //breadcrumbItem="Monthly Automatic Invoice Generator"
            breadcrumbItem="View Invoice"
          />

          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="from_date"
                        name="from_date"
                        value={searchData?.from_date}
                        onChange={handleDate}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        value={searchData?.to_date}
                        min={searchData?.from_date}
                        onChange={handleDate}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Ward</Label>
                      <Select
                        name="ward_id"
                        value={selectedWard}
                        onChange={(selected) => {
                          handleSelectChange(selected, "ward");
                        }}
                        options={wardOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Group</Label>
                      <Select
                        name="group_id"
                        value={selectedGroup}
                        onChange={(selected) => {
                          handleSelectChange(selected, "group");
                        }}
                        options={groupOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Staff</Label>
                      <Select
                        name="staff_id"
                        value={selectedStaff}
                        onChange={(selected) => {
                          handleSelectChange(selected, "staff");
                        }}
                        options={staffOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Row>
                    <Col md="3">
                      <div style={{ display: "flex" }}>

                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handleSearch()}
                          >
                            {"  "}
                            Search
                          </Button>
                        </div>

                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="danger"
                            type="submit"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>

                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button color="success" type="submit">
                            <CSVLink
                              data={dataToBeExported}
                              filename={
                                "Invoice_report_" + getDate(new Date()) + ".xls"
                              }
                              style={{ color: "white" }}
                            >
                              Export
                            </CSVLink>
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* <Col md="3">
                        <div className="mb-3" style={{ paddingTop: "30px",cursor:"pointer" }}>
                          <LoopIcon onClick={handleClick}></LoopIcon>
                        </div>

                      </Col> */}
                </Row>

                <MDBDataTable
                  id="autoInvoiceTableId"
                  responsive
                  bordered
                  data={data}
                  searching={true}
                  info={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(AutoInvoice));

AutoInvoice.propTypes = {
  error: PropTypes.any,
  invoiceList: PropTypes.array,
};
