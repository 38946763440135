import React from "react";
import { MDBDataTable } from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import Resete from "@mui/icons-material/ResetTvRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
  Nav,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./chat.scss";

import { Grid } from "@mui/material";
import ChatBody from "./components/chatBody/ChatBody";
import ChatNav from "./components/nav/Nav";

const DatatableTables = () => {
  const data = {
    columns: [
      {
        label: "SI#",
        field: "si",
        sort: "asc",
        width: 150,
      },
      {
        label: "Privilage Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Privilage Shortcode",
        field: "pcode",
        sort: "asc",
        width: 270,
      },
      {
        label: "Company Name",
        field: "cname",
        sort: "asc",
        width: 150,
      },
      // {
      //   label:"Branch Name",
      //   field:"branch",
      //   sort:"asc",
      //   width:150,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        si: "1",
        name: "Admin",
        pcode: "ADM",
        office: "Edinburgh",
        age: "61",
        date: "2011/04/25",
        salary: "$320",
        cname: (
          <>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  style={{ marginRight: "15%" }}
                  value="end"
                  control={<Checkbox />}
                  label="Mavoor road - Calicut"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="end"
                  control={<Checkbox />}
                  label="City Center - Thrissur"
                  labelPlacement="end"
                />

                <FormControlLabel
                  style={{ marginRight: "15%" }}
                  value="end"
                  control={<Checkbox />}
                  label="Kottakal - Malapuram"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="end"
                  control={<Checkbox />}
                  label="Kochi"
                  labelPlacement="end"
                />

                <Grid item lg="5"></Grid>
                <Row style={{ marginLeft: "-237px" }}>
                  <Col md="3">
                    <FormControlLabel
                      className="check-fields"
                      // style={{marginLeft:"-248px"}}
                      value="end"
                      control={<Checkbox />}
                      label="Australia"
                      labelPlacement="end"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </FormControl>
          </>
        ),
        action: (
          <>
            {" "}
            <EditIcon />
            <DeleteIcon />
          </>
        ),
        action: (
          <>
            {" "}
            <EditIcon />
            <DeleteIcon />
          </>
        ),
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Media Tool" />
          <ChatNav />
          <ChatBody />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
