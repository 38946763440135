import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import { withRouter, useHistory } from "react-router-dom";
import Select from "react-select";
//import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getDate,
  getFirstday,
  getLocalbody,
} from "../../../helpers/globalFunctions";
import moment from "moment";
import "./surveyresults.scss";
import { CSVLink } from "react-csv";
import { getCustomerType } from "../../../store/actions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


const SurveyResult = (props) => {
  
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const [searchData, setSearchData] = useState({
    // from_date: getFirstday(new Date()),
    // to_date: getDate(new Date()),
  });
  const [details, setDetails] = useState([]);
  // const [wasteItems, setWasteItems] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [surveyDetails, setSurveyDetails] = useState(
    []
  );
  const [customerType, setCustomerType] = useState([]);
  const dispatch = useDispatch();
  const { customerTypes } = useSelector((state) => state.customers);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();
  useEffect(() => {
    handleTableData();
    fetch_all_survey(currentLocalbody);
    
    fetch_all_staff(currentLocalbody);
     
    dispatch(getCustomerType());
   
  }, [currentLocalbody]);

  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);
  const fetch_all_staff = (localbody,query) => {
    let data = {
        keyword: query,
        localbody_id: [localbody],
      };
    axios
      .post(`${API_URL}customers/option/list` ,data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setStaffOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };

  const fetch_all_survey = () => {
    
    // let data = {
    //     keyword: query,
    //     localbody_id: [localbody],
    //   };
    axios
      .get(`${API_URL}survey/option?localbody_id=` +
    currentLocalbody,  {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let survey_data =
          res.data.data &&
          res.data.data.map((survey) => {
            return {
              label: survey?.unique_id,
              value: survey?._id,
            };
          });
        setSurveyOptions([
          {
            options: survey_data,
          },
        ]);
      });
  };
 
 
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

 

  const handleClick = (item) => {
    axios
      .get(`${API_URL}survey/survey_result/view?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        item.time = moment(result?.time, "hhmmss").format(
                "hh:mm a"
              );
        item.staff=result?.addedby.firstName
        item.unique_id=result?.survey_id.unique_id
        item.answer=result?.answer
        // item.answer = (
        //   <div>
        //     <ul>
        //       {result?.answer &&
        //         result?.answer.map((element, index) => {
        //           return <li>{element.localbody_name}</li>;
        //         })}
        //     </ul>
        //   </div>
        // );
        // result &&
        //   result.map((item) => {
        //     item.date = moment(item?.date).format("DD-MM-YYYY");
        //     item.time = moment(item?.time, "hhmmss").format(
        //       "hh:mm a"
        //     );
           
        //     let itemDetails = item.item;
        //     itemDetails &&
        //       itemDetails.map((el, index) => {
        //         el.id = index + 1;
        //       });
            //setSurveyDetails(itemDetails);
            setPopupData(item);
            setPopupView(true);
          //});
      });
  };

  function handleTableData(
    from_date = "",
    to_date = "",
    survey_id = "",
    // group_id = "",
    // category_id = ""
  ) {
    let user_id = "";
   

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      user_id = obj._id;
    }
    
    var url =
    `${API_URL}survey/result_list?localbody_id=` +
    currentLocalbody 
    // "&user_id=" +
    // user_id;
  if (from_date === "" && to_date === "") {
    from_date = from_date ? from_date :"";
    to_date = to_date ? to_date : "";
  }
  url =
    url +
    "&from_date=" +
    from_date +
    "&to_date=" +
    to_date+ 
    "&survey_id=" +
    survey_id 
    // "&group_id=" +
    // group_id +
    // "&category_id="+
    // category_id
  axios
    .get(url, {
      headers: {
        "x-access-token": accessToken,
      },
    })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
          item.staff=item.staff_firstName
          item.survey_uniqueid=item.unique_id
          item.date = moment(item.date).format("DD-MM-YYYY");
            // item.category &&
            //   item.category.map((category) => {
            //     item.category_name = category?.category_name;
            //   });
           


            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  // onClick={() => {
                  //   handleClick(item);
                  // }}
                  onClick={() => {
                    history.push("/Surveyresult/"+item?.survey_id , {
                      survey_id: item?.survey_id,
                      cust_phone: item?.cust_phone,
                      unique_id: item?.unique_id,
                    }
                    
                    );
                  }}
                ></i>

                {/* <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  // onClick={() => {
                  //   preUpdateWorkRequest(item);
                  //   toTop();
                  // }}
                ></i> */}
                {/* <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    // setWorkRequestIdToBeDeleted(item._id);
                    // setConfirmDeleteAlert(true);
                  }}
                ></i> */}
              </div>
            );
          });
        setDetails(result);
      });
  }
  

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
    
      {
        label: "Customer",
        field: "cust_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "cust_phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Survey ID",
        field: "survey_uniqueid",
        sort: "asc",
        width: 200,
      },
      {
        label: "Survey Name",
        field: "survey_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
  
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  const SurveyDetails = {
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: "Date",
        field: "date",
      },
      {
        label: "Customer Name",
        field: "cust_name",
      },
      {
        label: "Mobile",
        field: "cust_phone",
      },
      {
        label: "Survey Name",
        field: "survey_name",
      },
      
    ],
    rows: surveyDetails,
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

 
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      
      case "survey":
        setSelectedSurvey(selected);
        setSearchData({
          ...searchData,
          ["survey_id"]: selected.value,
        });
        break;
      default:
        break;
    }
  };
  const reset = () => {
    setSearchData({
    from_date: "",
    to_date: "",
    });
    setSelectedSurvey(null)
 
    //setSelectedStaff(null);
    
    handleTableData();
  };
  const handleSearch = () => {
    //let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    //let group_id = searchData?.group_id ? searchData.group_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let survey_id = searchData?.survey_id ? searchData.survey_id : "";
    handleTableData(date1, date2,survey_id);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Survey Result" />
          <Row>
            <Col className="col-12">
              {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {"19.00"}</h5>
              </Row> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Group</Label>
                        <Select
                          name="group_id"
                          value={selectedGroup}
                          onChange={(selected) => {
                            handleSelectChange(selected, "group");
                          }}
                          options={groupOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Category</Label>
                        <Select
                          name="category_id"
                          value={selectedCategory}
                          onChange={(selected) => {
                            handleSelectChange(selected, "category_id");
                          }}
                          options={categoryOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Survey</Label>
                        <Select
                          name="survey_id"
                          value={selectedSurvey}
                          onChange={(selected) => {
                            handleSelectChange(selected, "survey");
                          }}
                          options={surveyOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                 
                    <Col md="1" style={{ paddingTop: "18px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="primary"
                          type="submit"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </div>
                    </Col>
                    {/* <Col md="1" style={{ paddingTop: "14px" }}>
                      <div className="mt-2 mb-3">
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Waste_request_" +
                              getDate(new Date()) +
                              ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col> */}
                    <Col md="1" style={{ paddingTop: "18px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <MDBDataTable
                    id="SurveyID"
                    responsive
                    // striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Survey Result Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table id="popUpTable" className="table table-bordered dataTable">
              <tr>
                <td style={{ padding: "10px" }}>Date :</td>
                <td >{popupData?.date}</td>
                <td>Time :</td>
                <td style={{ textAlign: "left" }}>{popupData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Customer :</td>
                <td>{popupData?.cust_name}</td>
                <td>QR Code :</td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.cust_qr_code}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Mobile:</td>
                <td>{popupData?.cust_phone}</td>
                <td>Survey Name :</td>
                <td style={{ textAlign: "left" }}>{popupData?.survey_name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Survey ID :</td>
                <td>{popupData?.unique_id}</td>
                <td>Staff:</td>
                <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Question :</td>
                <td  colSpan={3} style={{ textAlign: "left" }}>{popupData?.question}</td>
                {/* <td>Collection ID:</td>
                <td style={{ textAlign: "left" }}>{popupData?.collect_unique_id}</td> */}
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Answer :</td>
                <td style={{ textAlign: "left" }}>{popupData?.answer}</td>
                
              </tr>
            </table>

            {surveyDetails && surveyDetails.length > 0 ? (
              <Row className="mt-3 mb-2">
                <h6
                  className="modal-title mt-0"
                  style={{ textAlign: "center" }}
                >
                  Survey Result Details
                </h6>
              </Row>
            ) : null}
            {surveyDetails && surveyDetails.length > 0 ? (
              <Row>
                <MDBDataTable
                  id="wasteCollectionItemDetailsId"
                  responsive
                  bordered
                  data={SurveyDetails}
                  searching={false}
                  info={false}
                  paging={false}
                  disableRetreatAfterSorting={true}
                />
              </Row>
            ) : null}
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};
export default SurveyResult;
