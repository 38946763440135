import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { apiError } from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody } from "../../../helpers/globalFunctions";
import toastr from "toastr";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import QRCode from "qrcode.react";
import "./qrcode.scss";
import saveAs from "file-saver";
const QrCode = (props) => {
  const [details, setDetails] = useState([]);
  const [master, setMaster] = useState({});
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [disabled, setDisabled] = useState(false);
  const [generateQR, setGenerateQR] = useState(false);
  // const API_URL = process.env.REACT_APP_APIURL;
  const formRef = useRef();
  // const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    handleTableData(currentLocalbody);
  }, [currentLocalbody]);

  const handleTableData = (id) => {
    axios
      .get(`${API_URL}qrcode/list?localbody_id=` + currentLocalbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.date = moment(item.qrcode_date).format("DD-MM-YYYY");
            item.time = moment(item.qrcode_time, "HHmmss").format("hh:mm a");
            let addedBy = item.qrcode_addedby || {};
            item.staff = addedBy?.firstName;
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Normal A4
                    </Tooltip>
                  }
                >
                  <i
                    className="fas fa-file-pdf"
                    style={{
                      fontSize: "1.5em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.5em",
                      //color:"#d12020"
                      color: "red",
                    }}
                    onClick={() => {
                      // history.push("/qr-code/" + item._id);
                      generateQrcode(item, 4);
                    }}
                  ></i>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Normal A3
                    </Tooltip>
                  }
                >
                  <i
                    className="fas fa-file-pdf"
                    style={{
                      fontSize: "1.5em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.5em",
                      //color:"#d12020"
                      color: "red",
                    }}
                    onClick={() => {
                      // history.push("/qr-code/" + item._id);
                      generateQrcode(item, 3);
                    }}
                  ></i>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Formatted A4
                    </Tooltip>
                  }
                >
                  <i
                    className="fas fa-file-pdf"
                    style={{
                      fontSize: "1.5em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.5em",
                      color: "#d12020",
                    }}
                    onClick={() => {
                      generateQrcodeNewFormat(item, 4);
                    }}
                  ></i>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Formatted A3
                    </Tooltip>
                  }
                >
                  <i
                    className="fas fa-file-pdf"
                    style={{
                      fontSize: "1.5em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.5em",
                      color: "#d12020",
                    }}
                    onClick={() => {
                      generateQrcodeNewFormat(item, 3);
                    }}
                  ></i>
                </OverlayTrigger>
              </div>
            );
          });
        setDetails(result);
      });
  };
  function generateQrcode(item, type) {
    let localbodyData = item.qrcode_localbody_id || {};
    let localbody = localbodyData?.localbody_name;

    if (type === 4) {
      saveAs(
        `${API_URL}` + item?.qrcode_file_a4,
        localbody +
          "(" +
          item?.qrcode_start +
          "-" +
          item?.qrcode_end +
          ")" +
          ".pdf"
      );
      // window.open( `${API_URL}`+ item?.qrcode_file_a4)
    } else {
      saveAs(
        `${API_URL}` + item?.qrcode_file_a3,
        localbody +
          "(" +
          item?.qrcode_start +
          "-" +
          item?.qrcode_end +
          ")" +
          ".pdf"
      );
    }
    // axios
    //   .get(`${API_URL}qrcode/pdf?qrcode_id=` + item._id + "&type=" + type, {
    //     headers: {
    //       "x-access-token": accessToken,
    //     },
    //   })
    //   .then((res) => {
    //     saveAs(
    //       `${API_URL}` + res.data.pdf,
    //       localbody +
    //         "(" +
    //         item?.qrcode_start +
    //         "-" +
    //         item?.qrcode_end +
    //         ")" +
    //         ".pdf"
    //     );
    //     // window.open( `${API_URL}`+ res.data.pdf);
    //   });
  }
  function generateQrcodeNewFormat(item, type) {
    let localbodyData = item.qrcode_localbody_id || {};
    let localbody = localbodyData?.localbody_name;

    if (type === 4) {
      saveAs(
        `${API_URL}` + item?.qrcode_file_format_a4,
        localbody +
          "(" +
          item?.qrcode_start +
          "-" +
          item?.qrcode_end +
          ")" +
          ".pdf"
      );
    } else {
      saveAs(
        `${API_URL}` + item?.qrcode_file_format_a3,
        localbody +
          "(" +
          item?.qrcode_start +
          "-" +
          item?.qrcode_end +
          ")" +
          ".pdf"
      );
    }
    // axios
    //   .get(`${API_URL}qrcode/pdf_new?qrcode_id=` + item._id + "&type=" + type, {
    //     headers: {
    //       "x-access-token": accessToken,
    //     },
    //   })
    //   .then((res) => {
    //     saveAs(
    //       `${API_URL}` + res.data.pdf,
    //       localbody +
    //         "(" +
    //         item?.qrcode_start +
    //         "-" +
    //         item?.qrcode_end +
    //         ")" +
    //         ".pdf"
    //     );
    //   });
  }
  // function handleDocument(data) {
  //   axios
  //     .get(`${API_URL}qrcode/pdf?qrcode_id=` + data._id, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       // let result = res.data.data;
  //       let qr =
  //         res.data.data &&
  //         res.data.data.map((item) => {
  //           return (
  //             <div
  //               id="printableArea"
  //               style={{
  //                 marginTop: 200,
  //                 display: "flex",
  //                 flexDirection: "row",
  //               }}
  //             >
  //               <div>
  //                 <QRCode size={50} value={item?.qp_path} />
  //                 {/* <QRCode
  //                    value=""style={{ marginRight: 50 }}/>
  //               */}
  //               </div>
  //             </div>
  //           );
  //         });
  //     });
  // }
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const reset = () => {
    formRef.current.reset();
    setMaster({});
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Start",
        field: "qrcode_start",
        sort: "asc",
        width: 200,
      },
      {
        label: "End",
        field: "qrcode_end",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        width: 300,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };

  const handleValidSubmit = (event, values) => {
    if (master?.qrcode_end && master?.qrcode_start) {
      let end = Number(master?.qrcode_end);
      let start = Number(master?.qrcode_start);
      if (end < start) {
        toastr.error("End value should be greater than start value");
        return;
      }
    }

    setGenerateQR(true);
    axios
      .post(`${API_URL}qrcode/?localbody_id=` + currentLocalbody, master, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toastr.success("QR Code generated successfully");
          handleTableData(currentLocalbody);
          formRef.current.reset();
          setMaster({});
          setGenerateQR(false);
        } else {
          setGenerateQR(false);
          toastr.error("Failed to generate QR Code");
          return;
        }
      })
      .catch((err) => {
        setGenerateQR(false);
        toastr.error(err.response.data.message);
        return;
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="QR Code" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label>Localbody</Label>
                          <Select
                            name="customer_community_id"
                            //   value={selectedBranch}
                            //   onChange={(value) => {
                            //     handleSelectedLocalbody(value);
                            //   }}
                            //   options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Start</Label>
                          <AvField
                            name="qrcode_start"
                            type="number"
                            errorMessage="Enter start value"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={master?.qrcode_start}
                            min={0}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">End</Label>
                          <AvField
                            name="qrcode_end"
                            type="number"
                            errorMessage="Enter end value"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={master?.qrcode_end}
                            min={0}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col  md="2">
                        <div style={{display:"flex"}} >
                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          {generateQR === false ? (
                            <Button
                              color="primary"
                              type="submit"
                              // disabled={disabled}
                            >
                              Generate
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={true}
                            >
                              Processing
                            </Button>
                          )}
                        </div>
                     
                        <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="qrcodeTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(QrCode));
