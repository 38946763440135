import React from "react";
import { MDBDataTable } from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import Resete from "@mui/icons-material/ResetTvRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import SendIcon from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./Post.css";
import { Grid } from "@mui/material";

const DatatableTables = () => {
  const data = {
    columns: [
      {
        label: "SI#",
        field: "si",
        sort: "asc",
        width: 150,
      },
      {
        label: "Privilage Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Privilage Shortcode",
        field: "pcode",
        sort: "asc",
        width: 270,
      },
      {
        label: "Company Name",
        field: "cname",
        sort: "asc",
        width: 150,
      },
      // {
      //   label:"Branch Name",
      //   field:"branch",
      //   sort:"asc",
      //   width:150,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        si: "1",
        name: "Admin",
        pcode: "ADM",
        office: "Edinburgh",
        age: "61",
        date: "2011/04/25",
        salary: "$320",
        cname: (
          <>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  style={{ marginRight: "15%" }}
                  value="end"
                  control={<Checkbox />}
                  label="Mavoor road - Calicut"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="end"
                  control={<Checkbox />}
                  label="City Center - Thrissur"
                  labelPlacement="end"
                />

                <FormControlLabel
                  style={{ marginRight: "15%" }}
                  value="end"
                  control={<Checkbox />}
                  label="Kottakal - Malapuram"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="end"
                  control={<Checkbox />}
                  label="Kochi"
                  labelPlacement="end"
                />

                <Grid item lg="5"></Grid>
                <Row style={{ marginLeft: "-237px" }}>
                  <Col md="3">
                    <FormControlLabel
                      className="check-fields"
                      // style={{marginLeft:"-248px"}}
                      value="end"
                      control={<Checkbox />}
                      label="Australia"
                      labelPlacement="end"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </FormControl>
          </>
        ),
        action: (
          <>
            {" "}
            <EditIcon />
            <DeleteIcon />
          </>
        ),
        action: (
          <>
            {" "}
            <EditIcon />
            <DeleteIcon />
          </>
        ),
      },
    ],
  };
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Post" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody style={{ marginRight: "-20%" }}>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Card
                            sx={{ minWidth: 275 }}
                            style={{ backgroundColor: "#4462ff" }}
                          >
                            <CardContent style={{ alignSelf: "center" }}>
                              <div
                                className="whatsapp"
                                data-toggle="tab"
                                data-target="#whatsapp"
                              >
                                {" "}
                                <span className="fa fa-globe"></span>{" "}
                              </div>
                              <div
                                className="active-all1"
                                data-toggle="tab"
                                data-target="#all"
                              >
                                {" "}
                                All{" "}
                              </div>
                              <div
                                className="active-all1"
                                data-toggle="tab"
                                data-target="#all"
                              >
                                {" "}
                                630{" "}
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Card sx={{ minWidth: 275 }}>
                            <CardContent
                              style={{ textAlign: "-webkit-center" }}
                            >
                              <div
                                className="whatsapp"
                                data-toggle="tab"
                                data-target="#whatsapp"
                              >
                                {" "}
                                <span className="fab fa-twitter"></span>{" "}
                              </div>
                              twitter
                              <Divider />
                              <Row>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="fas fa-at"></span>{" "}
                                    </div>{" "}
                                    200{" "}
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="fas fa-comment-dots"></span>{" "}
                                    </div>{" "}
                                    15{" "}
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="far fa-comments"></span>{" "}
                                    </div>{" "}
                                    162{" "}
                                  </div>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Card sx={{ minWidth: 275 }}>
                            <CardContent
                              style={{ textAlign: "-webkit-center" }}
                            >
                              <div
                                className="whatsapp"
                                data-toggle="tab"
                                data-target="#whatsapp"
                              >
                                {" "}
                                <span className="fab fa-facebook-f"></span>{" "}
                              </div>
                              facebook
                              <Divider />
                              <Row>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="fas fa-at"></span>{" "}
                                    </div>{" "}
                                    200{" "}
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="fas fa-comment-dots"></span>{" "}
                                    </div>{" "}
                                    15{" "}
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="far fa-comments"></span>{" "}
                                    </div>{" "}
                                    162{" "}
                                  </div>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Card sx={{ minWidth: 275 }}>
                            <CardContent
                              style={{ textAlign: "-webkit-center" }}
                            >
                              <div
                                className="whatsapp"
                                data-toggle="tab"
                                data-target="#whatsapp"
                              >
                                {" "}
                                <span className="fab fa-instagram"></span>{" "}
                              </div>
                              instagram
                              <Divider />
                              <Row>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="fas fa-at"></span>{" "}
                                    </div>{" "}
                                    200{" "}
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="fas fa-comment-dots"></span>{" "}
                                    </div>{" "}
                                    15{" "}
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="far fa-comments"></span>{" "}
                                    </div>{" "}
                                    162{" "}
                                  </div>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Card sx={{ minWidth: 275 }}>
                            <CardContent
                              style={{ textAlign: "-webkit-center" }}
                            >
                              <div
                                className="whatsapp"
                                data-toggle="tab"
                                data-target="#whatsapp"
                              >
                                {" "}
                                <span className="fab fa-linkedin"></span>{" "}
                              </div>
                              linkedin
                              <Divider />
                              <Row>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="fas fa-at"></span>{" "}
                                    </div>{" "}
                                    200{" "}
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="fas fa-comment-dots"></span>{" "}
                                    </div>{" "}
                                    15{" "}
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div
                                    className="active-all2"
                                    data-toggle="tab"
                                    data-target="#all"
                                  >
                                    <div>
                                      {" "}
                                      <span className="far fa-comments"></span>{" "}
                                    </div>{" "}
                                    162{" "}
                                  </div>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </div>
                      </Col>
                      <Row>
                        <Col md="1" style={{ width: "11%" }}>
                          <Button variant="outlined" className="btn-recent">
                            <span className="fas fa-sort-amount-down"></span>{" "}
                            Recent First
                          </Button>
                        </Col>
                        <Col md="6">
                          <div className="chatList__search">
                            <div className="search_wrap">
                              <input
                                type="text"
                                placeholder="Search for mentions..."
                                required
                              />
                              <button className="search-btn">
                                <i className="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col md="4" style={{ marginTop: "10px" }}>
                          <Stack spacing={2}>
                            <Pagination count={10} color="primary" />
                          </Stack>
                        </Col>
                      </Row>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Recent Post's</CardTitle>
                  <hr />
                  <Col md="12" style={{ textAlignLast: "end" }}>
                    <div className="date">
                      <span
                        className="fa fa-calendar"
                        style={{ color: "#4462ff" }}
                      ></span>{" "}
                      30/04/2020 05:56
                    </div>
                  </Col>
                  <Row>
                    <Col xl="12">
                      <Row>
                        <Col md={1}>
                          <div
                            className="whatsapp1"
                            data-toggle="tab"
                            data-target="#whatsapp"
                          >
                            {" "}
                            <span className="fab fa-twitter"></span>{" "}
                          </div>
                        </Col>
                        <Col md="4" style={{ marginLeft: "-3%" }}>
                          <h6>
                            SRV InfoTech earnings: Latest News & Videos,
                            Photos..
                          </h6>

                          <Divider />
                          <Row>
                            <Col xl="12">
                              <Row>
                                <Col md="3">
                                  <a className="source" href="#">
                                    twitter.com
                                  </a>
                                </Col>
                                <Col md="1">
                                  <span className="divider"> </span>
                                </Col>
                                <Col md="4">
                                  <div className="score">
                                    <span>Score: 6/10</span>
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        role="progressbar"
                                        aria-valuenow="60"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: "60%" }}
                                      ></div>
                                    </div>
                                  </div>{" "}
                                </Col>
                                <Col md="1">
                                  <span className="divider"> </span>
                                </Col>
                                <Col md="1">
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        positive
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        label="positive"
                                        size="small"
                                        onChange={handleChange}
                                      >
                                        <MenuItem value={10}>Neutral</MenuItem>
                                        <MenuItem value={20}>Positive</MenuItem>
                                        <MenuItem value={30}>Negative</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <p style={{ marginTop: "10px" }}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <Divider />
                      <Row>
                        <Col xl="12">
                          <Row>
                            <Col md="6">
                              <Stack spacing={2} direction="row">
                                <li className="active">
                                  <a href="#">
                                    <span className="fa fa-share"></span> Engage
                                  </a>
                                </li>
                                <li className="active">
                                  <a href="#">
                                    <span className="fa fa-tags"></span> Tags
                                  </a>
                                </li>
                                <li className="active">
                                  <a href="#">
                                    <span className="fa fa-trash"></span> Delete
                                  </a>
                                </li>
                                <li className="active">
                                  <a href="#">
                                    <span className="fa fa-file-text"></span>{" "}
                                    Add to PDF Report
                                  </a>
                                </li>
                                <div
                                  className="moredrop dropdown"
                                  style={{ marginTop: "-8px" }}
                                >
                                  <button
                                    className="btn dropdown-toggle"
                                    type="button"
                                    id="dropdownMore"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <span className="fa fa-chevron-circle-down"></span>
                                    More
                                  </button>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMore"
                                  >
                                    <li>
                                      <a href="#">
                                        <span className="fa fa-check-square-o"></span>{" "}
                                        Mark
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span className="fa fa-envelope-o"></span>{" "}
                                        Share by email
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span className="fa fa-bell-slash-o"></span>{" "}
                                        Mute Author
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span className="fa fa-user-o"></span>{" "}
                                        Author Profile
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </Stack>
                            </Col>
                            {/* <Col md="6">
                              <Stack spacing={2} direction="row">
                                <li className="active">
                                  <a href="#">
                                    <span className="fa fa-tags"></span> Action
                                  </a>
                                </li>
                                <li className="active">
                                  <a href="#">
                                    <span className="fa fa-tags"></span> Action
                                  </a>
                                </li>
                              </Stack>
                            </Col> */}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <button className="btn-create">
                <span className="fa fa-plus"></span> Create a Post
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
