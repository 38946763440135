import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";
import jwt_decode from "jwt-decode";

import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Select from "react-select";
import $ from "jquery";
import {
  getAllAccountDetails,
  addAccountDetail,
  deleteAccountDetail,
  updateAccountDetail,
} from "../../../store/actions";

import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getLocalbody } from "../../../helpers/globalFunctions";

const Accdetails = (props) => {
  const [accdetailObject, setAccdetailObject] = useState({
    localbody_id: getLocalbody(),
  });
  const [accdetailTemp, setaccdetailTemp] = useState([]);

  const [accdetailIdTobeUpdated, setAccdetaildToBeUpdated] = useState(null);
  const [accdetailIdToBeDeleted, setAccdetailIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [selectedAccType, setSelectedAccType] = useState(null);
  const [acctypeGroup, setacctypeGroup] = useState([]);
  const [userId, setUserId] = useState(1);
  const [invalidacctype, setinvalidacctype] = useState("none");
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const {
    accdetails,
    addingAccdetail,
    addAccdetailResponse,
    deleteAccdetailResponse,
    updateAccdetailResponse,
    error,
  } = useSelector((state) => state.accdetails);

  const dispatch = useDispatch();
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    dispatch(getAllAccountDetails(currentLocalbody));
    fetch_all_accounttypes();
  }, [currentLocalbody]);

  useEffect(() => {
    if (addAccdetailResponse.type === "success") {
      toastr.success(addAccdetailResponse.message);
      dispatch(getAllAccountDetails(currentLocalbody));
    } else if (addAccdetailResponse.type === "failure") {
      toastr.error(error.data.message, addAccdetailResponse.message);
    }
  }, [addAccdetailResponse]);

  useEffect(() => {
    if (deleteAccdetailResponse.type === "success") {
      toastr.success(deleteAccdetailResponse.message);
      dispatch(getAllAccountDetails(currentLocalbody));
    } else if (deleteAccdetailResponse.type === "failure") {
      toastr.error(error.data.message, deleteAccdetailResponse.message);
    }
  }, [deleteAccdetailResponse]);

  useEffect(() => {
    if (updateAccdetailResponse.type === "success") {
      setAccdetaildToBeUpdated(null);
      toastr.success(updateAccdetailResponse.message);
      dispatch(getAllAccountDetails(currentLocalbody));
    } else if (updateAccdetailResponse.type === "failure") {
      toastr.error(error.data.message, updateAccdetailResponse.message);
    }
  }, [updateAccdetailResponse]);

  let preupdateAccountDetail = (item) => {
    item.localbody_id = getLocalbody();
    if (item.acc_type_id != null) {
      let acctype = {
        label: item.acc_type_id.acc_type_name,
        value: item.acc_type_id._id,
      };
      handleSelectedAccType(acctype);
    }
    setAccdetaildToBeUpdated(item._id);
    setAccdetailObject(item);
  };

  useEffect(() => {
    let accdetailDuplicate = JSON.parse(JSON.stringify(accdetails));
    let accdetailData = [];
    accdetailDuplicate.map((item, index) => {
      item.fname = "";
      if (item.acc_detail_added_by) {
        item.fname = item.acc_detail_added_by.username;
      }
      if (item.acc_type_id) {
        item.acc_type_name = item.acc_type_id.acc_type_name;
      }
      item.auth_userid = userId;
      item.action = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.5em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              toTop();
              preupdateAccountDetail(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: "1em", cursor: "pointer" }}
            onClick={() => {
              setAccdetailIdToBeDeleted(item._id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;

      accdetailData.push(item);
    });
    setaccdetailTemp(accdetailData);
  }, [accdetails]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },

      {
        label: "Account Type",
        field: "acc_type_name",
        sort: "asc",
        width: 70,
      },
      {
        label: "Account Detail Name",
        field: "acc_detail_name",
        sort: "asc",
        width: 70,
      },
      {
        label: "Added By",
        field: "fname",
        sort: "asc",
        width: 70,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: accdetailTemp,
    // rows: [
    //   {
    //     id: 1,
    //     acc_type_name: "Expense",
    //     acc_detail_name: "Salary",
    //     fname: "admin",
    //     action: (
    //       <div style={{ display: "flex", justifyContent: "center" }}>
    //         <i
    //           className="far fa-edit"
    //           style={{
    //             fontSize: "1em",
    //             cursor: "pointer",
    //             marginLeft: "0.5em",
    //             marginRight: "0.5em",
    //           }}
    //           onClick={() => {}}
    //         ></i>
    //         <i
    //           className="far fa-trash-alt"
    //           style={{ fontSize: "1em", cursor: "pointer" }}
    //           onClick={() => {}}
    //         ></i>
    //       </div>
    //     ),
    //   },
    //   {
    //     id: 2,
    //     acc_type_name: "Bank",
    //     acc_detail_name: "Office Account",
    //     fname: "admin",
    //     action: (
    //       <div style={{ display: "flex", justifyContent: "center" }}>
    //         <i
    //           className="far fa-edit"
    //           style={{
    //             fontSize: "1em",
    //             cursor: "pointer",
    //             marginLeft: "0.5em",
    //             marginRight: "0.5em",
    //           }}
    //           onClick={() => {}}
    //         ></i>
    //         <i
    //           className="far fa-trash-alt"
    //           style={{ fontSize: "1em", cursor: "pointer" }}
    //           onClick={() => {}}
    //         ></i>
    //       </div>
    //     ),
    //   },
    //   {
    //     id: 3,
    //     acc_type_name: "Income",
    //     acc_detail_name: "Invoice",
    //     fname: "admin",
    //     action: (
    //       <div style={{ display: "flex", justifyContent: "center" }}>
    //         <i
    //           className="far fa-edit"
    //           style={{
    //             fontSize: "1em",
    //             cursor: "pointer",
    //             marginLeft: "0.5em",
    //             marginRight: "0.5em",
    //           }}
    //           onClick={() => {}}
    //         ></i>
    //         <i
    //           className="far fa-trash-alt"
    //           style={{ fontSize: "1em", cursor: "pointer" }}
    //           onClick={() => {}}
    //         ></i>
    //       </div>
    //     ),
    //   },
    // ],
  };
  function fetch_all_accounttypes() {
    axios
      .get(`${API_URL}account_type/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var brand_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.acc_type_name,
              value: item._id,
            };
          });

        setacctypeGroup([
          {
            options: brand_data,
          },
        ]);
      });
  }

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAccdetailObject({
      ...accdetailObject,
      [name]: value,
    });
  };

  function handleSelectedAccType(type) {
    if (type.value) {
      setinvalidacctype("none");
      $("#acc_type_id").attr("class", "select2-selection");
    } else {
      setinvalidacctype("block");
      $("#acc_type_id").attr("class", "select2-selection border border-danger");
    }

    setSelectedAccType(type);
    setAccdetailObject({
      ...accdetailObject,
      ["acc_type_id"]: type.value,
    });
  }

  const handleValidSubmitAccdetail = (event, values) => {
    if (selectedAccType == null) {
      setinvalidacctype("block");
      $("#acc_type_id").attr("class", "select2-selection border border-danger");
      toTop();
    } else {
      if (accdetailIdTobeUpdated) {
        axios
          .put(`${API_URL}account_detail`, accdetailObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Account details updated successfully");
              formRef.current.reset();
              setAccdetailObject({
                ["auth_userid"]: userId.toExponential,
                ["localbody_id"]: getLocalbody(),
              });
              dispatch(getAllAccountDetails(currentLocalbody));
              setSelectedAccType(null);
              setAccdetaildToBeUpdated(null);
            } else {
              toastr.error("Failed to update account details");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}account_detail`, accdetailObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Account details added successfully");
              formRef.current.reset();
              setAccdetailObject({
                ["auth_userid"]: userId.toExponential,
                ["localbody_id"]: getLocalbody(),
              });
              dispatch(getAllAccountDetails(currentLocalbody));
              setSelectedAccType(null);
            } else {
              toastr.error("Failed to add account details");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
      // accdetailIdTobeUpdated
      //   ? dispatch(updateAccountDetail(accdetailObject))
      //   : dispatch(addAccountDetail(accdetailObject));
    }
  };
  const reset = () => {
    formRef.current.reset();
    setAccdetailObject({
      ["auth_userid"]: userId.toExponential,
      ["localbody_id"]: getLocalbody(),
    });
    setAccdetailIdToBeDeleted(null);
    setAccdetaildToBeUpdated(null);
    setSelectedAccType(null);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}account_detail` + "/" + accdetailIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Account details deleted successfully");
                  if (
                    accdetailObject &&
                    accdetailObject?._id === accdetailIdToBeDeleted
                  ) {
                    formRef.current.reset();
                    setAccdetailObject({
                      ["auth_userid"]: userId.toExponential,
                      ["localbody_id"]: getLocalbody(),
                    });
                    setSelectedAccType(null);
                    setAccdetaildToBeUpdated(null);
                  }
                  dispatch(getAllAccountDetails(currentLocalbody));
                  // setConfirmDeleteAlert(false);
                } else {
                  toastr.error("Failed to delete account details");
                  return;
                }
              });
            // dispatch(deleteAccountDetail(accdetailIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Account Detail" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitAccdetail(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Account Type
                          </Label>
                          <Select
                            name="acc_type_id"
                            value={selectedAccType}
                            onChange={(value) => {
                              handleSelectedAccType(value);
                            }}
                            id="acc_type_id"
                            options={acctypeGroup}
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                        <div
                          style={{
                            display: invalidacctype,
                            Width: "100%",
                            marginTop: "0.25rem",
                            color: "#f46a6a",
                            fontSize: "87.5%",
                          }}
                        >
                          Select Account Type
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Account Detail Name
                          </Label>

                          <AvField
                            name="acc_detail_name"
                            value={accdetailObject.acc_detail_name}
                            placeholder="Account Detail Name"
                            type="text"
                            errorMessage="Enter Account Detail Name"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[A-Za-z ]+$",
                                errorMessage:
                                  "Account Details Name must be only with letter and spaces",
                              },
                            }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div style={{display:"flex"}} >
                       
                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          {accdetailIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingAccdetail ? true : false}
                            >
                              {addingAccdetail ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingAccdetail ? true : false}
                            >
                              {addingAccdetail ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                     
                        <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="account_type_table"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Accdetails;
