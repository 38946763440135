import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import toastr from "toastr";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { getAllSlider, addSlider } from "../../../store/actions";
import {
  getCompany,
  getLocalbody,
  getDateAndTime,
} from "../../../helpers/globalFunctions";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import "./slider.scss";
const ImagePopupView = (props) => {
  const [selectedVisibleTo, setSelectedVisibleTo] = useState(null);
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [sliderView, setSliderView] = useState(false);
  const [tableData, setTableData] = useState({});
  const [uploadProgress, setUploadProgress] = useState();
  const [sliderIdToBeDeleted, setsliderIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [sliderIdToBeUpdated, setSliderIdToBeUpdated] = useState(null);
  const [localbodyOptions, setLoacalbodyOptions] = useState([]);
  const [selectedLocalbody, setSelectedLocalbody] = useState(null);
  const [errors, setErrors] = useState({
    imageError: "",
    visibleToError: "",
    localbodyError: "",
    fromDateError: "",
    toDateError: "",
  });
  const [date, setDate] = useState(getDateAndTime(new Date()));
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const dispatch = useDispatch();
  const formRef = useRef();

  const { sliders, addSliderResponse } = useSelector(
    (state) => state.sliders
  );
  const [currentCompany, setCurrectCompany] = useState(getCompany());

  useEffect(() => {
    dispatch(getAllSlider(currentLocalbody));
    fetch_all_localbodies();
  }, [currentLocalbody]);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const fetch_all_localbodies = () => {
    axios
      .get(`${API_URL}localbody/alllist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let localbody_data =
          res.data.data &&
          res.data.data.map((item, index) => {
            return {
              label: item.localbody_name,
              value: item._id,
            };
          });
        setLoacalbodyOptions([
          {
            options: localbody_data,
          },
        ]);
      });
  };
  const toggleWasteActiveStatus = (id) => {
    let data = {
      id: id,
    };
    axios
      .put(`${API_URL}popup/toggle?id=` + id, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          dispatch(getAllSlider(currentLocalbody));
        }
      });
  };
  useEffect(() => {
    let popupData = JSON.parse(JSON.stringify(sliders));
    popupData.map((sliders, index) => {
      sliders.id = index + 1;
    
      sliders.localbody = (
        <div>
          <ul>
            {sliders.localbody &&
              sliders.localbody.map((element, index) => {
                return <li>{element.localbody_name}</li>;
              })}
          </ul>
        </div>
      );
   
      // sliders.localbodyName = "";
      // sliders.localbody &&
      // sliders.localbody.map((el) => {
      //     if (sliders.localbodyName === "")
      //     sliders.localbodyName = el?.localbody_name;
      //     else
      //     sliders.localbodyName =
      //     sliders.localbodyName + "," + el?.localbody_name;
      //   });
      // if (sliders.popup_visible === 0) item.visible_to = "All";
      // else if (item.popup_visible === 1) item.visible_to = "Staff";
      // else item.visible_to = "Customer";

      // item.from = moment(item?.popup_frmdatetime).format("DD-MM-YYYY,hh:mm a");
      // item.to = moment(item?.popup_todatetime).format("DD-MM-YYYY,hh:mm a");

      // let fromDate = moment(item?.from_date).format("DD-MM-YYYY");
      // let fromTime = moment(item.from_time, "HHmm").format("hh:mm a");
      // item.from = fromDate + "," + fromTime;

      // let toTime = moment(item.to_time, "HHmm").format("hh:mm a");
      // item.to = item?.to_date + "," + toTime;
      if (sliders?.up_date)
      sliders.date = moment(sliders?.up_date).format(
        "DD-MM-YYYY"
      );
    else if (sliders?.date)
      sliders.date = moment(sliders?.date).format("DD-MM-YYYY");
    else sliders.date = "";

    if (sliders?.up_time)
      sliders.time = moment(sliders?.up_time, "hhmmss").format(
        "hh:mm a"
      );
    else if (sliders?.time)
      sliders.time = moment(sliders?.time, "hhmmss").format(
        "hh:mm a"
      );
    else sliders.time = "";
    if (sliders?.updated_by) {
      let updated_by = sliders.updated_by || {};
      if (updated_by?.lastName)
        sliders.staff = updated_by?.firstName + " " + updated_by?.lastName;
      else sliders.staff = updated_by?.firstName;
    } else {
      let added_by = sliders.added_by || {};
      if (added_by?.lastName)
        sliders.staff = added_by?.firstName + " " + added_by?.lastName;
      else sliders.staff = added_by?.firstName;
    }
      sliders.image = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={`${API_URL}uploads/slider_images/${sliders.image}`}
            height="50"
            width="50"
          />
        </div>
      );
      // item.status = (
      //   <div
      //     style={{ width: "100%", display: "flex", justifyContent: "center" }}
      //   >
      //     <div
      //       className="form-check form-switch mb-3"
      //       style={{ textAlign: "center" }}
      //     >
      //       <input
      //         type="checkbox"
      //         className="form-check-input"
      //         id="customSwitch2"
      //         checked={item.popup_active_status === 0 ? true : false}
      //         onChange={(e) => {
      //           toggleWasteActiveStatus(item._id);
      //           // settoggleSwitch(!toggleSwitch);
      //         }}
      //         style={{ cursor: "pointer" }}
      //       />
      //     </div>
      //   </div>
      // );
      sliders.action = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <i
            className="fas fa-eye"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.2em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              handleClickOpenForm(sliders);
            }}
          ></i> */}
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.1em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              toTop();
              preUpdateSlider(sliders);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: "1em", cursor: "pointer" }}
            onClick={() => {
              setsliderIdToBeDeleted(sliders._id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
    });

    setDetails(popupData);
  }, [sliders]);

  const handleClickOpenForm = (item) => {
    item.localbodyName = "";
    item.popup_local_body &&
      item.popup_local_body.map((el) => {
        if (item.localbodyName === "") item.localbodyName = el;
        else item.localbodyName = item.localbodyName + "," + "\n" + el;
      });
    // item.createdAt = moment(item?.popup_date).format("MM-DD-YYYY");
    let fromDate = moment(item?.from_date).format("DD-MM-YYYY");
    let fromTime = moment(item.from_time, "HHmm").format("hh:mm a");
    item.from = fromDate + "," + fromTime;

    let toTime = moment(item.to_time, "HHmm").format("hh:mm a");
    item.to = item?.to_date + "," + toTime;
    setTableData(item);
    setSliderView(true);
  };

  let preUpdateSlider = (item) => {
    setSliderIdToBeUpdated(item._id);
    axios
      .get(`${API_URL}slider/view/?slider_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let element = res.data.data;
        // result &&
        //   result.map((element, index) => {
           // element.id = index + 1;
            //let options = {};
            // if (element?.popup_visible === 0) {
            //   options = {
            //     label: "All",
            //     value: 0,
            //   };
            // } else if (element?.popup_visible === 1) {
            //   options = {
            //     label: "Staff",
            //     value: 1,
            //   };
            // } else if (element?.popup_visible === 2) {
            //   options = {
            //     label: "Customer",
            //     value: 2,
            //   };
            // }
            // element.popup_localbody = [getLocalbody()];
            let localbodyOptions = [];
            let sliderLocalbody = [];
            element.localbody &&
              element.localbody.map((el) => {
                let val = {};
                val.label = el.localbody_name;
                val.value = el._id;
                //let localbodyOptions=element.localbody.localbody_name;
                
                localbodyOptions.push(val);
                sliderLocalbody.push(el._id);
              });
            setSelectedLocalbody(localbodyOptions);
            element.localbody = sliderLocalbody;
            // setSelectedVisibleTo(options);
            
          // });
        setMaster(element);
        
      });
  };
  const uploadImage = (e) => {
    let val = e.target.files[0];

    if (val?.size > 102400)
      toastr.error("Image size should be less than or equal to 100 Kb");
    else {
      const fd = new FormData();
      fd.append("image", e.target.files[0]);
      axios
        .post(`${API_URL}slider/uploadImage`, fd, {
          headers: {
            // "Content-Type": "multipart/form-data",
            "x-access-token": accessToken,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setUploadProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            setMaster({
              ...master,
              [`image`]: response.data.file.filename,
            });
            setErrors({
              ...errors,
              imageError: "",
            });
            // toastr.success("Image Uploaded");
          } else {
            toastr.error(response.data.message);
          }
        });
    }
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        // width: 10,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        // width: 10,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        // width: 10,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        // width: 70,
      },
      {
        label: "Localbody",
        field: "localbody",
        sort: "asc",
        // width: 70,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        // width: 90,
      },
      {
        label: "Url",
        field: "link",
        sort: "asc",
        // width: 10,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        // width: 70,
      },
   
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        // width: 100,
      },
    ],
    rows: details,
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "visibleTo":
        setSelectedVisibleTo(selected);
        setMaster({
          ...master,
          ["popup_visible"]: selected.value,
        });
        setErrors({
          ...errors,
          visibleToError: "",
        });
        break;
      case "localbody":
        let arrValue = [];
        selected &&
          selected.forEach((element) => {
            arrValue.push(element.value);
          });
        setSelectedLocalbody(selected);
        setMaster({
          ...master,
          ["localbody"]: arrValue,
        });
        setErrors({
          ...errors,
          localbodyError: "",
        });
        break;
      default:
        break;
    }
  };
  const handleDate = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "popup_frmdatetime") {
      setErrors({
        ...errors,
        fromDateError: "",
      });
    } else {
      setErrors({
        ...errors,
        toDateError: "",
      });
    }
  };
  const handleChangeInput = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {
        if (selectedLocalbody === null || !master?.image)
        {
      let errorValue = errors;
      
      if (selectedLocalbody === null) {
        errorValue.localbodyError = "Please select localbody";
      }
      if (!master?.image) {
        errorValue.imageError = "Please select image";
      }
     
      setErrors({
        ...errorValue,
      });
      return;
    }

    if (sliderIdToBeUpdated) {
      axios
        .put(`${API_URL}slider/update` , master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Slider updated successfully");
            dispatch(getAllSlider(currentLocalbody));
            setSliderIdToBeUpdated(null);
            setMaster({
              [`image`]: "",
            });
            setSelectedLocalbody(null);
            formRef.current.reset();
            
            setErrors({
              imageError: "",
           
              localbodyError: "",
           
            });
          } else {
            toastr.error("Failed to update popup");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}slider`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Slider created successfully");
            dispatch(getAllSlider(currentLocalbody));
            setMaster({
              [`image`]: "",
            });
            // setSliderIdToBeUpdated(null);
            setSelectedLocalbody(null);
            formRef.current.reset();
         
            setErrors({
              imageError: "",
         
              localbodyError: "",
             
            });
          } else {
            toastr.error("Failed to create slider");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }

    // dispatch(addPopup(master));
  };
  const deleteSliderImage = () => {
    setMaster({
      ...master,
      [`image`]: "",
    });
    setErrors({
      ...errors,
      imageError: "",
    });
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}slider`+"/" + sliderIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Slider deleted successfully");
                  if (master && master?._id === sliderIdToBeDeleted) {
                    setSliderIdToBeUpdated(null);
                    // setSelectedVisibleTo(null);
                    setSelectedLocalbody(null);
                    formRef.current.reset();
                    // setMaster({
                    //   popup_frmdatetime: "",
                    //   popup_todatetime: "",
                    // });
                    setErrors({
                      imageError: "",
                      visibleToError: "",
                      localbodyError: "",
                      fromDateError: "",
                      toDateError: "",
                    });
                  }
                  dispatch(getAllSlider(currentLocalbody));
                } else {
                  toastr.error("Failed to delete slider");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
  
      <Modal
        show={sliderView}
        toggle={() => setSliderView(!sliderView)}
        size="lg"
        centered={true}
      >
          
        <div className="modal-header">
          <h5 className="modal-title mt-0">Slider Details </h5>
          <button
            type="button"
            onClick={() => {
              setSliderView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
       
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px" }}>Name :</td>
                <td>{tableData?.name}</td>
                <td>Date :</td>
                <td style={{ textAlign: "left" }}>{tableData?.date}</td>
              </tr>
              <tr>
                {/* <td style={{ padding: "10px" }}>Localbody :</td>
                <td style={{ width: "296.59px" }}>
                  {tableData?.localbody}
                </td> */}
                <td>Time :</td>
                <td style={{ textAlign: "left" }}>{tableData?.time}</td>
              </tr>
              {/* <tr>
                <td style={{ padding: "10px" }}>From :</td>
                <td>{tableData?.from}</td>
                <td>To :</td>
                <td style={{ textAlign: "left" }}>{tableData?.to}</td>
              </tr> */}
              <tr>
                <td style={{ padding: "10px" }}>Image :</td>
                <td style={{ textAlign: "left" }}>
                  <img
                    style={{
                      width: "180px",
                      height: "180px",
                      paddingLeft: "0px",
                    }}
                    src={`${API_URL}uploads/slider_images/${tableData?.image}`}
                  ></img>
                </td>
         
                {/* <td>Status :</td>
                <td style={{ textAlign: "left" }}>
                  {tableData?.popup_active_status === 0 ? (
                    <Label style={{ color: "green", paddingTop: "18px" }}>
                      Active
                    </Label>
                  ) : (
                    <Label style={{ color: "red", paddingTop: "18px" }}>
                      Inactive
                    </Label>
                  )}
                </td> */}
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Slider" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Name</Label>
                          <AvField
                            name="name"
                            type="text"
                            errorMessage="Enter Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.name}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                     
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Localbody</Label>
                          <Select
                            isMulti
                            name="localbody"
                            value={selectedLocalbody}
                            onChange={(selected) => {
                              handleSelectChange(selected, "localbody");
                            }}
                            options={localbodyOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedLocalbody === null
                              ? errors.localbodyError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">URL</Label>
                          <AvField
                            name="link"
                       
                            type="text"
                           
                            className="form-control"
                           
                            id="validationCustom05"
                            value={master?.link}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      
                    
                      <Col md="3" style={{ display: "block" }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Choose Image
                          </Label>
                          {master?.image ? (
                            <div div className="img-wraps">
                              {master.image &&
                              master.image.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${master?.image}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/slider_images/${master?.image}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteSliderImage}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master?.image ? errors.imageError : ""}
                          </p>
                        </div>

                        {/* <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Choose Image
                          </Label>
                          <AvField
                            name="image_upload"
                            // value={productObject.product_seo_keys}
                            // placeholder="Product Image 1"
                            type="file"
                            errorMessage="Choose image"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={uploadImage}
                            rows="1"
                          />
                        </div> */}
                      </Col>
                      <Col md="1" style={{ paddingTop: "5px" }}>
                        <div className="mt-1">
                          {sliderIdToBeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="sliderTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ImagePopupView;
