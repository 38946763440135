import React, { useEffect, useRef, useState } from "react"
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Label, Modal, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import { getLocalbody } from "../../helpers/globalFunctions";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";


function Events() {


    const formRef = useRef()
    const [master, setMaster] = useState({ localBody: getLocalbody() })
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
    const API_URL = process.env.REACT_APP_APIURL || "https://localhost:3099/";

    const [uploadProgress, setUploadProgress] = useState();
    const [tableData, setTableData] = useState([])
    const [eventIdToBeUpdated, setEventIdToBeUpdated] = useState(null)
    const [eventIdToBeDeleted, setEventIdToBeDeleted] = useState(null)
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false)


    useEffect(() => {
        handleTableData()
    }, []);


    function handleInputChange(e) {
        let { name, value } = e.target;
        setMaster({ ...master, [name]: value })
    }

    const uploadImage = (e) => {
        const fd = new FormData();
        fd.append("uploadedFile", e.target.files[0]);
        axios
            .post(`${API_URL}events/image/upload`, fd, {
                headers: {
                    "x-access-token": accessToken,
                },
                onUploadProgress: (data) => {
                    //Set the progress value to show the progress bar
                    setUploadProgress(Math.round((100 * data.loaded) / data.total));
                },
            })
            .then((response) => {
                if (response.data.status === 200) {
                    setMaster({
                        ...master,
                        [`image`]: response.data.data.filename,
                    });
                    toastr.success("Image Uploaded successfully");
                } else {
                    toastr.error(response.data.message);
                }
            });
    };

    function deleteImage() {
        setMaster({ ...master, [`image`]: "" })
    }


    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0]

        const allowedTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
            "text/plain",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ]

        if (!allowedTypes.includes(selectedFile?.type)) {
            toastr.error(
                "Please select a valid file type (JPEG, PNG, PDF, TXT, DOC, DOCX, XLS, XLSX)"
            )
            return
        }
        const fd = new FormData()
        fd.append("pdf", e.target.files[0])
        axios.post(`${API_URL}events/pdf/uploads`, fd).then(response => {
            if (response.data.status === 200) {
                // handleDocumentValueChange(
                //     response.data.data.filename,
                //     "file"
                // )
                setMaster({
                    ...master,
                    [`pdf`]: response.data.data.filename
                })
                toastr.success("Document uploaded successfully")
            } else {
                toastr.error(response.data.message)
            }
        })
    }

    function handleDocumentValueChange() {
        setMaster({ ...master, [`pdf`]: "" })
    }

    function handleSubmit() {
        if (eventIdToBeUpdated) {
            axios
                .put(`${API_URL}events/edit/${eventIdToBeUpdated}`, master, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }).then(res => {
                    toastr.success(res.data.message)
                    reset()
                    setEventIdToBeUpdated(null)
                }).catch(err => {
                    toastr.error(err.response.data.message)
                })
        } else {
            axios
                .post(`${API_URL}events/add`, master, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }).then(res => {
                    toastr.success(res.data.message)
                    reset()
                }).catch(err => {
                    toastr.error(err.response.data.message)
                })
        }
    }

    function reset() {
        formRef.current.reset()
        handleTableData()
        setMaster({
            ...master,
            pdf: "",
            image: "",
            name: "",
            category: "",
            content: "",
            subject: ""
        })
    }

    function handleTableData() {
        axios
            .get(`${API_URL}events/list`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {
                let result = res.data.events;

                result.map((item, indx) => {

                    item.id = indx + 1;
                    item.Date = moment(item.date).format("DD-MM-YYYY")

                    item.action = (
                        <div style={{ justifyContent: "center", display: "flex" }}>
                            <i className="far fa-edit"
                                style={{
                                    cursor: "pointer",
                                    fontSize: "1em",
                                    marginRight: "0.5rem",
                                    marginLeft: "0.2rem"
                                }}
                                onClick={() => {
                                    toTop()
                                    handleUpdate(item)
                                }}
                            ></i>
                            <i className="far fa-trash-alt"
                                style={{
                                    cursor: "pointer",
                                    fontSize: "1em",
                                }}
                                onClick={() => {
                                    setEventIdToBeDeleted(item._id)
                                    setConfirmDeleteAlert(true)
                                }}
                            ></i>
                        </div>
                    )

                    if (item.status === 0) {
                        item.eventStatus = (
                            <h6 style={{ color: "green", textAlign: 'center' }}>Active</h6>
                        )
                    } else {
                        item.eventStatus = (
                            <h6 style={{ color: "red", textAlign: 'center' }}>Inactive</h6>
                        )
                    }
                })
                setTableData(result)
            })
    }

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 50,
            },
            {
                label: "Date",
                field: "Date",
                width: 50,
            },
            {
                label: "Event",
                field: "name",
                width: 50,
            },
            {
                label: "Category",
                field: "category",
                width: 50,
            },
            {
                label: "Content",
                field: "content",
                width: 50,
            },
            {
                label: "Subject",
                field: "subject",
                width: 50,
            },
            {
                label: "Status",
                field: "eventStatus",
                width: 50,
            },
            {
                label: "Action",
                field: "action",
                width: 50,
            },
        ],
        rows: tableData
    }

    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }

    function handleUpdate(item) {
        setEventIdToBeUpdated(item._id)

        setMaster({
            ...master,
            name: item.name,
            category: item.category,
            content: item.content,
            subject: item.subject,
            pdf: item.pdf,
            image: item.image
        })
    }


    return (
        <>
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}events/delete` + "/" + eventIdToBeDeleted, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                toastr.success(res.data.message)
                                handleTableData()
                                reset()
                                setEventIdToBeUpdated(null)
                            });
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}>
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Events" />

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <AvForm className="needs-validation" ref={formRef}
                                        onValidSubmit={(e, v) => {
                                            handleSubmit(e, v)
                                        }}
                                    >

                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Event</Label>
                                                    <AvField
                                                        name="name"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Event Name"
                                                        value={master?.name}
                                                        onChange={(e) => handleInputChange(e)}
                                                        errorMessage="Enter Name"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Category</Label>
                                                    <AvField
                                                        name="category"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Category"
                                                        value={master?.category}
                                                        onChange={(e) => handleInputChange(e)}
                                                        errorMessage="Enter Category"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Subject</Label>
                                                    <AvField
                                                        name="subject"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Subject"
                                                        value={master?.subject}
                                                        onChange={(e) => handleInputChange(e)}
                                                        errorMessage="Enter Subject"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Content</Label>
                                                    <AvField
                                                        name="content"
                                                        className="form-control"
                                                        type="textarea"
                                                        rows={1}
                                                        placeholder="Content"
                                                        value={master?.content}
                                                        onChange={(e) => handleInputChange(e)}
                                                        errorMessage="Enter Content"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom03">Upload Image</Label>
                                                    {master?.image ? (
                                                        <div div className="img-wraps">
                                                            {master.image && master.image.startsWith("http") ? (
                                                                <img alt="" width="150" height="150" src={`${master.image}`} />
                                                            ) : (
                                                                <img alt="" width="150" height="150" src={`${API_URL}uploads/events/image/${master.image}`} />
                                                            )}
                                                            <button
                                                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                                                onClick={deleteImage}
                                                                style={{ width: "150px" }}>
                                                                Delete
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <AvField name="image" type="file" className="form-control" id="validationCustom03" onChange={uploadImage} rows="1" />
                                                    )}
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-1">
                                                    <Label>Upload Document</Label>
                                                    {master?.pdf ? (
                                                        <div
                                                            div
                                                            className="img-wraps d-flex flex-column"
                                                        >
                                                            <file
                                                                alt=""
                                                                width="150"
                                                                height="150"
                                                                src={`${API_URL}uploads/events/pdf/${master.pdf}`}
                                                            />
                                                            {master.pdf}
                                                            <button
                                                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                                                onClick={() => {
                                                                    handleDocumentValueChange(
                                                                        "",
                                                                        "file"
                                                                    )
                                                                }}
                                                                style={{ width: "150px" }}
                                                                type="button"
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <AvField
                                                            name="pdf"
                                                            type="file"
                                                            className="form-control"
                                                            onChange={e => {
                                                                handleFileUpload(e)
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </Col>

                                            <Col md="3" style={{ marginTop: "25px" }}>
                                                {eventIdToBeUpdated ? (
                                                    <Button type="update" color="primary">Update</Button>
                                                ) : (
                                                    <Button type="submit" color="primary">Submit</Button>
                                                )}
                                            </Col>

                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="eventTableId"
                                        responsive
                                        bordered
                                        data={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </div>
            </div>
        </>
    )

}

export default Events;