import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import { getLocalbody, formatMoney } from "../../helpers/globalFunctions";
import Select from "react-select";
import moment from "moment";
import { Modal } from "react-bootstrap";
import "./creditnote.scss";


const AddCreditNote = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    customerError: "",
  });
  const [popupData, setPopuData] = useState({});
  const [popup, setPopup] = useState(false);
  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    fetch_all_customers(currentLocalbody, "");
    handleTableData();
  }, [currentLocalbody]);

  function handleTableData() {
    let data = {
      localbody_id: currentLocalbody,
    };
    axios
      .post(`${API_URL}customers/creditnotelist`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.date = moment(item?.credit_note_date).format("DD-MM-YYYY");
            item.time = moment(item?.credit_note_time, "hhmmss").format(
              "hh:mm a"
            );
            item.amount = formatMoney(item?.credit_note_creditamt);
            item.due = formatMoney(item?.credit_note_dueamt);
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    showDeleteForm(item);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  }
  const showDeleteForm = (item) => {
    let user_id = "";
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      user_id = obj._id;
    }
    setPopuData({
      user_id: user_id,
      id: item?._id,
    });
    setPopup(true);
  };
  const fetch_all_customers = (localbody, query) => {
    let data = {
      keyword: query,
      localbody_id: [localbody],
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
              due: customer?.cust_due,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const handleCustomerSearch = (query) => {
    let data = {
      keyword: query,
      localbody_id: currentLocalbody,
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
              due: customer?.cust_due,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const handleSelectChange = (selected) => {
    setSelectedCustomer(selected);
    setMaster({
      ...master,
      customer_id: selected.value,
      total_due: Number(selected.due),
    });
    setErrors({ customerError: "" });
  };
  const handleValidSubmit = () => {
    if (selectedCustomer === null) {
      setErrors({
        customerError: "Please select customer",
      });
    } else if (master?.amount > master?.total_due || master?.total_due == 0) {
      if (master?.total_due == 0) {
        toastr.error("Amount can't be added when total due is zero");
      } else toastr.error("Amount should be less than or equal to total due");
    } else {
      setIsLoading(true);
      axios
        .post(`${API_URL}customers/creditnote`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Credit note created sucessfully");
            formRef.current.reset();
            setSelectedCustomer(null);
            handleTableData();
            setErrors({
              customerError: "",
            });
            setMaster({
              customer_id: "",
              total_due: "",
              amount: null,
              comment: "",
            });
          } else {
            toastr.error("Failed to create credit note");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          setIsLoading(false);
        });
    }
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ref.No",
        field: "credit_note_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "ID",
        field: "credit_note_cust_reg",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "credit_note_cust_name",
        width: 300,
      },
      {
        label: "Amount",
        field: "amount",
        width: 300,
      },
      {
        label: "Due",
        field: "due",
        width: 300,
      },
      {
        label: "Staff",
        field: "staff",
        width: 300,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const reset = () => {
    formRef.current.reset();
    setSelectedCustomer(null);
    setMaster({
      customer_id: "",
      total_due: "",
      amount: null,
      comment: "",
    });
    setErrors({ customerError: "" });
  };
  const handlePopupInputChange = (e) => {
    setPopuData({
      ...popupData,
      [e.target.name]: e.target.value,
    });
  };
  const handleClosePopup = () => {
    setPopuData({});
    setPopup(false);
  };
  const deleteCreditNote = () => {
    axios
      .post(`${API_URL}customers/delete_creditnote`, popupData, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("Deleted successfully");
          setPopup(false);
          handleTableData();
          setPopuData({});
        } else toastr.error("Failed to delete credit note");
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  return (
    <React.Fragment>
      <Modal show={popup} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Delete Credit Note</h5>
          <button
            type="button"
            onClick={() => {
              handleClosePopup();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            deleteCreditNote();
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              {/* <Label>Reason</Label> */}
              <AvField
                name="reason"
                className="form-control"
                placeholder="Enter reason"
                type="textarea"
                errorMessage="Please enter reason"
                validate={{ required: { value: true } }}
                id="validationCustom04"
                value={popupData?.reason}
                onChange={handlePopupInputChange}
              />
            </div>
            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <button
                  className="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                >
                  Submit
                </button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Add Credit Note" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Customer</Label>
                          <Select
                            name="cust_id"
                            value={selectedCustomer}
                            onChange={(selected) => {
                              handleSelectChange(selected);
                            }}
                            options={customerOptions}
                            onInputChange={(input) =>
                              handleCustomerSearch(input)
                            }
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedCustomer === null
                              ? errors.customerError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            name="total_due"
                            label="Total Due"
                            placeholder="Total Due"
                            type="number"
                            className="form-control"
                            id="validationCustom02"
                            value={master?.total_due}
                            onChange={handleInputChange}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            name="amount"
                            label="Amount"
                            placeholder="Amount"
                            type="number"
                            errorMessage="Enter Amount"
                            className="form-control"
                            id="validationCustom02"
                            value={master?.amount}
                            validate={{ required: { value: true } }}
                            onChange={handleInputChange}
                            min={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            name="comment"
                            label="Comments"
                            placeholder="Comments"
                            type="textarea"
                            errorMessage="Enter comments"
                            className="form-control"
                            id="validationCustom02"
                            value={master?.comment}
                            validate={{ required: { value: true } }}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col
                        md="3"
                        className="mt-3"
                      // style={{ paddingTop: "4px" }}
                      >
                        <div>
                          <Button color="primary" type="submit"
                            disabled={isLoading ? "disabled" : ""}
                          >
                            {isLoading ? "Submitting" : "Submit"}
                          </Button>
                          <Button
                            color="danger"
                            type="reset"
                            style={{ marginLeft: "14px" }}
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="addCreditnoteTableID"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AddCreditNote;
