import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";
// Redux
import { connect } from "react-redux";
import { withRouter,useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getLocalbody } from "../../../helpers/globalFunctions";
import "./style.scss";

import {
  addChartOfAcc,
  updateChartOfAcc,
  getAllChartOfAcc,
  deleteChartOfAcc,
} from "../../../store/actions";
const Chart = (props) => {
  const [chartOfAccList, setChartOfAccList] = useState([]);
  const [accountDetailType, setAccountDetailType] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [chartOfAccIdTobeUpdated, setChartOfAccIdTobeUpdated] = useState(null);
  const [chartOfAccIdToBeDeleted, setChartOfAccIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [master, setMaster] = useState({
    localbody_id: getLocalbody(),
  });
  const [userId, setUserId] = useState(1);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [searchData, setSearchData] = useState({

  });
  const [selectedAccountTypeSearch, setSelectedAccountTypeSearch] = useState("");
  const [acctypeGroup, setacctypeGroup] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory();
  const formRef = useRef();

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const {
    chartOfAccounts,
    addingChartOfAccount,
    addChartOfAccountResponse,
    updateChartOfAccountResponse,
    error,
    deleteChartOfAccountResponse,
  } = useSelector((state) => state.chartOfAccounts);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    dispatch(getAllChartOfAcc(currentLocalbody));
    fetch_account_type();
    fetch_all_accounttypes();
  }, [currentLocalbody]);

  useEffect(() => {
    if (addChartOfAccountResponse.type === "success") {
      toastr.success(addChartOfAccountResponse.message);
      dispatch(getAllChartOfAcc(currentLocalbody));
    } else if (addChartOfAccountResponse.type === "failure") {
      toastr.error(error.data.message, addChartOfAccountResponse.message);
    }
  }, [addChartOfAccountResponse]);

  useEffect(() => {
    if (updateChartOfAccountResponse.type === "success") {
      setChartOfAccIdTobeUpdated(null);
      toastr.success(updateChartOfAccountResponse.message);
      dispatch(getAllChartOfAcc(currentLocalbody));
    } else if (updateChartOfAccountResponse.type === "failure") {
      toastr.error(error.data.message, updateChartOfAccountResponse.message);
    }
  }, [updateChartOfAccountResponse]);

  useEffect(() => {
    if (deleteChartOfAccountResponse.type === "success") {
      toastr.success(deleteChartOfAccountResponse.message);
      dispatch(getAllChartOfAcc(currentLocalbody));
    } else if (deleteChartOfAccountResponse.type === "failure") {
      toastr.error(error.data.message, deleteChartOfAccountResponse.message);
    }
  }, [deleteChartOfAccountResponse]);
  useEffect(() => {
    let chartOfAccountData = JSON.parse(JSON.stringify(chartOfAccounts));
    let data = [];
    chartOfAccountData.map((item, index) => {
   
      item.fname = "";
      if (item.acc_chart_added_by) {
        item.fname = item.acc_chart_added_by.username;
      }
      item.auth_userid = userId;
      item.acc_chart_balance = parseFloat(item.acc_chart_balance).toFixed(2);
      if (item.acc_detail_id) {
        item.acc_detail_name = item.acc_detail_id.acc_detail_name;
      }
      if (item.acc_detail_id.acc_type_id) {
        item.acc_type_name = item.acc_detail_id.acc_type_id.acc_type_name;
      }
      item.action = (
        <div class="action">
          <i
            className="fas fa-eye"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.2em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              history.push("/transaction-log/" + item._id)
              // window.location.assign("/transaction-log/" + item._id);
            }}
          ></i>
          <i
            className="far fa-edit"
            onClick={() => {
              toTop();
              preupdateChartOfAccount(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            onClick={() => {
              setChartOfAccIdToBeDeleted(item._id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;

      data.push(item);
    });
    setChartOfAccList(data);
    
  }, [chartOfAccounts]);
  function fetch_all_accounttypes() {
    axios
      .get(`${API_URL}account_type/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var brand_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.acc_type_name,
              value: item._id,
            };
          });

        setacctypeGroup([
          {
            options: brand_data,
          },
        ]);
      });
  }
  const handleSearchSelectChange = (selected, name) => {
    switch (name) {
      case "accountType":
        setSelectedAccountTypeSearch(selected);
        setSearchData({
          ...searchData,
          acc_type_id: selected.value,
        });
       
      default:
        break;
    }
  };
  const handleSearch = () => {
    
    let acc_type_id = searchData?.acc_type_id ? searchData.acc_type_id : "";

    //setSelectedAccountTypeSearch(acc_type_id)
    dispatch(getAllChartOfAcc(currentLocalbody,acc_type_id));
    
    
  };
  let preupdateChartOfAccount = (item) => {
    item.localbody_id = getLocalbody();
    let options = {
      label:
        item.acc_detail_id.acc_detail_name +
        "-" +
        item.acc_detail_id.acc_type_id.acc_type_name,
      value: item.acc_detail_id._id,
    };
    setSelectedAccountType(options);
    setChartOfAccIdTobeUpdated(item._id);
    setMaster(item);
  };
  function fetch_account_type() {
    axios
      .get(`${API_URL}account_detail/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var account_type_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label:
                item.acc_detail_name + "-" + item.acc_type_id.acc_type_name,
              value: item._id,
            };
          });

        setAccountDetailType([
          {
            options: account_type_data,
          },
        ]);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Account Type",
        field: "acc_type_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Account Detail Type",
        field: "acc_detail_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Chart Name",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "acc_chart_balance",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: chartOfAccList,
    // rows:[{
    //   id:1,
    //   acc_type_name:"Bank",
    //   acc_detail_name:"Staff Account",
    //   acc_chart_name:"Sreekala PM",
    //   acc_chart_balance:"0.00",
    //   action:(
    //     <div style={{ display: "flex", justifyContent: "center" }}>
    //     <i
    //       className="far fa-edit"
    //       style={{
    //         fontSize: "1em",
    //         cursor: "pointer",
    //         marginLeft: "1rem",
    //         marginRight: "1rem",
    //       }}
    //       onClick={() => {}}
    //     ></i>
    //     <i
    //       className="far fa-trash-alt"
    //       style={{ fontSize: "1em", cursor: "pointer" }}
    //       onClick={() => {}}
    //     ></i>
    //   </div>
    //   )
    // },{
    //   id:2,
    //   acc_type_name:"Bank",
    //   acc_detail_name:"Staff Account",
    //   acc_chart_name:"SINDHU P",
    //   acc_chart_balance:"67,850",
    //   action:(
    //     <div style={{ display: "flex", justifyContent: "center" }}>
    //     <i
    //       className="far fa-edit"
    //       style={{
    //         fontSize: "1em",
    //         cursor: "pointer",
    //         marginLeft: "1rem",
    //         marginRight: "1rem",
    //       }}
    //       onClick={() => {}}
    //     ></i>
    //     <i
    //       className="far fa-trash-alt"
    //       style={{ fontSize: "1em", cursor: "pointer" }}
    //       onClick={() => {}}
    //     ></i>
    //   </div>
    //   )
    // }]
  };
  function handleSelectedAccountType(selected) {
    setSelectedAccountType(selected);
    setMaster({
      ...master,
      ["acc_detail_id"]: selected.value,
    });
  }
  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setMaster({
      ...master,
      [name]: value,
    });
  };

  const handleValidSubmit = (event, values) => {
    if (selectedAccountType == null) {
      toastr.error("Account detail type is required");
      return;
    }
    if (chartOfAccIdTobeUpdated) {
      axios
        .put(`${API_URL}chart_of_account`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Chart of account updated successfully");
            formRef.current.reset();
            setSelectedAccountType(null);
            setMaster({
              ["auth_userid"]: userId,
              ["localbody_id"]: getLocalbody(),
            });
            dispatch(getAllChartOfAcc(currentLocalbody));
            setChartOfAccIdTobeUpdated(null);
          } else {
            toastr.error("Failed to update chart of account");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}chart_of_account`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Chart of account added successfully");
            formRef.current.reset();
            setSelectedAccountType(null);
            setMaster({
              ["auth_userid"]: userId,
              ["localbody_id"]: getLocalbody(),
            });
            dispatch(getAllChartOfAcc(currentLocalbody));
          } else {
            toastr.error("Failed to add chart of account");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
    // chartOfAccIdTobeUpdated
    //   ? dispatch(updateChartOfAcc(master))
    //   : dispatch(addChartOfAcc(master));
  };
  const reset = () => {
    formRef.current.reset();
    setMaster({ ["auth_userid"]: userId, ["localbody_id"]: getLocalbody() });
    setSelectedAccountType(null);
    setChartOfAccIdTobeUpdated(null);
    setChartOfAccIdToBeDeleted(null);
  };
  const resets = () => {
    setSelectedAccountTypeSearch(null);
    dispatch(getAllChartOfAcc(currentLocalbody));
   
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}chart_of_account` + "/" + chartOfAccIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Chart of account deleted successfully");
                  if(master&&master?._id === chartOfAccIdToBeDeleted){
                    formRef.current.reset();
                    setSelectedAccountType(null);
                    setMaster({
                      ["auth_userid"]: userId,
                      ["localbody_id"]: getLocalbody(),
                    });
                    setChartOfAccIdTobeUpdated(null);
                  }
                  dispatch(getAllChartOfAcc(currentLocalbody));
                } else {
                  toastr.error("Failed to delete chart of account");
                  return;
                }
              });
            // dispatch(deleteChartOfAcc(chartOfAccIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Chart of account" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Account Detail Type</Label>
                          <Select
                            name="customer_community_id"
                            value={selectedAccountType}
                            onChange={(value) => {
                              handleSelectedAccountType(value);
                            }}
                            options={accountDetailType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Chart Name</Label>
                          <AvField
                            name="acc_chart_name"
                            placeholder="Chart Name"
                            type="text"
                            errorMessage="Enter account type"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master.acc_chart_name}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Balance</Label>
                          <AvField
                            name="acc_chart_balance"
                            placeholder="Balance"
                            type="number"
                            errorMessage="Enter balance"
                            className="form-control"
                            min="0"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master.acc_chart_balance}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div style={{display:"flex"}} >
                      
                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          {chartOfAccIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingChartOfAccount ? true : false}
                            >
                              {addingChartOfAccount ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingChartOfAccount ? true : false}
                            >
                              {addingChartOfAccount ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                        {/* <div className="mt-4">
                          <Button
                            color="primary"
                            type="submit"
                          >
                            Save
                          </Button>
                        </div> */}
                    
                    <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                <Row>
                <Col md="2">
                      <div className="mb-3">
                        <Label>Account Type</Label>
                        <Select
                          name="accountType"
                          value={selectedAccountTypeSearch}
                          onChange={(selected) => {
                           handleSearchSelectChange(selected, "accountType");
                          }}
                          options={acctypeGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div style={{display:"flex"}} >
                    <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                        <Button
                          color="primary"
                          type="submit"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </div>
                   
                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => resets()}
                        >
                          Reset
                        </Button>
                      </div>
                      </div>
                    </Col>
                  
                  </Row>






                  <MDBDataTable
                    id="table_chartof_account"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

                  
          
        </div>
      </div>
    </React.Fragment>
  );
};

export default Chart;
