import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row, Label, Button, Container } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import { getLocalbody } from "../../helpers/globalFunctions";
import Table from "@mui/material/Table";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import "./style.scss";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Modal } from "react-bootstrap";
import moment from "moment";
import logoDark from "../../assets/images/logo-dark.svg";

function Balecreation() {
  const formRef = useRef();
  const [masterObject, setmasterObject] = useState({});
  const [baleObject, setBaleObject] = useState({});
  const API_URL = process.env.REACT_APP_APIURL || "https://localhost:3099/";
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [baleDetails, setBaleDetails] = useState([]);

  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
  const [baleIdToBeDeleted, setBaleIdToBeDeleted] = useState(null);
  const [baleIdToBeUpdated, setBaleIdToBeUpdated] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [baleSlipDetails, setBaleSlipDetails] = useState([]);
  const [viewObject, setViewObject] = useState({});
  const [errors, setErrors] = useState({ warehouseName: "", itemName: "" });

  const [darkBackground, setDarkBackground] = useState(false);
  const [popupView, setPopupView] = useState(false);

  const [baleValue, setbaleValue] = useState([
    {
      item_name: "",
      itemname: "",
      weight: "",
      total: "",
      credit_amount: "",
      debit_amount: "",
      receiver_name: "",
      receiver_number: "",
      receiver_address: "",
    },
  ]);

  useEffect(() => {
    fetch_warehouse();
    handleTableData();
  }, []);

  function fetch_warehouse() {
    axios
      .get(`${API_URL}warehouse/list?localbody_id=` + currentLocalbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var house_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.warehouse_name,
              value: el?._id,
            };
          });

        setWarehouseOptions([{ options: house_data }]);
      });
  }

  function handleTableData() {
    let data = {
      localbody: currentLocalbody,
    };
    axios
      .post(`${API_URL}api/staff/bale/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let Data = [];
        let result = res.data.data;

        result?.map((item, index) => {
          item.id = index + 1;
          item.date = moment(item.date).format("DD-MM-YYYY");
          item.warehouse = item?.warehouse?.warehouse_name;
          item.item_id = item?.item_id?.waste_items_name;
          item.staff = item?.addedby?.firstName;

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fa fa-credit-card"
                style={{
                  cursor: "pointer",
                  fontSize: "1em",
                  marginRight: "0.2em",
                  marginLeft: "0.5em",
                }}
                onClick={() => {
                  handleClickOpenModal(item);
                  setOpenModal(true)
                }}></i>

              <i
                className="far fa-edit"
                style={{
                  cursor: "pointer",
                  fontSize: "1em",
                  marginRight: "0.5rem",
                  marginLeft: "0.1rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateItem(item);
                }}></i>

              <i
                className="far fa-trash-alt"
                style={{ cursor: "pointer", fontSize: "1em" }}
                onClick={() => {
                  setBaleIdToBeDeleted(item?._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </div>
          );

          Data.push(item);

          setBaleObject(item);
        });

        setBaleDetails(Data);
      });
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let preUpdateItem = (item) => {
    setBaleIdToBeUpdated(item?._id);

    let data = {
      _id: item?._id,
    };

    axios
      .post(`${API_URL}api/staff/bale/singlelist`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let packageArray = [];

        let result = res.data.data;

        // result?.map((item, index) => {

        let itemname = {
          label: result?.item_id?.waste_items_name,
          value: result?.item_id?._id,
        };
        setSelectedCategory(itemname);

        let wrhouse = {
          label: result?.warehouse?.warehouse_name,
          value: result?.warehouse?._id,
          totalweight: result?.baled_weight,
        };
        setSelectedWarehouse(wrhouse);

        result?.bale_data?.map((item, index) => {
          let packageObject = {
            _id: item?._id,
            item_name: item?.item_name,
            itemname: item?.item_name,
            weight: item?.weight,
            total: item?.total,
            credit_amount: item?.credit_amount,
            debit_amount: item?.debit_amount,
            receiver_name: item?.receiver_name,
            receiver_number: item?.receiver_number,
            receiver_address: item?.receiver_address,
          };
        });

        //     let packageObject = {
        //         _id: item?._id,
        //         item_name: item?.item_name,
        //         itemname: item?.item_name,
        //         weight: item?.weight,
        //         total: item?.total,
        //         credit_amount: item?.credit_amount,
        //         debit_amount: item?.debit_amount,
        //         receiver_name: item?.receiver_name,
        //         receiver_number: item?.receiver_number,
        //         receiver_address: item?.receiver_address
        //     }

        //     setBaleObject(item);

        setmasterObject(result);

        //     packageArray.push(packageObject);

        // })

        // Assuming you want to update the state with the result
        let updatedBaleValue = result?.bale_data?.map((item, index) => {
          return {
            itemname: item.item_name,
            item_name: item.item_name,
            weight: item.weight,
            total: item.total,
            credit_amount: item.credit_amount,
            debit_amount: item.debit_amount,
            receiver_name: item.receiver_name,
            receiver_number: item.receiver_number,
            receiver_address: item.receiver_address,
          };
        });

        setbaleValue(updatedBaleValue);

        setBaleObject(packageArray);
      });
  };

  function handleSelectChange(selected, name) {
    switch (name) {
      case "warehouse":
        setSelectedWarehouse(selected);
        setSelectedCategory(null);
        setmasterObject({
          ...masterObject,
          ["warehouse"]: selected.value,
          ["item"]: "",
        });

        axios
          .post(
            `${API_URL}api/staff/warehouse/data`,
            {
              warehouse_id: selected.value,
            },
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            var house_data =
              res.data.data &&
              res.data.data.map((el) => {
                return {
                  label: el?.warehouse_item_name,
                  value: el?._id,
                  weight: el?.warehouse_item_totalweight,
                  warehouse_item_id: el?.warehouse_item_id,
                  IN: el?.weight_in,
                  OUT: el?.weight_out,
                };
              });

            setCategoryOptions([{ options: house_data }]);
          });
        break;

      case "item":
        setSelectedCategory(selected);
        setmasterObject({
          ...masterObject,
          ["item"]: selected.value,
          ["total_weight"]: selected.weight,
          ["category"]: selected.label,
          ["wareItem"]: selected.warehouse_item_id,
          ["In"]: selected.IN,
          ["Out"]: selected.OUT,
        });
        break;

      default:
        break;
    }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Warehouse",
        field: "warehouse",
        sort: "asc",
        width: 100,
      },
      {
        label: "Item",
        field: "item_id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Weight",
        field: "baled_weight",
        sort: "asc",
        width: 100,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: baleDetails,
  };

  function handleValidSubmit() {
    let updateData = {
      item_id: masterObject?.wareItem,
      totalweight: masterObject?.total_weight,
      localbody_id: currentLocalbody,
      warehouse_id: masterObject?.warehouse,
      total: masterObject?.total,
      package: baleValue,
    };

    let data = {
      _id: masterObject?._id,
      item_id: masterObject?.wareItem ? masterObject?.wareItem : masterObject?.item_id?._id,
      totalweight: masterObject?.total_weight,
      localbody_id: currentLocalbody,
      warehouse_id: masterObject?.warehouse?._id,
      total: masterObject?.total,
      package: baleValue,
    };

    if (selectedWarehouse === null || selectedCategory === null) {
      let errorVal = errors;
      if (selectedWarehouse === null) {
        errorVal.warehouseName = "Please select warehouse";
      }
      if (selectedCategory === null) {
        errorVal.itemName = "Please select item";
      }
      setErrors({
        ...errorVal,
      });
    } else {
      if (baleIdToBeUpdated) {
        axios
          .post(`${API_URL}api/staff/bale/updated`, data, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Bale updated successfully");
              formRef.current.reset();
              setmasterObject({});
              setbaleValue([
                {
                  item_name: "",
                  weight: "",
                  total: "",
                  credit_amount: "",
                  debit_amount: "",
                  receiver_name: "",
                  receiver_number: "",
                  receiver_address: "",
                  itemname: "",
                },
              ]);
              setBaleIdToBeUpdated(null);
              handleTableData();
              setSelectedCategory(null);
              setSelectedWarehouse(null);
              setErrors({
                ...errors,
                warehouseName: "",
                itemName: "",
              });
            } else {
              toastr.error("Failed to update");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}api/staff/bale`, updateData, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Bale created successfully");
              formRef.current.reset();
              setmasterObject({});
              handleTableData();
              setbaleValue([
                {
                  item_name: "",
                  weight: "",
                  total: "",
                  credit_amount: "",
                  debit_amount: "",
                  receiver_name: "",
                  receiver_number: "",
                  receiver_address: "",
                  itemname: "",
                },
              ]);
              setSelectedCategory(null);
              setSelectedWarehouse(null);
              setErrors({
                ...errors,
                warehouseName: "",
                itemName: "",
              });
            } else {
              toastr.error("Failed to create");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  }

  function addMore() {
    setbaleValue([
      ...baleValue,
      {
        item_name: "",
        itemname: "",
        weight: "",
        total: "",
        credit_amount: "",
        debit_amount: "",
        receiver_name: "",
        receiver_number: "",
        receiver_address: "",
      },
    ]);
  }

  function handleItemChange(e, index) {
    var list = [...baleValue];
    var item_name = e.target.value;
    list[index].item_name = item_name;
    setbaleValue(list);
  }

  function handleWeightChange(e, index) {
    var list = [...baleValue];
    var weight = e.target.value;
    list[index].weight = weight;
    setbaleValue(list);
  }

  function handletotalChange(e, index) {
    var list = [...baleValue];
    var total = e.target.value;
    list[index].total = total;
    setbaleValue(list);
  }

  function handleCreditChange(e, index) {
    var list = [...baleValue];
    var credit_amount = e.target.value;
    list[index].credit_amount = credit_amount;
    setbaleValue(list);
  }

  function handleDebitChange(e, index) {
    var list = [...baleValue];
    var debit_amount = e.target.value;
    list[index].debit_amount = debit_amount;
    setbaleValue(list);
  }

  function handleLengthChange(e, index) {
    var list = [...baleValue];
    var length = e.target.value;
    list[index].length = length;
    setbaleValue(list);
  }

  function handleNumberChange(e, index) {
    var list = [...baleValue];
    var breadth = e.target.value;
    list[index].breadth = breadth;
    setbaleValue(list);
  }

  function handleHeightChange(e, index) {
    var list = [...baleValue];
    var height = e.target.value;
    list[index].height = height;
    setbaleValue(list);
  }

  function handleRemove(index) {
    const list = [...baleValue];
    list.splice(index, 1);
    setbaleValue(list);
  }

  function handleClickOpenModal(item) {
    let data = {
      _id: item?._id,
    };

    axios
      .post(`${API_URL}api/staff/bale/singlelist`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let Data = [];
        let result = res.data.data;

        result?.bale_data?.map((item, index) => {
          item.id = index + 1;
          item.date = moment(item?.date).format("DD-MM-YYYY");
          item.warehouse = result?.warehouse?.warehouse_name;
          item.item_id = result?.item_id?.waste_items_name;
          item.total_weight = result?.total_weight;
          item.staff = result?.addedby?.firstName;
          item.baleId = result?._id;

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  cursor: "pointer",
                  fontSize: "1em",
                  marginRight: "0.5rem",
                  marginLeft: "0.2rem",
                }}
                onClick={() => {
                  handlePopup(item);
                  setPopupView(true);
                  setDarkBackground(true);
                }}></i>
            </div>
          );

          setmasterObject(item);
          setOpenModal(true);
          Data.push(item);
        });
        setBaleSlipDetails(Data);

        setViewObject(Data);
      });
  }

  const baleSlip = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Item",
        field: "item_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Weight",
        field: "weight",
        sort: "asc",
        width: 100,
      },
      {
        label: "Total",
        field: "total",
        sort: "asc",
        width: 100,
      },
      {
        label: "Debit",
        field: "debit_amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Credit",
        field: "credit_amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Length",
        field: "length",
        sort: "asc",
        width: 100,
      },
      {
        label: "Breadth",
        field: "breadth",
        sort: "asc",
        width: 100,
      },
      {
        label: "Height",
        field: "height",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: baleSlipDetails,
  };

  const popupData1 = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Item Name",
        field: "item_name",
        width: 100,
      },
      {
        label: "Quantity",
        field: "quantity",
        width: 100,
      },
      {
        label: "Amount",
        field: "amount",
        width: 100,
      },
      {
        label: "Price",
        field: "price",
        width: 100,
      },
    ],
    rows: [
      {
        id: "1",
        item_name: "Plastic Cover",
        quantity: "8",
        amount: "50",
        price: "50",
      },
    ],
  };

  function handlePopup(item) {
    axios
      .get(`${API_URL}api/staff/bale/delivery_note?baleId=${item?.baleId}&bale_data_Id=${item._id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => { });
  }

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          onConfirm={() => {
            axios
              .delete(`${API_URL}api/staff/bale/deleted?_id=` + baleIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Bale deleted successfully");
                  formRef.current.reset();
                  setSelectedCategory(null);
                  setSelectedWarehouse(null);
                  setmasterObject({});
                  setBaleIdToBeUpdated(null);
                  handleTableData();
                  setbaleValue([
                    {
                      item_name: "",
                      weight: "",
                      total: "",
                      credit_amount: "",
                      debit_amount: "",
                      receiver_name: "",
                      receiver_number: "",
                      receiver_address: "",
                      itemname: "",
                    },
                  ]);
                } else {
                  toastr.error("Failed to delete");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : (
        ""
      )}

      <Modal show={openModal} size="xl" centered={true}>
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setOpenModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>

        <div className="modal-body">
          <table className="table table-borderless">
            <tr>
              <td style={{ fontWeight: "bold" }}>Date</td>
              <td>: {masterObject?.date}</td>
              <td style={{ fontWeight: "bold" }}>Warehouse</td>
              <td>: {masterObject?.warehouse}</td>
              <td style={{ fontWeight: "bold" }}>Item</td>
              <td>: {masterObject?.item_id}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Total Weight</td>
              <td>: {masterObject?.total_weight}</td>
              <td style={{ fontWeight: "bold" }}>Staff</td>
              <td>: {masterObject?.staff}</td>
            </tr>
          </table>

          <MDBDataTable
            id="baleSlipTable"
            responsive
            bordered
            data={baleSlip}
            searching={false}
            info={false}
            disableRetreatAfterSorting={true}
            paging={false}
          />
        </div>
      </Modal>

      {/* Delivery Note */}
      <Modal show={popupView} size="lg" centered={true} className={darkBackground ? "light-background" : ""}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Delivery Note</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>

        <div className="modal-body">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="6">
                        <div className="invoice-title">
                          <h4 className="font-size-16">
                            <span>Merikkutty &nbsp;</span>
                          </h4>
                          <p className="mb-1">EKU10201078</p>
                          <p className="mb-1">
                            {" "}
                            <i className="uil uil-phone me-1"></i>
                            8281186484
                          </p>
                          <p className="mb-1">EKU10/PERUMBADAVU</p>
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="invoice-title text-sm-end">
                          <div>
                            <p>
                              <img src={logoDark} alt="logo" height="42" width="233" />
                            </p>
                          </div>
                          <div>
                            <b>
                              <p className="mb-1" style={{ fontSize: "20px", marginRight: "31px", marginTop: "10px" }}>
                                DELIVERY NOTE
                              </p>
                            </b>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />

                    <Row>
                      <Col sm="6">
                        <div className="text-muted">
                          <h5 className="font-size-15 mb-2">Ship To :</h5>
                          <p className="mb-1">Shihas v.n.p</p>
                          <p className="mb-1">EKU06201090</p>
                          <p className="mb-1">
                            {" "}
                            <i className="uil uil-phone me-1"></i>
                            8848437771
                          </p>
                          <p className="mb-1">EKU6/PERUVAMBA</p>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="text-muted text-sm-end">
                          <tr>
                            <th style={{ padding: "10px", paddingLeft: "170px" }}>Delivery Note :</th>
                            <td>8</td>
                          </tr>
                          <tr>
                            <th style={{ padding: "10px", paddingLeft: "117px" }}>Date :</th>
                            <td>12 Jan 2023</td>
                          </tr>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />

                    <Row>
                      <Col xl="12">
                        <div className="py-2">
                          <MDBDataTable id="poupTableId" responsive bordered info={false} searching={false} paging={false} data={popupData1} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Bale Creation" />

          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-vaidation"
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}>
                    <Row>
                      <Col md="3">
                        <Label>Warehouse</Label>
                        <Select
                          name="warehouse"
                          value={selectedWarehouse}
                          classNamePrefix="select2-selection"
                          options={warehouseOptions}
                          onChange={(selected) => {
                            handleSelectChange(selected, "warehouse");
                          }}
                          isDisabled={baleIdToBeUpdated ? true : ""}
                        />
                        <p className="text-danger" style={{ fontSize: "11px" }}>
                          {selectedWarehouse === null ? errors?.warehouseName : ""}
                        </p>
                      </Col>

                      <Col md="3">
                        <Label>Item</Label>
                        <Select
                          name="item"
                          options={categoryOptions}
                          classNamePrefix="select2-selection"
                          value={selectedCategory}
                          onChange={(selected) => {
                            handleSelectChange(selected, "item");
                          }}
                          isDisabled={baleIdToBeUpdated ? true : ""}
                        />
                        <p className="text-danger" style={{ fontSize: "11px" }}>
                          {selectedCategory === null ? errors?.itemName : ""}
                        </p>
                      </Col>

                      <Col md="3">
                        <Label>Weight</Label>
                        <AvField
                          name="total_weight"
                          placeholder="Weight"
                          value={masterObject?.total_weight}
                          className="form-control"
                          readOnly={true}
                          disabled={baleIdToBeUpdated ? true : ""}
                        />
                      </Col>

                      {selectedCategory ? (
                        <Col md="3" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <p style={{ marginRight: "10px" }}>In: {masterObject?.In}</p>
                          <p>Out: {masterObject?.Out}</p>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>

                    <Row style={{ marginTop: "10px" }}>
                      <Col xl="12">
                        <Table id="baleItemTable" style={{ textAlign: "center", border: "1px solid #dadada" }} className="table table-bordered dataTable">
                          <TableHead className="table table-bordered dataTable">
                            <TableRow style={{ textAlign: "center" }}>
                              <TableCell
                                style={{
                                  width: "0rem",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "700",
                                  fontSize: "0.875rem",
                                  color: "#282828",
                                  border: "1px solid #dadada",
                                }}>
                                #
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "700",
                                  fontSize: "0.875rem",
                                  color: "#282828",
                                  border: "1px solid #dadada",
                                }}>
                                Item Name
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "700",
                                  fontSize: "0.875rem",
                                  color: "#282828",
                                  border: "1px solid #dadada",
                                }}>
                                Weight
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "700",
                                  fontSize: "0.875rem",
                                  color: "#282828",
                                  border: "1px solid #dadada",
                                }}>
                                Total
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "700",
                                  fontSize: "0.875rem",
                                  color: "#282828",
                                  border: "1px solid #dadada",
                                }}>
                                Credit
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "700",
                                  fontSize: "0.875rem",
                                  color: "#282828",
                                  border: "1px solid #dadada",
                                }}>
                                Debit
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "700",
                                  fontSize: "0.875rem",
                                  color: "#282828",
                                  border: "1px solid #dadada",
                                }}>
                                Length
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "700",
                                  fontSize: "0.875rem",
                                  color: "#282828",
                                  border: "1px solid #dadada",
                                }}>
                                Breadth
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "700",
                                  fontSize: "0.875rem",
                                  color: "#282828",
                                  border: "1px solid #dadada",
                                }}>
                                Height
                              </TableCell>
                              {baleValue?.length !== 1 ? (
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    border: "1px solid #dadada",
                                  }}>
                                  Action
                                </TableCell>
                              ) : null}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {baleValue &&
                              baleValue?.map((el, index) => {
                                return (
                                  <TableRow>
                                    <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                      {index + 1}
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="item_name"
                                        type="text"
                                        placeholder="Item Name"
                                        value={el?.item_name}
                                        onChange={(e) => {
                                          handleItemChange(e, index);
                                        }}
                                        className="form-control"
                                        errorMessage="Enter Item"
                                        validate={{ required: { value: true } }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="weight"
                                        type="number"
                                        placeholder="Weight"
                                        min={0}
                                        value={el?.weight}
                                        onChange={(e) => {
                                          handleWeightChange(e, index);
                                        }}
                                        className="form-control"
                                        errorMessage="Enter Weight"
                                        validate={{ required: { value: true } }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="total"
                                        type="number"
                                        min={0}
                                        placeholder="total"
                                        className="form-control"
                                        value={el?.total}
                                        onChange={(e) => {
                                          handletotalChange(e, index);
                                        }}
                                        errorMessage="Enter Total"
                                        validate={{ required: { value: true } }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="credit_amount"
                                        type="number"
                                        min={0}
                                        placeholder="Credit"
                                        value={el?.credit_amount}
                                        onChange={(e) => {
                                          handleCreditChange(e, index);
                                        }}
                                        className="form-control"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="debit_amount"
                                        type="number"
                                        min={0}
                                        placeholder="Debit"
                                        value={el?.debit_amount}
                                        className="form-control"
                                        onChange={(e) => {
                                          handleDebitChange(e, index);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="length"
                                        type="number"
                                        placeholder="Length"
                                        value={el?.length}
                                        className="form-control"
                                        onChange={(e) => {
                                          handleLengthChange(e, index);
                                        }}
                                        errorMessage="Enter Length"
                                        validate={{ required: { value: true } }}
                                        min={0}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="breadth"
                                        type="number"
                                        placeholder="Breadth"
                                        value={el?.breadth}
                                        className="form-control"
                                        min={0}
                                        onChange={(e) => {
                                          handleNumberChange(e, index);
                                        }}
                                        errorMessage="Enter Breadth"
                                        validate={{ required: { value: true } }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="height"
                                        type="number"
                                        min={0}
                                        placeholder="Height"
                                        value={el?.height}
                                        className="form-control"
                                        onChange={(e) => {
                                          handleHeightChange(e, index);
                                        }}
                                        validate={{ required: { value: true } }}
                                        errorMessage="Enter Height"
                                      />
                                    </TableCell>
                                    {baleValue?.length !== 1 && (
                                      <TableCell>
                                        <i
                                          className="uil uil-times"
                                          style={{
                                            fontSize: "20px",
                                            cursor: "pointer",
                                            paddingLeft: "5px",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          onClick={() => handleRemove(index)}></i>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </Col>
                    </Row>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <span
                        style={{ display: "flex", cursor: "pointer" }}
                        className="bg-dark badge bg-secondary font-size-12 addspan"
                        onClick={() => addMore()}>
                        <i class="mdi mdi mdi-plus-thick"></i> New Item
                      </span>
                    </div>

                    <Col className="btnlist py-3">
                      {baleIdToBeUpdated ? (
                        <Button type="update" color="primary">
                          Update
                        </Button>
                      ) : (
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      )}
                    </Col>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <MDBDataTable id="baleTableId" responsive bordered info={true} searching={true} entries={20} disableRetreatAfterSorting={true} data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>

    </React.Fragment>
  );
}

export default Balecreation;
