import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import { GET_STAFF_SALARY } from "./actionTypes";
import { getStaffSalarySuccess, getStaffSalaryFail } from "./actions";
import { getStaffSalary } from "../../helpers/backend_helper";

function* fetchStaffSalary({ localbody_id: localbody_id, month: month, days: days, work_days: work_days }) {
  try {
    const response = yield call(getStaffSalary, localbody_id, month, days, work_days);

    yield put(getStaffSalarySuccess(response));
  } catch (error) {
    yield put(getStaffSalaryFail(error));
  }
}
function* fetchStaffSalarySaga() {
  yield takeEvery(GET_STAFF_SALARY, fetchStaffSalary);
}

export default fetchStaffSalarySaga;
