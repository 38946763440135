import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./foodwasteprocessingitems.scss";
const AddCategory = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [categoryIdTobeUpdated, setCategoryIdTobeUpdated] = useState(null);
  const [categoryIdTobeDeleted, setCategoryIdTobeDeleted] = useState(null);
  const [uploadProgress, setUploadProgress] = useState();
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [errors, setErrors] = useState({
    imageError: "",
  
  });
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    handleTableData();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
//   const preUpdateCategory = (item) => {
   
 
//     if (item?.category_name || item?.category_icon) {
//       setMaster({
//         category_name: item.category_name,
//         category_icon: item.category_icon,
//         _id: item._id,
//       });
//       setCategoryIdTobeUpdated(item._id);
//     }
//   };

  let preUpdateCategory = (item) => {
   
    setCategoryIdTobeUpdated(item._id);
    axios
    .get(`${API_URL}foodwaste_require_items/view/?item_id=` + item._id, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res) => {
      let element = res.data.data;
      
      setMaster(element);
    
    });
  };
  const handleTableData = () => {
    axios
      .get(`${API_URL}foodwaste_require_items/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
          
            if (item?.update_date)
            item.date = moment(item?.update_date).format(
              "DD-MM-YYYY"
            );
          else if (item?.create_date)
            item.date = moment(item?.create_date).format("DD-MM-YYYY");
          else item.date = "";

          if (item?.update_time)
            item.time = moment(item?.update_time, "hhmmss").format(
              "hh:mm a"
            );
          else if (item?.create_time)
            item.time = moment(item?.create_time, "hhmmss").format(
              "hh:mm a"
            );
          else item.time = "";
          if (item?.updatedBy) {
            let updatedBy = item.updatedBy || {};
            if (updatedBy?.lastName)
              item.staff = updatedBy?.firstName + " " + updatedBy?.lastName;
            else item.staff = updatedBy?.firstName;
          } else {
          let createdBy = item.createdBy || {};
            if (createdBy?.lastName)
              item.staff = createdBy?.firstName + " " + createdBy?.lastName;
            else item.staff = createdBy?.firstName;
          }
            item.require_icon = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/foodwaste_requireicon/${item.require_icon}`}
                    height="30"
                    width="30"
                  />
                </div>
              );
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="uil-edit-alt"
                  style={{
                    fontSize: "1.2em",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                  
                    toTop();
                    preUpdateCategory(item);
                  }}
                ></i>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  onClick={() => {
                    setCategoryIdTobeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  };
  const uploadImage = (e) => {
    let val = e.target.files[0];

    if (val?.size > 102400)
      toastr.error("Image size should be less than or equal to 100 Kb");
    else {
      const fd = new FormData();
      fd.append("require_icon", e.target.files[0]);
      axios
        .post(`${API_URL}api/staff/foodwasterequireicon`, fd, {
          headers: {
            // "Content-Type": "multipart/form-data",
            "x-access-token": accessToken,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setUploadProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            setMaster({
              ...master,
              [`require_icon`]: response.data.file.filename,
            });
            setErrors({
              ...errors,
              imageError: "",
            });
            // toastr.success("Image Uploaded");
          } else {
            toastr.error(response.data.message);
          }

        });
    }
  };
 
  const deletePopupImage = () => {
    setMaster({
      ...master,
      [`require_icon`]: "",
    });
    setErrors({
      ...errors,
      imageError: "",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },

      {
        label: "Item",
        field: "require_name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Icon",
        field: "require_icon",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {
    if (categoryIdTobeUpdated) {
      axios
        .put(`${API_URL}foodwaste_require_items/update`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Item updated successfully");
            setMaster({});
            handleTableData();
            setCategoryIdTobeUpdated(null);
          } else {
            toastr.error("Failed to update Item");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}foodwaste_require_items/`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Item added successfully");
            setMaster({});
            handleTableData();
          } else {
            toastr.error("Failed to add Item");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  return (
    <>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}foodwaste_require_items` + "/" + categoryIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Item deleted successfully");
                  if (master && master._id === categoryIdTobeDeleted) {
                    formRef.current.reset();
                    setMaster({});
                    setCategoryIdTobeUpdated(null);
                  }
                  setCategoryIdTobeUpdated(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Item");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Foodwaste Processing Items" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleValidSubmit();
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Item Name</Label>
                          <AvField
                            name="require_name"
                            placeholder="Item Name"
                            type="text"
                            errorMessage="Enter Item Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.require_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>  <Col md="3" style={{ display: "block" }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Choose Icon Image
                          </Label>
                          {master.require_icon ? (
                            <div div className="img-wraps">
                              {master.require_icon &&
                              master.require_icon.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${master.require_icon}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/foodwaste_requireicon/${master.require_icon}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deletePopupImage}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="require_icon"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master?.require_icon ? errors.imageError : ""}
                          </p>
                        </div>

                        {/* <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Choose Image
                          </Label>
                          <AvField
                            name="image_upload"
                            // value={productObject.product_seo_keys}
                            // placeholder="Product Image 1"
                            type="file"
                            errorMessage="Choose image"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={uploadImage}
                            rows="1"
                          />
                        </div> */}
                      </Col>


                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          {categoryIdTobeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="manageCategoryTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddCategory;
