import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import Save from "@mui/icons-material/Save";
import Upload from "@mui/icons-material/Upload";

import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Modal
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./branch.scss";

const ManageBranch = () => {
  const [state, setState] = useState("");
  const [open, setOpen] = useState(false);
  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [city, setCity] = useState("");
  const [company, setCompany] = useState("");

  const handleChange1 = (selected) => {
    setCity(selected);
  };
  const handleChange = (selected) => {
    setState(selected);
  };
  const handleClickOpenForm = (row) => {
    if (row === "row1") {
      setPopupData({
        cid: "MK0001",
        cname: "SRV Infotech",
        code: "SRV",
        pname: "Vijith",
        mobile: "9099865790",
        city: "Kannur",
        addedby: "admin",
      });
    } else if (row === "row2") {
      setPopupData({
        cid: "MK0002",
        cname: "HAZEL GREEN",
        code: "SGH",
        pname: "Vijith",
        mobile: "8904567834",
        city: "Kannur",
        addedby: "admin",
      });
    } else {
      setPopupData({
        cid: "MK0003",
        cname: "MyKIT",
        code: "MGH",
        pname: "Satyanand",
        mobile: "9873426709",
        city: "Kozhikode",
        addedby: "admin",
      });
    }
    // setTableData(item);

    setPopupView(true);
  };

  const handleChange2 = (selected) => {
    setCompany(selected);
  };

  const data = {
    columns: [
      {
        label: "Company ID",
        field: "cid",
        sort: "asc",
        width: 150,
      },
      {
        label: "Company",
        field: "cname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Code",
        field: "code",
        sort: "asc",
        width: 200,
      },
      {
        label: "Contact Person",
        field: "pname",
        sort: "asc",
        width: 100,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 10,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 100,
      },
      {
        label: "Added By",
        field: "addedby",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [
      {
        cid: "MK0001",
        cname: "SRV Infotech",
        code: "SRV",
        pname: "Vijith",
        mobile: "9099865790",
        city: "Kannur",
        addedby: "admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                handleClickOpenForm("row1");
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        cid: "MK0002",
        cname: "HAZEL GREEN",
        code: "SGH",
        pname: "Vijith",
        mobile: "8904567834",
        city: "Kannur",
        addedby: "admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                handleClickOpenForm("row2");
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },

      {
        cid: "MK0003",
        cname: "MyKIT",
        code: "MGH",
        pname: "Satyanand",
        mobile: "9873426709",
        city: "Kozhikode",
        addedby: "admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                handleClickOpenForm("row3");
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Branch Details</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <th>Company ID	 :</th>
                <td>{popupData?.cid}</td>
                <th>Company	 :</th>
                <td style={{ textAlign: "left" }}>{popupData?.cname}</td>
              </tr>
              <tr>
                <th>Code :</th>
                <td>{popupData?.code}</td>
                <th>Contact Person :</th>
                <td style={{ textAlign: "left" }}>{popupData?.pname}</td>
              </tr>
              <tr>
                <th>Mobile :</th>
                <td>{popupData?.mobile}</td>
                <th>City :</th>
                <td style={{ textAlign: "left" }}>{popupData?.city}</td>
              </tr>
              <tr>
                <th>Added By :</th>
                <td style={{ textAlign: "left" }}>{popupData?.addedby}</td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Branch" />

          {/*Text field 1*/}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Company</Label>
                          <Select
                            name="company_id"
                            value={company}
                            onChange={(selected) => {
                              handleChange2(selected);
                            }}
                            options={[
                              { label: "company 1", value: 1 },
                              { label: "company 2", value: 2 },
                              { label: "company 3", value: 3 },
                              { label: "company 4", value: 4 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Branch Name
                          </Label>
                          <AvField
                            name="companyname"
                            placeholder="Branch Name"
                            type="text"
                            errorMessage="Enter Branch Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Short Code</Label>
                          <AvField
                            name="shortcode"
                            placeholder="Short Code"
                            type="text"
                            errorMessage="Enter Short code"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Contact Person
                          </Label>
                          <AvField
                            name="contactperson"
                            placeholder="Contact Person"
                            type="text"
                            errorMessage="Enter Contact Person"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>

                      {/*Text field 2*/}
                    </Row>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Designation
                          </Label>
                          <AvField
                            name="designation"
                            placeholder="Designation"
                            type="text"
                            errorMessage="Enter Designation"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Mobile No.</Label>
                          <AvField
                            name="mobileno"
                            placeholder="Mobile No."
                            type="tel"
                            errorMessage="Enter valid 10 digit number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Landline No.
                          </Label>
                          <AvField
                            name="landlineno"
                            placeholder="Landline No."
                            type="phone"
                            errorMessage="Enter valid 11 digit number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                            minLength={11}
                            maxLength={11}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Email ID</Label>
                          <AvField
                            name="email"
                            placeholder="Email ID"
                            type="email"
                            errorMessage=" Please provide a valid Email ID."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                    </Row>

                    {/*Text field 3*/}
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Website</Label>
                          <AvField
                            name="website"
                            placeholder="Website"
                            type="text"
                            errorMessage=" Please provide a Website url."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Address</Label>
                          <AvField
                            name="address"
                            placeholder="Address"
                            type="text"
                            errorMessage="Enter Your Address"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            name="state_id"
                            value={state}
                            onChange={(selected) => {
                              handleChange(selected);
                            }}
                            options={[
                              { label: "Andhra Pradesh", value: 1 },
                              { label: "Arunachal Pradesh", value: 2 },
                              { label: "Assam", value: 3 },
                              { label: "Bihar", value: 4 },
                              { label: "Chhattisgarh", value: 5 },
                              { label: "Goa", value: 6 },
                              { label: "Gujarat", value: 7 },
                              { label: "Haryana", value: 8 },
                              { label: "Himachal Pradesh", value: 9 },
                              { label: "Jammu and Kashmir", value: 10 },
                              { label: "Jharkhand", value: 11 },
                              { label: "Karnataka", value: 12 },
                              { label: "Kerala", value: 13 },
                              { label: "Madhya Pradesh", value: 14 },
                              { label: "Maharashtra", value: 15 },
                              { label: "Manipur", value: 16 },
                              { label: "Meghalaya", value: 17 },
                              { label: "Mizoram", value: 18 },
                              { label: "Nagaland", value: 19 },
                              { label: "Odisha", value: 20 },
                              { label: "Punjab", value: 21 },
                              { label: "Rajasthan", value: 22 },
                              { label: "Sikkim", value: 23 },
                              { label: "Tamil Nadu", value: 24 },
                              { label: "Telangana", value: 25 },
                              { label: "Tripura", value: 26 },
                              { label: "Uttar Pradesh", value: 27 },
                              { label: "Uttarakhand", value: 28 },
                              { label: "West Bengal", value: 29 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>City</Label>
                          <Select
                            name="city_id"
                            value={city}
                            onChange={(selected) => {
                              handleChange1(selected);
                            }}
                            options={[
                              {
                                label: "City1",
                                value: 1,
                              },
                              {
                                label: "City2",
                                value: 2,
                              },
                              {
                                label: "City3",
                                value: 3,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>

                    {/*Text field 4*/}
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Pincode</Label>
                          <AvField
                            name="pincode"
                            placeholder="Pincode"
                            type="text"
                            errorMessage=" Please provide a Pincode."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">GSTIN</Label>
                          <AvField
                            name="gstin"
                            placeholder="GSTIN"
                            type="text"
                            errorMessage="Enter Your GSTIN"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">PAN No.</Label>
                          <AvField
                            name="panno"
                            placeholder="PAN No"
                            type="text"
                            errorMessage="Enter PAN No."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">CIN No.</Label>
                          <AvField
                            name="cinno"
                            placeholder="CIN No."
                            type="text"
                            errorMessage="Enter Your CIN No."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                    </Row>

                    {/*Text field 5*/}
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">TDS No.</Label>
                          <AvField
                            name="tdsno"
                            placeholder="TDS No"
                            type="text"
                            errorMessage="Enter Your TDS No."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3" style={{ display: "block" }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Company Logo(300x400)
                          </Label>
                          <AvField
                            name="image_upload"
                            // value={productObject.product_seo_keys}
                            // placeholder="Product Image 1"
                            type="file"
                            errorMessage="Choose image"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            // onChange={uploadImage}
                            rows="1"
                          />
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Company Logo(300x400)
                          </Label>
                          <br />
                          <Button variant="contained" component="label">
                            <Upload></Upload>
                            {"  "}
                            Upload File
                            <input type="file" hidden />
                          </Button>
                        </div>
                      </Col> */}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Latitude</Label>
                          <AvField
                            name="latitude"
                            placeholder="Latitude"
                            type="text"
                            errorMessage="Enter Latitude"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Longitude</Label>
                          <AvField
                            name="longitude"
                            placeholder="Longitude"
                            type="text"
                            errorMessage="Enter Longitude"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Delivery Area(Km)
                          </Label>
                          <AvField
                            name="longitude"
                            placeholder="Longitude"
                            type="text"
                            errorMessage="Enter Longitude"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Delivery Type Rate
                          </Label>
                          <AvField
                            name="longitude"
                            placeholder="Longitude"
                            type="text"
                            errorMessage="Enter Longitude"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Delivery Slot
                          </Label>
                          <AvField
                            name="longitude"
                            placeholder="Longitude"
                            type="text"
                            errorMessage="Enter Longitude"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                          />
                        </div>
                      </Col>
                      <Col md="3" style={{ marginTop: "3%" }}>
                        <div className="mb-3">
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="invalidCheck"
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="invalidCheck"
                            >
                              Agree to terms and conditions
                            </label>
                            <div className="invalid-feedback">
                              You must agree before submitting.
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/*Text Field End*/}

                    <Button color="primary" type="submit">
                      {/* <Save></Save> {"  "} */}
                      Submit
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageBranch;
