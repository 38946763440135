import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getDate,
  getFirstday,
  isSuperAdmin,
} from "../../../helpers/globalFunctions";
import toastr from "toastr";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Modal } from "react-bootstrap";
import './staffWalletReposrt.scss';


const StaffWalletReport = (props) => {
  const [details, setDetails] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [popupDetails, setPopupDetails] = useState([])
  const [popupSearchData, setPopupSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [selectedUserId, setSelectedUserId] = useState("");
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [month, setMonth] = useState(moment().format("YYYY-MM"))
  const [dataToBeExported, setDataToBeExported] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [payModal, setPayModal] = useState(false)
  const [custDetails, setCustDetails] = useState([])
  const [darkBackground, setDarkBackground] = useState(false);
  const [payableDetails, setPayableDetails] = useState([])


  useEffect(() => {
    handleTableData();
  }, [currentLocalbody]);

  const handleTableData = (from_date = "", to_date = "") => {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var val = localStorage.getItem("authUser");
      const obj = JSON.parse(val);
      userId = obj._id;
    }

    var url =
      `${API_URL}api/staff/staff_wallet_report?user_id=` +
      userId +
      "&localbody_id=" +
      currentLocalbody;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url =
      url +
      "&fromdate=" +
      from_date +
      "&todate=" +
      to_date;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        let dataToBeExported = [];

        let result = res.data.result;
        result &&
          result.map((el, index) => {
            el.id = index + 1;
            if (el?.lastName) el.staffName = el?.firstName + " " + el.lastName;
            else el.staffName = el?.firstName;

            el.pay1 = (
              <span className="table-link-hover"
                onClick={() => {
                  handlePay(el)
                }}
              >
                {el?.payment_amount}
              </span>
            )
            el.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenForm(el);
                  }}
                ></i>
              </div>
            );

            let exportItem = {};
            exportItem.id = index + 1;
            exportItem.Staff = el.firstName + " " + el.lastName;;
            exportItem.Collected_Amount = el.collected_amount;
            exportItem.Collected_Customers = el.collected_customers;
            exportItem.Payment_Amount = el.payment_amount;
            exportItem.Balance = el.balance;

            dataToBeExported.push(exportItem)
          });

        setDetails(result);

        setDataToBeExported(dataToBeExported)
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  const handleClickOpenForm = (data) => {
    setSelectedUserId(data?._id);
    fetch_staff_wallet_details(data?._id);
    setPopupView(true);
  };

  const fetch_staff_wallet_details = (id) => {
    let data = {
      localbody_id: currentLocalbody,
      user_id: id,
      from_date: popupSearchData?.from_date,
      to_date: popupSearchData?.to_date,
    };
    axios
      .post(`${API_URL}api/staff/staff_wallet_detailed_report`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        let result = res.data.result;
        result &&
          result.map((el, index) => {
            el.id = index + 1;

            el._id1 = moment(el._id).format("DD-MM-YYYY")

            el.name1 = (
              <span
                className="table-link-hover"
                onClick={() => {
                  handleValid(el, id);
                }
                }>
                {el?.customers}
              </span>
            );

            setPopupDetails(el)
          });
        setPopupData(result);
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };


  function handleValid(el, id) {

    var url =
      `${API_URL}api/staff/staff-wallet-report-customer-list?user_id=` +
      id +
      "&localbody_id=" +
      currentLocalbody +
      "&WalletDate=" +
      el._id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then(res => {

        let Data = []
        let result = res.data.result

        result.map((item, index) => {

          item.receipt.map((recei, index) => {

            recei.cust.map((cust, index) => {

              cust.id = index + 1
              Data.push(cust)
            })
          })

          setCustDetails(Data)
          setOpenModal(true)
          setDarkBackground(true);
        })
      })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Staff",
        field: "staffName",
        sort: "asc",
        width: 400,
      },
      {
        label: "Collected Amount",
        field: "collected_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Collected Customers",
        field: "collected_customers",
        sort: "asc",
        width: 200,
      },
      {
        label: "Payment Amount",
        field: "pay1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "balance",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };

  const staffWalletDetails = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "_id1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Customers",
        field: "name1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Payment",
        field: "payment",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "balance",
        sort: "asc",
        width: 200,
      },
    ],
    rows: popupData,
  };

  function handlePay(el) {

    let userId = el?._id
    var url =
      `${API_URL}reports/staff-wallet-report-detail?localbody_id=` +
      currentLocalbody +
      "&user_id=" +
      userId +
      "&fromdate=" +
      month;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {
        let Data = []
        let result = res.data.data
        result.map((item, index) => {

          item.id = index + 1

          Data.push(item)
        })
        setPayableDetails(Data)
        setPayModal(true)
      })
  }

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setPopupSearchData({
        ...popupSearchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setPopupSearchData({
        ...popupSearchData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const reset = () => {
    setPopupSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setPopupData()
  };

  const closePopup = () => {
    setPopupData([]);
    setSelectedUserId("");
    setPopupSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setPopupView(false);
  };


  function handleChangeDate(e) {
    setMonth(e.target.value)
  }

  function handleSearch() {
    let date1 = popupSearchData?.from_date ? popupSearchData?.from_date : "";
    let date2 = popupSearchData?.to_date ? popupSearchData?.to_date : "";

    handleTableData(date1, date2)
  }

  const resetMonth = () => {
    setPopupSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date())
    })
    handleTableData()
  }

  const custData = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Reg No.",
        field: "cust_reg_no",
        width: 150,
      },
      {
        label: "Customer",
        field: "cust_name",
        width: 100,
      },
      {
        label: "Mobile",
        field: "cust_phone",
        width: 100,
      },
    ],
    rows: custDetails,
  }

  const payData = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Transfer From",
        field: "fund_transfer_from",
        width: 150,
      },
      {
        label: "Transfer To",
        field: "fund_transfer_to",
        width: 100,
      },
      {
        label: "Amount",
        field: "fund_transfer_amount",
        width: 100,
      },
    ],
    rows: payableDetails,
  }


  return (
    <>

      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Staff Wallet Details</h5>
          <button
            type="button"
            onClick={() => {
              closePopup();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <Col xl="12">
              <Row>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">From</Label>
                    <input
                      className="form-control"
                      type="date"
                      id="from_date"
                      name="from_date"
                      value={popupSearchData?.from_date}
                      onChange={handleDate}
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">To</Label>
                    <input
                      className="form-control"
                      type="date"
                      id="to_date"
                      name="to_date"
                      value={popupSearchData?.to_date}
                      min={popupSearchData?.from_date}
                      onChange={handleDate}
                    />
                  </div>
                </Col>
                <Col md="5">
                  <div className="mt-2" style={{ paddingTop: "18px" }}>
                    <Button
                      color="primary"
                      type="submit"
                      onClick={() => fetch_staff_wallet_details(selectedUserId)}
                    >
                      Search
                    </Button>
                    <Button
                      color="danger"
                      type="reset"
                      onClick={() => reset()}
                      style={{ marginLeft: "15px" }}
                    >
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <MDBDataTable
                  id="staffWalletDetailsTableId"
                  responsive
                  bordered
                  data={staffWalletDetails}
                  info={true}
                  searching={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </Row>
            </Col>
          </div>
        </AvForm>
      </Modal>

      {/* Customer details */}
      <Modal show={openModal} centered={true} size="small" className={darkBackground ? "light-background" : ""}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Customer Details</h5>
          <button
            type="button"
            onClick={() => {
              setOpenModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>

        <div className="modal-body">
          <Col xl="12">

            <Row className="mt-3">
              <MDBDataTable
                id="customersTableId"
                responsive
                bordered
                info={true}
                searching={true}
                disableRetreatAfterSorting={true}
                entries={20}
                data={custData}
              />
            </Row>
          </Col>
        </div>

      </Modal>

      {/* Payment Details */}
      <Modal show={payModal} centered={true} size="small" className={darkBackground ? "light-background" : ""}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Payment Details</h5>
          <button
            type="button"
            onClick={() => {
              setPayModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>

        <div className="modal-body">
          <Col xl="12">

            <Row className="mt-3">
              <MDBDataTable
                id="paymentTableId"
                responsive
                bordered
                info={true}
                searching={true}
                disableRetreatAfterSorting={true}
                entries={20}
                data={payData}
              />
            </Row>
          </Col>
        </div>

      </Modal>


      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Staff Wallet Report" />

          <Row>
            <Col md="12">
              <AvForm>
                <Row>

                  {/* <Col md="2">
                    <Label>Month</Label>
                    <AvField
                      name="current_month"
                      className="form-control"
                      type="month"
                      // max={moment().format("YYYY-MM")}
                      onChange={handleChangeDate}
                      value={month}
                    />
                  </Col> */}

                  <Col md="2">
                    <div className="mb-3">
                      <Label>From</Label>
                      <AvField
                        name="from_date"
                        type="date"
                        className="form-control"
                        value={popupSearchData?.from_date}
                        onChange={handleDate}
                      />
                    </div>
                  </Col>

                  <Col md="2">
                    <div className="mb-3">
                      <Label>To</Label>
                      <AvField
                        name="to_date"
                        type="date"
                        className="form-control"
                        onChange={handleDate}
                        value={popupSearchData?.to_date}
                        min={popupSearchData?.from_date}
                        max={moment(popupSearchData.from_date).add(30, 'days').format('YYYY-MM-DD')}
                      />
                    </div>
                  </Col>

                  <Col md="1">
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginTop: "25px" }}>
                        <Button color="primary" type="submit" onClick={() => handleSearch()}>Search</Button>
                      </div>

                      <div style={{ marginLeft: "15px", marginTop: "25px" }}>
                        <Button color="danger" type="reset" onClick={() => resetMonth()}>Reset</Button>
                      </div>
                      {isSuperAdmin() ? (
                      <div style={{ marginLeft: "15px", marginTop: "25px" }}>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Staffwallet_report_" + month + ".xls"
                            }
                            style={{ color: "white" }}
                          >Export</CSVLink>
                        </Button>
                      </div>
                  ) : null}
                    </div>
                  </Col>

                </Row>

              </AvForm>

              <Card>
                <CardBody>
                  <MDBDataTable
                    id="staffwalletTableId"
                    responsive
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </>
  );
};


export default StaffWalletReport;
