import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, NavItem } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import Select from "react-select";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
// import { getLocalbody } from "../../../helpers/globalFunctions";
import "./requestItem.scss";

const AddRequestItem = (props) => {
  const [master, setMaster] = useState({});
  const [errors, setErrors] = useState({
    iconError: "",
  });
  const [details, setDetails] = useState([]);
  const [requestIdToBeDeleted, setRequestIdToBeDeleted] = useState(null);
  const [requestIdToBeUpdated, setRequestIdToBeUpdated] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
  }, []);

  const handleTableData = () => {
    axios
      .get(`${API_URL}foodwaste/requireitem_list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((el, index) => {
            el.id = index + 1;
            if (el?.updated_date)
              el.date = moment(el?.updated_date).format("DD-MM-YYYY");
            else if (el?.date) el.date = moment(el?.date).format("DD-MM-YYYY");
            else el.date = "";
            if (el?.updated_time)
              el.time = moment(el?.updated_time, "hhmmss").format("hh:mm a");
            else if (el?.time)
              el.time = moment(el?.time, "hhmmss").format("hh:mm a");
            else el.time = "";

            let addedBy = el?.addedby || {};
            el.staff_name = addedBy?.firstName;

            if (el?.require_icon) {
              el.icon = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/foodwaste_requireicon/${el?.require_icon}`}
                    height="50"
                    width="50"
                  />
                </div>
              );
            }
            el.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateRequest(el);
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                      setRequestIdToBeDeleted(el._id);
                      setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preUpdateRequest = (item) => {
    setRequestIdToBeUpdated(item?._id);
    item.require_item_id = item?._id;
    setMaster(item);
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const uploadImage = (e) => {
    let val = e.target.files[0];

    if (val?.size > 102400)
      toastr.error("Icon size should be less than or equal to 100 Kb");
    else {
      const fd = new FormData();
      fd.append("foodwaste_requireicon", e.target.files[0]);
      axios
        .post(`${API_URL}api/staff/foodwasterequireicon`, fd, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            setMaster({
              ...master,
              [`require_icon`]: response.data.file.filename,
            });
            setErrors({
              ...errors,
              iconError: "",
            });
          } else {
            toastr.error(response.data.message);
          }
        });
    }
  };
  const deleteIcon = () => {
    setMaster({
      ...master,
      [`require_icon`]: "",
    });
    setErrors({
      ...errors,
      iconError: "",
    });
  };
  const handleValidSubmit = () => {
    if (!master?.require_icon) {
      setErrors({
        iconError: "Please choose icon",
      });
    } else {
      if (requestIdToBeUpdated) {
        axios
          .put(`${API_URL}foodwaste/requireitem/update`, master, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Request item updated successfully");
              handleTableData();
              formRef.current.reset();
              setRequestIdToBeUpdated(null);
              setMaster({
                require_name: "",
                require_icon: "",
              });
              setErrors({
                ...errors,
                iconError: "",
              });
            } else {
              toastr.error(res.data.message, "Failed to update request item");
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}foodwaste/requireitem`, master, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Request item added successfully");
              handleTableData();
              formRef.current.reset();
              setMaster({
                require_name: "",
                require_icon: "",
              });
              setErrors({
                ...errors,
                iconError: "",
              });
            } else {
              toastr.error(res.data.message, "Failed to add request item");
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "require_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Icon",
        field: "icon",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  return (
    <>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}foodwaste/deletes/` + requestIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Request item deleted successfully");
                  if (master && master?._id === requestIdToBeDeleted) {
                    formRef.current.reset();
                    setMaster({
                      require_name: "",
                      require_icon: "",
                    });
                    setErrors({
                      iconError: "",
                    });
                    setRequestIdToBeUpdated(null);
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete request item");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Add Request Item" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleValidSubmit();
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            name="require_name"
                            label="Name"
                            placeholder="Name"
                            type="text"
                            errorMessage="Enter Name"
                            className="form-control"
                            id="validationCustom02"
                            value={master?.require_name}
                            validate={{ required: { value: true } }}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3" style={{ display: "block" }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Choose Icon
                          </Label>
                          {master?.require_icon ? (
                            <div div className="img-wraps">
                              {master.require_icon &&
                              master.require_icon.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${master.require_icon}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/foodwaste_requireicon/${master.require_icon}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteIcon}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="require_icon"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master?.require_icon ? errors.iconError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="mb-3 mt-3"
                          style={{ paddingTop: "10px" }}
                        >
                          {requestIdToBeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="addRequestTableId"
                    responsive
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddRequestItem;
