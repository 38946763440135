import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { getLocalbody } from "../../../helpers/globalFunctions";
import { Modal } from "react-bootstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import "./StaffComplaintNew.scss";
import { Divider, Grid } from "@mui/material";
import toastr, { error } from "toastr";
import DialogActions from "@mui/material/DialogActions";
import { useReactToPrint } from 'react-to-print';
import { PrintableComponentStaff } from "./PrintableComponentStaff";


const DatatableTables = () => {
  
  const [master, setMaster] = useState({
    complaint_localbody: getLocalbody(),
  });
  const printRef = useRef()
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [details, setDetails] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedComplaintCategory, setSelectedComplaintCategory] =
    useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [staffComplaintIdToBeUpdated, setStaffComplaintIdToBeUpdated] =
    useState(null);
  const [staffComplaintIdToBeDeleted, setStaffComplaintIdToBeDeleted] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const [complaintCategoryOptions, setComplaintCategoryOptions] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [userId, setUserId] = useState(1);
  const [uploadProgress, setUploadProgress] = useState();
  const [errors, setErrors] = useState({
    wardError: "",
    staffError: "",
    complaintcategoryError: "",
    //priorityError: "",
    groupError: "",
    customerError: "",
  });
  const [complaintPopupTable, setComplaintPopupTable] = useState([]);
  const [popupData, setPopupData] = useState({});
  const [followUp, setFollowUp] = useState({});
  const [popupError, setPopupError] = useState({
    popupStatusError: "",
    popupCommentError: "",
  });
  const [selectedPopupStatus, setSelectedPopupStatus] = useState(null);
  useEffect(() => {
    handleTableData();
    fetch_all_wards(currentLocalbody);
    fetch_all_staff(currentLocalbody);
    fetch_complaint_catagory();
    fetch_all_customers(currentLocalbody, "");
  }, [currentLocalbody]);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);

      setUserId(obj._id);
    }
  }, [userId]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  })


  function handleTableData() {
    axios
      .get(`${API_URL}staff_complaint/list?localbody_id=` + currentLocalbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            //item.date = moment(item.date).format("DD-MM-YYYY");
            item.time = moment(item.time, "HHmmss").format("hh:mm a");

            if (item?.defendant_lastname)
              item.defendant =
                item?.defendant_firstname + " " + item.defendant_lastname;
            else item.defendant = item?.defendant_firstname;

            if (item.complaint_priority === "0") item.complaint_priority = "Low";
            else if (item.complaint_priority === "1")
              item.complaint_priority = "Medium";
            else if (item.complaint_priority === "2") item.complaint_priority = "High";

            if (item.complaint_activestatus === 0)
              item.complaint_activestatus = (
                <h6 style={{ color: "blue" }}>New</h6>
              );
            else if (item.complaint_activestatus === 1)
              item.complaint_activestatus = (
                <h6 style={{ color: "green" }}>Solved</h6>
              );
            else
              item.complaint_activestatus = (
                <h6 style={{ color: "red" }}>Unsolved</h6>
              );

            if (item.image && item.image.length > 0) {
              item.image &&
                item.image.map((img) => {
                  item.image = img;
                });
            } else item.image = "";

            if (item?.updatedby_firstname) {
              if (item?.updatedby_lasttname)
                item.staff =
                  item?.updatedby_firstname + " " + item.updatedby_lasttname;
              else item.staff = item?.updatedby_firstname;
            } else {
              if (item?.addedbyuser__lasttname)
                item.staff =
                  item?.addedbyuser_firstname +
                  " " +
                  item.addedbyuser__lasttname;
              else item.staff = item?.addedbyuser_firstname;
            }

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => handleClickOpenModal(item)}
                ></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    preUpdateStaffComplaint(item);
                    toTop();
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setStaffComplaintIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  }
  const fetch_all_customers = (localbody, query) => {
    let data = {
      keyword: query,
      localbody_id: [localbody],
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const handleCustomerSearch = (query) => {
    let data = {
      keyword: query,
      localbody_id: currentLocalbody,
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const preUpdateStaffComplaint = (item) => {
    setStaffComplaintIdToBeUpdated(item._id);
    axios
      .get(`${API_URL}staff_complaint/?complaint_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            let wardOptions = {
              label: item?.ward_name,
              value: item?.complaint_ward,
            };
            setSelectedWard(wardOptions);
            fetch_all_groups(wardOptions);
            item.complaint_against = item?.staff_id;
            let staffName = "";
            if (item?.against_secondname)
              staffName =
                item?.against_firstname + " " + item?.against_secondname;
            else staffName = item?.against_firstname;
            let staff_options = {
              label: staffName,
              value: item?.staff_id,
            };
            setSelectedStaff(staff_options);
            item.complaint_category = item?.complaint_type;
            let complaintCatOptions = {
              label: item?.category_name,
              value: item?.complaint_type,
            };
            let group_data = {
              label: item?.group_name,
              value: item?.complaint_group,
            };
            setSelectedGroup(group_data);
            setSelectedComplaintCategory(complaintCatOptions);
            let priorityOption = {};
            if (item.complaint_priority === "0") {
              priorityOption = {
                label: "Low",
                value: "0",
              };
            } else if (item.complaint_priority === "1") {
              priorityOption = {
                label: "Medium",
                value: "1",
              };
            } else if (item.complaint_priority === "2") {
              priorityOption = {
                label: "High",
                value: "2",
              };
            }
            setSelectedPriority(priorityOption);
            item.cust_id = item?.customer_id;
            let customer_data = {
              label: item?.addedby_name,
              value: item?.customer_id,
            };
            setSelectedCustomer(customer_data);

            if (item.image && item.image.length > 0) {
              item.image &&
                item.image.map((img) => {
                  item.image = img;
                });
            } else item.image = "";
          });
        setMaster(...result);
      });
  };
  const fetch_all_groups = (selected) => {
    axios
      .get(
        `${API_URL}api/staff/groups?localbody_id=` +
        currentLocalbody +
        "&ward_id=" +
        selected.value,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data && res.data.success === true) {
          var group_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.group_name,
                value: el._id,
              };
            });
        } else {
          group_data = [];
        }

        setGroupOptions([
          {
            options: group_data,
          },
        ]);
      });
  };

  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const fetch_complaint_catagory = () => {
    axios
      .get(`${API_URL}api/customer/complaint_type_list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var complaint_category_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });

        setComplaintCategoryOptions([
          {
            options: complaint_category_data,
          },
        ]);
      });
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSelectedGroup(null);
        setSelectedStaff(null);
        setMaster({
          ...master,
          ["complaint_ward"]: selected.value,
        });
        fetch_all_groups(selected);
        fetch_all_staff(selected);

        break;
      case "staff":
        setSelectedStaff(selected);
        setMaster({
          ...master,
          // ["staff"]: selected.value,
          ["complaint_against"]: selected.value,
        });
        break;
      case "category":
        setSelectedComplaintCategory(selected);
        setMaster({
          ...master,
          ["complaint_category"]: selected.value,
        });
        break;
      case "priority":
        setSelectedPriority(selected);
        setMaster({
          ...master,
          ["complaint_priority"]: selected.value,
        });
        break;
      case "group":
        setSelectedGroup(selected);
        setMaster({
          ...master,
          ["complaint_group"]: selected.value,
        });
        break;
      case "customer":
        setSelectedCustomer(selected);
        setMaster({
          ...master,
          ["cust_id"]: selected.value,
        });
        setErrors({
          ...errors,
          customerError: "",
        });
        break;
      case "status":
        setSelectedPopupStatus(selected);
        setFollowUp({
          ...followUp,
          followup_status: selected.value,
        });
        setPopupError({
          ...popupError,
          popupStatusError: "",
        });
        break;
      default:
        break;
    }
  };
  const handleValidSubmit = () => {
    if (
      selectedWard === null ||
      selectedStaff === null ||
      selectedComplaintCategory === null ||
      // selectedPriority === null ||
      selectedGroup === null ||
      selectedCustomer === null
    ) {
      let errorVal = errors;
      if (selectedWard === null) {
        errorVal.wardError = "please select ward";
      }
      if (selectedStaff === null) {
        errorVal.staffError = "please select staff";
      }
      if (selectedComplaintCategory === null) {
        errorVal.complaintcategoryError = "please select complaint category";
      }
      //   if (selectedPriority === null) {
      //     errorVal.priorityError = "please select priority";
      //   }
      if (selectedGroup === null) {
        errorVal.groupError = "please select group";
      }
      if (selectedCustomer === null) {
        errorVal.customerError = "please select customer";
      }
      setErrors({
        ...errorVal,
      });
    } else {
      if (staffComplaintIdToBeUpdated) {
        axios
          .put(
            `${API_URL}staff_complaint?complaint_id=` +
            staffComplaintIdToBeUpdated,
            master,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Staff complaint updated successfully");
              formRef.current.reset();
              setStaffComplaintIdToBeUpdated(null);
              setSelectedStaff(null);
              setSelectedPriority(null);
              setSelectedWard(null);
              setSelectedComplaintCategory(null);
              setMaster({
                complaint_localbody: getLocalbody(),
              });
              setSelectedGroup(null);
              handleTableData();
              setSelectedCustomer(null);
              setErrors({
                wardError: "",
                staffError: "",
                complaintcategoryError: "",
                //priorityError: "",
                groupError: "",
                customerError: "",
              });
            } else {
              toastr.error("Failed to update Staff complaint");
              return;
            }
          })
          .catch((err) => {
            toastr.err(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}staff_complaint`, master, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Staff complaint created sucessfully");
              formRef.current.reset();
              setSelectedComplaintCategory(null);
              setSelectedStaff(null);
              setSelectedPriority(null);
              setSelectedWard(null);
              setMaster({
                complaint_localbody: getLocalbody(),
              });
              handleTableData();
              setSelectedGroup(null);
              setSelectedCustomer(null);
              setErrors({
                wardError: "",
                staffError: "",
                complaintcategoryError: "",
                //priorityError: "",
                groupError: "",
                customerError: "",
              });
            } else {
              toastr.error("Failed to create Staff complaint");
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
          });
      }
    }
  };

  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleClickOpenModal = (item) => {
    axios
      .get(`${API_URL}staff_complaint/followuplist?complaint_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            //         if (item?.up_date)
            //   item.date = moment(item?.up_date).format(
            //     "DD-MM-YYYY"
            //   );
            // else if (item?.date)
            //   item.date = moment(item?.date).format("DD-MM-YYYY");
            // else item.date = "";

            // if (item?.up_time)
            //   item.time = moment(item?.up_time, "hhmmss").format(
            //     "hh:mm a"
            //   );
            // else if (item?.time)
            //   item.time = moment(item?.time, "hhmmss").format(
            //     "hh:mm a"
            //   );
            // else item.time = "";
            // if (item?.updated_by) {
            //   let updated_by = item.updated_by || {};
            //   if (updated_by?.lastName)
            //     item.staff = updated_by?.firstName + " " + updated_by?.lastName;
            //   else item.staff = updated_by?.firstName;
            // } else {
            //   let added_by = item.added_by || {};
            //   if (added_by?.lastName)
            //     item.staff = added_by?.firstName + " " + added_by?.lastName;
            //   else item.staff = added_by?.firstName;
            // }
            item.time = moment(item.followup_time, "hhmmss").format("hh:mm a");
            item.date = moment(item.followup_date).format("DD-MM-YYYY");
            item.staff = item.followup_addedby.firstName;

            if (item.complaint_priority === "0") item.complaint_priority = "Low";
            else if (item.complaint_priority === "1") item.complaint_priority = "Medium";
            else if (item.complaint_priority === "2") item.complaint_priority = "High";

            // if (item.followup_status === 0)
            //   item.status = <h6 style={{ color: "blue" }}>New</h6>;
            // else 
            if (item.followup_status === 1)
              item.status = <h6 style={{ color: "green" }}>Solved</h6>;
            else if (item.followup_status === 2) item.status = <h6 style={{ color: "red" }}>Unsolved</h6>;
          });
        setComplaintPopupTable(result);
      });
    setPopupError({
      popupCommentError: "",
      popupStatusError: "",
    });
    setFollowUp({
      followup_refno: item._id,
      followup_status: "",
      followup_comment: "",
    });
    setPopupData(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function createData(
    date,
    time,
    complaintId,
    customer,
    mobile,
    staff,
    complaint_priority,
    status
  ) {
    return {
      date,
      time,
      complaintId,
      customer,
      mobile,
      staff,
      complaint_priority,
      status,
    };
  }

  const rows = [
    createData(
      "2011/04/25",
      "13:45",
      "123",
      "Abhirag K",
      "989559454",
      "TPB3/Kuppam	",
      "High",
      "Pending",
      "Kamala"
    ),
  ];

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "Complainant",
        field: "complainant",
        sort: "asc",
        width: 150,
      },
      {
        label: "Complaint ID",
        field: "complaint_id",
        sort: "asc",
        width: 150,
      },

      {
        label: "Defendant",
        field: "defendant",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: "150",
      },

      // {
      //   label: "Mobile",
      //   field: "staff_mobile",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Localbody",
      //   field: "localbody",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Ward",
        field: "ward",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Group",
      //   field: "group",
      //   sort: "asc",
      //   width: 150,
      // },
      //   {
      //     label: "Priority",
      //     field: "complaint_priority",
      //     sort: "asc",
      //     width: 150,
      //   },
      {
        label: "Status",
        field: "complaint_activestatus",
        sort: "asc",
        width: 150,
      },
      //   {
      //     label: "Staff",
      //     field: "staff",
      //     sort: "asc",
      //     width: 150,
      //   },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  const complaintTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Complainant",
      //   field: "complainant",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Complaint ID",
      //   field: "complaint_id",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Comments",
        field: "followup_comment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      //   {
      //     label: "Priority",
      //     field: "priority",
      //     sort: "asc",
      //     width: 150,
      //   },

    ],
    rows: complaintPopupTable,
  };
  const handleSubmitPopup = () => {
    if (selectedPopupStatus === null || !followUp?.followup_comment) {
      let errorVal = popupError;
      if (selectedPopupStatus === null) {
        errorVal.popupStatusError = "Please select status";
      }
      if (!followUp?.followup_comment) {
        errorVal.popupCommentError = "Please add comments";
      }

      setPopupError({
        ...errorVal,
      });
    } else {
      axios
        .post(`${API_URL}staff_complaint/complaint_followup`, followUp, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Follow up submitted successfully");
            handleCloseModal();
            setSelectedPopupStatus(null);
            setPopupError({
              popupStatusError: "",
              popupCommentError: "",
            });
            handleTableData();
          } else {
            toastr.error("Failed to Submit Follow up");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const handlePopupInputChange = (e) => {
    setFollowUp({
      ...followUp,
      [e.target.name]: e.target.value,
    });
    // setPopupError({
    //   ...popupError,
    //   popupCommentError: "",
    // });
  };
  const deleteComplaintImage = () => {
    setMaster({
      ...master,
      [`image`]: "",
    });
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("complaint_image", e.target.files[0]);
    axios
      .post(`${API_URL}staff_complaint/complaintImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.file;
          let filename = "";
          result &&
            result.map((item) => {
              filename = item?.filename;
            });
          setMaster({
            ...master,
            [`image`]: filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}staff_complaint?complaint_id=` +
                staffComplaintIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Staff complaint deleted successfully");
                  if (master && master?._id === staffComplaintIdToBeDeleted) {
                    formRef.current.reset();
                    setStaffComplaintIdToBeUpdated(null);
                    setSelectedStaff(null);
                    setSelectedPriority(null);
                    setSelectedWard(null);
                    setSelectedComplaintCategory(null);
                    setMaster({
                      complaint_localbody: getLocalbody(),
                    });
                    setSelectedGroup(null);
                    setSelectedCustomer(null);
                    setErrors({
                      wardError: "",
                      staffError: "",
                      complaintcategoryError: "",
                      priorityError: "",
                      groupError: "",
                      customerError: "",
                    });
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete Staff complaint");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Staff Complaint" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="ward"
                            value={selectedWard}
                            onChange={(selected) => {
                              handleSelectChange(selected, "ward");
                            }}
                            options={wardOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedWard === null ? errors.wardError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Group</Label>
                          <Select
                            name="complaint_group"
                            value={selectedGroup}
                            onChange={(selected) => {
                              handleSelectChange(selected, "group");
                            }}
                            options={groupOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedGroup === null ? errors.groupError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            name="complaint_against"
                            value={selectedStaff}
                            onChange={(selected) => {
                              handleSelectChange(selected, "staff");
                            }}
                            options={staffOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedStaff === null ? errors.staffError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Customer</Label>
                          <Select
                            name="cust_id"
                            value={selectedCustomer}
                            onChange={(selected) => {
                              handleSelectChange(selected, "customer");
                            }}
                            options={customerOptions}
                            onInputChange={(input) =>
                              handleCustomerSearch(input)
                            }
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedCustomer === null
                              ? errors.customerError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Complaint Category</Label>
                          <Select
                            name="complaint_category"
                            value={selectedComplaintCategory}
                            onChange={(selected) => {
                              handleSelectChange(selected, "category");
                            }}
                            options={complaintCategoryOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedComplaintCategory === null
                              ? errors.complaintcategoryError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Priority</Label>
                          <Select
                            name="complaint_priority"
                            value={selectedPriority}
                            onChange={(selected) => {
                              handleSelectChange(selected, "priority");
                            }}
                            options={[
                              {
                                label: "Low",
                                value: "0",
                              },
                              {
                                label: "Medium",
                                value: "1",
                              },
                              {
                                label: "High",
                                value: "2",
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          {/* <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedPriority === null
                              ? errors.priorityError
                              : ""}
                          </p> */}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Comments</Label>
                          <AvField
                            name="comment"
                            placeholder="Comments"
                            type="textarea"
                            errorMessage="Add Comment"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleInputChange}
                            value={master?.comment}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Image</Label>
                          {master?.image ? (
                            <div div className="img-wraps">
                              {master.image &&
                                master.image.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${master.image}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/complaint_images/${master.image}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteComplaintImage}
                                style={{ width: "150px" }}
                              // type="reset"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>

                      {/* <label
                          htmlFor="icon-button-file"
                          style={{ marginTop: "2%" }}
                        >
                          <Input
                            accept="image/*"
                            id="icon-button-file"
                            type="file"
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <AccountCircleIcon />
                          </IconButton>
                        </label> */}

                      <Col md="1" style={{ paddingTop: "4px", display: "flex" }}>
                        <div className="mt-4">
                          {staffComplaintIdToBeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>

                        <div className="mt-4" style={{ marginLeft: "15px" }}>
                          <Button color="success" type="print" onClick={handlePrint}>Download</Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View Complaint</CardTitle>
                  <hr /> */}
                  <MDBDataTable
                    id="staffComplaintId"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>
            <Modal
              show={openModal}
              toggle={handleCloseModal}
              size="xl"
              centered={true}
            >
              <div className="modal-header">
                {/* <h5 className="modal-title mt-0">Staff Complaint</h5> */}
                <button
                  type="button"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                {/* <h5>Basic Details</h5> */}
                <table
                  id="product_available_day"
                  className="table table-bordered dataTable"
                >
                  {/* <tr>
                      <td style={{ padding: "10px" }}>
                      Defendant :
                      </td>
                      <td >{popupData?.defendant}</td>
                      <td style={{ padding: "25px", width: "184px" }}>
                      Complainant :
                      </td>
                      <td >{popupData?.complainant}</td>
                      <td style={{ paddingLeft: "25px" }}>Category :</td>
                      <td style={{ textAlign: "left" }}>
                        {popupData?.category}
                      </td>
                    </tr> */}
                  <tr>
                    <td style={{ padding: "10px" }}>Date :</td>
                    <td>{popupData?.date}</td>
                    <td style={{ paddingLeft: "25px" }}>Time :</td>
                    <td style={{ textAlign: "left" }}>{popupData?.time}</td>
                    <td style={{ paddingLeft: "25px" }}>Defendant :</td>
                    <td style={{ textAlign: "left" }}>{popupData?.defendant}</td>

                  </tr>
                  <tr>

                    <td style={{ padding: "10px" }}>Complainant :</td>
                    <td>{popupData?.complainant}</td>
                    <td style={{ paddingLeft: "25px" }}>Ward :</td>
                    <td style={{ textAlign: "left" }}>{popupData?.ward}</td>
                    <td style={{ paddingLeft: "25px" }}>Group :</td>
                    <td style={{ textAlign: "left" }}>{popupData?.group}</td>

                  </tr>
                  <tr  >
                    <td style={{ padding: "10px" }}>Category :</td>
                    <td>{popupData?.category}</td>
                    <td style={{ paddingLeft: "25px" }}>Status :</td>
                    <td style={{ textAlign: "left" }}>{popupData?.complaint_activestatus}</td>
                    <td style={{ paddingLeft: "25px" }}>Priority :</td>
                    <td style={{ textAlign: "left" }}>{popupData?.complaint_priority}</td>
                    <td></td>
                  </tr>
                  {/* <td style={{ paddingLeft: "25px" }}>Status :</td>
                      <td style={{ textAlign: "left" }}>{popupData?.complaint_activestatus}</td> */}
                  {/* <td style={{paddingLeft: "25px"  }}>
                        Image :
                      </td>
                      <td hidden={!popupData?.image} style={{ textAlign: "left" }}>
                        <img
                          style={{
                            width: "80px",
                            height: "80px",
                            paddingLeft: "0px",
                          }}
                          src={`${API_URL}uploads/complaint_images/${popupData?.image}`}
                        ></img>
                      </td> */}





                  {/* <td style={{ paddingLeft: "25px" }}>Date :</td>
                      <td style={{ textAlign: "left" }}>{popupData?.date}</td> */}




                  <tr>  <td style={{ padding: "10px" }}>Comments :</td>
                    <td colSpan={3} style={{ textAlign: "left" }}>{popupData?.comment}</td></tr>
                  <tr hidden={!popupData?.image}>
                    <td style={{ padding: "10px", textAlign: "left" }}>
                      Image :
                    </td>
                    <td style={{ textAlign: "left" }}>
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          paddingLeft: "0px",
                        }}
                        src={`${API_URL}uploads/complaint_images/${popupData?.image}`}
                      ></img>
                    </td>
                    <td style={{ textAlign: "left" }}></td>
                    <td style={{ textAlign: "left" }}>{ }</td>

                  </tr>
                  {/* <tr hidden={!popupData?.image}>
                      <td style={{ padding: "10px", textAlign: "left" }}>
                        Image :
                      </td>
                      <td style={{ textAlign: "left" }}>
                        <img
                          style={{
                            width: "180px",
                            height: "180px",
                            paddingLeft: "0px",
                          }}
                          src={`${API_URL}uploads/complaint_images/${popupData?.image}`}
                        ></img>
                      </td>
                      <td style={{ textAlign: "left" }}></td>
                      <td style={{ textAlign: "left" }}>{}</td>
                    </tr> */}
                  {/* <tr>
                      <td style={{ padding: "10px" }}>Mobile :</td>
                      <td>{popupData?.mobile}</td>
                      <td></td>
                      <td style={{ textAlign: "left" }}>{}</td>
                    </tr> */}
                </table>
              </div>

              <Row>
                <Col className="col-12">
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleSubmitPopup(e, v);
                    }}
                  >
                    <Row style={{ paddingLeft: "15px" }}>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="followup_status"
                            value={selectedPopupStatus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "status");
                            }}
                            options={[
                              {
                                label: "Solved",
                                value: 1,
                              },
                              {
                                label: "Unsolved",
                                value: 2,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedPopupStatus === null
                              ? popupError.popupStatusError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="5">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Comments</Label>
                          <AvField
                            name="followup_comment"
                            placeholder="Comments"
                            type="textarea"
                            // errorMessage="Add Comment"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handlePopupInputChange}
                            value={followUp?.followup_comment}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!followUp?.followup_comment
                              ? popupError.popupCommentError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <Button style={{ backgroundColor: "blue", marginTop: "40px" }}
                          type="submit"
                        // onClick={handleCloseModal}
                        >
                          Submit
                        </Button>
                      </Col>


                    </Row>
                  </AvForm>



                  <Row
                    style={{ paddingLeft: "15px", paddingRight: "15px" }}
                    className="mt-2"
                  >
                    <MDBDataTable
                      id="staffcomplaintPopupTableDataId"
                      responsive
                      bordered
                      data={complaintTableData}
                      paging={false}
                      searching={false}
                    />
                  </Row>
                </Col>
              </Row>
              <DialogActions
                style={{ paddingRight: "15px" }}
                className="mb-2"
              >
                {/* <Col md="3">
                        <div className="mb-3"> */}


                {/* </div>
                    </Col> */}

              </DialogActions>

            </Modal>
          </div>

          <div style={{ display: 'none' }}>
            <PrintableComponentStaff ref={printRef} data={details} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
