import React from "react";
import { MDBDataTable } from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import Resete from "@mui/icons-material/ResetTvRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import $ from "jquery";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./WasteReportWard.scss";
import { Grid } from "@mui/material";


const DatatableTables = () => {
  const data = {
    columns: [
      {
        label: "#",
        field: "si",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ward No.",
        field: "WNo",
        sort: "asc",
        width: 150,
      },
      {
        label: "House No.",
        field: "HNo",
        sort: "asc",
        width: 270,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Group",
        field: "group",
        sort: "asc",
        width: 150,
      },
      {
        label: "Plastic Waste",
        field: "PWaste",
        sort: "asc",
        width: 200,
      },
      {
        label: "Chappal",
        field: "chappal",
        sort: "asc",
        width: 200,
      },
      {
        label: "Glass",
        field: "glass",
        sort: "asc",
        width: 200,
      },
      {
        label: "E-Waste",
        field: "EWaste",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total",
        field: "total",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        si: "",
        WNo: "",
        HNo: "",
        staff: "",
        group: "",
        PWaste: "",
        chappal: "",
        glass: "",
        EWaste: "",
        total: "",
      },
    ],
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Waste Report Ward" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="ward_id"
                            // value={}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected);
                            // }}
                            options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            // onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            // onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Weight</Label>
                          <Select
                            name="ward_id"
                            // value={}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected);
                            // }}
                            options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mt-4">
                          <Button color="danger" type="submit">
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View Waste Report Ward</CardTitle>
                  <hr />
                  <MDBDataTable responsive bordered data={data} /> */}
                  <Row className="mt-4">
                    <div className="table-responsive">
                      {/* <div className="row">
                        <div
                          data-test="datatable-entries"
                          className="col-sm-12 col-md-6"
                        >
                          <div
                            data-test="datatable-select"
                            className="dataTables_length bs-select"
                          >
                            <label>
                              Show entries
                              <select className="custom-select custom-select-sm form-control form-control-sm">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </label>
                          </div>
                        </div> */}
                        {/* <div
                          data-test="datatable-search"
                          className="col-sm-12 col-md-6"
                          style={{ width: "revert", marginLeft: "auto" }}
                        >
                          <div
                            data-test="datatable-input"
                            className="mdb-datatable-filter flex-row"
                          >
                            <input
                              className="form-control form-control-sm ml-0 my-1"
                              type="text"
                              placeholder="Search"
                              aria-label="Search"
                              value=""
                            />
                          </div>
                        </div> */}
                      </div>
                      <table
                        id="dtBasicExample"
                        className="table table-bordered"
                        style={{ border: "1px solid #ced4da" }}
                      >
                        <thead>
                          <tr>
                            <th rowSpan="2">#</th>

                            <th rowSpan="2">Ward No.</th>
                            <th rowSpan="2">House No.</th>
                            <th rowSpan="2">Staff</th>
                            <th rowSpan="2">Group</th>
                            <th colSpan="2">Plastic Waste</th>
                            <th colSpan="1">Chappal</th>
                            <th colSpan="1">Glass</th>
                            <th colSpan="2">E-waste</th>
                            <th rowSpan="2">Total</th>
                          </tr>
                          <tr>
                            <td>Bag</td>
                            <td>Weight(Kg)</td>
                            <td>Bag</td>
                            <td>Weight(Kg)</td>
                            <td>Bag</td>
                            <td>Weight(Kg)</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>

                            <td>W1</td>
                            <td>H1</td>
                            <td>W1</td>
                            <td>H1</td>
                            <td>Priya</td>
                            <td>GN1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                            {/* <td>1</td>
                            <td>10</td>
                            <td>15</td> */}
                          </tr>
                          <tr>
                            <td>2</td>

                            <td>W2</td>
                            <td>H2</td>
                            <td>W2</td>
                            <td>H2</td>
                            <td>Anisha</td>
                            <td>GN2</td>
                            <td>1</td>
                            <td>2</td>
                            <td>4</td>
                            <td>0</td>
                            {/* <td>1</td>
                            <td>8</td>
                            <td>14</td> */}
                          </tr>
                        </tbody>
                      </table>
                      {/* <div className="row">
                        <div
                          data-test="datatable-info"
                          className="col-sm-12 col-md-5"
                        >
                          <div
                            className="dataTables_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to 2 of 2 entries
                          </div>
                        </div>
                        <div
                          data-test="datatable-pagination"
                          className="col-sm-12 col-md-7"
                        >
                          <div className="dataTables_paginate">
                            <ul data-test="pagination" className="pagination">
                              <li
                                data-test="page-item"
                                className="disabled page-item"
                              >
                                <a
                                  data-test="page-link"
                                  aria-label="Previous"
                                  className="page-link page-link"
                                >
                                  <span>Previous</span>
                                </a>
                              </li>
                              <li
                                data-test="page-item"
                                className="active page-item"
                              >
                                <a
                                  data-test="page-link"
                                  className="page-link page-link"
                                >
                                  1<span className="sr-only">(current)</span>
                                </a>
                              </li>
                              <li
                                data-test="page-item"
                                className="disabled page-item"
                              >
                                <a
                                  data-test="page-link"
                                  aria-label="Next"
                                  className="page-link page-link"
                                >
                                  <span>Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
