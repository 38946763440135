import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { Modal } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import { Row, Col, Card, CardBody, Button, Label, NavItem, CardHeader } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import $ from "jquery";
import moment from "moment";
import "./attendanceSummary.scss";
import axios from "axios";
import toastr from "toastr";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody } from "../../../helpers/globalFunctions";
import Select from "react-select";
import { yellow } from "@mui/material/colors";

const AttendanceSummary = () => {
  const [searchData, setSearchData] = useState({ from_date: moment().format("YYYY-MM") });
  const [details, setDetails] = useState([]);
  const [events, setEvent] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [showPopup, setShowPopup] = useState(false);
  const [popupTableData, setPopupTableData] = useState([]);
  const [staffName, setStaffName] = useState("");
  const [isEdit, setIsEdit] = useState('none');
  const [ismultiEdit, setIsmultiEdit] = useState('none');
  const [isProcessing, setIsProcessing] = useState(false);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [updateData, setUpdateData] = useState({});
  const [holidays, setHolidays] = useState([]);
  const [attendanceStatus, setAttendance] = useState(null);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [privilage, setPrivilage] = useState([]);
  const [title, setTitle] = useState(moment(searchData.from_date).format("MMMM YYYY"));
  const calendarRef = React.useRef();
  useEffect(() => {
    handleTableData(searchData.from_date);
    var year_from_month = moment().format("YYYY-MM").split("-")[0];
    var month_from_month = moment().format("YYYY-MM").split("-")[1];
    var month_days = new Date(year_from_month, month_from_month, 0).getDate();
    month_days++;
    setstartDate(moment().format("YYYY-MM") + "-" + "01");
    setendDate(moment().format("YYYY-MM") + "-" + month_days);
  }, [currentLocalbody]);
  const style = {
    // control: (base) => ({
    //   ...base,
    //   border: 1,
    //   boxShadow: "none",
    // }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
    }),
  };
  const handleTableData = (from_date = "") => {
    let url =
      `${API_URL}api/staff/attendancelist?localbody_id=` + currentLocalbody;
    url = url + "&from_date=" + from_date;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((element, index) => {
            element.id = index + 1;
            element.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenModal(element, from_date);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  };
  const handleClickOpenModal = (data, month) => {
    setStaffName(data?._id);
    setUpdateData({ staff_id: data._id });
    axios
      .get(
        `${API_URL}api/staff/attendancesinglelist?staff_id=` +
        data?._id +
        "&localbody_id=" +
        currentLocalbody +
        "&month=" + month,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {

          var attendance = [];
          let result = res.data.data;

          result &&
            result.map((el, index) => {
              el.id = index + 1;

              var privilage = res.data.privilage;
              setPrivilage(privilage)
              if (el?.status === "2") {
                attendance.push({ title: 'Present', start: el?.date, color: '#abd5c5' },)
                el.attendanceStatus = (
                  <span style={{ color: "green" }}>Present</span>
                );
              }

              else if (el?.status === "0") {
                attendance.push({ title: 'Absent', start: el?.date, color: '#d17a7a' },)
                el.attendanceStatus = (
                  <span style={{ color: "red" }}>Absent</span>
                );
              }
              else if (el?.status === "1") {
                attendance.push({ title: 'Halfday', start: el?.date, color: 'lightslategray' },)
                el.attendanceStatus = (
                  <span style={{ color: "#ed9900" }}>Half Day</span>
                );
              }
              else el.attendanceStatus = el.status;
              el.date = moment(el?.date).format("DD-MM-YYYY");
            });
          let item = res.data.holiday;
          var holidays = [];

          item &&
            item.map((el, index) => {
              el.id = index + 1;
              el.holiday_date = moment(el?.holiday_date).format("D");
              holidays.push(el)
            }
            )


          //   // if (el?.status === "2")
          //   // {
          //     // holiday.push({ date: el?.holiday_date,name:el?.holiday_name })
          //     // el.holiday = (
          //     //   <span style={{ color: "green" }}></span>
          //     // );
          //   // }


          //   //el.holiday_date = moment(el?.holiday_date).format("DD-MM-YYYY");

          // });
          setHolidays(holidays);

          setPopupTableData(result);
          setEvent(attendance);
        } else { setPopupTableData([]); setEvent([]); }
      });
    setShowPopup(true);
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Present",
        field: "Total_present",
        sort: "asc",
        width: 270,
      },
      {
        label: "Half Day",
        field: "Total_halfday",
        sort: "asc",
        width: 150,
      },
      {
        label: "Leave",
        field: "Total_absent",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "LOP",
      //   field: "Total_lop",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  const popupDataTable = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Attendance",
        field: "attendanceStatus",
        sort: "asc",
        width: 270,
      },
    ],
    rows: popupTableData,
  };
  let handleDate = (e) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };
  const reset = () => {
    setSearchData({
      from_date: moment().format("YYYY-MM"),
    });
    handleTableData(searchData.from_date);
  };
  const handleSearch = () => {
    setTitle(moment(searchData.from_date).format("MMMM YYYY"));
    let date1 = searchData?.from_date ? searchData.from_date : "";
    var year_from_month = date1.split("-")[0];
    var month_from_month = date1.split("-")[1];
    var month_days = new Date(year_from_month, month_from_month, 0).getDate();
    month_days++;
    handleTableData(date1);
    setstartDate(date1 + "-" + "01");
    setendDate(date1 + "-" + month_days);
  };
  const closePopup = () => {
    setPopupTableData([]);
    setStaffName("");
    setIsmultiEdit("none");
    setShowPopup(false);
    handleTableData(searchData?.from_date);
  };
  const handleSelectChange = (selected) => {
    setAttendance(selected);
    if (typeof selected.value != "undefined") {
      $("#attendance").removeAttr('style');
      setValidationError(null);
    }

    setUpdateData({
      ...updateData,
      ['attendance_status']: selected.value
    })
  };
  const handleDateClick = arg => {
    const event = arg.event;
    if (privilage == 2) {
      if (arg.dateStr == moment().format("YYYY-MM-DD")) {
        let calendarApi = calendarRef.current.getApi();
        calendarApi.select(arg.dateStr);
        var display_date = moment(arg.dateStr).format("DD/MM/YYYY") + " - " + moment(arg.dateStr).format("DD/MM/YYYY");

        setUpdateData({
          start_date: arg.dateStr,
          end_date: arg.dateStr,
          staff_id: staffName,
          attendance_status: attendanceStatus,
          localbody_id: currentLocalbody,
          display_date: display_date
        });
      }
      else {
        setIsmultiEdit('none');
      }

    }
    else {
      if (arg.dateStr <= moment().format("YYYY-MM-DD")) {
        let calendarApi = calendarRef.current.getApi();
        calendarApi.select(arg.dateStr);
        var display_date = moment(arg.dateStr).format("DD/MM/YYYY") + " - " + moment(arg.dateStr).format("DD/MM/YYYY");

        setUpdateData({
          start_date: arg.dateStr,
          end_date: arg.dateStr,
          staff_id: staffName,
          attendance_status: attendanceStatus,
          localbody_id: currentLocalbody,
          display_date: display_date
        });
      }
      else {
        setIsmultiEdit('none');
      }


    }

  }
  const handleDraging = arg => {
    setAttendance(null);

    var start_date = arg.startStr;
    var end = arg.endStr;
    var end_date = new Date(end);
    end_date.setDate(end_date.getDate() - 1);
    end_date = moment(end_date).format("YYYY-MM-DD");
    setIsmultiEdit('block');
    if (start_date == end_date) {
      var display_date = moment(start_date).format("DD/MM/YYYY")
    }
    else {
      var display_date = moment(start_date).format("DD/MM/YYYY") + " - " + moment(end_date).format("DD/MM/YYYY")
    }
    setUpdateData({
      start_date: start_date,
      end_date: end_date,
      staff_id: staffName,
      attendance_status: attendanceStatus,
      localbody_id: currentLocalbody,
      display_date: display_date
    });
  }
  const handleEventClick = arg => {
    const event = arg.event;
    const eventDate = moment(event.start);
    if (privilage == 2) {
      const currentDate = moment();
      const currentMonthStartDate = moment().startOf('month');
      const currentMonthEndDate = moment().endOf('month');


      if (eventDate.isBetween(currentMonthStartDate, currentMonthEndDate, null, '[]')) {

        if (event.title == "Present") {
          var status = 2;
          setAttendance({ label: "Present", value: "2" })
        }
        else if (event.title == "Absent") {
          var status = 0;
          setAttendance({ label: "Absent", value: "0" })
        }
        else if (event.title == "Halfday") {
          var status = 1;
          setAttendance({ label: "Halfday", value: "1" });
        }
        setUpdateData({
          start_date: moment(event.start).format("YYYY-MM-DD"),
          end_date: moment(event.start).format("YYYY-MM-DD"),
          display_date: moment(event.start).format("DD/MM/YYYY"),
          staff_id: staffName,
          attendance_status: status,
          localbody_id: currentLocalbody,
        });
        let calendarApi = calendarRef.current.getApi();
        calendarApi.select(event.start);
      }
      else {
        setIsmultiEdit('none');
      }
    }
    else {
      if (event.title == "Present") {
        var status = 2;
        setAttendance({ label: "Present", value: "2" })
      }
      else if (event.title == "Absent") {
        var status = 0;
        setAttendance({ label: "Absent", value: "0" })
      }
      else if (event.title == "Halfday") {
        var status = 1;
        setAttendance({ label: "Halfday", value: "1" });
      }
      setUpdateData({
        start_date: moment(event.start).format("YYYY-MM-DD"),
        end_date: moment(event.start).format("YYYY-MM-DD"),
        display_date: moment(event.start).format("DD/MM/YYYY"),
        staff_id: staffName,
        attendance_status: status,
        localbody_id: currentLocalbody,
      });
      let calendarApi = calendarRef.current.getApi();
      calendarApi.select(event.start);

    }




  }
  const handleValidEventSubmit = (e, values) => {
    setIsProcessing(true);
    axios
      .post(
        `${API_URL}api/staff/add_attendance_crm`,
        updateData,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          setUpdateData({});
          setIsEdit("none");
          setIsProcessing(false);
          let calendarApi = calendarRef.current.getApi();
          calendarApi.unselect();
          var attendance = [];
          let result = res.data.data;
          result &&
            result.map((el, index) => {
              el.id = index + 1;


              if (el?.status === "2") {
                attendance.push({ title: 'Present', start: el?.date, color: '#abd5c5' },)
                el.attendanceStatus = (
                  <span style={{ color: "green" }}>Present</span>
                );
              }

              else if (el?.status === "0") {
                attendance.push({ title: 'Absent', start: el?.date, color: '#d17a7a' },)
                el.attendanceStatus = (
                  <span style={{ color: "red" }}>Absent</span>
                );
              }
              else if (el?.status === "1") {
                attendance.push({ title: 'Halfday', start: el?.date, color: 'lightslategray' },)
                el.attendanceStatus = (
                  <span style={{ color: "#ed9900" }}>Half Day</span>
                );
              }
              else el.attendanceStatus = el.status;
              el.date = moment(el?.date).format("DD-MM-YYYY");
            });
          setPopupTableData(result);
          setEvent(attendance);
        }
      });
  };
  const handleValidMultipleSubmit = (e, values) => {
    if (attendanceStatus == null) {
      $("#attendance").attr('style', 'border: #f46a6a thin solid');
      setValidationError("Select Attendance Status");
      return;
    }
    $("#attendance").removeAttr('style');
    setValidationError(null);
    setIsProcessing(true);
    axios
      .post(
        `${API_URL}api/staff/add_attendance_mutiple`,
        updateData,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          setIsProcessing(false);
          setUpdateData({});
          setIsmultiEdit("none");
          let calendarApi = calendarRef.current.getApi();
          calendarApi.unselect();
          var attendance = [];
          let result = res.data.data;
          result &&
            result.map((el, index) => {
              el.id = index + 1;


              if (el?.status === "2") {
                attendance.push({ title: 'Present', start: el?.date, color: '#abd5c5' },)
                el.attendanceStatus = (
                  <span style={{ color: "green" }}>Present</span>
                );
              }

              else if (el?.status === "0") {
                attendance.push({ title: 'Absent', start: el?.date, color: '#d17a7a' },)
                el.attendanceStatus = (
                  <span style={{ color: "red" }}>Absent</span>
                );
              }
              else if (el?.status === "1") {
                attendance.push({ title: 'Halfday', start: el?.date, color: 'lightslategray' },)
                el.attendanceStatus = (
                  <span style={{ color: "#ed9900" }}>Half Day</span>
                );
              }
              else el.attendanceStatus = el.status;
              el.date = moment(el?.date).format("DD-MM-YYYY");
            });
          setPopupTableData(result);
          setEvent(attendance);
        }
      });
  };
  return (
    <>
      <React.Fragment>
        <Modal show={showPopup} size="lg" centered={true} >
          <div className="modal-header">
            <AvForm onValidSubmit={handleValidMultipleSubmit} style={{ width: "500px", display: ismultiEdit }}>
              <Row>
                <Col className="col-6">
                  <label>{updateData?.display_date}</label>

                  <Select
                    id="attendance"
                    styles={style}
                    name="status"
                    menuPosition="fixed"
                    placeholder="Select"
                    value={attendanceStatus}
                    onChange={(selected) => {
                      handleSelectChange(selected);
                    }}
                    classNamePrefix="select2-selection"
                    options={[
                      { label: "Present", value: "2" },
                      { label: "Halfday", value: "1" },
                      { label: "Absent", value: "0" }
                    ]}
                  />
                  <p
                    className="text-danger"
                    style={{ fontSize: "11px" }}
                  >
                    {validationError}
                  </p>


                </Col>
                <Col>
                  <div className="text-right" style={{ paddingTop: "26px" }}>
                    <button
                      type="submit"
                      className="btn btn-success save-event"
                      disabled={isProcessing ? true : false}
                    >
                      {isProcessing ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </Col>
              </Row>

            </AvForm>
            <button
              type="button"
              onClick={() => {
                closePopup();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <Row>
            {/* <Col className="col-12"> */}
            <Col className="col-3" >
              <Card >
                <CardBody className="d-grid" >
                  {/* <Button
                        color="primary"
                        className="btn font-16 btn-block"
                        // onClick={}
                        id="btn-new-event"
                      >
                        <i className="mdi mdi-plus-circle-outline" />
                                            Create New Event
                                        </Button> */}
                  <Row className="mt-2">
                    {/* <img src={} alt="" className="img-fluid d-block" /> */}
                  </Row>
                  <div id="external-events" >
                    <label style={{ marginLeft: "20px", fontSize: "16px" }}>HOLIDAYS</label>
                    {/* <h4 style={{marginLeft:"20px"}}>HOLIDAYS</h4> */}

                    {/* <Button
                        color="danger"
                        className="btn font-16 btn-block"
                        id="btn-new-event"
                      >HOLIDAYS</Button>  */}

                  </div>
                  <p></p>

                  {/* <br /> */}


                  {/* {holidays[0]?.holiday_date} */}

                  {holidays &&
                    holidays.map((items, i) => (

                      // <Button color="warning">{items.holiday_date}</Button>


                      <div
                        className={`external-event ${items.holiday_date} text-white fc-event `}
                        style={{ backgroundColor: "#d16258" }}
                      // key={"cat-" + category.id}
                      // draggable
                      // onDrag={event => onDrag(event, category)}
                      >
                        <div id="data">
                          <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />

                          {items.holiday_date}
                          {"  "}
                          {items.holiday_name}
                        </div>

                      </div>

                    ))}

                  {/* <ol className="activity-feed mb-0 ps-2 mt-4 ms-1" >
                        <li className="feed-item">
                          <p className="mt-0 mb-0">Andrei Coman magna sed porta finibus, risus
                                                                        posted a new article: Forget UX Rowland</p>
                        </li>
                        <li className="feed-item">
                          <p className="mt-0 mb-0">Zack Wetass, sed porta finibus, risus Chris Wallace Commented Developer Moreno</p>
                        </li>
                        <li className="feed-item">
                          <p className="mt-0 mb-0">Zack Wetass, Chris combined Commented UX Murphy</p>
                        </li>
                      </ol> */}
                </CardBody>
              </Card>
            </Col>
            <Col >
              <Card>
                <CardBody>
                  <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
                    <div className="modal-body" >
                      <FullCalendar
                        ref={calendarRef}
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                        ]}
                        initialView="dayGridMonth"
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "",
                          center: "title",
                          right: "",
                        }}

                        events={events}
                        //editable={true}
                        //droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        validRange={() => {
                          return {
                            start: startDate,
                            end: endDate
                          };
                        }}
                        selectAllow={(info) => {
                          if (info.startStr < moment().format("YYYY-MM-DD"))
                            return false;
                          else
                            return true;
                        }}
                        select={handleDraging}

                      />
                      {/* <MDBDataTable
                id="attendancePopupTableId"
                responsive
                bordered
                data={popupDataTable}
                disableRetreatAfterSorting={true}
                paging={false}
                searching={false}
                entries={20}
              /> */}
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            {/* </Col> */}
          </Row>
        </Modal>

        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Attendance" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            {/* <Label htmlFor="validationCustom05">From</Label> */}
                            <input
                              className="form-control"
                              type="month"
                              id="from_date"
                              name="from_date"
                              value={searchData?.from_date}
                              onChange={handleDate}
                              max={moment().format("YYYY-MM")}
                            />
                          </div>
                        </Col>
                        {/* <Col md="2">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">To</Label>
                            <input
                              className="form-control"
                              type="date"
                              id="to_date"
                              name="to_date"
                              value={searchData?.to_date}
                              min={searchData?.from_date}
                              onChange={handleDate}
                            />
                          </div>
                        </Col> */}
                        <Col md="2">
                          <div style={{ display: "flex" }} >
                            <div className="mt-1 mb-3 mx-1" >


                              <Button
                                color="primary"
                                type="submit"
                                onClick={() => handleSearch()}
                              >
                                Search
                              </Button>
                            </div>


                            <div className="mt-1 mb-3 mx-1" >

                              <Button
                                color="danger"
                                type="reset"
                                onClick={() => reset()}
                              >
                                Reset
                              </Button>
                            </div>
                          </div>

                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardHeader><center><h5>Attendance - {title}</h5></center></CardHeader>
                  <CardBody>
                    <MDBDataTable
                      id="attendanceSummaryTableId"
                      responsive
                      bordered
                      data={data}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default AttendanceSummary;
