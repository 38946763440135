import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { Modal } from "react-bootstrap";
import toastr from "toastr";
import "./Incentive.scss";
import Select from "react-select";
import { getLocalbody } from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { Row, Col, Card, CardBody, Button, Label, Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";


const Incentive = () => {
  const userTemplate = {
    slab_name: "",
    monthly_count_from: "",
    monthly_count_to: "",
    monthly_per_house_amount: "",
    daily_count_from: "",
    daily_count_to: "",
    daily_per_house_amount: ""
  };
  const [users, setUsers] = useState([{
    slab_name: "",
    monthly_count_from: "",
    monthly_count_to: "",
    monthly_per_house_amount: "",
    daily_count_from: "",
    daily_count_to: "",
    daily_per_house_amount: ""
  }]);
  const [form, setForm] = useState(false);
  const [details, setDetails] = useState([]);
  const [tableData, setTableData] = useState({});
  const [selectedType, setSelectedType] = useState({ label: "Monthly", value: 2 });
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [incentiveObject, setIncentiveObject] = useState({
    localbodyname_id: getLocalbody(),
    //incentive_type:2
  });
  const [popupTableValues, setPopupTableValues] = useState([]);
  const [incentiveIdTobeUpdated, setIncentiveIdToBeUpdated] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [incentiveIdToBeDeleted, setIncentiveIdToBeDeleted] = useState(null);
  const [errors, setErrors] = useState({
    localbodytypeError: "",
  });
  const [incetiveValue, setincentiveValue] = useState("");
  const [values, setValues] = useState([
    {
      incentive_name: "",
      monthly_target: null,
      monthly_per_house_amount: null,
      daily_target: null,
      daily_per_house_amount: null,
      //incentive_type: null,
    },
  ]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData(currentLocalbody);
  }, [currentLocalbody]);

  function handleTableData(id) {
    var url =
      `${API_URL}incentive/incentive_list?localbody_id=` + currentLocalbody;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        result.map((item, index) => {
          // if(item.incentive_type==1)
          // {
          //   item.incentive_type1="Daily";
          // }
          // else
          // {
          //   item.incentive_type1="Monthly";
          // }
          item.time = moment(item.createdAt).format("hh:mm a");
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpenForm(item);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateIncentive(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.3em", cursor: "pointer", marginTop: "-7px" }}
                onClick={() => {
                  setIncentiveIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
        });
        setDetails(result);
      });
  }
  const handleValidSubmit = () => {

    if (incentiveIdTobeUpdated) {
      axios
        .put(
          `${API_URL}incentive/update`,
          incentiveObject,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Incentive updated successfully");
            {
              formRef.current.reset();
              handleTableData();
              setIncentiveIdToBeUpdated(null);
              setUsers([{
                slab_name: "",
                monthly_count_from: "",
                monthly_count_to: "",
                monthly_per_house_amount: "",
                daily_count_from: "",
                daily_count_to: "",
                daily_per_house_amount: "",
              }]);
              setIncentiveObject({
                localbodyname_id: getLocalbody(),
                //incentive_type:2
              });
            }
          } else {
            toastr.error("Failed to update Incentive");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}incentive`, incentiveObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Incentive added succesfully");
            formRef.current.reset();
            handleTableData();
            setIncentiveIdToBeUpdated(null);
            setIncentiveObject(
              {
                localbodyname_id: getLocalbody(),
                //incentive_type:2
              }
            );
            setUsers([{
              slab_name: "",
              monthly_count_from: "",
              monthly_count_to: "",
              monthly_per_house_amount: "",
              daily_count_from: "",
              daily_count_to: "",
              daily_per_house_amount: "",
            }]);
            //setSelectedType({label:"Monthly",value:2});
          } else {
            toastr.error("Failed to add incentive");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }

  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let preUpdateIncentive = (item) => {
    setIncentiveIdToBeUpdated(item._id);
    item.localbodyname_id = getLocalbody();
    setUsers(item.slab);
    // if(item.incentive_type==1)
    // {
    //   var obj={label:"Daily",value:1}
    // }
    // else
    // {
    //   var obj={label:"Monthly",value:2}
    // }
    //setSelectedType(obj);
    setIncentiveObject(item);
  };

  const handleClickOpenForm = (item) => {

    let tableData = item.slab;
    tableData && tableData.map((el, index) => {
      el.id = index + 1;
    })
    setPopupTableValues(tableData);
    setTableData(item);
    setForm(true);
  };
  const reset = () => {
    formRef.current.reset();
    setIncentiveIdToBeUpdated(null);
    setIncentiveObject({
      localbody_name: getLocalbody(),
      //incentive_type:2
    });
    setUsers([
      {
        slab_name: "",
        monthly_count_from: null,
        monthly_count_to: null,
        monthly_per_house_amount: null,
        daily_count_from: null,
        daily_count_to: null,
        per_house_daily_amount: null,
      },
    ]);
  };

  const addUser = () => {
    setUsers([...users, userTemplate]);
  };

  const removeUser = (index) => {
    const values = [...users];
    values.splice(index, 1);
    setUsers(values);
    setIncentiveObject({
      ...incentiveObject,
      slab: values
    });
  };

  function handleInputChange(e) {
    setIncentiveObject({ ...incentiveObject, [e.target.name]: e.target.value });
  }
  // const handleSelectedType = (selected)=>{
  //   setSelectedType(selected);
  //   setIncentiveObject({ ...incentiveObject, ["incentive_type"]: selected.value });
  // }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Date",
      //   field: "date",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Type",
      //   field: "incentive_type1",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Name",
        field: "incentive_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Monthly Target",
        field: "monthly_target",
        sort: "asc",
        width: 150,
      },
      {
        label: "Monthly Amount",
        field: "monthly_per_house_amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Daily Target",
        field: "daily_target",
        sort: "asc",
        width: 150,
      },
      {
        label: "Daily Amount",
        field: "daily_per_house_amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: details,
  };
  const popupTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "slab_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Month Target From",
        field: "monthly_count_from",
        sort: "asc",
        width: 150,
      },
      {
        label: "Month Target to",
        field: "monthly_count_to",
        sort: "asc",
        width: 150,
      },
      {
        label: "Month Amount",
        field: "monthly_per_house_amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Daily Target From",
        field: "daily_count_from",
        sort: "asc",
        width: 150,
      },
      {
        label: "Daily Target to",
        field: "daily_count_to",
        sort: "asc",
        width: 150,
      },
      {
        label: "Daily Amount",
        field: "daily_per_house_amount",
        sort: "asc",
        width: 150,
      },
    ],
    rows: popupTableValues,
  };

  function handleTableInputChange(e, index) {
    const { name, value } = e.target;
    const list = [...users];

    list[index][name] = value;

    setUsers(list);
    setIncentiveObject({ ...incentiveObject, slab: list });
  }
  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}incentive` + "/" + incentiveIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Incentive deleted successfully");
                    if (
                      incentiveObject &&
                      incentiveObject._id === incentiveIdToBeDeleted
                    ) {
                      setincentiveValue("");
                      formRef.current.reset();
                      setIncentiveObject({
                        localbodyname_id: getLocalbody(),
                        //incentive_type:2
                      });
                      setIncentiveIdToBeUpdated(null);
                    }
                    handleTableData();
                  } else {
                    toastr.error("Failed to delete incentive");
                    return;
                  }
                })
                .catch((err) => {
                  toastr.error(err.response.data.message);
                  return;
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <Modal
          show={form}
          toggle={() => setForm(!form)}
          size="xl"
          centered={true}
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={() => {
                setForm(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
            <div className="modal-body">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <td style={{ padding: "10px" }}>Date</td>
                  <td style={{ textAlign: "left" }}>: {tableData?.date}
                  </td>
                  <td style={{ padding: "10px" }}>
                    Time</td>
                  <td style={{ textAlign: "left", width: "130px" }}>: {tableData?.time}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>
                    Incentive Name
                  </td>
                  <td style={{ textAlign: "left", width: "130px" }}>: {tableData?.incentive_name}</td>
                  <td style={{ padding: "10px" }}>
                    Monthly Target
                  </td>
                  <td style={{ textAlign: "left" }}>: {tableData?.monthly_target}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Monthly Amount/House</td>
                  <td style={{ textAlign: "left", width: "130px" }}>
                    : {tableData?.monthly_per_house_amount}
                  </td>
                  <td style={{ padding: "10px" }}>
                    Daily Target
                  </td>
                  <td style={{ textAlign: "left" }}>: {tableData?.daily_target}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Daily Amount/House</td>
                  <td style={{ textAlign: "left", width: "130px" }}>
                    : {tableData?.daily_per_house_amount}
                  </td>
                  <td style={{ padding: "10px" }}>Staff</td>
                  <td style={{ textAlign: "left" }}>
                    : {tableData?.staff_name}
                  </td>
                </tr>


              </table>
              <Row className="mt-3">
                <h5 className="modal-title mt-0 mb-3">Incentive Slab Details</h5>
                <MDBDataTable
                  id="IncentivepopupTableID"
                  responsive
                  bordered
                  data={popupTableData}
                  searching={false}
                  info={false}
                  paging={false}
                  disableRetreatAfterSorting={true}
                //entries={10}
                />
              </Row>
            </div>
          </AvForm>
        </Modal>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage Incentive" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      ref={formRef}
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        {/* <Col md="3">
                          <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                              Type
                            </Label>
                            <Select
                            name="incentive_type"
                            value={selectedType}
                            onChange={(selected) => {
                              handleSelectedType(selected);
                            }}
                            options={[
                              { label: "Monthly", value: 2 },
                              { label: "Daily", value: 1 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          </div>
                        </Col> */}

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Name
                            </Label>
                            <AvField
                              name="incentive_name"
                              placeholder="Name"
                              type="text"
                              value={incentiveObject?.incentive_name}
                              errorMessage="Enter Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Monthly Target
                            </Label>
                            <AvField
                              name="monthly_target"
                              placeholder="Target (Count)"
                              type="number"
                              errorMessage="Enter monthly target count"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={incentiveObject?.monthly_target}
                              onChange={handleInputChange}
                              min={0}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Monthly Amount/ House
                            </Label>
                            <AvField
                              name="monthly_per_house_amount"
                              placeholder="Amount"
                              type="number"
                              validate={{ required: { value: true } }}
                              errorMessage="Enter amount/ house"
                              className="form-control"
                              id="validationCustom01"
                              value={incentiveObject?.monthly_per_house_amount}
                              onChange={handleInputChange}
                              min={0}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Daily Target
                            </Label>
                            <AvField
                              name="daily_target"
                              placeholder="Target (Count)"
                              type="number"
                              errorMessage="Enter daily target count"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={incentiveObject?.daily_target}
                              onChange={handleInputChange}
                              min={0}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Daily Amount/ House
                            </Label>
                            <AvField
                              name="daily_per_house_amount"
                              placeholder="Amount"
                              type="number"
                              validate={{ required: { value: true } }}
                              errorMessage="Enter amount/ house"
                              className="form-control"
                              id="validationCustom01"
                              value={incentiveObject?.daily_per_house_amount}
                              onChange={handleInputChange}
                              min={0}
                            />
                          </div>
                        </Col>

                      </Row>

                      <Row>

                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Slab Name</th>
                                <th>Month Target from</th>
                                <th>Month Target to</th>
                                <th>Month Amount/House</th>
                                <th>Daily Target from</th>
                                <th>Daily Target to</th>
                                <th>Daily Amount/House</th>
                                {users.length !== 1 ? <th>Action</th> : null}
                              </tr>
                            </thead>
                            <tbody>
                              {users && users.map((item, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>

                                    <td>
                                      {" "}
                                      <div className="mb-3">
                                        <Input
                                          name="slab_name"
                                          type="text"
                                          value={item?.slab_name}
                                          style={{ appearance: "auto" }}
                                          classNamePrefix="select2-selection"
                                          onChange={(e) =>
                                            handleTableInputChange(e, index)
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="mb-3">
                                        <Input
                                          name="monthly_count_from"
                                          type="number"
                                          value={item?.monthly_count_from}
                                          style={{ appearance: "auto" }}
                                          classNamePrefix="select2-selection"
                                          onChange={(e) =>
                                            handleTableInputChange(e, index)
                                          }
                                          min={0}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="mb-3">
                                        <Input
                                          name="monthly_count_to"
                                          type="number"
                                          value={item?.monthly_count_to}
                                          style={{ appearance: "auto" }}
                                          classNamePrefix="select2-selection"
                                          onChange={(e) =>
                                            handleTableInputChange(e, index)
                                          }
                                          min={0}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="mb-3">
                                        <Input
                                          name="monthly_per_house_amount"
                                          type="number"
                                          value={item?.monthly_per_house_amount}
                                          style={{ appearance: "auto" }}
                                          classNamePrefix="select2-selection"
                                          onChange={(e) =>
                                            handleTableInputChange(e, index)
                                          }
                                          min={0}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="mb-3">
                                        <Input
                                          name="daily_count_from"
                                          type="number"
                                          value={item?.daily_count_from}
                                          style={{ appearance: "auto" }}
                                          classNamePrefix="select2-selection"
                                          onChange={(e) =>
                                            handleTableInputChange(e, index)
                                          }
                                          min={0}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="mb-3">
                                        <Input
                                          name="daily_count_to"
                                          type="number"
                                          value={item?.daily_count_to}
                                          style={{ appearance: "auto" }}
                                          classNamePrefix="select2-selection"
                                          onChange={(e) =>
                                            handleTableInputChange(e, index)
                                          }
                                          min={0}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="mb-3">
                                        <Input
                                          name="daily_per_house_amount"
                                          type="number"
                                          value={item?.daily_per_house_amount}
                                          style={{ appearance: "auto" }}
                                          classNamePrefix="select2-selection"
                                          onChange={(e) =>
                                            handleTableInputChange(e, index)
                                          }
                                          min={0}
                                        />
                                      </div>
                                    </td>

                                    {users.length !== 1 && (
                                      <td>
                                        <i
                                          className="uil uil-times"
                                          style={{
                                            fontSize: "20px",
                                            cursor: "pointer",
                                            paddingLeft: "5px",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          onClick={() => removeUser(index)}
                                        ></i>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                      <span
                        onClick={() => addUser()}
                        style={{ width: "190px", cursor: "pointer" }}
                      >
                        {" "}
                        + Add New Row{" "}
                      </span>
                      <br />
                      <Row>
                        <Col md="2">
                          <div style={{ display: "flex" }} >
                            <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                              {incentiveIdTobeUpdated ? (
                                <Button
                                  color="primary"
                                  type="submit"
                                >
                                  {"Update"}
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  type="submit"
                                >
                                  {"Submit"}
                                </Button>
                              )}
                            </div>

                            <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                              <Button
                                color="danger"
                                type="reset"
                                onClick={() => reset()}
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      id="incentiveTable"
                      responsive
                      bordered
                      data={data}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

    </>
  );
};

export default Incentive;

