import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap";
import Select from "react-select";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { getLocalbody } from "../../../helpers/globalFunctions";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { getWardOptions } from "../../../store/actions";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";


function Flat() {

    const formRef = useRef();
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
    const [masterObject, setmasterObject] = useState({});
    const [errors, setErrors] = useState({ localbodyName: "", wardName: "" });

    const [selectedLocalbody, setselectedLocalbody] = useState(null);
    const [localbodyOptions, setLocalbodyOptions] = useState([]);

    const [flatIdToBeUpdated, setFlatIdToBeUpdated] = useState(null);
    const [flatIdToBeDeleted, setFlatIdToBeDeleted] = useState(null);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);

    const [flatDetails, setFlatDetails] = useState([]);
    const [selectedWard, setSelectedWard] = useState(null);

    const dispatch = useDispatch();
    const { wardOptions } = useSelector((state) => state.wards);


    useEffect(() => {
        fetch_localbody(currentLocalbody);
        handleTableData();
    }, []);


    function handleValidSubmit() {

        let data = {
            _id: masterObject?._id,
            localbody: masterObject?.located_localbody?._id,
            flat_name: masterObject?.flat_name,
            ward: masterObject?.ward,
        }

        if (selectedLocalbody === null || selectedWard === null) {
            let errorVal = errors;
            if (selectedLocalbody === null) {
                errorVal.localbodyName = "Please select localbody";
            }
            if (selectedWard === null) {
                errorVal.wardName = "Please select ward";
            }
            setErrors({
                ...errorVal,
            });
        } else {
            if (flatIdToBeUpdated) {
                axios
                    .put(`${API_URL}api/staff/editflat`, data, {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    }).then((res) => {

                        if (res.data.response === true) {
                            toastr.success("Flat updated successfully");
                            formRef.current.reset();
                            handleTableData();
                            setselectedLocalbody(null);
                            setFlatIdToBeUpdated(null);
                            setSelectedWard(null);
                            setErrors({
                                ...errors,
                                localbodyName: "",
                                wardName: "",
                            });
                        } else {
                            toastr.error("Failed to update");
                            return;
                        }
                    })
                    .catch((err) => {
                        toastr.err(err.response.data.message);
                        return;
                    })

            } else {
                axios
                    .post(`${API_URL}api/staff/addflat`, masterObject, {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    }).then((res) => {

                        if (res.data.response === true) {
                            toastr.success("Flat added successfully");
                            formRef.current.reset();
                            handleTableData();
                            setselectedLocalbody(null);
                            setSelectedWard(null);
                            setErrors({
                                ...errors,
                                localbodyName: "",
                                wardName: "",
                            });
                        } else {
                            toastr.error("Failed to add");
                            return;
                        }
                    })
                    .catch((err) => {
                        toastr.err(err.response.data.message);
                        return;
                    })
            }
        }

    }

    function fetch_localbody() {
        axios
            .get(`${API_URL}localbody/list`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                var local_data =
                    res.data.data &&
                    res.data.data.map((el, indx) => {

                        return {
                            label: el?.localbody_name,
                            value: el?._id
                        };
                    });
                setLocalbodyOptions([{ options: local_data }])
            })
    }


    function handleSelectChange(selected, name) {
        switch (name) {
            case "localbody":
                setselectedLocalbody(selected);
                setmasterObject({
                    ...masterObject,
                    localbody: selected.value,
                });
                dispatch(getWardOptions(selected.value));
                setSelectedWard(null);
                break;

            case "ward":
                setSelectedWard(selected);
                setmasterObject({
                    ...masterObject,
                    ward: selected.value,
                });
                break;

            default:
                break;
        }
    }


    function handleChangeInput(e) {
        let name = e.target.name;
        let value = e.target.value;
        setmasterObject({ ...masterObject, [name]: value });
    }

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 50,
            },
            {
                label: "Date",
                field: "date1",
                width: 100,
            },
            {
                label: "Time",
                field: "time1",
                width: 100,
            },
            {
                label: "Localbody",
                field: "localbody",
                width: 100,
            },
            {
                label: "Flat Name",
                field: "flat_name",
                width: 100,
            },
            {
                label: "Ward",
                field: "ward",
                width: 100,
            },
            {
                label: "Staff",
                field: "staff",
                width: 100,
            },
            {
                label: "Action",
                field: "action",
                width: 100,
            },
        ],
        rows: flatDetails,
    }

    function handleTableData() {
        axios
            .get(`${API_URL}api/staff/flatlist?localbody=${currentLocalbody}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let localbodyData = [];
                let result = res.data.data;

                result &&
                    result?.map((item, index) => {

                        item.id = index + 1;
                        item.date1 = moment(item?.createdAt).format("DD-MM-YYYY");
                        item.time1 = moment(item?.createdAt).format("hh:mm a");
                        item.localbody = item?.located_localbody?.localbody_name;
                        item.ward = item?.ward?.ward_name;
                        item.staff = item?.addedby?.firstName;

                        item.action = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <i className="far fa-edit"
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "1em",
                                        marginLeft: "0.5rem",
                                        marginRight: "0.5rem"
                                    }}
                                    onClick={() => {
                                        toTop();
                                        preUpdateItem(item);
                                    }}
                                ></i>

                                <i className="far fa-trash-alt"
                                    style={{ cursor: "pointer", fontSize: "1em" }}
                                    onClick={() => {
                                        setConfirmDeleteAlert(true);
                                        setFlatIdToBeDeleted(item?._id);
                                    }}
                                ></i>
                            </div>
                        )

                        localbodyData.push(item);
                    })

                setFlatDetails(localbodyData);
            })
    }


    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }

    let preUpdateItem = (item) => {
        setFlatIdToBeUpdated(item?._id);

        axios
            .get(`${API_URL}api/staff/flat/singlelist?_id=${item?._id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data.data;

                result?.map((item, indx) => {

                    let localbody1 = {
                        label: item?.located_localbody?.localbody_name,
                        value: item?.located_localbody?._id,
                    };
                    setselectedLocalbody(localbody1);

                    let ward1 = {
                        label: item?.ward?.ward_name,
                        value: item?.ward?._id,
                    };
                    setSelectedWard(ward1);

                    item.flat_name = item?.flat_name;

                    item.flat1 = item?.flat_name;

                    setmasterObject(item);
                })
            })
    }


    return (
        <React.Fragment>

            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}api/staff/deleteflat?_id=` + flatIdToBeDeleted, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            }).then((res) => {

                                if (res.data.response === true) {
                                    toastr.success("Flat deleted successfully");
                                    formRef.current.reset();
                                    handleTableData();
                                    setmasterObject({});
                                    setselectedLocalbody(null);
                                    setSelectedWard(null);
                                    setFlatIdToBeUpdated(null);
                                } else {
                                    toastr.error("Failed to delete");
                                    return;
                                }
                            })
                            .catch((err) => {
                                toastr.err(err.response.data.message);
                                return;
                            })
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : ""}

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Add Flat" />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm className="needs-validation"
                                        ref={formRef}
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v)
                                        }}
                                    >

                                        <Row>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Localbody</Label>
                                                    <Select
                                                        name="localbody"
                                                        value={selectedLocalbody}
                                                        classNamePrefix="select2-selection"
                                                        placeholder="localbody"
                                                        options={localbodyOptions}
                                                        onChange={(selected) => {
                                                            handleSelectChange(selected, "localbody")
                                                        }}
                                                    />
                                                    <p className="text-danger" style={{ fontSize: "11px" }}>
                                                        {selectedLocalbody === null ? errors.localbodyName : ""}
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Ward</Label>
                                                    <Select
                                                        name="ward"
                                                        classNamePrefix="select2-selection"
                                                        placeholder="Ward"
                                                        value={selectedWard}
                                                        options={wardOptions?.map((item) => {
                                                            return {
                                                                label: item?.ward_name,
                                                                value: item?._id
                                                            }
                                                        })}
                                                        onChange={(selected) => {
                                                            handleSelectChange(selected, "ward")
                                                        }}
                                                    />
                                                    <p className="text-danger" style={{ fontSize: "11px" }}>
                                                        {selectedWard === null ? errors?.wardName : ""}
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Flat Name</Label>
                                                    <AvField
                                                        name="flat_name"
                                                        type="text"
                                                        placeholder="Flat name"
                                                        errorMessage="Enter flat name"
                                                        validate={{ required: { value: true } }}
                                                        value={masterObject?.flat_name}
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3" style={{ marginTop: "26px" }}>
                                                {flatIdToBeUpdated ? (
                                                    <Button color="primary" type="update">Update</Button>
                                                ) : (
                                                    <Button color="primary" type="submit">Submit</Button>
                                                )}
                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="flatTableId"
                                        responsive
                                        bordered
                                        searching={true}
                                        info={true}
                                        entries={20}
                                        data={data}
                                        disableRetreatAfterSorting={true}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )

}


export default Flat;