import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Row, Col, CardBody, Card, Label, Button } from "reactstrap";
import "./style.scss";
import { Modal } from "react-bootstrap";
import { AvForm } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import axios from "axios";
import { getDate, getLocalbody, isSuperAdmin } from "../../../helpers/globalFunctions";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { CSVLink } from "react-csv";
import "./style.scss";
import Pagination from "@mui/material/Pagination";



function VisitPendingReport() {

    const [masterObject, setmasterObject] = useState({});
    const API_URL = process.env.REACT_APP_APIURL || "https://localhost:3099/";
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

    const [selectedWard, setSelectedWard] = useState(null);
    const [wardOptions, setWardOptions] = useState([]);

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [groupOptions, setGroupOptions] = useState([]);

    const [dataToBeExported, setDataToBeExported] = useState([]);
    const [pendingDetails, setPendingDetails] = useState([]);

    const [selectedBillingType, setSelectedBillingType] = useState(null);
    const [selectedPendingType, setSelectedPendingType] = useState(null);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [staffOptions, setStaffOptions] = useState([]);
    const [details, setDetails] = useState([]);


    useEffect(() => {
        fetch_ward();
        handleTableData();
        fetch_staff();
    }, []);

    function handleTableData(ward = "", group = "", billingtype = "", pendingtype = "", staffs = "", page = 1, limit = 20) {
        let userId = "";
        if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser");
            const obj = JSON.parse(data);
            userId = obj._id;
        }
        var url =
            `${API_URL}api/staff/collect/pending/customer?localbody=` +
            currentLocalbody +
            "&user_id=" +
            userId;

        url =
            url +
            "&ward=" +
            ward +
            "&group=" +
            group +
            "&billingtype=" +
            billingtype +
            "&pendingtype=" +
            pendingtype +
            "&staffs=" +
            staffs +
            "&page=" +
            page +
            "&limit=" +
            limit;
        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data.data;
                setTotalPages(res.data.count);

                let Data = [];
                let dataToBeExported = [];
                let id = (page - 1) * limit; // Reset id for each page
                result?.map((item, indx) => {
                    id++;
                    item.id = id;

                    let exportItem = {};

                    // export data
                    exportItem.Id = item?.id;
                    exportItem.Reg_no = item?.cust_reg_no;
                    exportItem.Customer = item?.cust_name;
                    exportItem.Address = item?.cust_address;
                    exportItem.Phone = item?.cust_phone;
                    exportItem.Designation = item?.designations;
                    exportItem.Ward = item?.ward;
                    exportItem.Group = item?.group;
                    exportItem.Package = item?.package;
                    exportItem.Billing_Type = item?.billingtypes;

                    Data.push(item);

                    dataToBeExported.push(exportItem);
                })
                setDetails(result);

                setPendingDetails(Data);

                setDataToBeExported(dataToBeExported);
            })
    }

    const ward = masterObject?.ward;
    const group = masterObject?.group;
    const billingtype = masterObject?.billingtype;
    const pendingtype = masterObject?.pendingtype;
    const staffs = masterObject?.staffs;


    // pagination
    const [searchValue, setSearchValue] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPageCount, setPerPageCount] = useState(20);
    const [indexOfLastTransfer, setindexOfLastTransfer] = useState(currentPage * PerPageCount);
    const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(indexOfLastTransfer - PerPageCount);

    const paginate = (event, value) => {
        setCurrentPage(value);
        // Call handleTableData with updated page value
        handleTableData(ward, group, billingtype, pendingtype, staffs, value, PerPageCount);
    };

    const resetPaginate = () => {
        setCurrentPage(1);
        const indexOfLastTransfer = 20;
        const indexOfFirstTransfer = 0;
        setindexOfFirstTransfer(indexOfFirstTransfer);
        setindexOfLastTransfer(indexOfLastTransfer);
    }

    const debounce = (func, delay) => {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => func(...args), delay);
        };
    }


    useEffect(() => {
        if (details?.length === 0 && indexOfFirstTransfer !== 0) {
            resetPaginate();
            handleTableData(searchValue, currentLocalbody, ward, group, billingtype, pendingtype, staffs, currentPage, PerPageCount);
        }
    }, [details]);




    function fetch_ward() {
        axios
            .get(`${API_URL}api/staff/wards?localbody_id=` + currentLocalbody, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                var ward_data =
                    res.data.data &&
                    res.data.data.map((el) => {

                        return {
                            label: el?.ward_name,
                            value: el?._id
                        }
                    })
                setWardOptions([{ options: ward_data }])
            })
    }

    function fetch_staff() {

        let userId = "";
        if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser")
            const obj = JSON.parse(data)
            userId = obj._id
        }

        var url =
            `${API_URL}api/staff/team_leaders_options?localbody=` +
            currentLocalbody +
            "&user_id=" +
            userId;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {

                var staff_data =
                    res.data.data &&
                    res.data.data.map((el) => {

                        return {
                            label: el?.firstName + " " + el?.lastName,
                            value: el?._id
                        }
                    })
                setStaffOptions(staff_data)
            })
    }

    function handleSelectChange(selected, name) {
        switch (name) {
            case "ward":
                setSelectedWard(selected);
                setSelectedGroup(null);
                setmasterObject({
                    ...masterObject,
                    ["ward"]: selected.value,
                    ["group"]: ""
                });

                axios
                    .get(`${API_URL}api/staff/groups?localbody_id=` + currentLocalbody +
                        "&ward_id=" +
                        selected.value,
                        {
                            headers: {
                                "x-access-token": accessToken,
                            },
                        },
                    ).then((res) => {

                        var group_data =
                            res.data.data &&
                            res.data.data.map((el) => {

                                return {
                                    label: el?.group_name,
                                    value: el?._id,
                                }
                            })
                        setGroupOptions([{ options: group_data }])
                    })

                break;

            case "group":
                setSelectedGroup(selected);
                setmasterObject({
                    ...masterObject,
                    ["group"]: selected.value,
                });
                break;

            case "billingtype":
                setSelectedBillingType(selected);
                setmasterObject({
                    ...masterObject,
                    billingtype: selected.value,
                });
                break;

            case "pendingtype":
                setSelectedPendingType(selected);
                setmasterObject({
                    ...masterObject,
                    pendingtype: selected.value,
                });
                break;

            case "staffs":
                setSelectedStaff(selected);
                const selectedValues = selected.map(option => option.value); // Extract values from the array of selected options
                setmasterObject({
                    ...masterObject,
                    staffs: selectedValues, // Update with the array of selected values
                });
                break;


            default:
                break;
        }
    }


    function reset() {
        setSelectedGroup(null);
        setSelectedWard(null);
        setSelectedPendingType(null);
        setSelectedBillingType(null);
        setSelectedStaff(null);
        setmasterObject({});
        handleTableData(searchValue)
        resetPaginate();
    }


    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Reg No.",
                field: "cust_reg_no",
                sort: "asc",
                width: 150,
            },
            {
                label: "Customer",
                field: "cust_name",
                sort: "asc",
                width: 270,
            },
            {
                label: "Address",
                field: "cust_address",
                sort: "asc",
                width: 150,
            },
            {
                label: "Phone",
                field: "cust_phone",
                sort: "asc",
                width: 100,
            },
            {
                label: "Designation",
                field: "designations",
                sort: "asc",
                width: 150,
            },
            {
                label: "Ward",
                field: "ward",
                sort: "asc",
                width: 150,
            },
            {
                label: "Group",
                field: "group",
                sort: "asc",
                width: 150,
            },
            {
                label: "Package",
                field: "package",
                sort: "asc",
                width: 150,
            },
            {
                label: "Billing Type",
                field: "billingtypes",
                sort: "asc",
                width: 150,
            },
            {
                label: "Supervisor",
                field: "supervisor",
                sort: "asc",
                width: 150,
            },
            {
                label: "Team Leader",
                field: "teamleader",
                sort: "asc",
                width: 150,
            },
        ],
        rows: pendingDetails,
    }


    function handleSearch(value) {
        setSearchValue(value);
        resetPaginate();
        let ward = masterObject?.ward ? masterObject.ward : "";
        let group = masterObject?.group ? masterObject.group : "";
        let billingtype = masterObject?.billingtype ? masterObject.billingtype : "";
        let pendingtype = masterObject?.pendingtype ? masterObject.pendingtype : "";
        let staffs = masterObject?.staffs ? masterObject.staffs : "";
        handleTableData(ward, group, billingtype, pendingtype, staffs);
    }


    return (
        <React.Fragment>

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Visit Pending Report" />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Ward</Label>
                                                <Select
                                                    name="ward_name"
                                                    classNamePrefix="select2-selection"
                                                    value={selectedWard}
                                                    options={wardOptions}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "ward")
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Group</Label>
                                                <Select
                                                    name="group"
                                                    classNamePrefix="select2-selection"
                                                    options={groupOptions}
                                                    value={selectedGroup}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "group")
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Billing Type</Label>
                                                <Select
                                                    name="billingtype"
                                                    classNamePrefix="select2-selection"
                                                    value={selectedBillingType}
                                                    options={[
                                                        { label: "Monthly", value: "61f775c6aef04ceabc89eae7" },
                                                        { label: "Spot", value: "61f77607aef04ceabc89eae9" }
                                                    ]}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "billingtype")
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Pending Type</Label>
                                                <Select
                                                    name="pendingtype"
                                                    classNamePrefix="select2-selection"
                                                    value={selectedPendingType}
                                                    options={[
                                                        { label: "Daily", value: 1 },
                                                        { label: "Weekly", value: 2 },
                                                        { label: "Twice", value: 4 },
                                                        { label: "Monthly", value: 3 }
                                                    ]}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "pendingtype")
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Staff</Label>
                                                <Select
                                                    name="staffs"
                                                    isMulti
                                                    classNamePrefix="select2-selection"
                                                    value={selectedStaff}
                                                    options={staffOptions}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "staffs")
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="3" style={{ marginTop: "26px" }}>
                                            <div style={{ display: "flex" }}>
                                                <div>
                                                    <Button type="submit" color="primary"
                                                        onClick={() => handleSearch()}
                                                    >Search</Button>
                                                </div>

                                                <div style={{ marginLeft: "10px" }}>
                                                    <Button type="reset" color="danger"
                                                        onClick={() => reset()}
                                                    >Reset</Button>
                                                </div>
                                                {isSuperAdmin() ? (
                                                <div style={{ marginLeft: "10px" }}>
                                                    <Button type="submit" color="success">
                                                        <CSVLink
                                                            data={dataToBeExported}
                                                            filename={
                                                                "Visit_pending_report_" + getDate(new Date()) + ".xls"
                                                            }
                                                            style={{ color: "white" }}
                                                        >Export</CSVLink>
                                                    </Button>
                                                </div>
                                                        ) : null}
                                            </div>
                                        </Col>

                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="pendingTableId"
                                        responsive
                                        bordered
                                        info={false}
                                        searching={true}
                                        disbaleRetreatAfterSorting={true}
                                        paging={false}
                                        data={data}
                                        onSearch={debounce(handleSearch, 500)}
                                        entries={PerPageCount}
                                    />
                                    {totalPages > 20 && (
                                        <div className="pagination-div">
                                            <div className="page-details">
                                                Showing {((currentPage - 1) * PerPageCount) + 1} to{" "}
                                                {Math.min(currentPage * PerPageCount, totalPages)} of{" "}
                                                {totalPages} entries
                                            </div>
                                            <Pagination
                                                color="standard"
                                                shape="rounded"
                                                defaultPage={1}
                                                count={Math.ceil(totalPages / PerPageCount)}
                                                page={currentPage}
                                                onChange={paginate}
                                                info={true}
                                                size="large"
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </div>
            </div>
        </React.Fragment>
    )

}

export default VisitPendingReport;