import React, { useState, useEffect } from "react";
import "./AppmenuNew.scss";

import {
    Row,
    Col,
    Card,
    CardBody,
    // CardTitle,
    // CardSubtitle,
    // Button,
    Label,
    // CardHeader,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";

function AppmenuNew() {
    const [details, setDetails] = useState([]);
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [privilegeOptions, setPrivilegeOptions] = useState([]);

    function tToggle() {
        var body = document.body;
        body.classList.toggle("vertical-collpsed");
        body.classList.toggle("sidebar-enable");
    }

    async function getData() {
        let data = await axios.get(`${API_URL}app_dashboard_menu/list-new`, {
            headers: {
                "x-access-token": accessToken
            }
        });
        return data;
    }

    function fetch_all_privilege() {
        axios.get(`${API_URL}privilage/items`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then(async (res) => {
            var privilege_data = [];
            let response = await getData();
            let data = response.data.data;
            res.data.data.map((el) => {
                var count = 0;
                data.map((item) => {
                    var propertyExists = item.result.some(itm => itm["privilege_name"] === el.privilege_name);

                    if (!propertyExists) {
                        item.result.push({ "_id": el._id, "privilege_name": el.privilege_name, 'exists': false })
                    } else
                        count++;


                    var tot_assign = item.result.filter(itm => itm["exists"] === true);


                    item.result = item.result.slice().sort((a, b) => a._id.localeCompare(b._id));
                    if (tot_assign.length == res.data.data.length)
                        item.exists = true;
                    else
                        item.exists = false;


                });
                privilege_data.push({
                    label: el.privilege_name,
                    value: el._id,
                    exists: (count == data.length) ? true : false
                });
            });
            setDetails(data);
            setPrivilegeOptions(privilege_data);
        });
    }
    const handleClicked = (e, i, j) => {

        let list = [...details];
        list[i].result[j].exists = e.target.checked;

        let data = {
            privilege: list[i].result[j]._id,
            status: e.target.checked,
            menuId: list[i]._id
        };

        axios.post(`${API_URL}app_dashboard_menu/assign-rule`, data, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            var count = 0;
            list.map((item) => {
                const propertyExists = item.result.some(itm => (itm["privilege_name"] === list[i].result[j].privilege_name && itm["exists"] == true));
                if (propertyExists)
                    count++;



                var tot_assign = item.result.filter(itm => itm["exists"] === true);
                if (tot_assign.length == privilegeOptions.length)
                    item.exists = true;
                else
                    item.exists = false;



            })
            setDetails(list);
            var options = [...privilegeOptions];
            var pos = options.findIndex(item => item.label === list[i].result[j].privilege_name)

            if (count == list.length) {
                options[pos].exists = true;
            } else {
                options[pos].exists = false;
            }
            setPrivilegeOptions(options)


        }).catch((err) => {
            return;
        });
    }

    const handleClickAllPrivilege = (e, id, name, i) => {
        let list = [...privilegeOptions];
        list[i].exists = e.target.checked;
        setPrivilegeOptions(list);
        let data = {
            id: id,
            status: e.target.checked
        };
        axios.post(`${API_URL}app_dashboard_menu/assign-all-rule`, data, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            let rules = [...details];
            rules.map((rule) => {
                if (!list[i].exists) {
                    rule.result = rule.result.map(item => ({
                        ...item,
                        exists: item["privilege_name"] === name ? false : item.exists
                    }));
                } else {
                    rule.result = rule.result.map(item => ({
                        ...item,
                        exists: item["privilege_name"] === name ? true : item.exists
                    }));
                }
                var tot_assign = rule.result.filter(itm => itm["exists"] === true);
                if (tot_assign.length == privilegeOptions.length)
                    rule.exists = true;
                else
                    rule.exists = false;


            })
            setDetails(rules)

        }).catch((err) => {
            return;
        });
    }

    const handleClickAllMenu = (e, i, id) => {
        var data = {
            status: e.target.checked,
            menuId: id
        };
        let list = [...details];
        list[i].exists = data.status;
        list[i].result.map((item) => {
            item.exists = data.status;
        })
        let options = [...privilegeOptions];
        options.map((el) => {
            var count = 0;
            list.map((item1) => {
                var propertyExists = item1.result.some(itm => (itm["privilege_name"] === el.label && itm["exists"] == true));
                if (propertyExists)
                    count++;


            });
            if (count == list.length)
                el.exists = true;
            else
                el.exists = false;


        });
        setPrivilegeOptions(options);
        setDetails(list);
        axios.post(`${API_URL}app_dashboard_menu/assign-all-privilege`, data, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => { });
    }

    useEffect(() => {
        fetch_all_privilege();

        tToggle()
        return () => {
            tToggle()
        }
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="App Dashboard Menu" />

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <div className="table-container" style={{ overflowX: "auto", maxHeight: "400px" }}>
                                        <table className="w-100 newappdashboard-table">
                                            <thead style={{ position: "sticky", top: 0, zIndex: 2, backgroundColor: "white" }}>
                                                <tr style={{ textAlign: "center", verticalAlign: "bottom" }}>
                                                    <th style={{ position: "sticky", left: 0, zIndex: 2, top: 0, backgroundColor: "white" }}>#</th>
                                                    {
                                                        privilegeOptions.map((privilege, index) => {
                                                            return (
                                                                <th className="toCapitalize" >
                                                                    {
                                                                        privilege.label.toLowerCase()
                                                                    }
                                                                    <div><input type="checkbox"
                                                                        checked={
                                                                            privilege?.exists ? "checked" : ""
                                                                        }
                                                                        onClick={
                                                                            (e) => {
                                                                                handleClickAllPrivilege(e, privilege.value, privilege.label, index)
                                                                            }
                                                                        } /></div>
                                                                </th>
                                                            )
                                                        })
                                                    } </tr>
                                            </thead>

                                            <tbody style={{}}> {
                                                details.map((item, index1) => {

                                                    return (
                                                        <tr>
                                                            <td style={{ overflowY: "auto", position: "sticky", left: 0, zIndex: 1, backgroundColor: 'white' }}>
                                                                <div style={{ display: "flex" }}><input type="checkbox"
                                                                    onClick={
                                                                        (e) => {
                                                                            handleClickAllMenu(e, index1, item._id)
                                                                        }
                                                                    }
                                                                    checked={
                                                                        item?.exists ? "checked" : ""
                                                                    }
                                                                    className="mx-1" /> {
                                                                        item?.name
                                                                    }</div>
                                                            </td>
                                                            {
                                                                item.result.map((subItem, index2) => {
                                                                    return (
                                                                        <td className="text-center">
                                                                            <input type="checkbox"
                                                                                onClick={
                                                                                    (e) => {
                                                                                        handleClicked(e, index1, index2)

                                                                                    }
                                                                                }
                                                                                checked={
                                                                                    subItem?.exists ? "checked" : ""
                                                                                } />
                                                                        </td>
                                                                    )
                                                                })
                                                            } </tr>
                                                    )
                                                })
                                            } </tbody>
                                        </table>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AppmenuNew;
