import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";

import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody, Button, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { getCompaniesOptions, getCompaniesMasterOptions } from "../../../store/actions";

import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";


const Privileges = (props) => {
  const [privilegeObject, setPrivilegeObject] = useState({});
  const [privilegeTemp, setprivilegeTemp] = useState([]);
  const [selectedPrivilege, setSelectedPrivilege] = useState(null);
  const [privilegeOptions, setPrivilegeOptions] = useState([]);

  const [privilegeIdTobeUpdated, setPrivilegedToBeUpdated] = useState(null);
  const [privilegeIdToBeDeleted, setPrivilegeIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [userId] = useState(1);
  // const [allocationStatus, setAllocationStatus] = useState({});
  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const {
    // privilages,
    addingPrivilege,
    addPrivilegeResponse,
    deletePrivilegeResponse,
    updatePrivilegeResponse,
    error,
  } = useSelector((state) => state.privilages);
  const { companiesOptions } = useSelector((state) => state.companies);
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(getAllPrivileges());
    dispatch(getCompaniesOptions());
    handleTableData();
    fetch_all_privilege();
  }, []);

  function fetch_all_privilege() {
    axios
      .get(`${API_URL}privilage/items`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var privilege_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.privilege_name,
              value: el._id,
              multi_localbody: el?.multi_localbody,
            };
          });

        setPrivilegeOptions([
          {
            options: privilege_data,
          },
        ]);
      });
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function handleTableData() {
    // var url = `${API_URL}privilage/list`;

    // axios
    //   .get(url, {
    //     headers: {
    //       "x-access-token": accessToken,
    //     },
    //   })
    axios
      .get(`${API_URL}privilage/list`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          // let alloted_companies = item.alloted_companies;
          // let comp = []

          item.id = index + 1;
          // companies&&companies.forEach(element => {

          //   alloted_companies&&alloted_companies.forEach(el => {
          //      if(el === element._id){
          //         comp.push(element);
          //      }
          //   });

          // });

          // item.company = (
          //   <div>
          //     <ul>
          //       {comp &&
          //         comp.map((elm, index) => {
          //           return <li>{elm.company_name}</li>;
          //         })}
          //     </ul>
          //   </div>
          // );

          item.action = (
            <div class="action">
              {/* <i
                className="far fa-edit"
                onClick={() => {
                  toTop();
                  preupdatePrivilege(item);
                }}></i> */}
              <i
                className="far fa-trash-alt"
                onClick={() => {
                  setPrivilegeIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </div>
          );
        });

        setprivilegeTemp(result);
      });
  }

  useEffect(() => {
    setCompanies(companiesOptions);
  }, [companiesOptions]);

  useEffect(() => {
    if (addPrivilegeResponse.type === "success") {
      toastr.success(addPrivilegeResponse.message);
      //dispatch(getAllPrivileges());
      handleTableData();
    } else if (addPrivilegeResponse.type === "failure") {
      toastr.error(error.data.message, addPrivilegeResponse.message);
    }
  }, [addPrivilegeResponse]);

  useEffect(() => {
    if (deletePrivilegeResponse.type === "success") {
      toastr.success(deletePrivilegeResponse.message);
      //dispatch(getAllPrivileges());
      handleTableData();
    } else if (deletePrivilegeResponse.type === "failure") {
      toastr.error(error.data.message, deletePrivilegeResponse.message);
    }
  }, [deletePrivilegeResponse]);

  useEffect(() => {
    if (updatePrivilegeResponse.type === "success") {
      setPrivilegedToBeUpdated(null);
      toastr.success(updatePrivilegeResponse.message);
      //dispatch(getAllPrivileges());
      handleTableData();
    } else if (updatePrivilegeResponse.type === "failure") {
      toastr.error(error.data.message, updatePrivilegeResponse.message);
    }
  }, [updatePrivilegeResponse]);

  let preupdatePrivilege = (item) => {
    setPrivilegedToBeUpdated(item._id);
    setSelectedPrivilege({ label: item?.parent?.privilege_name, value: item?.parent?._id });
    item.parent = item?.parent?._id;
    setPrivilegeObject(item);
  };

  // useEffect(() => {
  //   let privilegeDuplicate = JSON.parse(JSON.stringify(privilages));
  //   let privilegeData = [];
  //   privilegeDuplicate.map((item, index) => {
  //     item.action = (
  //       <div class="action">
  //         <i
  //           className="far fa-edit"
  //           onClick={() => {
  //             preupdatePrivilege(item);
  //           }}
  //         ></i>
  //         <i
  //           className="far fa-trash-alt"
  //           onClick={() => {
  //             setPrivilegeIdToBeDeleted(item._id);
  //             setConfirmDeleteAlert(true);
  //           }}
  //         ></i>
  //       </div>
  //     );
  //     item.id = index + 1;

  //     privilegeData.push(item);
  //   });
  //   setprivilegeTemp(privilegeData);
  // }, [privilages]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "privilege_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Shortcode",
        field: "privilege_code",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: privilegeTemp,
  };

  let handleChangeInput = (e) => {
    let { name, value, type, checked } = e.target;
    if (type === "checkbox") value = checked;
    setPrivilegeObject({
      ...privilegeObject,
      [name]: value,
    });
  };

  const handleValidSubmitPrivilege = (event, values) => {
    if ((!selectedPrivilege || selectedPrivilege.value === "" || selectedPrivilege.value === undefined) && privilegeObject.privilege_name !== "Super Admin") {
      toastr.error("Please select a privilege");
      return;
    }

    if (privilegeIdTobeUpdated) {
      axios
        .put(`${API_URL}privilage/update`, privilegeObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Privilege updated successfully");
            formRef.current.reset();
            setPrivilegeObject({ ["auth_userid"]: userId });
            setPrivilegedToBeUpdated(null);
            handleTableData();
          } else {
            toastr.error("Failed to update privilege");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}privilage`, privilegeObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Privilege created successfully");
            formRef.current.reset();
            setPrivilegeObject({ ["auth_userid"]: userId });
            handleTableData();
          } else {
            toastr.error("Failed to create privilege");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
    // privilegeIdTobeUpdated
    //   ? dispatch(updatePrivilege(privilegeObject))
    //   : dispatch(addPrivilege(privilegeObject));

    // formRef.current.reset();
    // setPrivilegeObject({ ["auth_userid"]: userId });
  };

  const reset = () => {
    formRef.current.reset();
    setPrivilegeObject({});
    setPrivilegeIdToBeDeleted(null);
    setPrivilegedToBeUpdated(null);
    setSelectedPrivilege(null)
  };

  const handleCheckboxChange = (e, index, item, el, element) => {
    let data = {
      company_id: element._id,
      privilage_id: item?._id,
      localbody_id: el._id,
      status: e.target.checked === true ? 1 : 0,
    };
    axios
      .post(`${API_URL}privilage/rule`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser");
            var user_obj = JSON.parse(data);
            dispatch(getCompaniesMasterOptions(user_obj._id));
            handleTableData();
          }
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };


  return (
    <React.Fragment>

      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}privilage` + "/" + privilegeIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Privilege deleted successfully");
                  handleTableData();
                } else {
                  toastr.error("Failed to delete privilege");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            // dispatch(deletePrivilege(privilegeIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Privilege" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitPrivilege(e, v);
                    }}>

                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Privilege</Label>

                          <AvField
                            name="privilege_name"
                            value={privilegeObject.privilege_name}
                            placeholder="Privilege"
                            type="text"
                            errorMessage="Enter Privilege"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[A-Za-z ]+$",
                                errorMessage: "Privilege must be only with letter and spaces",
                              },
                            }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Shortcode</Label>

                          <AvField
                            name="privilege_code"
                            value={privilegeObject.privilege_code}
                            placeholder="Shortcode"
                            type="text"
                            errorMessage="Enter Shortcode"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[A-Za-z ]+$",
                                errorMessage: "Shortcode must be only with letter and spaces",
                              },
                            }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      {privilegeObject?.privilege_name !== "Super Admin" && (
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>Under privilege</Label>
                            <Select
                              name="privilage"
                              value={selectedPrivilege}
                              onChange={(value) => {
                                setSelectedPrivilege(value);
                                setPrivilegeObject({
                                  ...privilegeObject,
                                  parent: value.value,
                                });
                              }}
                              options={privilegeOptions}
                              classNamePrefix="select2-selection"
                            />
                            <p className="text-danger" style={{ fontSize: "11px" }}>
                              {/* {selectedPrivilege === null ? errors.privilegeError : ""} */}
                            </p>
                          </div>
                        </Col>
                      )}

                      <Col md="2 d-flex align-items-end">
                        <div className="form-check mb-4">
                          <Label
                            className="form-check-label"
                            htmlFor="horizontal-customCheck"
                            style={{
                              cursor: "pointer",
                            }}>
                            Multiple LocalBodies
                          </Label>
                          <Input
                            name="multi_localbody"
                            type="checkbox"
                            checked={privilegeObject.multi_localbody}
                            className="form-check-input"
                            id="horizontal-customCheck"
                            onClick={handleChangeInput}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div style={{ display: "flex" }}>
                          <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                            {privilegeIdTobeUpdated ? (
                              <Button color="primary" type="submit" disabled={addingPrivilege ? true : false}>
                                {addingPrivilege ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button color="primary" type="submit" disabled={addingPrivilege ? true : false}>
                                {addingPrivilege ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>

                          <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                            <Button color="danger" type="reset" onClick={() => reset()}>
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Col>

                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            {/* <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="account_type_table"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    entries={10}
                  />
                </CardBody>
              </Card>
            </Col> */}
            <Col xl="12">
              <Card>
                <CardBody>
                  <Table style={{ textAlign: "center" }} id="waste_id" className="table table-bordered dataTable">
                    <TableHead>
                      <TableRow style={{ textAlign: "center" }}>
                        <TableCell style={{ width: "10px", textAlign: "center" }}>#</TableCell>
                        <TableCell style={{ width: "140px", textAlign: "center" }}>Name</TableCell>
                        <TableCell style={{ width: "120px", textAlign: "center" }}>Shortcode</TableCell>
                        {privilegeTemp &&
                          companies.map((item) => {
                            return <TableCell style={{ width: "100", textAlign: "center" }}>{item.company_name}</TableCell>;
                          })}
                        <TableCell style={{ width: "10px", textAlign: "center" }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {privilegeTemp &&
                        privilegeTemp.map((item, index) => (
                          <TableRow style={{ verticalAlign: "top" }}>
                            <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>{item.privilege_name}</TableCell>
                            <TableCell style={{ textAlign: "left" }}>{item.privilege_code}</TableCell>
                            {/* <TableCell> */}
                            {item.company &&
                              item.company.map((element, index) => {
                                return (
                                  <TableCell>
                                    <div>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                        }}>
                                        {element.companys_localbody &&
                                          element.companys_localbody.map((el, index) => {
                                            return (
                                              <li style={{ textAlign: "left" }} className="mb-2">
                                                <input
                                                  type="checkbox"
                                                  className="mb-2"
                                                  name="allocation_status"
                                                  id="allocation_status"
                                                  checked={el.allocation_status === 1 ? true : false}
                                                  onChange={(e) => handleCheckboxChange(e, index, item, el, element)}
                                                />
                                                <span
                                                  style={{
                                                    marginLeft: "5px",
                                                  }}>
                                                  {el.localbody_name}
                                                </span>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </div>
                                  </TableCell>
                                );
                              })}
                            {/* </TableCell> */}
                            {/* {
                              item.company.companys_localbody &&
                                item.company.companys_localbody.map(
                                  (el, index) => (
                                      <TableCell>
                                        <div>
                                          <ul style={{ listStyleType: "none" }}>
                                            <li
                                              style={{ textAlign: "left" }}
                                              className="mb-2"
                                            >
                                              <input
                                                type="checkbox"
                                                className="mb-2"
                                              />
                                              <span
                                                style={{
                                                  marginLeft: "5px",
                                                }}
                                              >
                                                {el.localbody_name}
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </TableCell>
                                  )
                                )
                            } */}
                            <div class="action">
                              {/* <i
                                className="far fa-edit"
                                onClick={() => {
                                  toTop();
                                  preupdatePrivilege(item);
                                }}></i> */}
                              <i
                                className="far fa-trash-alt"
                                onClick={() => {
                                  setPrivilegeIdToBeDeleted(item._id);
                                  setConfirmDeleteAlert(true);
                                }}></i>
                            </div>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>

    </React.Fragment>
  );
};


export default Privileges;
