import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";
import $ from "jquery";
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./ComplaintStatistic.scss";
import {
  getDate,
  getFirstday,
  getLocalbody,
} from "../../../helpers/globalFunctions";

const ComplaintStatistics = (props) => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [details, setDetails] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    handleTableData();
    fetch_all_wards(currentLocalbody);
  }, [currentLocalbody]);

  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
        let date1 = searchData?.from_date ? searchData.from_date : "";
        let date2 = searchData?.to_date ? searchData.to_date : "";
        if (ward_data && ward_data.length > 0) {
          setSelectedWard(ward_data[0]);
          setSearchData({
            ...searchData,
            ["ward_id"]: ward_data[0]?.value,
          });

          handleTableData(date1, date2, ward_data[0]?.value);
        } else handleTableData(date1, date2, "");
      });
  }

  const handleSelectChange = (selected) => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    setSelectedWard(selected);
    setSearchData({
      ...searchData,
      ["ward_id"]: selected.value,
    });
    handleTableData(date1, date2, selected.value);
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(date1, date2, ward_id);
  };

  function handleTableData(from_date = "", to_date = "", ward_id = "") {
    var url = `${API_URL}complaint_statistics`;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
      }
    url =
      url +
      "?fromdate=" +
      from_date +
      "&todate=" +
      to_date +
      "&ward_id=" +
      ward_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.categoryName = (
              <div  style={{justifyContent:"center",display:"flex"}}>
                <ul>
                  {item.method &&
                    item.method.map((element, index) => {
                      return <li>{element.Categoryname}</li>;
                    })}
                </ul>
              </div>
            );
            item.new = (
              <div style={{marginLeft:17}}>
                <ul>
                  {item.method &&
                    item.method.map((element, index) => {
                      return <li>{element.New}</li>;
                    })}
                </ul>
              </div>
            );
            item.Closed = (
              <div style={{marginLeft:20}}>
                <ul>
                  {item.method &&
                    item.method.map((element, index) => {
                      return <li>{element.Closed}</li>;
                    })}
                </ul>
              </div>
            );
            item.Pending = (
              <div style={{marginLeft:25}}>
                <ul>
                  {item.method &&
                    item.method.map((element, index) => {
                      return <li>{element.Pending}</li>;
                    })}
                </ul>
              </div>
            );
            item.Pending_for_onemonth = (
              <div style={{marginLeft:45}}>
                <ul>
                  {item.method &&
                    item.method.map((element, index) => {
                      return <li>{element.Pending_for_onemonth}</li>;
                    })}
                </ul>
              </div>
            );
          });
        setDetails(result);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "categoryName",
        sort: "asc",
        width: 150,
      },
      {
        label: "New",
        field: "new",
        sort: "asc",
        width: 270,
      },
      {
        label: "Closed",
        field: "Closed",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pending",
        field: "Pending",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pending For 1 Month",
        field: "Pending_for_onemonth",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total",
        field: "TotalCustomers",
        sort: "asc",
        width: 150,
      },
    ],
    rows: details,
  };

  const reset = () => {
    let ward_id = "";
    let options = wardOptions[0].options;
    if (options && options.length > 0) {
      setSelectedWard(options[0]);
      ward_id = options[0].value;
    }
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
      ward_id: ward_id,
    });
    handleTableData(getFirstday(new Date()), getDate(new Date()), ward_id);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Complaint Statistics" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <div className="mb-1">
                          <Label htmlFor="validationCustom05">From</Label>

                          <input
                            className="form-control"
                            type="date"
                            value={searchData?.from_date}
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-1">
                          <Label htmlFor="validationCustom05">To</Label>

                          <input
                            className="form-control"
                            type="date"
                            value={searchData?.to_date}
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                            min={searchData?.from_date}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="ward_id"
                            value={selectedWard}
                            onChange={(selected) => {
                              handleSelectChange(selected, "ward");
                            }}
                            options={wardOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3" className="mt-4">
                        <Button
                          style={{ marginTop: "5px" }}
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>Complaint Statistics</CardTitle>
                  <hr /> */}
                  <MDBDataTable
                    id="complaintStatisticsId"
                    responsive
                    bordered
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComplaintStatistics;