import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from "reactstrap"
import CountUp from 'react-countup';


const SalesAnalyticsChart = (props) => {
    //const dateList =props.dateList;
    const graphData = props.graphData;
    const collected_data=[];
    const visit_data=[];
    const closed_data=[];
    const no_waste_data=[];
    const receipt_data=[];
    const month=[];
    graphData&&graphData.map((value, key) => {
        month.push(value._id)
        collected_data.push(value.collected_count);
        visit_data.push(value.visit_count);
        closed_data.push(value.closed_count);
        no_waste_data.push(value.no_waste_count);
        receipt_data.push(value.receipt_count);
    }
    )
    const series = [{
        name: 'Waste Collected',
        type: 'line',
        data: collected_data
    }, {
        name: 'Visit',
        type: 'line',
        data: visit_data
    },
    {
        name: 'House Closed',
        type: 'area',
        data: closed_data
    },
    {
        name: 'No Waste',
        type: 'line',
        data: no_waste_data
    },
 {
        name: 'Payment Made',
        type: 'area',
        data: receipt_data
    }]

    const options = {
        chart: {
            stacked: !1,
            toolbar: {
                show: !1
            }
        },
        stroke: {
            width: [2, 4],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '30%'
            }
        },
        colors: ['#a7c97d','#a9945b','#ddb8cd','#a3a5af','#e9e9e9'],

        fill: {
            opacity: [1, 1],
            gradient: {
                inverseColors: !1,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        markers: {
            size: 0
        },

        xaxis: {
            categories: month,
        },
        yaxis: {
            title: {
                text: 'Customers',
            },
        },
        tooltip: {
            shared: !0,
            intersect: !1,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " points";
                    }
                    return y;

                }
            }
        },
        grid: {
            borderColor: '#f1f1f1'
        }
    }

    return (
        <React.Fragment>
                    <div className="mt-3">
                        <ReactApexChart
                            options={options}
                            series={series}
                            height="339"
                            type="line"
                            className="apex-charts"
                        />
                    </div>

        </React.Fragment>
    )
}

export default SalesAnalyticsChart