import{
    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_FAIL,
    GET_CUST_VISITLOG,
    GET_CUST_VISITLOG_SUCCESS,
    GET_CUST_VISITLOG_FAIL,
    GET_CUST_RECEIPTS,
    GET_CUST_RECEIPTS_FAIL,
    GET_CUST_RECEIPTS_SUCCESS,
    GET_CUST_INVOICE,
    GET_CUST_INVOICE_SUCCESS,
    GET_CUST_INVOICE_FAIL,
    GET_CUST_STATEMENT,
    GET_CUST_STATEMENT_SUCCESS,
    GET_CUST_STATEMENT_FAIL,

    ADD_CUSTOMERS,
    ADD_CUSTOMERS_SUCCESS,
    ADD_CUSTOMERS_FAIL,
    UPDATE_CUSTOMERS,
    UPDATE_CUSTOMERS_SUCCESS,
    UPDATE_CUSTOMERS_FAIL,
    DELETE_CUSTOMERS,
    DELETE_CUSTOMERS_SUCCESS,
    DELETE_CUSTOMERS_FAIL,
    GET_CUSTOMER_TYPE,
    GET_CUSTOMER_TYPE_SUCCESS,
    GET_CUSTOMER_TYPE_FAIL
} from "./actionTypes"

export const getCustomers = () => ({
    type: GET_CUSTOMERS,
  });
  
  export const getCustomersSuccess = (customer) => ({
    type: GET_CUSTOMERS_SUCCESS,
    payload: customer,
  });
  
  export const getCustomersFail = (error) => ({
    type: GET_CUSTOMERS_FAIL,
    payload: error,
  });

  export const getCustVisitLog = (custId) => ({
    type: GET_CUST_VISITLOG,
    custId:custId
  });
  
  export const getCustVisitLogSuccess = (visitlog) => ({
    type: GET_CUST_VISITLOG_SUCCESS,
    payload:visitlog,
  });
  
  export const getCustVisitLogFail = (error) => ({
    type: GET_CUST_VISITLOG_FAIL,
    payload: error,
  });

  export const getCustReceipts = (custId) => ({
    type: GET_CUST_RECEIPTS,
    custId:custId
  });
  
  export const getCustReceiptsSuccess = (receipts) => ({
    type: GET_CUST_RECEIPTS_SUCCESS,
    payload:receipts,
  });
  
  export const getCustReceiptsFail = (error) => ({
    type: GET_CUST_RECEIPTS_FAIL,
    payload: error,
  });
  export const getCustInvoice = (custId) => ({
    type: GET_CUST_INVOICE,
    custId:custId
  });
  
  export const getCustInvoiceSuccess = (invoice) => ({
    type: GET_CUST_INVOICE_SUCCESS,
    payload:invoice,
  });
  
  export const getCustInvoiceFail = (error) => ({
    type: GET_CUST_INVOICE_FAIL,
    payload: error,
  });

  export const getCustStatement = (custId) => ({
    type: GET_CUST_STATEMENT,
    custId:custId
  });
  
  export const getCustStatementSuccess = (statement) => ({
    type: GET_CUST_STATEMENT_SUCCESS,
    payload:statement,
  });
  
  export const getCustStatementFail = (error) => ({
    type: GET_CUST_STATEMENT_FAIL,
    payload: error,
  });

  export const addCustomers = (data) => ({
    type: ADD_CUSTOMERS,
    payload: data,
  });
  
  export const addCustomersSuccess = (data) => ({
    type: ADD_CUSTOMERS_SUCCESS,
    payload: data,
  });
  
  export const addCustomersFail = (error) => ({
    type: ADD_CUSTOMERS_FAIL,
    payload: error,
  });

  export const updateCustomers = (data) => ({
    type: UPDATE_CUSTOMERS,
    payload: data,
  });
  
  export const updateCustomersSuccess = (data) => ({
    type: UPDATE_CUSTOMERS_SUCCESS,
    payload: data,
  });
  
  export const updateCustomersFail = (error) => ({
    type: UPDATE_CUSTOMERS_FAIL,
    payload: error,
  });
  
  export const deleteCustomers = (data, auth_user) => ({
    type: DELETE_CUSTOMERS,
    payload: data,
    auth_data: auth_user,
  });
  
  export const deleteCustomersSuccess = (data) => ({
    type:DELETE_CUSTOMERS_SUCCESS,
    payload: data,
  });
  
  export const deleteCustomersFail = (error) => ({
    type: DELETE_CUSTOMERS_FAIL,
    payload: error,
  });

  export const getCustomerType = (categoryId) => ({
    type: GET_CUSTOMER_TYPE,
    payload: categoryId,
  });
  
  export const getCustomerTypeSuccess = (data) => ({
    type: GET_CUSTOMER_TYPE_SUCCESS,
    payload: data,
  });
  
  export const getCustomerTypeFail = (error) => ({
    type: GET_CUSTOMER_TYPE_FAIL,
    payload: error,
  });