import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
//import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { apiError } from "../../../store/actions";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import "./viewTariff.scss";

const ViewTraffic = (props) => {
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [details, setDetails] = useState([]);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [popupView, setPopupView] = useState(false);
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [packageOptions, setPackageOptions] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [tableData, setTableData] = useState({});
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  // const dispatch = useDispatch();

  useEffect(() => {
    fetch_all_wards(currentLocalbody);
    fetch_all_packages(currentLocalbody);
    handleTableData();
  }, [currentLocalbody]);

  function handleTableData(
    from_date = "",
    to_date = "",
    ward_id = "",
    group_id = "",
    package_id = "",
    status_id = ""
  ) {
    var url = `${API_URL}view_tariff/list?localbody_id=` + currentLocalbody;

    // if (from_date === "" && to_date === "") {
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }
    // }

    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&package_id=" +
      package_id +
      "&status_id=" +
      status_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.date = moment(item.tariff_assign_date).format("DD-MM-YYYY");
            if (item.status === 0) {
              item.Status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => {
                      updatePackageStatus(item);
                    }}
                  >
                    Active
                  </Button>
                </div>
              );
            } else if (item.status === 1) {
              item.Status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => {
                      updatePackageStatus(item);
                    }}
                  >
                    Inactive
                  </Button>
                </div>
              );
            } else {
              item.Status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      updatePackageStatus(item);
                    }}
                  >
                    Hold
                  </Button>
                </div>
              );
            }
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.5em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  }
  const updatePackageStatus = (item) => {
    // let data = {
    //   _id:item._id
    // }
    axios
      .put(`${API_URL}view_tariff/status?_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          handleTableData();
        }
      });
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  function fetch_all_packages(id) {
    axios
      .get(`${API_URL}api/staff/packages_all?localbody_id=`+id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          var package_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.package_name,
                value: el._id,
              };
            });

          setPackageOptions([
            {
              options: package_data,
            },
          ]);
        } else setPackageOptions([]);
      });
  }

  const handleClickOpenForm = (item) => {
    // let itemData = [];
    // itemData.push(item);
    setTableData(item);
    setPopupView(true);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Customer ID",
        field: "cust_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "cust_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Package",
        field: "package_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Validity",
        field: "package_validity",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff_name",
        sort: "asc",
        width: 200,
      },

      {
        label: "Basic Fee",
        field: "package_basic_fee",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  const handleSelectChange = (selected, name) => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let package_id = searchData?.package_id ? searchData.package_id : "";
    let status_id = searchData?.status_id ? searchData.status_id : "";

    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
        });
        setSelectedGroup(null);
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
              currentLocalbody +
              "&ward_id=" +
              selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        handleTableData(
          date1,
          date2,
          selected.value,
          "",
          package_id,
          status_id
        );
        break;
      case "group":
        setSelectedGroup(selected);
        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          ward_id,
          selected.value,
          package_id,
          status_id
        );
        break;
      case "package":
        setSelectedPackage(selected);
        setSearchData({
          ...searchData,
          ["package_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          ward_id,
          group_id,
          selected.value,
          status_id
        );
        break;
      case "status":
        setSelectedStatus(selected);
        setSearchData({
          ...searchData,
          ["status_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          ward_id,
          group_id,
          package_id,
          selected.value
        );
        break;
      default:
        break;
    }
  };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let package_id = searchData?.package_id ? searchData.package_id : "";
    let status_id = searchData?.status_id ? searchData.status_id : "";

    // let fromDate = moment(date1, "DD-MM-YYYY");
    // let toDate = moment(date2, "DD-MM-YYYY");
    // let result = toDate.diff(fromDate, "days");
    // if (Number(result) + 1 < 0) {
    //   setSearchData({
    //     ...searchData,
    //     [e.target.name]: e.target.value,
    //     ["to_date"]: "",
    //   });
    //   date2 = "";
    // } else {
    //   setSearchData({
    //     ...searchData,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

    handleTableData(date1, date2, ward_id, group_id, package_id, status_id);
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedPackage(null);
    setSelectedStatus(null);
    handleTableData();
  };
  return (
    <React.Fragment>
      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Tariff Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px", width: "282px" }}>
                  Package Name :
                </td>
                <td>{tableData?.package_name}</td>
                <td>Date :</td>
                <td style={{ textAlign: "left" }}>{tableData?.date}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", width: "282px" }}>Customer :</td>
                <td>{tableData?.cust_name}</td>
                <td>Customer ID :</td>
                <td style={{ textAlign: "left" }}>{tableData?.cust_reg_no}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", width: "282px" }}>
                  Package Validity in Days :
                </td>
                <td>{tableData?.package_validity}</td>
                <td>Basic Fee :</td>
                <td style={{ textAlign: "left" }}>
                  {tableData?.package_basic_fee}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px", width: "282px" }}>Status :</td>
                <td>
                  {tableData?.status === 0
                    ? "Active"
                    : tableData?.status === 1
                    ? "Inactive"
                    : "Hold"}
                </td>
                <td>Staff :</td>
                <td style={{ textAlign: "left" }}>{tableData?.staff_name}</td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="View Tariff" />
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      <input
                        className="form-control"
                        type="date"
                        id="from_date"
                        name="from_date"
                        value={searchData?.from_date}
                        onChange={handleDate}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        value={searchData?.to_date}
                        min={searchData?.from_date}
                        onChange={handleDate}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Ward</Label>
                      <Select
                        name="ward_id"
                        value={selectedWard}
                        onChange={(selected) => {
                          handleSelectChange(selected, "ward");
                        }}
                        options={wardOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Group</Label>
                      <Select
                        name="group_id"
                        value={selectedGroup}
                        onChange={(selected) => {
                          handleSelectChange(selected, "group");
                        }}
                        options={groupOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Package</Label>
                      <Select
                        name="package_id"
                        value={selectedPackage}
                        onChange={(selected) => {
                          handleSelectChange(selected, "package");
                        }}
                        options={packageOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Status</Label>
                      <Select
                        name="status_id"
                        value={selectedStatus}
                        onChange={(selected) => {
                          handleSelectChange(selected, "status");
                        }}
                        options={[
                          {
                            label: "Active",
                            value: 0,
                          },
                          {
                            label: "Inactive",
                            value: 1,
                          },
                          {
                            label: "Hold",
                            value: 2,
                          },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="1">
                    <div className="mt-2 mb-3">
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
                <MDBDataTable
                  id="viewTariffId"
                  responsive
                  bordered
                  data={data}
                  searching={true}
                  info={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(ViewTraffic));
