import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Table } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import Select from "react-select";
const Survey = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const formRef = useRef();

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Survey",
        field: "survey",
        sort: "asc",
        width: 200,
      },
      {
        label: "Question Type",
        field: "qtype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Question",
        field: "qn",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        width: 300,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Survey" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    // onValidSubmit={(e, v) => {
                    //   handleValidSubmit(e, v);
                    // }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Survey</Label>
                          <Select
                            name="ward_id"
                            // value={selectedWardSearch}
                            // onChange={(selected) => {
                            //   handleSearchSelectChange(selected, "ward");
                            // }}
                            options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            name="ward_id"
                            // value={selectedWardSearch}
                            // onChange={(selected) => {
                            //   handleSearchSelectChange(selected, "ward");
                            // }}
                            options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                    <div className="py-2">
                        <div className="table-responsive mb-3">
                          <Table className="table-nowrap table-centered mb-0">
                            <tbody>
                              <tr>
                                <td style={{padding:"0"}}>
                                  <Col>
                                    <AvField
                                      name="qn"
                                      placeholder="Question"
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={master?.qn}
                                      // validate={{ required: { value: true } }}
                                      // onChange={handleInputChange}
                                    />
                                  </Col>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div> 
                    </Row> */}
                    {/* <Row>
                      <div className="py-2">
                        <div className="table-responsive mb-3">
                          <Table className="table-nowrap table-centered mb-0">
                            <tbody>
                              <tr>
                                <td style={{ width: "349px" }}>
                                  <Col>
                                    <AvField
                                      name="qn"
                                      placeholder="Question"
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={master?.qn}
                                      // validate={{ required: { value: true } }}
                                      // onChange={handleInputChange}
                                    />
                                  </Col>
                                </td>
                                <td>
                                  <Row>
                                    <Col>
                                      <AvField
                                        name="qn"
                                        placeholder="Choice 1"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        value={master?.qn}
                                      />
                                    </Col>
                                    <Col>
                                      <AvField
                                        name="qn"
                                        placeholder="Choice 2"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        value={master?.qn}
                                      />
                                    </Col>
                                    <Col>
                                      <AvField
                                        name="qn"
                                        placeholder="Choice 3"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        value={master?.qn}
                                      />
                                    </Col>
                                    <Col>
                                      <AvField
                                        name="qn"
                                        placeholder="Choice 4"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        value={master?.qn}
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mt-3">
                                    <Col>
                                      <AvField
                                        name="qn"
                                        placeholder="Choice 5"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        value={master?.qn}
                                      />
                                    </Col>
                                    <Col>
                                      <AvField
                                        name="qn"
                                        placeholder="Choice 6"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        value={master?.qn}
                                      />
                                    </Col>
                                    <Col>
                                      <AvField
                                        name="qn"
                                        placeholder="Choice 7"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        value={master?.qn}
                                      />
                                    </Col>
                                    <Col>
                                      <AvField
                                        name="qn"
                                        placeholder="Choice 8"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        value={master?.qn}
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mt-3">
                                    <Col>
                                      <AvField
                                        name="qn"
                                        placeholder="Choice 9"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        value={master?.qn}
                                      />
                                    </Col>
                                    <Col>
                                      <AvField
                                        name="qn"
                                        placeholder="Choice 10"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        value={master?.qn}
                                      />
                                    </Col>
                                    <Col />
                                    <Col />
                                  </Row>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Row> */}
                    <Row>
                      <Col
                        md="3"
                        className="mt-3"
                        // style={{ paddingTop: "4px" }}
                      >
                        <div>
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="SurveyTableID"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Survey;
