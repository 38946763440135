import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
//import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getDate,
  getFirstday,
  getLocalbody,
} from "../../helpers/globalFunctions";
import moment from "moment";
import "./wasterequest.scss";
import { CSVLink } from "react-csv";
import { getCustomerType } from "../../store/actions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const WasteRequest = (props) => {
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [details, setDetails] = useState([]);
  // const [wasteItems, setWasteItems] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [customerType, setCustomerType] = useState([]);
  const dispatch = useDispatch();
  const { customerTypes } = useSelector((state) => state.customers);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
    fetch_all_wards(currentLocalbody);
    // fetch_waste_items();
    fetch_all_staff(currentLocalbody);
    dispatch(getCustomerType());
  }, [currentLocalbody]);

  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);
  const fetch_all_staff = (localbody, query) => {
    let data = {
      keyword: query,
      localbody_id: [localbody],
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setStaffOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const updatePackageStatus = (item) => {
    axios
      .put(`${API_URL}wasterequest/status/` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          handleTableData();
        }
        // } else {
        //   toastr.error(res.data.message);
        //   return;
        // }
      });
  };
  // const fetch_waste_items = () => {
  //   axios
  //     .get(`${API_URL}reports/waste_item`, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       let result = res.data.data;
  //       let value = [];
  //       result &&
  //         result.map((item) => {
  //           let data = {
  //             label: (
  //               <>
  //                 <Row>
  //                   <Col xl="12">
  //                     <Row>
  //                       <table>
  //                         <thead>
  //                           <tr>
  //                             <th colSpan="2">{item?.waste_items_name}</th>
  //                           </tr>
  //                           <tr>
  //                             <td>Bag</td>
  //                             <td style={{ paddingLeft: "27px" }}>
  //                               Weight(Kg)
  //                             </td>
  //                           </tr>
  //                         </thead>
  //                       </table>
  //                     </Row>
  //                   </Col>
  //                 </Row>
  //               </>
  //             ),
  //             field: item?.waste_items_name,
  //             sort: "asc",
  //             width: 400,
  //           };
  //           value.push(data);
  //         });
  //       setWasteItems(value);
  //     });
  // };
  function handleTableData(
    from_date = "",
    to_date = "",
    ward_id = "",
    group_id = "",
    customer_id = "",
    category_id = ""
  ) {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      userId = obj._id;
    }
    var url =
      `${API_URL}wasterequest/list?localbody_id=` +
      currentLocalbody +
      "&user_id=" +
      userId;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }
    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&customer_id=" +
      customer_id;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let dataToBeExported = [];
        result &&
          result.map((item, index) => {
            let exportItem = {};
            item.id = index + 1;
            item.date = moment(item.date).format("DD-MM-YYYY");
            item.Weight = item.weight;
            //item.waste_items_name = item?.waste_items_id?.waste_items_name;
            item.ward_name = item.ward_id?.ward_name;
            //item.waste_items_name="";



            item.cust_name = item?.customer_id?.cust_name;
            item.staff = item?.followup_addedby?.firstName;

            item.group_name = (

              item?.group_id &&
              item?.group_id.map((element, index) => {

                return element?.group_name

              })

            );


            item.waste_items_name = (
              <div>
                <ul>
                  {item.waste_items_id &&
                    item.waste_items_id.map((element, index) => {
                      return <li>{element.waste_items_name}</li>;
                    })}
                </ul>
              </div>
            );




            // item.group_id.map((element, index) => {
            //   element.group_name=element.group_name
            // })








            //export data
            //exportItem.Id = item.id;
            exportItem.Date = item?.date;
            // exportItem.Waste = item?.waste_items_id?.waste_items_name;
            exportItem.Waste = (

              item?.waste_items_id &&
              item?.waste_items_id.map((element, index) => {

                return element?.waste_items_name

              }));

            exportItem.Weight = item?.weight;
            exportItem.Bag = item?.quantity;
            exportItem.Ward = item?.ward_id?.ward_name;
            //exportItem.Group =  item?.group_id?.group_name;
            exportItem.Group = (

              item?.group_id &&
              item?.group_id.map((element, index) => {

                return element?.group_name

              })

            );
            exportItem.Customer = item?.customer_id?.cust_name;

            if (item.followup_status === 0) {
              item.followup_status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => {
                      updatePackageStatus(item);
                    }}
                  >
                    New
                  </Button>
                </div>
              );
            } else {
              item.followup_status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => {
                      updatePackageStatus(item);
                    }}
                  >
                    Collected
                  </Button>
                </div>
              );
            }

            //       el?.bag + "/" + el?.weight;
            //   });

            item.items &&
              item.items.map((element) => {
                exportItem[element?.name + "(Bag/Weight)"] =
                  element?.bag + "/" + element?.weight;
              });

            // item.waste_item &&
            //   item.waste_item.map((el) => {
            //     let itemname = el?.waste_items_name;
            //     item[itemname] = (
            //       <>
            //         <Row>
            //           <Col xl="12">
            //             <Row>
            //               <table>
            //                 <tbody>
            //                   <td>{el?.bag}</td>
            //                   <td style={{ width: "89.69px" }}>{el?.weight}</td>
            //                 </tbody>
            //               </table>
            //             </Row>
            //           </Col>
            //         </Row>
            //       </>
            //     );
            //     exportItem[itemname + "(Bag/Weight)"] =
            //       el?.bag + "/" + el?.weight;
            //   });

            item.itemsName = (
              <div>
                <ul>
                  {item.items &&
                    item.items.map((element) => {
                      let strLength = element?.name.length;
                      var name = element?.name;
                      if (strLength && strLength > 14) {
                        var length = 14;
                        name = name.substring(0, length) + "...";
                      }
                      return (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip
                              id="button-tooltip-2"
                              style={{ position: "absolute", top: "-30px" }}
                            >
                              {element?.name}
                            </Tooltip>
                          }
                        >
                          <li
                            style={{
                              padding: "5px",
                            }}
                          >
                            {name}
                          </li>
                        </OverlayTrigger>
                      );
                    })}
                </ul>
              </div>
            );
            item.bag = (
              <div>
                <ul>
                  {item.items &&
                    item.items.map((element) => {
                      return (
                        <li
                          style={{
                            padding: "5px",
                          }}
                        >
                          {element?.bag}
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
            item.weight = (
              <div>
                <ul>
                  {item.items &&
                    item.items.map((element) => {
                      return (
                        <li
                          style={{
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          {element?.weight}
                        </li>
                      );
                    })}
                </ul>
              </div>
            );

            dataToBeExported.push(exportItem);
          });
        setDetails(result);
        setDataToBeExported(dataToBeExported);
      });
  }
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Customer",
        field: "cust_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Waste",
        field: "waste_items_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ward",
        field: "ward_name",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Group",
      //   field: "group_name",
      //   sort: "asc",
      //   width: 400,
      // },

      {
        label: "Bag",
        field: "quantity",
        sort: "asc",
        //width: 50,
      },
      {
        label: "Kg",
        field: "Weight",
        sort: "asc",
        //width: 50,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "followup_status",
        sort: "asc",
        //width: 50,
      },
      // ...wasteItems,
    ],
    rows: details,
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let category_id = searchData?.category_id ? searchData.category_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
          group_id: "",
        });
        setSelectedGroup(null);
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
            currentLocalbody +
            "&ward_id=" +
            selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        // handleTableData(
        //   date1,
        //   date2,
        //   selected.value,
        //   "",
        //   staff_id,
        //   category_id
        // );
        break;
      case "group":
        setSelectedGroup(selected);
        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   selected.value,
        //   staff_id,
        //   category_id
        // );
        break;
      case "customer":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["customer_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   group_id,
        //   selected.value,
        //   category_id
        // );
        break;
      case "customerType":
        setSelectedCustomerType(selected);
        setSearchData({
          ...searchData,
          category_id: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   group_id,
        //   staff_id,
        //   selected.value
        // );
        break;
      default:
        break;
    }
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedStaff(null);
    setSelectedCustomerType(null);
    setGroupOptions([]);
    handleTableData();
  };
  const handleSearch = () => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let customer_id = searchData?.customer_id ? searchData.customer_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let category_id = searchData?.category_id ? searchData.category_id : "";
    handleTableData(date1, date2, ward_id, group_id, customer_id, category_id);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Waste Request" />
          <Row>
            <Col className="col-12">
              {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {"19.00"}</h5>
              </Row> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Group</Label>
                        <Select
                          name="group_id"
                          value={selectedGroup}
                          onChange={(selected) => {
                            handleSelectChange(selected, "group");
                          }}
                          options={groupOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Customer</Label>
                        <Select
                          name="customer_id"
                          value={selectedStaff}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customer");
                          }}
                          options={staffOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      {/* <div className="mb-3">
                        <Label>Customer Type</Label>
                        <Select
                          name="category_id"
                          value={selectedCustomerType}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customerType");
                          }}
                          options={customerType}
                          classNamePrefix="select2-selection"
                        />
                      </div> */}
                    </Col>
                    <Col md="3" >
                      <div style={{ display: "flex" }}>
                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </Button>
                        </div>

                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button color="success" type="submit">
                            <CSVLink
                              data={dataToBeExported}
                              filename={
                                "Waste_request_" +
                                getDate(new Date()) +
                                ".xls"
                              }
                              style={{ color: "white" }}
                            >
                              Export
                            </CSVLink>
                          </Button>
                        </div>

                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <MDBDataTable
                    id="waterequestTableId"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default WasteRequest;
