import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Table,
} from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
// Redux
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import "./WastePlotReport.scss";

const WarehouseReport = (props) => {
  const [details, setDetails] = useState([]);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [groupDataForTable, setgroupDataForTable] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  //   const [grandTotal, setGrandTotal] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
    // fetch_all_wards(currentLocalbody);
    fetch_all_warehouse();
  }, [currentLocalbody]);

  // function fetch_all_wards(localbody) {
  //   axios
  //   .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       var ward_data =
  //         res.data.data &&
  //         res.data.data.map((el) => {
  //           return {
  //             label: el.ward_name,
  //             value: el._id,
  //           };
  //         });

  //       setWardOptions([
  //         {
  //           options: ward_data,
  //         },
  //       ]);
  //     });
  // }
  const fetch_all_warehouse = () => {
    axios
      .get(`${API_URL}warehouse/list/options`, 
      {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var warehouse_data = res.data.data;
          let data = warehouse_data &&
          warehouse_data.map((el) => {
            return {
              label: el.warehouse_name,
              value: el._id,
            };
          });
        setWarehouseOptions([
          {
            options: data,
          },
        ]);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Warehouse",
        field: "warehouse_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Item",
        field: "item",
        sort: "asc",
        width: 5,
      },
      {
        label: "Bag",
        field: "bag",
        sort: "asc",
        width: 50,
      },
      {
        label: "Weight",
        field: "weight",
        sort: "asc",
        width: 50,
      },
    ],
    rows: details,
  };

  function handleTableData(
    from_date = "",
    to_date = "",
    // ward_id = "",
    warehouse_id = ""
  ) {
    var url =
      `${API_URL}reports/warehouse_report?localbody_id=` + currentLocalbody;

      if (from_date === "" && to_date === "") {
        from_date = from_date ? from_date : getFirstday(new Date());
        to_date = to_date ? to_date : getDate(new Date());
        }
    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      // "&ward_id=" +
      // ward_id +
      "&warehouse_id=" +
      warehouse_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        result &&
          result.map((item, index) =>  {
          
          
            
          

          item.id = index + 1;
          // item.item = "";
          // item.items&&item.items.map((element)=>{
          //   if(item.item === "")
          //      item.item = element.driver_drop_items_name;
          //   else  item.item = item.item + "," + element.driver_drop_items_name;   
          // })
          item.item = (
            
              <ul style={{listStyle:'none' }}>
              {item.items &&
                    item.items.map((element, index) => {
                      return <li style={{padding:'8px', borderBottom:'1px solid #d1d1d1',marginLeft:'-40px',paddingLeft:'30px',marginRight:'-12px'}}>{element.driver_drop_items_name}</li>;
                    })}
              </ul>
            
          );
          item.bag = (
            
              <ul style={{listStyle:'none' }}>
              {item.items &&
                    item.items.map((element, index) => {
                      return <li style={{padding:'8px', borderBottom:'1px solid #d1d1d1',marginLeft:'-40px',paddingLeft:'30px',marginRight:'-12px'}}>{element.driver_drop_items_bags}</li>;
                    })}
              </ul>
            
          );
          // item.weight = "";
          // item.items&&item.items.map((element)=>{
          //   if(item.weight === "")
          //      item.weight = element.driver_drop_items_weight;
          //   else  item.weight = item.weight + "," + element.driver_drop_items_weight;   
          // })
          item.weight = (
            
              <ul style={{textAlign:'left' , listStyle:'none'}}>
              {item.items &&
                    item.items.map((element, index) => {
                      return <li style={{padding:'8px', borderBottom:'1px solid #d1d1d1',marginLeft:'-40px',paddingLeft:'30px',marginRight:'-12px'}}>{element.driver_drop_items_weight}</li>;
                    })}
              </ul>
            
          );
 
        });
        setDetails(result);
      });
    }
    // if (item.group_ward) {
    //   var result = item.group_ward.map(function (itm) {
    //     return { label: itm.driver_drop_items_name, value: itm.id };
    //   });



  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let warehouse_id = searchData?.warehouse_id ? searchData.warehouse_id : "";
    
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(date1, date2, warehouse_id);
  };

  const handleSelectChange = (selected, name) => {
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let warehouse_id = searchData?.warehouse_id ? searchData.warehouse_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    
    //   case "ward":
    //     setSelectedWard(selected);
    //     setSearchData({
    //       ...searchData,
    //       ["ward_id"]: selected.value,
    //     });
    //     handleTableData(date1, date2, selected.value, staff_id);
    //     break;
     
        setSelectedWarehouse(selected);
        setSearchData({
          ...searchData,
          ["warehouse_id"]: selected.value,
        });
        handleTableData(date1, date2, selected.value , warehouse_id);
        
    
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    // setSelectedWard(null);
    setSelectedWarehouse(null);
    handleTableData();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Warehouse Report" />
          <Row>
            <Col className="col-12">
              {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {"19.00"}</h5>
              </Row> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label>Warehouse</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Warehouse</Label>
                        <Select
                          name="warehouse_id"
                          value={selectedWarehouse}
                          onChange={(selected) => {
                            handleSelectChange(selected, "warehouse");
                          }}
                          options={warehouseOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    {/* <Col md="1" style={{ paddingTop: "5px" }}>
                      <div className="mt-4">
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Ward_wise_report_" +
                              getDate(new Date()) +
                              ".xls"
                            }
                            style={{ color: "white" }}
                          >
                          Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col> */}
                    <Col md="1" style={{ paddingTop: "5px" }}>
                      <div className="mt-4">
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <MDBDataTable
                    id="table3"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WarehouseReport;