import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import toastr from "toastr";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import LoopIcon from "@mui/icons-material/Loop";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { getDate } from "../../helpers/globalFunctions";
import "./manageNotification.scss";
const ManageNotification = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [selectedNotificationFor, setSelectedNotificationFor] = useState(null);
  const [popupView, setPopupView] = useState(false);
  const [localbodyOptions, setLoacalbodyOptions] = useState([]);
  const [selectedLocalbody, setSelectedLocalbody] = useState(null);
  const [uploadProgress, setUploadProgress] = useState();
  const [notificationIdToBeDeleted, setNotificationIdToBeDeleted] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [notificationIdToBeUpdated, setNotificationIdToBeUpdated] =
    useState(null);
  const [tableData, setTableData] = useState({});
  const [errors, setErrors] = useState({
    localbodyError: "",
    notificationlistError: "",
    // fromDateError: "",
    // timeError: "",
  });
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [showStaff, setShowStaff] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    handleTableData();
    fetch_all_localbodies();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preUpdateNotification = (item) => {
    setNotificationIdToBeUpdated(item._id);
    axios
      .get(`${API_URL}notification/?notification_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((element, index) => {
            element.id = index + 1;
            let options = {};
            if (element?.notification_visible === 0) {
              options = {
                label: "All",
                value: 0,
              };
            } else if (element?.notification_visible === 1) {
              options = {
                label: "Staff",
                value: 1,
              };
              setShowStaff(true);
            } else if (element?.notification_visible === 2) {
              options = {
                label: "Customer",
                value: 2,
              };
              setShowCustomer(true);
            }
            setSelectedNotificationFor(options);
            let localbodyOptions = [];
            let notificationLocalbody = [];
            item.notification_localbody &&
              item.notification_localbody.forEach((el) => {
                let val = {};
                val.label = el.localbody_name;
                val.value = el._id;
                localbodyOptions.push(val);
                notificationLocalbody.push(el._id);
              });
            setSelectedLocalbody(localbodyOptions);
            element.notification_localbody = notificationLocalbody;

            let wardOptions = [];
            let notificationWard = [];
            element.notification_ward &&
              element.notification_ward.forEach((el) => {
                let val = {};
                val.label = el.ward_name;
                val.value = el._id;
                wardOptions.push(val);
                notificationWard.push(el._id);
              });
            setSelectedWard(wardOptions);
            fetch_all_wards(notificationLocalbody);
            element.notification_ward = notificationWard;

            let customerOptions = [];
            let notificationCustomer = [];
            element.notification_customer &&
              element.notification_customer.forEach((el) => {
                let val = {};
                val.label = el.cust_name;
                val.value = el._id;
                customerOptions.push(val);
                notificationCustomer.push(el._id);
              });
            setSelectedCustomer(customerOptions);
            fetch_all_customers(notificationLocalbody, "");
            element.notification_customer = notificationCustomer;

            let staffOptions = [];
            let notificationStaff = [];
            element.notification_user &&
              element.notification_user.forEach((el) => {
                let val = {};
                if (el?.lastName)
                  val.label = el?.firstName + " " + el?.lastName;
                else val.label = el?.firstName;

                val.value = el._id;
                staffOptions.push(val);
                notificationStaff.push(el._id);
              });
            setSelectedStaff(staffOptions);
            fetch_all_staff(notificationLocalbody);
            element.notification_user = notificationStaff;
          });
        setMaster(...result);
      });
  };
  function handleTableData() {
    axios
      .get(`${API_URL}notification/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            if (item.notification_visible === 0) item.notification_list = "All";
            else if (item.notification_visible === 1)
              item.notification_list = "Staff";
            else item.notification_list = "Customer";

            item.date = moment(item.date).format("DD-MM-YYYY");
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
            item.createdAt = moment(
              item.notification_datetime,
              "DD-MM-YYYYHHmmss"
            ).format("DD-MM-YYYY,hh:mm a");

            // if (item?.notification_status === 0) item.status = "Pending";
            // else if (item?.notification_status === 1) item.status = "Delete";
            // else item.status = "Send";
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                ></i>
                {/* <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    preUpdateNotification(item);
                    toTop();
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setNotificationIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i> */}
              </div>
            );
          });
        setDetails(result);
      });
  }
  const fetch_all_localbodies = () => {
    axios
      .get(`${API_URL}localbody/alllist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let localbody_data =
          res.data.data &&
          res.data.data.map((item, index) => {
            return {
              label: item.localbody_name,
              value: item._id,
            };
          });
        setLoacalbodyOptions([
          {
            options: localbody_data,
          },
        ]);
      });
  };
  const handleClickOpenForm = (item) => {
    setTableData(item);
    setPopupView(true);
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 70,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 70,
      },
      {
        label: "Title",
        field: "notification_title",
        sort: "asc",
        width: 70,
      },
      {
        label: "Message",
        field: "notification_message",
        sort: "asc",
        width: 70,
      },
      {
        label: "Notification List",
        field: "notification_list",
        sort: "asc",
        width: 70,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 70,
      // },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: details,
    // rows:[{
    //   id:1,
    //   title:"Drop",
    //   Message:"Driver dropped your waste",
    //   notification_list:"Customer",
    //   From:"02-02-2022,12.30 PM",
    //   to:"02-02-2022,05.30 PM",
    //   action:(
    //     <div style={{ display: "flex", justifyContent: "center" }}>
    //       <i
    //         className="fas fa-eye"
    //         style={{
    //           fontSize: "1em",
    //           cursor: "pointer",
    //           marginLeft: "0.2em",
    //           marginRight: "0.5em",
    //         }}
    //         onClick={() => {
    //           handleClickOpenForm();
    //         }}
    //       ></i>
    //     <i
    //       className="far fa-edit"
    //       style={{
    //         fontSize: "1em",
    //         cursor: "pointer",
    //         marginLeft: "0.1em",
    //         marginRight: "0.5em",
    //       }}
    //       onClick={() => {}}
    //     ></i>
    //     <i
    //       className="far fa-trash-alt"
    //       style={{ fontSize: "1em", cursor: "pointer" }}
    //       onClick={() => {}}
    //     ></i>
    //   </div>
    //   )
    // }]
  };
  const reset = () => {
    formRef.current.reset();
    setMaster({});
    setSelectedNotificationFor(null);
    // window.location.reload();
  };
  const handleChangeInput = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "notification_date") {
      setErrors({
        ...errors,
        // fromDateError: "",
      });
    } else if (e.target.name === "notification_time") {
      setErrors({
        ...errors,
        // timeError: "",
      });
    }
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "noticationFor":
        setSelectedNotificationFor(selected);
        setMaster({
          ...master,
          ["notification_visible"]: selected.value,
        });

        if (selected.label === "Staff") setShowStaff(true);
        else {
          setSelectedStaff(null);
          setShowStaff(false);
        }
        if (selected.label === "Customer") setShowCustomer(true);
        else {
          setSelectedCustomer(null);
          setShowCustomer(false);
        }
        setErrors({
          ...errors,
          notificationlistError: "",
        });
        break;
      case "localbody":
        let arrValue = [];
        if (selected === null) {
          setSelectedStaff(null);
          setSelectedCustomer(null);
          setSelectedWard(null);
        }
        selected &&
          selected.forEach((element) => {
            arrValue.push(element.value);
          });
        setSelectedLocalbody(selected);
        fetch_all_wards(arrValue);
        fetch_all_staff(arrValue);
        fetch_all_customers(arrValue, "");
        setMaster({
          ...master,
          ["notification_localbody"]: arrValue,
        });
        setErrors({
          ...errors,
          localbodyError: "",
        });
        break;
      case "wardMulti":
        setSelectedWard(selected);
        let wardArray = [];
        selected &&
          selected.forEach((element) => {
            wardArray.push(element.value);
          });
        setMaster({ ...master, ["notification_ward"]: wardArray });
        break;
      case "staffMulti":
        setSelectedStaff(selected);
        let staffArray = [];
        selected &&
          selected.forEach((element) => {
            staffArray.push(element.value);
          });
        setMaster({ ...master, ["notification_user"]: staffArray });
        break;
      case "customerMulti":
        setSelectedCustomer(selected);
        let customerArray = [];
        selected.forEach((element) => {
          customerArray.push(element.value);
        });
        setMaster({ ...master, ["notification_customer"]: customerArray });
        break;
      default:
        break;
    }
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}localbody/ward?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          var ward_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.ward_name,
                value: el._id,
              };
            });
        } else {
          ward_data = [];
        }

        // var ward_data =
        //   res.data.data &&
        //   res.data.data.map((el) => {
        //     return {
        //       label: el.ward_name,
        //       value: el._id,
        //     };
        //   });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      })
      .catch(() => {
        setWardOptions([
          {
            options: [],
          },
        ]);
      });
  }
  const fetch_all_staff = (localbody) => {
    axios
      .get(`${API_URL}user/localbody?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            let name = "";
            if (el?.lastName) name = el?.firstName + " " + el?.lastName;
            else name = el?.firstName;
            return {
              label: name,
              value: el._id,
            };
          });

        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      })
      .catch(() => {
        setStaffOptions([
          {
            options: [],
          },
        ]);
      });
  };
  const fetch_all_customers = (localbody, query) => {
    let data = {
      keyword: query,
      localbody_id: localbody,
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const handleStaffSearch = (query) => {
    axios
      .get(
        `${API_URL}user/localbody?localbody_id=` +
        master?.notification_localbody +
        "&keyword=" +
        query,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            let name = "";
            if (el?.lastName) name = el?.firstName + " " + el?.lastName;
            else name = el?.firstName;
            return {
              label: name,
              value: el._id,
            };
          });

        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      })
      .catch(() => {
        setStaffOptions([
          {
            options: [],
          },
        ]);
      });
  };
  const handleCustomerSearch = (query) => {
    let data = {
      keyword: query,
      localbody_id: master?.notification_localbody,
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("notification_image", e.target.files[0]);
    axios
      .post(`${API_URL}notification/image`, fd, {
        headers: {
          // "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setMaster({
            ...master,
            [`notification_image`]: response.data.file.filename,
          });
          // toastr.success("Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const deleteNotificationImage = () => {
    setMaster({
      ...master,
      [`notification_image`]: "",
    });
  };
  const handleValidSubmit = () => {
    if (
      selectedLocalbody === null || selectedNotificationFor === null
      //!master?.notification_date ||
      //!master?.notification_time
    ) {
      let errorValue = errors;

      if (selectedLocalbody === null) {
        errorValue.localbodyError = "Please select localbody";
      }

      if (selectedNotificationFor === null) {
        errorValue.notificationlistError = "Please select notification list";
      }

      // if (!master?.notification_date) {
      //   errorValue.fromDateError = "Please choose from";
      // }
      // if (!master?.notification_time) {
      //   errorValue.timeError = "Please choose time";
      // }
      setErrors({
        ...errorValue,
      });
      return;
    }

    if (notificationIdToBeUpdated) {
      axios
        .put(
          `${API_URL}notification?notification_id=` + notificationIdToBeUpdated,
          master,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Notification updated successfully");
            handleTableData();
            setSelectedNotificationFor(null);
            setSelectedLocalbody(null);
            formRef.current.reset();
            setMaster({
              // notification_date: "",
              // notification_time: "",
            });
            setErrors({
              localbodyError: "",
              notificationlistError: ""
              // fromDateError: "",
              // timeError: "",
            });
            setNotificationIdToBeUpdated(null);
            setSelectedStaff(null);
            setSelectedCustomer(null);
            setSelectedWard(null);
            setShowCustomer(false);
            setShowStaff(false);
            setStaffOptions([]);
            setWardOptions([]);
            setCustomerOptions([]);
          } else {
            toastr.error("Failed to update notification");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}notification`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Notification created successfully");
            handleTableData();
            setSelectedNotificationFor(null);
            setSelectedLocalbody(null);
            formRef.current.reset();
            setMaster({
              // notification_date: "",
              // notification_time: "",
            });
            setErrors({
              localbodyError: "",
              notificationlistError: ""
              // fromDateError: "",
              // timeError: "",
            });
            setSelectedStaff(null);
            setSelectedCustomer(null);
            setSelectedWard(null);
            setShowCustomer(false);
            setShowStaff(false);
            setStaffOptions([]);
            setWardOptions([]);
            setCustomerOptions([]);
          } else {
            toastr.error("Failed to create notification");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}notification/?notification_id=` +
                notificationIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Notification deleted successfully");
                  if (master && master?._id === notificationIdToBeDeleted) {
                    setSelectedNotificationFor(null);
                    setSelectedLocalbody(null);
                    formRef.current.reset();
                    setMaster({
                      //notification_date: "",
                      //notification_time: "",
                    });
                    setErrors({
                      localbodyError: "",
                      notificationlistError: ""
                      // fromDateError: "",
                      // timeError: "",
                    });
                    setNotificationIdToBeUpdated(null);
                    setSelectedStaff(null);
                    setSelectedCustomer(null);
                    setSelectedWard(null);
                    setShowCustomer(false);
                    setShowStaff(false);
                    setStaffOptions([]);
                    setWardOptions([]);
                    setCustomerOptions([]);
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete notification");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Notification Details</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px", textAlign: "left", width: "184px" }}>Title :</td>
                <td style={{ width: "100px", wordBreak: "break-all" }}>{tableData?.notification_title}</td>
                <td style={{ paddingLeft: "25px" }}>
                  Notification List :
                </td>
                <td style={{ textAlign: "left", paddingLeft: "0px" }}>{tableData?.notification_list}</td>


                {/* <td style={{paddingLeft:"25px" }}>Message :</td>
                <td style={{ textAlign: "left" }}>
                  {tableData?.notification_message}
                </td> */}
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Date :</td>
                <td>{tableData?.date}</td>
                <td style={{ paddingLeft: "25px" }}>Time :</td>
                <td style={{ textAlign: "left" }}>{tableData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Message :</td>
                <td colSpan={3}>
                  {tableData?.notification_message}
                </td>
                <td></td>
                {/* <td style={{ padding: "10px"}}>
                  Notification List :
                </td>
                <td>{tableData?.notification_list}</td>
                <td></td> */}
                {/* <td style={{ textAlign: "left" }}>Created At :</td>
                <td style={{ textAlign: "left" }}>{tableData?.createdAt}</td> */}
              </tr>

              <tr hidden={!tableData?.notification_image}>
                <td style={{ padding: "10px", textAlign: "left" }}>Image :</td>
                <td style={{ textAlign: "left" }}>
                  <img
                    style={{
                      width: "180px",
                      height: "180px",
                      paddingLeft: "0px",
                    }}
                    src={`${API_URL}uploads/notification_images/${tableData?.notification_image}`}
                  ></img>
                </td>
                {/* <td style={{ textAlign: "left" }}>Status :</td>
                <td style={{ textAlign: "left" }}>{tableData?.status}</td> */}
              </tr>
              <tr hidden={tableData?.notification_image}>
                <td style={{ padding: "10px", textAlign: "left" }}></td>
                <td>{tableData?.status}</td>
                <td style={{ textAlign: "left" }}></td>
                <td style={{ textAlign: "left" }}>{ }</td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Notification" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Title</Label>
                          <AvField
                            name="notification_title"
                            type="text"
                            errorMessage="Enter Title"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.notification_title}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Message</Label>
                          <AvField
                            name="notification_message"
                            value={master?.notification_message}
                            // placeholder="
                            // type="textarea"
                            type="text"
                            errorMessage="Enter message"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                            rows="1"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Notification List</Label>
                          <Select
                            name="notification_visible"
                            value={selectedNotificationFor}
                            onChange={(selected) => {
                              handleSelectChange(selected, "noticationFor");
                            }}
                            options={[
                              { label: "All", value: 0 },
                              { label: "Staff", value: 1 },
                              { label: "Customer", value: 2 },
                            ]}
                            classNamePrefix="select2-selection"

                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedNotificationFor === null
                              ? errors.notificationlistError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Localbody</Label>
                          <Select
                            isMulti
                            name="notification_localbody"
                            value={selectedLocalbody}
                            onChange={(selected) => {
                              handleSelectChange(selected, "localbody");
                            }}
                            options={localbodyOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedLocalbody === null
                              ? errors.localbodyError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            isMulti
                            name="notification_ward"
                            value={selectedWard}
                            onChange={(selected) => {
                              handleSelectChange(selected, "wardMulti");
                            }}
                            options={wardOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      {showStaff === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Staff</Label>
                            <Select
                              isMulti
                              name="notification_user"
                              value={selectedStaff}
                              onChange={(selected) => {
                                handleSelectChange(selected, "staffMulti");
                              }}
                              options={staffOptions}
                              onInputChange={(input) =>
                                handleStaffSearch(input)
                              }
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : null}
                      {showCustomer === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Customer</Label>
                            <Select
                              isMulti
                              name="notification_customer"
                              value={selectedCustomer}
                              onChange={(selected) => {
                                handleSelectChange(selected, "customerMulti");
                              }}
                              options={customerOptions}
                              onInputChange={(input) =>
                                handleCustomerSearch(input)
                              }
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : null}
                      {/* <Col md="3">
                        <div className="mb-1">
                          <Label htmlFor="validationCustom05">From</Label>

                          <input
                            className="form-control"
                            type="date"
                            value={master?.notification_date}
                            id="notification_date"
                            name="notification_date"
                            onChange={handleChangeInput}
                            min={getDate(new Date())}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master?.notification_date
                              ? errors.fromDateError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-1">
                          <Label htmlFor="validationCustom05">Time</Label>

                          <input
                            className="form-control"
                            type="time"
                            value={master?.notification_time}
                            id="notification_time"
                            name="notification_time"
                            onChange={handleChangeInput}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master?.notification_time ? errors.timeError : ""}
                          </p>
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-1">
                          <Label htmlFor="validationCustom05">URL</Label>
                          <AvField
                            name="notification_url"
                            type="text"
                            //errorMessage="Enter Url"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.notification_url}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3" style={{ display: "block" }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Choose Image
                          </Label>
                          {master?.notification_image ? (
                            <div div className="img-wraps">
                              {master.notification_image &&
                                master.notification_image.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${master.notification_image}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/notification_images/${master.notification_image}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteNotificationImage}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="notification_image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>
                      {/* <Col md="3" style={{ display: "block" }}>
                        <div className="mb-1">
                          <Label htmlFor="validationCustom03">
                            Choose Image
                          </Label>
                          <AvField
                            name="notification_image"
                            type="file"
                            errorMessage="Choose image"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={uploadImage}
                            rows="1"
                          />
                        </div>
                      </Col> */}
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {notificationIdToBeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3" style={{ paddingTop: "33px" }}>
                          <LoopIcon onClick={reset}></LoopIcon>
                        </div>

                      </Col> */}
                      {/* <Col md="1" style={{ paddingTop: "5px" }}>
                        <div className="mt-3">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col> */}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="manageNotificationTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ManageNotification;