import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./workrequest.scss";
import { getLocalbody } from "../../../helpers/globalFunctions";


const AddCategory = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [categoryIdTobeUpdated, setCategoryIdTobeUpdated] = useState(null);
  const [categoryIdTobeDeleted, setCategoryIdTobeDeleted] = useState(null);
  const [uploadProgress, setUploadProgress] = useState();
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [errors, setErrors] = useState({
    imageError: "",
  });
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

  useEffect(() => {
    handleTableData();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  // const preUpdateCategory = (item) => {


  //   // if (item?.category_name || item?.category_icon) {
  //     setMaster({
  //       category_name: item.category_name,
  //       require_icon: item.require_icon,
  //       _id: item._id,
  //     });
  //     setCategoryIdTobeUpdated(item._id);
  //   // }
  // };
  let preUpdateCategory = (item) => {
    setCategoryIdTobeUpdated(item._id);
    axios
      .get(`${API_URL}workrequestcategory/view/?category_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let element = res.data.data;

        setMaster(element);
      });
  };
  const handleTableData = () => {
    axios
      .get(`${API_URL}workrequestcategory/list?localbody=${currentLocalbody}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {

            item.id = index + 1;

            if (item?.updated_date)
              item.date = moment(item?.updated_date).format(
                "DD-MM-YYYY"
              );
            else if (item?.date)
              item.date = moment(item?.date).format("DD-MM-YYYY");
            else item.date = "";

            if (item?.updated_time)
              item.time = moment(item?.updated_time, "hhmmss").format(
                "hh:mm a"
              );
            else if (item?.time)
              item.time = moment(item?.time, "hhmmss").format(
                "hh:mm a"
              );
            else item.time = "";
            if (item?.category__updatedby) {
              let category__updatedby = item.category__updatedby || {};
              if (category__updatedby?.lastName)
                item.staff = category__updatedby?.firstName + " " + category__updatedby?.lastName;
              else item.staff = category__updatedby?.firstName;
            } else {
              let category__addedby = item.category__addedby || {};
              if (category__addedby?.lastName)
                item.staff = category__addedby?.firstName + " " + category__addedby?.lastName;
              else item.staff = category__addedby?.firstName;
            }
            if (item?.category_icon) {
              item.category_icon = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/workrequest_categoryicon/${item?.category_icon}`}
                    height="30"
                    width="30"
                  />
                </div>
              );
            }
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="uil-edit-alt"
                  style={{
                    fontSize: "1.2em",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {

                    toTop();
                    preUpdateCategory(item);
                  }}
                ></i>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  onClick={() => {
                    setCategoryIdTobeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );

            if (item?.category_status === 0) {
              item.status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button color="warning" size="sm" style={{ width: '107px' }}>In Quantity</Button>
                </div>
              )
            } else if (item?.category_status === 1) {
              item.status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button color="danger" size="sm" style={{ width: '107px' }}>Out Stock</Button>
                </div>
              )
            } else {
              item.status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button color="success" size="sm">Ready to purchase</Button>
                </div>
              )
            }
          });
        setDetails(result);
      });
  };

  const uploadImage = (e) => {
    let val = e.target.files[0];

    if (val?.size > 102400)
      toastr.error("Image size should be less than or equal to 100 Kb");
    else {
      const fd = new FormData();
      fd.append("category_icon", e.target.files[0]);
      axios
        .post(`${API_URL}workrequestcategory/icon`, fd, {
          headers: {
            // "Content-Type": "multipart/form-data",
            "x-access-token": accessToken,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setUploadProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            setMaster({
              ...master,
              [`category_icon`]: response.data.file.filename,
            });
            setErrors({
              ...errors,
              imageError: "",
            });
            // toastr.success("Image Uploaded");
          } else {
            toastr.error(response.data.message);
          }

        });
    }
  };

  const deletePopupImage = () => {
    setMaster({
      ...master,
      [`category_icon`]: "",
    });
    setErrors({
      ...errors,
      imageError: "",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },

      {
        label: "Category",
        field: "category_name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Min. Quantity",
        field: "minimum_quantity",
        sort: "desc",
        width: 400,
      },
      {
        label: "Quantity",
        field: "quantity",
        sort: "desc",
        width: 400,
      },
      {
        label: "Unit",
        field: "unit",
        sort: "desc",
        width: 400,
      },
      {
        label: "Rate",
        field: "rate",
        sort: "desc",
        width: 400,
      },
      {
        label: "Icon",
        field: "category_icon",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },
      {
        label: "Status",
        field: "status",
        sort: "desc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {

    let data = {
      category_name: master?.category_name,
      rate: master?.rate,
      unit: master?.unit,
      quantity: master?.quantity,
      localbody: currentLocalbody,
      category_icon: master?.category_icon,
      minimum_quantity: master?.minimum_quantity
    }

    if (categoryIdTobeUpdated) {
      axios
        .put(`${API_URL}workrequestcategory/update`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Category updated successfully");
            setMaster({});
            handleTableData();
            setCategoryIdTobeUpdated(null);
          } else {
            toastr.error("Failed to update Category");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}workrequestcategory/`, data, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Category added successfully");
            setMaster({});
            handleTableData();
          } else {
            toastr.error("Failed to add Category");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };



  function reset() {
    setSelectedStatus(null);
    setMaster({});
    handleTableData();
  }

  function handleSearch() {
    let category_status = master?.category_status ? master.category_status : "";

    handleTableData(category_status);
  }


  return (
    <>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}workrequestcategory` + "/" + categoryIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("category deleted successfully");
                  if (master && master._id === categoryIdTobeDeleted) {
                    formRef.current.reset();
                    setMaster({});
                    setCategoryIdTobeUpdated(null);
                  }
                  setCategoryIdTobeUpdated(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete category");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Work Request Category" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleValidSubmit();
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Work RequestCategory</Label>
                          <AvField
                            name="category_name"
                            placeholder="Work Request Category"
                            type="text"
                            errorMessage="Enter Work Request Category"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.category_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Unit</Label>
                          <AvField
                            name="unit"
                            placeholder="Unit"
                            type="text"
                            errorMessage="Enter Unit"
                            validate={{ required: { value: true } }}
                            className="form-control"
                            value={master?.unit}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Minimum Quantity</Label>
                          <AvField
                            name="minimum_quantity"
                            placeholder="Minimum Quantity"
                            type="number"
                            min={0}
                            errorMessage="Enter Minimum Quantity"
                            validate={{ required: { value: true } }}
                            className="form-control"
                            value={master?.minimum_quantity}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Quantity</Label>
                          <AvField
                            name="quantity"
                            placeholder="Quantity"
                            type="number"
                            min={0}
                            errorMessage="Enter Quantity"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            value={master?.quantity}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Rate</Label>
                          <AvField
                            name="rate"
                            placeholder="Rate"
                            type="number"
                            min={0}
                            errorMessage="Enter Rate"
                            validate={{ required: { value: true } }}
                            value={master?.rate}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ display: "block" }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Choose Icon Image
                          </Label>
                          {master.category_icon ? (
                            <div div className="img-wraps">
                              {master.category_icon &&
                                master.category_icon.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${master.category_icon}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/workrequest_categoryicon/${master.category_icon}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deletePopupImage}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="category_icon"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master?.category_icon ? errors.imageError : ""}
                          </p>
                        </div>

                        {/* <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Choose Image
                          </Label>
                          <AvField
                            name="image_upload"
                            // value={productObject.product_seo_keys}
                            // placeholder="Product Image 1"
                            type="file"
                            errorMessage="Choose image"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={uploadImage}
                            rows="1"
                          />
                        </div> */}
                      </Col>

                      <Col style={{ marginTop: "25px" }}>
                        {/* <div className="mb-3" style={{ paddingTop: "25px" }}> */}
                        <div className="mb-3">
                          {categoryIdTobeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  {/* <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Status</Label>
                        <Select
                          type="text"
                          value={selectedStatus}
                          placeholder="Status"
                          classNamePrefix="select2-selection"
                          validate={{ required: { value: true } }}
                          options={[
                            { label: "In Stock", value: 0 },
                            { label: "Out Stock", value: 1 }
                          ]}
                          onChange={(selected) => {
                            handleSelectChange(selected, "status")
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button color="primary" type="search" onClick={() => handleSearch()}>Filter</Button>
                    </Col>

                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button color="danger" type="reset" onClick={() => reset()}>Reset</Button>
                    </Col>
                  </Row> */}
                  <MDBDataTable
                    id="manageCategoryTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddCategory;
