import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";
import jwt_decode from "jwt-decode";

import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllAccountTypes,
  addAccountType,
  deleteAccountType,
  updateAccountType,
} from "../../../store/actions";

import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getLocalbody } from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./type.scss";

const Acctypes = (props) => {
  const [acctypeObject, setAcctypeObject] = useState({
    localbody_id: getLocalbody(),
  });
  const [acctypeTemp, setacctypeTemp] = useState([]);

  const [acctypeIdTobeUpdated, setAcctypedToBeUpdated] = useState(null);
  const [acctypeIdToBeDeleted, setAcctypeIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

  const [userId, setUserId] = useState(1);
  const formRef = useRef();

  const {
    acctypes,
    addingAcctype,
    addAcctypeResponse,
    deleteAcctypeResponse,
    updateAcctypeResponse,
    error,
  } = useSelector((state) => state.acctypes);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const dispatch = useDispatch();
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    dispatch(getAllAccountTypes(currentLocalbody));
  }, [currentLocalbody]);

  useEffect(() => {
    if (addAcctypeResponse.type === "success") {
      toastr.success(addAcctypeResponse.message);
      dispatch(getAllAccountTypes(currentLocalbody));
    } else if (addAcctypeResponse.type === "failure") {
      toastr.error(error.data.message, addAcctypeResponse.message);
    }
  }, [addAcctypeResponse]);

  useEffect(() => {
    if (deleteAcctypeResponse.type === "success") {
      toastr.success(deleteAcctypeResponse.message);
      dispatch(getAllAccountTypes(currentLocalbody));
    } else if (deleteAcctypeResponse.type === "failure") {
      toastr.error(error.data.message, deleteAcctypeResponse.message);
    }
  }, [deleteAcctypeResponse]);

  useEffect(() => {
    if (updateAcctypeResponse.type === "success") {
      setAcctypedToBeUpdated(null);
      toastr.success(updateAcctypeResponse.message);
      dispatch(getAllAccountTypes(currentLocalbody));
    } else if (updateAcctypeResponse.type === "failure") {
      toastr.error(error.data.message, updateAcctypeResponse.message);
    }
  }, [updateAcctypeResponse]);

  let preupdateAccountType = (item) => {
    item.localbody_id = getLocalbody();
    setAcctypedToBeUpdated(item._id);
    setAcctypeObject(item);
  };

  useEffect(() => {
    let acctypeDuplicate = JSON.parse(JSON.stringify(acctypes));
    let acctypeData = [];
    acctypeDuplicate.map((item, index) => {
      item.fname = "";
      if (item.acc_type_addedby) {
        item.fname = item.acc_type_addedby.username;
      }
      item.action = (
        <div class="action">
          <i
            className="far fa-edit"
            onClick={() => {
              toTop();
              preupdateAccountType(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            onClick={() => {
              setAcctypeIdToBeDeleted(item._id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;

      acctypeData.push(item);
    });
    setacctypeTemp(acctypeData);
  }, [acctypes]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },

      {
        label: "Account Type",
        field: "acc_type_name",
        sort: "asc",
        width: 70,
      },
      {
        label: "Added By",
        field: "fname",
        sort: "asc",
        width: 70,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: acctypeTemp,
  };

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAcctypeObject({
      ...acctypeObject,
      [name]: value,
    });
  };
  const handleValidSubmitAcctype = (event, values) => {
    // acctypeIdTobeUpdated
    //   ? dispatch(updateAccountType(acctypeObject))
    //   : dispatch(addAccountType(acctypeObject));

    if (acctypeIdTobeUpdated) {
      axios
        .put(`${API_URL}account_type`, acctypeObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Account type updated successfully");
            formRef.current.reset();
            setAcctypeObject({
              ["auth_userid"]: userId,
              ["localbody_id"]: getLocalbody(),
            });
            dispatch(getAllAccountTypes(currentLocalbody));
            setAcctypedToBeUpdated(null);
          } else {
            toastr.error("Failed to update account type");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}account_type`, acctypeObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Account type added successfully");
            formRef.current.reset();
            setAcctypeObject({
              ["auth_userid"]: userId,
              ["localbody_id"]: getLocalbody(),
            });
            dispatch(getAllAccountTypes(currentLocalbody));
          } else {
            toastr.error("Failed to add account type");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const reset = () => {
    formRef.current.reset();
    setAcctypeObject({
      localbody_id: getLocalbody(),
      auth_userid: userId,
    });
    setAcctypeIdToBeDeleted(null);
    setAcctypedToBeUpdated(null);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}account_type` + "/" + acctypeIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Account type deleted successfully");
                  if(acctypeObject&&acctypeObject?._id === acctypeIdToBeDeleted){
                    formRef.current.reset();
                    setAcctypeObject({
                      ["auth_userid"]: userId,
                      ["localbody_id"]: getLocalbody(),
                    });
                    setAcctypedToBeUpdated(null);
                  }
                  dispatch(getAllAccountTypes(currentLocalbody));   
                } else {
                  toastr.error("Failed to delete account type");
                  return;
                }
              });
            // dispatch(deleteAccountType(acctypeIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Account Type" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitAcctype(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Account Type
                          </Label>

                          <AvField
                            name="acc_type_name"
                            value={acctypeObject.acc_type_name}
                            placeholder="Account Type"
                            type="text"
                            errorMessage="Enter Account Type"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[A-Za-z ]+$",
                                errorMessage:
                                  "Account Type must be only with letter and spaces",
                              },
                            }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div style={{display:"flex"}}>
                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          {acctypeIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingAcctype ? true : false}
                            >
                              {addingAcctype ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingAcctype ? true : false}
                            >
                              {addingAcctype ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                     
                        <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="account_type_table"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    entries={20}
                    info={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Acctypes;
