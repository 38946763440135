import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Modal } from "react-bootstrap";
import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import "./wastecollection.scss";

const WasteCollection = (props) => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });

  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [wasteCollectionStatus, setWasteCollectionStatus] = useState([]);
  const [wasteCollectionitemDetails, setWasteCollectionitemDetails] = useState(
    []
  );
  const [selectedWasteCollectionStatus, setSelectedWasteCollectionStatus] =
    useState(null);
  const [details, setDetails] = useState([]);
  // const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    fetch_all_staff(currentLocalbody);
    handleTableData();
    fetch_waste_collection_status();
    fetch_all_wards(currentLocalbody)
  }, [currentLocalbody]);
  function handleTableData(
    from_date = "",
    to_date = "",
    ward_id = "",
    staff_id = "",
    status_id = ""
  ) {

    var url = `${API_URL}offlinecollection/wastecollect?localbody_id=` + currentLocalbody;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&staff_id=" +
      staff_id +
      "&status_id=" +
      status_id;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        // let dataToBeExported = [];
        result &&
          result.map((item, index) => {
            // let exportItem = {};
            item.id = index + 1;
            item.date = moment(item?.offline_clt_date).format("DD-MM-YYYY");
            item.time = moment(item?.offline_clt_time, "hhmmss").format(
              "hh:mm a"
            );
            if (item.offline_sync_status === 1) {
              item.status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="success"
                    size="sm"

                  >
                    Synced
                  </Button>
                </div>
              );
            }
            else {
              item.status = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="warning"
                    size="sm"

                  >
                    Pending
                  </Button>
                </div>
              );
            }

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.1em",
                  }}
                  onClick={() => {
                    handleClick(item);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  }
  const handleClick = (item) => {
    axios
      .get(`${API_URL}offlinecollection/wastecollect_view?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item) => {
            item.date = moment(item?.offline_clt_date).format("DD-MM-YYYY");
            item.time = moment(item?.offline_clt_time, "hhmmss").format(
              "hh:mm a"
            );
            if (item?.offline_clt_sync_date) {
              item.syncDate = moment(item?.offline_clt_sync_date).format(
                "DD-MM-YYYY"
              );
            }
            if (item?.offline_clt_sync_time) {
              item.syncTime = moment(
                item?.offline_clt_sync_time,
                "hhmmss"
              ).format("hh:mm a");
            }

            let itemDetails = item.item;
            itemDetails &&
              itemDetails.map((el, index) => {
                el.id = index + 1;
              });
            setWasteCollectionitemDetails(itemDetails);
            setPopupData(item);
            setPopupView(true);
          });
      });
  };
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_waste_collection_status = () => {
    axios
      .get(`${API_URL}api/staff/wastecollectstatus`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var collection_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.collect_status_name,
              value: el._id,
            };
          });
        setWasteCollectionStatus([
          {
            options: collection_data,
          },
        ]);
      });
  };

  const wasteCollectionItemDetails = {
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: "Item Name",
        field: "offline_clt_waste_item",
      },
      {
        label: "Bags",
        field: "waste_item_clt_bag",
      },
      {
        label: "Weight",
        field: "waste_item_clt_weight",
      },
      {
        label: "Price",
        field: "waste_item_clt_price",
      },
    ],
    rows: wasteCollectionitemDetails,
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "ID",
        field: "unique_ID",
        sort: "asc",
        width: 200,
      },
      {
        label: "QR Code",
        field: "offline_clt_qrcode",
        width: 200,
      },
      {
        label: "Customer",
        field: "customer_name",
        width: 200,
      },

      {
        label: "Collect Status",
        field: "status_name",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff_name",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        width: 200,
      },
    ],
    rows: details,
  };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let status_id = searchData?.status_id ? searchData.status_id : "";

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

    // handleTableData(date1, date2, staff_id, status_id);
  };

  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null)
    setSelectedWasteCollectionStatus(null)
    setSelectedStaff(null);
    handleTableData();
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSearchData({
          ...searchData,
          ward_id: selected.value,

        });
        break;
      case "staff":
        setSelectedStaff(selected)
        setSearchData({
          ...searchData,
          staff_id: selected.value,
        });
        //handleTableData()
        break;
      case "status":
        setSelectedWasteCollectionStatus(selected)
        setSearchData({
          ...searchData,
          status_id: selected.value,
        });


        //handleTableData()
        break;

      default:
        break;
    }
  };
  const handleSearch = () => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let status_id = searchData?.status_id ? searchData.status_id : "";
    handleTableData(date1, date2, ward_id, staff_id, status_id);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Offline Waste Collection" />
          <Row>
            <Col className="col-12">
              {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {grandTotal}</h5>
              </Row> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          name="staff_id"
                          value={selectedStaff}
                          onChange={(selected) => {
                            handleSelectChange(selected, "staff");
                          }}
                          options={staffOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Collect Status</Label>
                        <Select
                          name="status_id"
                          value={selectedWasteCollectionStatus}
                          onChange={(selected) => {
                            handleSelectChange(selected, "status");
                          }}
                          options={wasteCollectionStatus}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "20px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="primary"
                          type="submit"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "20px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="wastecollectionId"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Waste Collection Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table id="popUpTable" className="table table-bordered dataTable">
              <tr>
                <td style={{ padding: "10px" }}>Date :</td>
                <td>{popupData?.date}</td>
                <td>Time :</td>
                <td style={{ textAlign: "left" }}>{popupData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Customer :</td>
                <td>{popupData?.customer_name}</td>
                <td>QR Code :</td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.offline_clt_qrcode}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Collect Status :</td>
                <td>{popupData?.status_name}</td>
                <td>Sync Date :</td>
                <td style={{ textAlign: "left" }}>{popupData?.syncDate}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Sync Time :</td>
                <td>{popupData?.syncTime}</td>
                <td>Staff:</td>
                <td style={{ textAlign: "left" }}>{popupData?.staff_name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>ID :</td>
                <td>{popupData?.unique_ID}</td>
                <td>Collection ID:</td>
                <td style={{ textAlign: "left" }}>{popupData?.collect_unique_id}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Item :</td>
                <td>{popupData?.offline_clt_item_name}</td>
                <td>Bags:</td>
                <td style={{ textAlign: "left" }}>{popupData?.bags}</td>
              </tr>
            </table>

            {wasteCollectionitemDetails && wasteCollectionitemDetails.length > 0 ? (
              <Row className="mt-3 mb-2">
                <h6
                  className="modal-title mt-0"
                  style={{ textAlign: "center" }}
                >
                  Waste Collection Item Details
                </h6>
              </Row>
            ) : null}
            {wasteCollectionitemDetails && wasteCollectionitemDetails.length > 0 ? (
              <Row>
                <MDBDataTable
                  id="wasteCollectionItemDetailsId"
                  responsive
                  bordered
                  data={wasteCollectionItemDetails}
                  searching={false}
                  info={false}
                  paging={false}
                  disableRetreatAfterSorting={true}
                />
              </Row>
            ) : null}
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default WasteCollection;
