import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./complaintcategory.scss";


const AddClass = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [categoryIdTobeUpdated, setCategoryIdTobeUpdated] = useState(null);
  const [categoryIdTobeDeleted, setCategoryIdTobeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    handleTableData();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
//   const preUpdateCategory = (item) => {
   
 
//     if (item?.category_name) {
       
//       setMaster({
//         category_name: item.category_name,
//         _id: item._id,
//       });
//       setCategoryIdTobeUpdated(item._id);
//     }
//   };

  let preUpdateCategory = (item) => {
   
    setCategoryIdTobeUpdated(item._id);
    axios
    .get(`${API_URL}complaintcategory/view/?category_id=` + item._id, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res) => {
      let element = res.data.data;
   

    
      
     
      setMaster(element);
    
    });
  };
  const handleTableData = () => {
    axios
      .get(`${API_URL}complaintcategory/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.category_name=item?.category_name;
            if (item?.update_date)
              item.date = moment(item?.update_date).format(
                "DD-MM-YYYY"
              );
            else if (item?.create_date)
              item.date = moment(item?.create_date).format("DD-MM-YYYY");
            else item.date = "";

            if (item?.update_time)
              item.time = moment(item?.update_time, "hhmmss").format(
                "hh:mm a"
              );
            else if (item?.create_time)
              item.time = moment(item?.create_time, "hhmmss").format(
                "hh:mm a"
              );
            else item.time = "";
            if (item?.updatedBy) {
              let updatedBy = item.updatedBy || {};
              if (updatedBy?.lastName)
                item.staff = updatedBy?.firstName + " " + updatedBy?.lastName;
              else item.staff = updatedBy?.firstName;
            } else {
            let createdBy = item.createdBy || {};
              if (createdBy?.lastName)
                item.staff = createdBy?.firstName + " " + createdBy?.lastName;
              else item.staff = createdBy?.firstName;
            }
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="uil-edit-alt"
                  style={{
                    fontSize: "1.2em",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                  
                    toTop();
                    preUpdateCategory(item);
                  }}
                ></i>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  onClick={() => {
                    setCategoryIdTobeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },

      {
        label: "Complaint Category",
        field: "category_name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {
    if (categoryIdTobeUpdated) {
      axios
        .put(`${API_URL}complaintcategory/update`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Complaint Category updated successfully");
            setMaster({});
            handleTableData();
            setCategoryIdTobeUpdated(null);
          } else {
            toastr.error("Failed to update Complaint Category");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}complaintcategory/`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Complaint Category added successfully");
            setMaster({});
            handleTableData();
          } else {
            toastr.error("Failed to add Complaint Category");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  return (
    <>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}complaintcategory` + "/" + categoryIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Complaint Category deleted successfully");
                  if (master && master._id === categoryIdTobeDeleted) {
                    formRef.current.reset();
                    setMaster({});
                    setCategoryIdTobeUpdated(null);
                  }
                  setCategoryIdTobeUpdated(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete class");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Complaint Category" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleValidSubmit();
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Complaint Category</Label>
                          <AvField
                            name="category_name"
                            placeholder="Complaint Category"
                            type="text"
                            errorMessage="Enter Complaint Category"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.category_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          {categoryIdTobeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="manageClassTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddClass;
