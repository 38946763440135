import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  // Modal,
  // Table,
} from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getDate,
  getFirstday,
  getArraySumByKey,
  formatMoney,
  isSuperAdmin,
} from "../../../helpers/globalFunctions";
import "./daywiseReport.scss";
import { getCustomerType } from "../../../store/actions";

const DaywiseReport = (props) => {
  const SuperAdmin = isSuperAdmin();
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [details, setDetails] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [customerType, setCustomerType] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const { customerTypes } = useSelector((state) => state.customers);
  useEffect(() => {
    handleTableData();
    fetch_all_staff(currentLocalbody);
    fetch_all_wards(currentLocalbody);
    // dispatch(getCustomerType());
  }, [currentLocalbody]);

  useEffect(() => {
    if (selectedCategory != null) {
      dispatch(getCustomerType(selectedCategory.value));
    }
  }, [selectedCategory]);

  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);

  function handleTableData(
    from_date = "",
    to_date = "",
    ward_id = "",
    group_id = "",
    staff_id = "",
    category_type = "",
    category_id = "",
    limit = 30
  ) {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      userId = obj._id;
    }
    var url =
      `${API_URL}reports/daywise_report?localbody_id=` +
      currentLocalbody +
      "&user_id=" +
      userId;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&staff_id=" +
      staff_id +
      "&category_type=" +
      category_type +
      "&category_id=" +
      category_id +
      "&limit=" +
      limit;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let dataToBeExported = [];
        let vistHouse = 0;
        let paidHouse = 0;
        let houseAmt = 0;
        let vistShop = 0;
        let paidShop = 0;
        let ShopAmt = 0;
        let totalAmount = 0;
        let totalWeight = 0;
        result &&
          result.map((item, index) => {
            let exportItem = {};
            item.id = index + 1;

            item.date = moment(item?._id).format("DD-MM-YYYY");
            item.total_weight = item.totalweight.toFixed(2);

            //export data
            exportItem.id = item.id;
            exportItem.Date = item.date;
            exportItem.Visited_Houses = item.visited_houses;
            exportItem.Paid_Houses = item.paid_houses;
            exportItem.House_Amt = item.house_amt;
            exportItem.Visited_Shops = item.visited_shops;
            exportItem.Paid_Shops = item.paid_shops;
            exportItem.Shop_Amt = item.shop_amt;
            exportItem.Total = item.total_amount;
            exportItem.Weight = item.total_weight;

            dataToBeExported.push(exportItem);

            // calculate total  summary
            totalAmount += item?.total_amount;
            vistHouse += item?.visited_houses;
            paidHouse += item?.paid_houses;
            houseAmt += item?.house_amt;
            vistShop += item?.visited_shops;
            paidShop += item?.paid_shops;
            ShopAmt += item?.shop_amt;
            totalWeight += parseFloat(item?.total_weight);
          });
        // add total summary row
        if (result.length !== 0) {
          result.push({
            id: "",
            date: <b>Total Summary</b>,
            visited_houses: <b>{vistHouse}</b>,
            paid_houses: <b>{paidHouse}</b>,
            house_amt: <b>{houseAmt}</b>,
            visited_shops: <b>{vistShop}</b>,
            paid_shops: <b>{paidShop}</b>,
            shop_amt: <b>{ShopAmt}</b>,
            total_amount: <b>{totalAmount}</b>,
            total_weight: <b>{totalWeight.toFixed(2)}</b>,
          });
        }
        // let test = getArraySumByKey(result,"total_amount")
        // result.push({
        //   id:"",
        //   date:"",
        //   visited_houses:"",
        //   paid_houses:"",
        //   house_amt:"",
        //   visited_shops:"",
        //   paid_shops:"",
        //   shop_amt:"Total",
        //   total_amount:test,
        //   total_weight:""
        // })
        setDetails(result);
        let total = getArraySumByKey(result, "total_amount");
        setTotal(total);
        setDataToBeExported(dataToBeExported);
      });
  }
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Visited Houses",
        field: "visited_houses",
        sort: "asc",
        width: 200,
      },
      {
        label: "Paid Houses",
        field: "paid_houses",
        sort: "asc",
        width: 200,
      },
      {
        label: "House ₹",
        field: "house_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Visited Shops",
        field: "visited_shops",
        sort: "asc",
        width: 200,
      },
      {
        label: "Paid Shops",
        field: "paid_shops",
        sort: "asc",
        width: 200,
      },
      {
        label: "Shop ₹",
        field: "shop_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total ₹",
        field: "total_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Weight(Kg)",
        field: "total_weight",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  //   const fetch_all_ward = () => {
  //     var url = `${API_URL}ward/options`;
  //     axios
  //       .get(url, {
  //         headers: {
  //           "x-access-token": accessToken,
  //         },
  //       })
  //       .then((res) => {
  //         var ward_data =
  //           res.data.data &&
  //           res.data.data.map((value) => {
  //             return {
  //               label: value.customer_name,
  //               value: value.customer_id,
  //             };
  //           });

  //         setwardOptions([
  //           {
  //             options: ward_data,
  //           },
  //         ]);
  //       });
  //   };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let category_id = searchData?.category_id ? searchData.category_id : "";

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

    // handleTableData(date1, date2, ward_id, group_id, staff_id, category_id);
  };

  const handleSelectChange = (selected, name) => {
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let date1 = searchData?.from_date ? searchData.from_date : "";
    // let date2 = searchData?.to_date ? searchData.to_date : "";
    // let category_id = searchData?.category_id ? searchData.category_id : "";

    switch (name) {
      case "ward":
        setSelectedWard(selected);

        setSelectedGroup(null);

        // let wardArray = [];
        // selected &&
        //   selected.forEach((element) => {
        //     wardArray.push(element?.value);
        //   });

        // if (wardArray && wardArray.length === 0) {
        //   setSearchData({
        //     ...searchData,
        //     ["ward_id"]: "",
        //   });
        //   handleTableData(date1, date2, "", "", staff_id);
        // } else {
        //   setSearchData({
        //     ...searchData,
        //     ["ward_id"]: [...wardArray],
        //   });
        //   handleTableData(date1, date2, [...wardArray], "", staff_id);
        // }

        // let data = {
        //   ward_id: wardArray,
        // };
        // axios
        //   .post(`${API_URL}api/staff/ward_groups`, data, {
        //     headers: {
        //       "x-access-token": accessToken,
        //     },
        //   })
        //   .then((res) => {
        //     if (res.data && res.data.success === true) {
        //       var group_data =
        //         res.data.data &&
        //         res.data.data.map((el) => {
        //           return {
        //             label: el.group_name,
        //             value: el._id,
        //           };
        //         });
        //     } else {
        //       group_data = [];
        //     }

        //     setGroupOptions([
        //       {
        //         options: group_data,
        //       },
        //     ]);
        //   });
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
          ["group_id"]: "",
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   selected.value,
        //   "",
        //   staff_id,
        //   category_id
        // );
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
              currentLocalbody +
              "&ward_id=" +
              selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });

        break;
      case "group":
        setSelectedGroup(selected);
        // let groupArray = [];
        // selected &&
        //   selected.forEach((element) => {
        //     groupArray.push(element?.value);
        //   });

        // if (groupArray && groupArray.length === 0) {
        //   setSearchData({
        //     ...searchData,
        //     ["group_id"]: "",
        //   });
        //   handleTableData(date1, date2,ward_id, "", staff_id);
        // } else {
        //   setSearchData({
        //     ...searchData,
        //     ["group_id"]: [...groupArray],
        //   });
        //   handleTableData(date1, date2, ward_id,[...groupArray], staff_id);
        // }
        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   selected.value,
        //   staff_id,
        //   category_id
        // );
        break;
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   group_id,
        //   selected.value,
        //   category_id
        // );
        break;
      case "category_type":
        setSelectedCategory(selected);
        setSelectedCustomerType(null);
        setSearchData({
          ...searchData,
          ["category_type"]: selected.value,
          ["category_id"]: "",
        });
        break;
      case "customerType":
        setSelectedCustomerType(selected);
        setSearchData({
          ...searchData,
          category_id: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   group_id,
        //   staff_id,
        //   selected.value
        // );
        break;
      default:
        break;
    }
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedStaff(null);
    setSelectedCategory(null);
    setSelectedCustomerType(null);
    handleTableData();
  };
  const handleSearch = () => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let category_id = searchData?.category_id ? searchData.category_id : "";
    let category_type = searchData?.category_type
      ? searchData.category_type
      : "";
    handleTableData(
      date1,
      date2,
      ward_id,
      group_id,
      staff_id,
      category_type,
      category_id
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Day wise Report" />
          <Row>
            <Col className="col-12">
              {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {grandTotal}</h5>
              </Row> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          //isMulti
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Group</Label>
                        <Select
                          //isMulti
                          name="group_id"
                          value={selectedGroup}
                          onChange={(selected) => {
                            handleSelectChange(selected, "group");
                          }}
                          options={groupOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          name="staff_id"
                          value={selectedStaff}
                          onChange={(selected) => {
                            handleSelectChange(selected, "staff");
                          }}
                          options={staffOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>Category Type</Label>
                        <Select
                          name="category_type"
                          value={selectedCategory}
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "Residential", value: 1 },
                            { label: "Commercial", value: 2 },
                          ]}
                          onChange={(selected) => {
                            handleSelectChange(selected, "category_type");
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>Customer Type</Label>
                        <Select
                          name="category_id"
                          value={selectedCustomerType}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customerType");
                          }}
                          options={customerType}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div style={{ display: "flex" }}>
                        <div
                          className="mt-1 mb-3 mx-1"
                          style={{ paddingTop: "24px" }}
                        >
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </Button>
                        </div>
                        {isSuperAdmin() ? (
                          <div
                            className="mt-1 mb-3 mx-1"
                            style={{ paddingTop: "24px" }}
                          >
                            <Button color="success" type="submit">
                              {/* <i className="mdi mdi-microsoft-excel"></i> */}
                              <CSVLink
                                data={dataToBeExported}
                                filename={
                                  "Day_wise_report_" +
                                  getDate(new Date()) +
                                  ".xls"
                                }  
                                style={{ color: "white" }}
                              >
                                Export
                              </CSVLink>
                            </Button>
                          </div>
                        ) : null}

                        <div
                          className="mt-1 mb-3 mx-1"
                          style={{ paddingTop: "24px" }}
                        >
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="daywiseReportTableId"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                  {/* <span className="grand_total_label">Total {""}</span>
                  <span className="grand_total_value">
                    {" "}
                    {formatMoney(total)}
                  </span> */}
                  {/* <span className="grand_total_value">Total  {formatMoney(total)}</span> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DaywiseReport;
