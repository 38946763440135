import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
//import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getDate,
    getFirstday,
    getLocalbody,
} from "../../helpers/globalFunctions";
import moment from "moment";
import "./wasterequest.scss";
import { CSVLink } from "react-csv";
import Pagination from "@mui/material/Pagination";
const NewWasteRequest = (props) => {
    const [isModal, setisModal] = useState(false);
    const [modalData, setModalData] = useState("");
    const [wardOptions, setWardOptions] = useState([]);
    const [selectedWard, setSelectedWard] = useState(null);
    const [groupOptions, setGroupOptions] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [searchData, setSearchData] = useState({
        from_date: getFirstday(new Date()),
        to_date: getDate(new Date()),
    });
    const [details, setDetails] = useState([]);
    // const [wasteItems, setWasteItems] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [dataToBeExported, setDataToBeExported] = useState([]);
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    /** pagination */
    //   const [searchValue, setSearchValue] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPageCount, setPerPageCount] = useState(20);
    const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
        currentPage * PerPageCount
    );

    const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
        indexOfLastTransfer - PerPageCount
    );
    const paginate = (event, value) => {
        setCurrentPage(value);
        const indexOfLastTransfer = value * PerPageCount;
        const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
        setindexOfFirstTransfer(indexOfFirstTransfer);
        setindexOfLastTransfer(indexOfLastTransfer);

        handleTableData(searchData?.from_date, searchData?.to_date, searchData?.ward_id, searchData?.group_id, searchData?.customer_id, indexOfFirstTransfer, indexOfLastTransfer);
    };
    const resetPaginate = () => {
        setCurrentPage(1);
        const indexOfLastTransfer = PerPageCount;
        const indexOfFirstTransfer = 0;
        setindexOfFirstTransfer(indexOfFirstTransfer);
        setindexOfLastTransfer(indexOfLastTransfer);
    }

    useEffect(() => {
        if (details.length == 0 && indexOfFirstTransfer != 0) {
            resetPaginate();
            handleTableData(searchData?.from_date, searchData?.to_date, searchData?.ward_id, searchData?.group_id, searchData?.customer_id);
        }

    }, [details])
    /** end pagination */
    useEffect(() => {
        handleTableData();
        fetch_all_wards(currentLocalbody);
        fetch_all_customers(currentLocalbody);
    }, [currentLocalbody]);

    function fetch_all_customers(localbody = currentLocalbody, ward = "", group = "") {
        axios
            .get(`${API_URL}feedback/customers?localbody_id=` + localbody + "&ward=" + ward + "&group=" + group, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el.cust_name,
                            value: el._id,
                        };
                    });

                setStaffOptions([
                    {
                        options: data,
                    },
                ]);
            });
    }
    const updatePackageStatus = (item) => {
        axios
            .put(`${API_URL}wasterequest/status/` + item._id, item, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                if (res.data.success === true) {
                    handleTableData();
                }
                // } else {
                //   toastr.error(res.data.message);
                //   return;
                // }
            });
    };

    function handleTableData(
        from_date = "",
        to_date = "",
        ward_id = "",
        group_id = "",
        customer_id = "",
        start = 0,
        end = PerPageCount
    ) {
        let userId = "";
        if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser");
            const obj = JSON.parse(data);
            userId = obj._id;
        }
        var url =
            `${API_URL}api/staff/all-waste-requests?localbody_id=` +
            currentLocalbody +
            "&user_id=" +
            userId;
        if (from_date === "" && to_date === "") {
            from_date = from_date ? from_date : getFirstday(new Date());
            to_date = to_date ? to_date : getDate(new Date());
        }
        url =
            url +
            "&from_date=" +
            from_date +
            "&to_date=" +
            to_date +
            "&ward=" +
            ward_id +
            "&group=" +
            group_id +
            "&customer_id=" +
            customer_id + "&start=" + start + "&end=" + end;
        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                let result = res.data.data;
                setTotalPages(res.data.limit);
                let dataToBeExported = [];
                let id = start;
                result &&
                    result.map((item, index) => {
                        let exportItem = {};
                        id++;
                        item.id = id;
                        item.date = moment(item.date).format("DD-MM-YYYY");
                        item.ward_name = item.ward_id?.ward_name;
                        item.cust_name = item?.customer_id?.cust_name;


                        item.group_name = (

                            item?.group_id &&
                            item?.group_id.map((element, index) => {

                                return element?.group_name

                            })

                        );

                        exportItem.Date = item?.date;
                        // exportItem.Waste = item?.waste_items_id?.waste_items_name;
                        exportItem.Waste = (

                            item?.waste_items &&
                            item?.waste_items.map((element, index) => {

                                return element?.items_name

                            }));

                        exportItem.Weight = item?.total_weight;
                        exportItem.Bag = item?.total_bag;
                        exportItem.Ward = item?.ward_id?.ward_name;
                        //exportItem.Group =  item?.group_id?.group_name;
                        exportItem.Group = (

                            item?.group_id &&
                            item?.group_id.map((element, index) => {

                                return element?.group_name

                            })

                        );
                        exportItem.Customer = item?.customer_id?.cust_name;

                        if (item.status === 0) {
                            item.followup_status = (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        color="success"
                                        size="sm"
                                    // onClick={() => {
                                    //   updatePackageStatus(item);
                                    // }}
                                    >
                                        New
                                    </Button>
                                </div>
                            );
                        } else {
                            item.followup_status = (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        color="danger"
                                        size="sm"
                                    // onClick={() => {
                                    //   updatePackageStatus(item);
                                    // }}
                                    >
                                        Collected
                                    </Button>
                                </div>
                            );
                        }

                        //       el?.bag + "/" + el?.weight;
                        //   });

                        item.items &&
                            item.items.map((element) => {
                                exportItem[element?.name + "(Bag/Weight)"] =
                                    element?.bag + "/" + element?.weight;
                            });




                        dataToBeExported.push(exportItem);
                        item.action = (
                            <i className="fas fa-eye"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setisModal(true);
                                    setModalData(item);
                                }}
                            ></i>
                        )
                    });
                setDetails(result);
                setDataToBeExported(dataToBeExported);
            });
    }
    function fetch_all_wards(localbody) {
        axios
            .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var ward_data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el.ward_name,
                            value: el._id,
                        };
                    });

                setWardOptions([
                    {
                        options: ward_data,
                    },
                ]);
            });
    }
    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 400,
            },
            {
                label: "Customer",
                field: "cust_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Group",
                field: "group_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Ward",
                field: "ward_name",
                sort: "asc",
                width: 400,
            },
            // {
            //   label: "Group",
            //   field: "group_name",
            //   sort: "asc",
            //   width: 400,
            // },

            {
                label: "Bag",
                field: "total_bag",
                sort: "asc",
                //width: 50,
            },
            {
                label: "Kg",
                field: "total_weight",
                sort: "asc",
                //width: 50,
            },
            {
                label: "Amount",
                field: "total_amount",
                sort: "asc",
                width: 200,
            },
            {
                label: "Status",
                field: "followup_status",
                sort: "asc",
                //width: 50,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                //width: 50,
            },
            // ...wasteItems,
        ],
        rows: details,
    };
    let handleDate = (e) => {
        var date1 = $("#from_date").val();
        var date2 = $("#to_date").val();

        // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
        // let group_id = searchData?.group_id ? searchData.group_id : "";
        // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
        // let category_id = searchData?.category_id ? searchData.category_id : "";
        let toDate = moment(date1);
        let fromDate = moment(date2);
        let result = fromDate.diff(toDate, "days");

        if (result + 1 <= 0) {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
                ["to_date"]: "",
            });
            date2 = "";
        } else {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
            });
        }
    };
    const handleSelectChange = (selected, name) => {
        switch (name) {
            case "ward":
                setSelectedWard(selected);
                setSelectedStaff(null);
                setSearchData({
                    ...searchData,
                    ["ward_id"]: selected.value,
                    group_id: "",
                    customer_id: ""
                });
                fetch_all_customers(currentLocalbody, selected.value)
                setSelectedGroup(null);
                axios
                    .get(
                        `${API_URL}api/staff/groups?localbody_id=` +
                        currentLocalbody +
                        "&ward_id=" +
                        selected.value,
                        {
                            headers: {
                                "x-access-token": accessToken,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data && res.data.success === true) {
                            var group_data =
                                res.data.data &&
                                res.data.data.map((el) => {
                                    return {
                                        label: el.group_name,
                                        value: el._id,
                                    };
                                });
                        } else {
                            group_data = [];
                        }

                        setGroupOptions([
                            {
                                options: group_data,
                            },
                        ]);
                    });
                // handleTableData(
                //   date1,
                //   date2,
                //   selected.value,
                //   "",
                //   staff_id,
                //   category_id
                // );
                break;
            case "group":
                setSelectedGroup(selected);
                setSelectedStaff(null);
                setSearchData({
                    ...searchData,
                    ["group_id"]: selected.value,
                    "customer_id": ""
                });
                fetch_all_customers(currentLocalbody, searchData?.ward_id, selected.value)
                // handleTableData(
                //   date1,
                //   date2,
                //   ward_id,
                //   selected.value,
                //   staff_id,
                //   category_id
                // );
                break;
            case "customer":
                setSelectedStaff(selected);
                setSearchData({
                    ...searchData,
                    ["customer_id"]: selected.value,
                });
                // handleTableData(
                //   date1,
                //   date2,
                //   ward_id,
                //   group_id,
                //   selected.value,
                //   category_id
                // );
                break;

            default:
                break;
        }
    };
    const reset = () => {
        setSearchData({
            from_date: getFirstday(new Date()),
            to_date: getDate(new Date()),
        });
        setSelectedWard(null);
        setSelectedGroup(null);
        setSelectedStaff(null);
        fetch_all_customers(currentLocalbody);
        setGroupOptions([]);
        handleTableData();
    };
    const handleSearch = () => {
        resetPaginate();
        let ward_id = searchData?.ward_id ? searchData.ward_id : "";
        let group_id = searchData?.group_id ? searchData.group_id : "";
        let customer_id = searchData?.customer_id ? searchData.customer_id : "";
        let date1 = searchData?.from_date ? searchData.from_date : "";
        let date2 = searchData?.to_date ? searchData.to_date : "";
        let category_id = searchData?.category_id ? searchData.category_id : "";
        handleTableData(date1, date2, ward_id, group_id, customer_id, category_id);
    };
    return (
        <React.Fragment>
            <Modal
                isOpen={isModal}
                toggle={() => setisModal(!isModal)}
                size="lg"
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Waste Request Details </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setisModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <table className="table table-borderless dataTable">
                        <tbody>
                            <tr>
                                <td><b>Date</b></td><td>{modalData?.date}</td>
                                <td><b>Time</b></td><td>{modalData?.time}</td>
                                <td><b>Customer</b></td><td>{modalData?.cust_name}</td>
                            </tr>
                            <tr>
                                <td><b>Group</b></td><td>{modalData?.group_name}</td>
                                <td><b>Ward</b></td><td>{modalData?.ward_name}</td>
                                <td><b>Status</b></td><td>{modalData?.followup_status}</td>
                            </tr>
                        </tbody>

                    </table>
                    <table className="table table-bordered dataTable">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Waste</th>
                                <th>Rate</th>
                                <th>Bag</th>
                                <th>Weight</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                modalData?.waste_items?.map((dt, ind) => {
                                    return (
                                        <tr>
                                            <td>{ind + 1}</td>
                                            <td>{dt?.item_name}</td>
                                            <td style={{ textAlign: "right" }}>{parseFloat(dt?.rate ? dt.rate : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: "right" }}>{parseFloat(dt?.bag ? dt.bag : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: "right" }}>{parseFloat(dt?.weight ? dt.weight : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: "right" }}>{parseFloat(dt?.amount ? dt.amount : 0).toFixed(2)}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan={3}><b>Total</b></td>
                                <td style={{ textAlign: "right" }}><b>{parseFloat(modalData?.total_bag ? modalData.total_bag : 0).toFixed(2)}</b></td>
                                <td style={{ textAlign: "right" }}><b>{parseFloat(modalData?.total_weight ? modalData.total_weight : 0).toFixed(2)}</b></td>
                                <td style={{ textAlign: "right" }}><b>{parseFloat(modalData?.total_amount ? modalData.total_amount : 0).toFixed(2)}</b></td>
                            </tr>
                        </tbody>

                    </table>
                    <Row>
                        {
                            modalData?.images?.map((dt, ind) => {
                                return (
                                    <Col md="2" className="mt-2">
                                        <img src={`${API_URL}uploads/waste_request/${dt}`} width={"100%"} />
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </div>
            </Modal>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Waste Request" />
                    <Row>
                        <Col className="col-12">
                            {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {"19.00"}</h5>
              </Row> */}
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label htmlFor="validationCustom05">From</Label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    id="from_date"
                                                    name="from_date"
                                                    value={searchData?.from_date}
                                                    onChange={handleDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label htmlFor="validationCustom05">To</Label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    id="to_date"
                                                    name="to_date"
                                                    value={searchData?.to_date}
                                                    min={searchData?.from_date}
                                                    onChange={handleDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Ward</Label>
                                                <Select
                                                    name="ward_id"
                                                    value={selectedWard}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "ward");
                                                    }}
                                                    options={wardOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Group</Label>
                                                <Select
                                                    name="group_id"
                                                    value={selectedGroup}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "group");
                                                    }}
                                                    options={groupOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Customer</Label>
                                                <Select
                                                    name="customer_id"
                                                    value={selectedStaff}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "customer");
                                                    }}
                                                    options={staffOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2" >
                                            <div style={{ display: "flex" }}>
                                                <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "25px" }}>
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => handleSearch()}
                                                    >
                                                        Search
                                                    </Button>
                                                </div>

                                                <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "25px" }}>
                                                    <Button color="success" type="submit">
                                                        <CSVLink
                                                            data={dataToBeExported}
                                                            filename={
                                                                "Waste_request_" +
                                                                getDate(new Date()) +
                                                                ".xls"
                                                            }
                                                            style={{ color: "white" }}
                                                        >
                                                            Export
                                                        </CSVLink>
                                                    </Button>
                                                </div>

                                                <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "25px" }}>
                                                    <Button
                                                        color="danger"
                                                        type="reset"
                                                        onClick={() => reset()}
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <MDBDataTable
                                        id="waterequestTableId"
                                        responsive
                                        bordered
                                        data={data}
                                        searching={false}
                                        info={false}
                                        disableRetreatAfterSorting={true}
                                        paging={false}
                                        //onSearch={(value) => { handleSearch(value) }}
                                        //onSearch={debounce(handleSearch, 500)}
                                        entries={PerPageCount}
                                    />
                                    {totalPages > PerPageCount && (
                                        <div className="pagination-div">
                                            {/* Display the page details */}
                                            <div className="page-details">
                                                Showing {indexOfFirstTransfer + 1} to {indexOfFirstTransfer + details.length} of {totalPages} entries
                                            </div>
                                            <Pagination
                                                color="standard"
                                                shape="rounded"
                                                defaultPage={1}
                                                count={Math.ceil(totalPages / PerPageCount)}
                                                page={currentPage}
                                                onChange={paginate}
                                                info={true}
                                                size="large"
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};
export default NewWasteRequest;
