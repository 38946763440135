import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
// import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { apiError } from "../../../store/actions";
import $ from "jquery";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import { CSVLink } from "react-csv";
import "./helperCollection.scss";

const HelperCollection = (props) => {
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [wasteOptions, setWasteOptions] = useState([]);
  const [selectedWaste, setSelectedWaste] = useState(null);
  const [details, setDetails] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [wasteItemDetails, setWasteItemDetails] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
    fetch_all_staff(currentLocalbody);
    fetch_all_wards(currentLocalbody);
    fetch_all_wasteitems();
  }, [currentLocalbody]);

  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  const fetch_all_wasteitems = () => {
    axios
      .get(`${API_URL}wasteItems/list/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var waste_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.waste_items_name,
              value: el._id,
            };
          });
        setWasteOptions([
          {
            options: waste_data,
          },
        ]);
      });
  };
  function handleTableData(
    from_date = "",
    to_date = "",
    ward_id = "",
    group_id = "",
    staff_id = "",
    waste_item_id = ""
  ) {
    var url =
      `${API_URL}waste/helper_collection?localbody_id=` + currentLocalbody;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&staff_id=" +
      staff_id +
      "&waste_item_id=" +
      waste_item_id;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let dataToBeExported = [];
        result &&
          result.map((item, index) => {
            let exportItem = {};

            if (item?.waste_clt_delete_status === 1) {
              item.id = <span className="text-danger">{index + 1}</span>;
              if (item?.staff_lastname)
                item.staff = (
                  <span className="text-danger">
                    {item.staff_firstname + " " + item.staff_lastname}
                  </span>
                );
              else
                item.staff = (
                  <span className="text-danger">{item.staff_firstname}</span>
                );
              if (item.waste_clt_total_amount === 0) item.amount = null;
              else
                item.amount = (
                  <span className="text-danger">
                    {item.waste_clt_total_amount}
                  </span>
                );

              item.waste_clt_date = (
                <span className="text-danger">{item?.waste_clt_date}</span>
              );
              item.cust_id = (
                <span className="text-danger">{item?.cust_id}</span>
              );
              item.cust_name = (
                <span className="text-danger">{item?.cust_name}</span>
              );
              item.category = (
                <span className="text-danger">{item?.category}</span>
              );
              item.group_name = (
                <span className="text-danger">{item?.group_name}</span>
              );
              item.ward_name = (
                <span className="text-danger">{item?.ward_name}</span>
              );
              item.status_name = (
                <span className="text-danger">{item?.status_name}</span>
              );
            } else {
              item.id = index + 1;
              if (item?.staff_lastname)
                item.staff = item.staff_firstname + " " + item.staff_lastname;
              else item.staff = item.staff_firstname;
              if (item.waste_clt_total_amount === 0) item.amount = null;
              else item.amount = item.waste_clt_total_amount;
            }

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.1em",
                  }}
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                ></i>
              </div>
            );

            //setting export data
            exportItem.id = item.id;
            exportItem.Date = item.waste_clt_date;
            exportItem.Customer_ID = item.cust_id;
            exportItem.Name = item.cust_name;
            exportItem.Category = item?.category;
            exportItem.Group = item.group_name;
            exportItem.Ward = item.ward_name;
            exportItem.Amount = item.waste_clt_total_amount;
            exportItem.Address1 = item?.cust_address;
            exportItem.Address2 = item?.cust_address1;
            exportItem.Staff = item.staff;
            exportItem.Status = item.status_name;

            dataToBeExported.push(exportItem);
          });
        setDetails(result);
        setDataToBeExported(dataToBeExported);
      });
  }
  const handleClickOpenForm = (row) => {
    let data = {
      collection_id: row._id,
    };
    axios
      .post(`${API_URL}waste/helper_collection_view`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item) => {
            if (item?.staff_lastname)
              item.staff = item.staff_firstname + " " + item.staff_lastname;
            else item.staff = item.staff_firstname;
            item.time = moment(item.waste_clt_time, "HHmmss").format("hh:mm a");

            let itemInfo = item.items_info;
            itemInfo &&
              itemInfo.map((item, index) => {
                item.id = index + 1;
              });
            setWasteItemDetails(itemInfo);
            setPopupData(item);
          });

        setPopupView(true);
      });
  };
  useEffect(() => { }, []);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "waste_clt_date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "cust_id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "cust_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 200,
      },
      {
        label: "Group",
        field: "group_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ward",
        field: "ward_name",
        sort: "asc",
        width: 200,
      },

      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  const wasteCollectingItemDetails = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Item Name",
        field: "waste_clt_item_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Price",
        field: "waste_clt_item_price",
        sort: "asc",
        width: 150,
      },
      {
        label: "Bags",
        field: "waste_clt_item_no_bag",
        sort: "asc",
        width: 150,
      },
      {
        label: "Weight",
        field: "waste_clt_item_no_kg",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total",
        field: "waste_clt_item_amt",
        sort: "asc",
        width: 150,
      },
    ],
    rows: wasteItemDetails,
  };
  const handleSelectChange = (selected, name) => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let waste_item_id = searchData?.waste_item_id
      ? searchData.waste_item_id
      : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
        });
        setSelectedGroup(null);
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
            currentLocalbody +
            "&ward_id=" +
            selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        handleTableData(
          date1,
          date2,
          selected.value,
          "",
          staff_id,
          waste_item_id
        );
        break;
      case "group":
        setSelectedGroup(selected);
        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          ward_id,
          selected.value,
          staff_id,
          waste_item_id
        );
        break;
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          ward_id,
          group_id,
          selected.value,
          waste_item_id
        );
        break;
      case "waste":
        setSelectedWaste(selected);
        setSearchData({
          ...searchData,
          ["waste_item_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          ward_id,
          group_id,
          staff_id,
          selected.value
        );
      default:
        break;
    }
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let waste_item_id = searchData?.waste_item_id
      ? searchData.waste_item_id
      : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

    handleTableData(date1, date2, ward_id, group_id, staff_id, waste_item_id);
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedStaff(null);
    setSelectedWaste(null);
    handleTableData();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Helper Collection" />
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="from_date"
                        name="from_date"
                        value={searchData?.from_date}
                        onChange={handleDate}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        value={searchData?.to_date}
                        onChange={handleDate}
                        min={searchData?.from_date}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Ward</Label>
                      <Select
                        name="ward_id"
                        value={selectedWard}
                        onChange={(selected) => {
                          handleSelectChange(selected, "ward");
                        }}
                        options={wardOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Group</Label>
                      <Select
                        name="group_id"
                        value={selectedGroup}
                        onChange={(selected) => {
                          handleSelectChange(selected, "group");
                        }}
                        options={groupOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Staff</Label>
                      <Select
                        name="staff_id"
                        value={selectedStaff}
                        onChange={(selected) => {
                          handleSelectChange(selected, "staff");
                        }}
                        options={staffOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Waste Items</Label>
                      <Select
                        name="waste_item_id"
                        value={selectedWaste}
                        onChange={(selected) => {
                          handleSelectChange(selected, "waste");
                        }}
                        options={wasteOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  {/* <Row> */}
                  <Col md="2">
                    <div style={{ display: "flex" }}>
                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Helper_collection_report_" +
                              getDate(new Date()) +
                              ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>

                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </Col>
                  {/* </Row> */}
                </Row>
                <MDBDataTable
                  id="helperCollectionId"
                  responsive
                  bordered
                  data={data}
                  searching={true}
                  info={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Helper Collection Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px" }}>Date :</td>
                <td>{popupData?.waste_clt_date}</td>
                <td>Time :</td>
                <td style={{ textAlign: "left" }}>{popupData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Customer ID :</td>
                <td>{popupData?.cust_id}</td>
                <td>Name :</td>
                <td style={{ textAlign: "left" }}>{popupData?.cust_name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Group :</td>
                <td>{popupData?.group_name}</td>
                <td>Ward :</td>
                <td style={{ textAlign: "left" }}>{popupData?.ward_name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Amount :</td>
                <td>{popupData?.waste_clt_total_amount}</td>
                <td>Staff :</td>
                <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{popupData?.status_name}</td>
                <td></td>
                <td style={{ textAlign: "left" }}>{ }</td>
              </tr>
            </table>
            {wasteItemDetails && wasteItemDetails.length > 0 ? (
              <Row className="mt-3 mb-2">
                <h6
                  className="modal-title mt-0"
                  style={{ textAlign: "center" }}
                >
                  Helper Collection Item Details
                </h6>
              </Row>
            ) : null}
            {wasteItemDetails && wasteItemDetails.length > 0 ? (
              <Row>
                <MDBDataTable
                  id="wasteCollectionItemDetailId"
                  responsive
                  bordered
                  data={wasteCollectingItemDetails}
                  searching={false}
                  info={false}
                  paging={false}
                  disableRetreatAfterSorting={true}
                //entries={10}
                />
              </Row>
            ) : null}
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(
  connect(mapStateToProps, { apiError })(HelperCollection)
);

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
