import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from "reactstrap"
import CountUp from 'react-countup';


const SalesAnalyticsChart = (props) => {
    //const dateList =props.dateList;
    const graphData = props.graphData;
    const receipt_data=[];
    const waste_data=[];
    const month=[];
    graphData&&graphData.map((value, key) => {
        month.push(value._id)
        receipt_data.push(value.receipt_count);
        waste_data.push(value.waste_count);
    }
    )
    const series = [{
        name: 'Receipt',
        type: 'area',
        data: receipt_data
    }, {
        name: 'Waste Collection',
        type: 'line',
        data: waste_data
    }]

    const options = {
        chart: {
            stacked: !1,
            toolbar: {
                show: !1
            }
        },
        stroke: {
            width: [2, 4],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '30%'
            }
        },
        colors: ['#dfe2e6', '#f1b44c'],

        fill: {
            opacity: [0.25, 1],
            gradient: {
                inverseColors: !1,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        markers: {
            size: 0
        },

        xaxis: {
            categories: month,
        },
        yaxis: {
            title: {
                text: 'Customers',
            },
        },
        tooltip: {
            shared: !0,
            intersect: !1,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " points";
                    }
                    return y;

                }
            }
        },
        grid: {
            borderColor: '#f1f1f1'
        }
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                   
                    <center><CardTitle className="mb-4 h4">Waste Collection & Payment Collection</CardTitle></center>
                    {/* <div className="mt-1">
                        <ul className="list-inline main-chart mb-0">
                            <li className="list-inline-item chart-border-left me-0 border-0">
                                <h3 className="text-primary"><span><CountUp end={2371} separator="," prefix="₹" /></span><span className="text-muted d-inline-block font-size-15 ms-3">Receipt</span></h3>
                            </li>{" "}
                            <li className="list-inline-item chart-border-left me-0">
                            <h3 ><span><CountUp end={2371} separator="," prefix="₹" /></span><span className="text-muted d-inline-block font-size-15 ms-3">Waste Collection</span></h3>
                            
                            </li>{" "}
                        </ul>
                    </div> */}

                    <div className="mt-3">
                        <ReactApexChart
                            options={options}
                            series={series}
                            height="339"
                            type="line"
                            className="apex-charts"
                        />
                    </div>
                </CardBody>
            </Card>

        </React.Fragment>
    )
}

export default SalesAnalyticsChart