import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { getLocalbody } from "../../helpers/globalFunctions";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";


function DashboardChart() {

    const [master, setmaster] = useState({});
    const [followup, setFollowup] = useState({});
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

    const API_URL = process.env.REACT_APP_APIURL || "https://localhost:3099/";

    const [custDetails, setCustDetails] = useState([]);
    const [presentDetails, setPresentDetails] = useState([]);

    const [unpaidDetails, setUnpaidDetails] = useState([]);

    const [verifiedCust, setVerifiedCust] = useState([]);
    const [visitCust, setVisitCust] = useState([]);
    const [paidCust, setPaidCust] = useState([]);
    const [unpaidCust, setUnpaidCust] = useState([]);
    const [details, setDetails] = useState([]);
    const [summaryDetails, setSummaryDetails] = useState([]);

    const [currentDate, setCurrentDate] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedStaff, setSelectedStaff] = useState('');
    const [openModal, setOpenModal] = useState(false)
    const [detailsChart, setDetailsChart] = useState([])
    const [halfModal, setHalfModal] = useState(false)
    const [halfChart, setHalfChart] = useState([])
    const [absentModal, setAbsentModal] = useState(false)
    const [absentChart, setAbsentChart] = useState([])


    useEffect(() => {
        // Get the current date in the format "YYYY-MM-DD"
        const formattedDate = new Date().toISOString().split('T')[0];
        setCurrentDate(formattedDate);

        // Update the master state with the current date
        setmaster((prevmaster) => ({
            ...prevmaster,
            currentDate: formattedDate,
        }));
    }, []);

    useEffect(() => {
        // Get the current date in the format "YYYY-MM-DD"
        const formattedDate = new Date().toISOString().split('T')[0];
        setCurrentDate(formattedDate);

        // Update the master state with the current date
        setFollowup((prevfollowup) => ({
            ...prevfollowup,
            currentDate: formattedDate,
        }));
    }, []);

    useEffect(() => {
        // Get the current date in the format "YYYY-MM-DD"
        const currentDate = new Date().toISOString().split('T')[0];

        // Assuming master is an object with a to_date property
        const masterToDate = master?.to_date;

        // Set the selected date based on master.to_date or currentDate
        const dateToUse = masterToDate || currentDate;

        // Update the state with the selected date
        setSelectedDate(dateToUse);
    }, [master]); // Run this effect whenever the master state changes

    useEffect(() => {
        // Get the current date in the format "YYYY-MM-DD"
        const currentDate = new Date().toISOString().split('T')[0];

        // Assuming master is an object with a to_date property
        const masterToDate = master?.to_date;

        // Set the selected date based on master.to_date or currentDate
        const dateToUse = masterToDate || currentDate;

        // Update the state with the selected date
        setSelectedStaff(dateToUse);
    }, [followup]); // Run this effect whenever the master state changes



    useEffect(() => {
        handleCustomer();
        handleUnpaid();
        handleStatus();
        handleSummary();
    }, []);



    // staff attendance data
    function handleCustomer(date = "") {

        let userId = "";
        if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser");
            const obj = JSON.parse(data);
            userId = obj._id;
        }

        // Ensure master and currentDate are defined
        if (!date) {
            const selectedDate = master?.to_date || currentDate || new Date().toISOString().split('T')[0];
            date = selectedDate;
        }

        var url =
            `${API_URL}dashboard/staff-dashboard?localbody_id=` +
            currentLocalbody +
            "&user_id=" +
            userId;

        url =
            url +
            "&date=" +
            date;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data;

                setPresentDetails(result);

                const data = [];
                result.total_absent ? data.push(result.total_absent) : data.push(0);
                result.total_present ? data.push(result.total_present) : data.push(0);
                result.total_half ? data.push(result.total_half) : data.push(0);

                setCustDetails(data);

            })
    }

    const options = {
        labels: ["Absent", "Present", "Half Day"],
        colors: ["#ef0c2c", "#3251e7", "#787b7e"],
        legend: {
            show: !0,
            position: "right",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: !1,
            fontSize: "14px",
            offsetX: 0,
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: !1,
                    },
                },
            },
        ],
    };

    //donut chart 1

    function handleStatus() {
        let userId = "";
        if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser");
            var obj = JSON.parse(data);
            userId = obj._id;
        }
        var url =
            `${API_URL}dashboard/customer-dashboard?localbody_id=` +
            currentLocalbody +
            "&user_id=" +
            userId;


        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data;

                setDetails(result);

                // verified cutomers
                let Data = [];
                result.verified_customers ? Data.push(result.verified_customers) : Data.push(0);
                result.notVerified_customers ? Data.push(result.notVerified_customers) : Data.push(0);

                setVerifiedCust(Data);

                // visit / novisit
                let Data2 = [];
                result.total_visit ? Data2.push(result.total_visit) : Data2.push(0);
                result.total_pending ? Data2.push(result.total_pending) : Data2.push(0);

                setVisitCust(Data2);

                // paid / unpaid
                let Data3 = [];
                result.total_paid_customer ? Data3.push(result.total_paid_customer) : Data3.push(0);
                result.total_unpaid_customer ? Data3.push(result.total_unpaid_customer) : Data3.push(0);

                setPaidCust(Data3);

                // unpaid / paid
                let Data4 = [];
                result.total_unpaid_customer ? Data4.push(result.total_unpaid_customer) : Data4.push(0);
                result.total_paid_customer ? Data4.push(result.total_paid_customer) : Data4.push(0);

                setUnpaidCust(Data4);
            })
    }


    const data = {
        labels: ["Active ", "Inactive"],
        datasets: [
            {
                data: verifiedCust,
                backgroundColor: ["#556ee6", "#ebeff2"],
                hoverBackgroundColor: ["#556ee6", "#ebeff2"],
                hoverBorderColor: "#fff",
            },
        ],
    };

    //daunout2
    const data2 = {
        labels: ["Visit", "No Vist"],
        datasets: [
            {
                data: visitCust,
                backgroundColor: ["#556ee6", "#ebeff2"],
                hoverBackgroundColor: ["#556ee6", "#ebeff2"],
                hoverBorderColor: "#fff",
            },
        ],
    };

    const data3 = {
        labels: ["Paid", "Unpaid"],
        datasets: [
            {
                data: paidCust,
                backgroundColor: ["#556ee6", "#ebeff2"],
                hoverBackgroundColor: ["#556ee6", "#ebeff2"],
                hoverBorderColor: "#fff",
            },
        ],
    };

    const data4 = {
        labels: ["Unpaid", "Paid"],
        datasets: [
            {
                data: unpaidCust,
                backgroundColor: ["#556ee6", "#ebeff2"],
                hoverBackgroundColor: ["#556ee6", "#ebeff2"],
                hoverBorderColor: "#fff",
            },
        ],
    };

    // bar chart data

    function handleSummary() {
        let userId = "";
        if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser");
            var obj = JSON.parse(data);
            userId = obj._id;
        }


        var url =
            `${API_URL}dashboard/visit-summary?localbody_id=` +
            currentLocalbody +
            "&user_id=" +
            userId;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data;

                let Data = [];
                result.daily_visitpending ? Data.push(result.daily_visitpending) : Data.push(0);
                result.twice_visitpending ? Data.push(result.twice_visitpending) : Data.push(0);
                result.weekly_visitpending ? Data.push(result.weekly_visitpending) : Data.push(0);
                result.monthly_visitpending ? Data.push(result.monthly_visitpending) : Data.push(0);

                setSummaryDetails(Data);
            })
    }

    const data5 = {
        labels: ["Daily", "Twice", "Weekly", "Monthly"],
        datasets: [
            {
                label: "Visit ",
                backgroundColor: "rgba(52, 195, 143, 0.8)",
                borderColor: "rgba(52, 195, 143, 0.8)",
                borderWidth: 1,
                hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
                hoverBorderColor: "rgba(52, 195, 143, 0.9)",
                data: summaryDetails,
            },
        ],
    };

    const option5 = {
        scales: {
            xAxes: [
                {
                    barPercentage: 0.3,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true, // Start the y-axis at 0
                        stepSize: 10, // Set the interval between y-axis ticks
                    },
                },
            ],
        },
    };

    // up paid splitup chart

    function handleUnpaid(date = "") {
        let userId = "";
        if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser");
            const obj = JSON.parse(data);
            userId = obj._id;
        }

        if (!date) {
            const selectedStaff = followup?.to_date || currentDate || new Date().toISOString().split('T')[0];
            date = selectedStaff;
        }

        var url =
            `${API_URL}dashboard/unpaid-splitup-dashboard?localbody_id=` +
            currentLocalbody +
            "&user_id=" +
            userId;

        url =
            url +
            "&date=" +
            date;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data;

                let data = [];
                result.houseClosed ? data.push(result.houseClosed) : data.push(0);
                result.noWaste ? data.push(result.noWaste) : data.push(0);
                result.pending ? data.push(result.pending) : data.push(0);

                setUnpaidDetails(data);
            })
    }

    const optionsunpaid = {
        labels: ["Door Closed", "No waste", "Pending"],
        colors: ["#EE4B2B", "rgba(52, 195, 143, 0.8)", "#00FFFF"],
        legend: {
            show: !0,
            position: "right",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: !1,
            fontSize: "14px",
            offsetX: 0,
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: !1,
                    },
                },
            },
        ],
    };

    // line target charts


    const optionsTarget = {
        chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
        colors: ["#5b73e8", "#f1b44c"],
        dataLabels: {
            enabled: !1,
        },
        stroke: {
            width: [2, 2],
            curve: "straight",
        },
        title: {
            text: "Target and Achieved",
            align: "left",
        },
        grid: {
            row: {
                colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.1,
            },
            borderColor: "#f1f1f1",
        },
        markers: {
            style: "inverted",
            size: 5,
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
            title: {
                text: "Month",
            },
        },
        yaxis: {
            title: {
                text: "Target",
            },
            min: 5,
            max: 40,
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    };

    // Assuming you have a function to handle date changes
    function handleDateChange(event) {
        const selectedDate = event.target.value;
        handleCustomer(selectedDate);

        // Update master with the selected date
        setmaster(prevMaster => ({
            ...prevMaster,
            to_date: selectedDate
        }));

        // Conditionally call handleCustomer based on halfModal
        // if (!halfModal) {
        //     handleHalf(selectedDate);
        // }
    }


    function handleStaffChange(e) {
        const selectedStaff = e.target.value;
        handleUnpaid(selectedStaff);
    }


    // attendance details
    function handlePresent(status = "", date = "") {

        if (status === 2) {
            status = 2
        }
        if (status === 1) {
            status = 1
        }
        if (status === 0) {
            status = 0
        }
        var url =
            `${API_URL}api/staff/attendance-staff-details?localbody=` +
            currentLocalbody;

        // Ensure master and currentDate are defined
        if (!date) {
            const date1 = master?.to_date || currentDate || new Date().toISOString().split('T')[0];
            date = date1;
        }

        url =
            url +
            "&date=" +
            date +
            "&status=" +
            status;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                }
            }).then((res) => {
                let result = res.data;

                // Present details

                setOpenModal(true);
                setDetailsChart(result)

            })
    }

    function handleAbsent(status = "", date = "") {

        if (status === 2) {
            status = 2
        }
        if (status === 1) {
            status = 1
        }
        if (status === 0) {
            status = 0
        }
        var url =
            `${API_URL}api/staff/attendance-staff-details?localbody=` +
            currentLocalbody;

        // Ensure master and currentDate are defined
        if (!date) {
            const date1 = master?.to_date || currentDate || new Date().toISOString().split('T')[0];
            date = date1;
        }

        url =
            url +
            "&date=" +
            date +
            "&status=" +
            status;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                }
            }).then((res) => {
                let result = res.data;

                // Present details

                setAbsentModal(true);
                setAbsentChart(result)

            })
    }


    function handleHalf(status = "", date = "") {

        if (status === 2) {
            status = 2
        }
        if (status === 1) {
            status = 1
        }
        if (status === 0) {
            status = 0
        }
        var url =
            `${API_URL}api/staff/attendance-staff-details?localbody=` +
            currentLocalbody;

        // Ensure master and currentDate are defined
        if (!date) {
            const date1 = master?.to_date || currentDate || new Date().toISOString().split('T')[0];
            date = date1;
        }

        url =
            url +
            "&status=" +
            status +
            "&date=" +
            date;


        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                }
            }).then((res) => {
                let result = res.data.data;

                // Present details

                setHalfModal(true);
                setHalfChart(result)

            })
    }


    return (
        <>

            <div className="page-content">
                <div className="container-fluid">
                    <Row style={{ rowGap: "20px" }}>
                        <Col md="6">
                            <Card style={{ height: "100%", marginBottom: "0px" }}>
                                <CardBody>
                                    <Row>
                                        <Col md="6">
                                            <h6>Staff Attendance</h6>
                                            <h6>Total Staff: {presentDetails?.total_staff}</h6>
                                        </Col>
                                        <Col style={{ display: "flex", justifyContent: "flex-end" }} md="6">
                                            <AvForm>
                                                <AvField
                                                    name="to_date"
                                                    type="date"
                                                    value={master.to_date || currentDate}
                                                    className="form-control"
                                                    onChange={handleDateChange}
                                                />
                                            </AvForm>
                                        </Col>

                                    </Row>

                                    <ReactApexChart
                                        options={options}
                                        series={custDetails}
                                        type="pie"
                                        height="200px"
                                        className="apex-charts"
                                    />

                                    <Col md="6" style={{ marginLeft: "400px", display: "flex", cursor: "pointer" }}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip
                                                    id="button-tooltip-2"
                                                    style={{ position: "absolute", top: "-30px" }}
                                                >
                                                    Present
                                                </Tooltip>
                                            }
                                        >
                                            <h6
                                                style={{
                                                    marginRight: "10px",
                                                    transition: "color 0.3s", // Smooth transition effect
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handlePresent(2, master.to_date)}
                                            >
                                                <TaskAltIcon />
                                            </h6>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip
                                                    id="button-tooltip-2"
                                                    style={{ position: "absolute", top: "-30px" }}
                                                >
                                                    Absent
                                                </Tooltip>
                                            }
                                        >
                                            <h6 style={{ marginRight: "10px" }} onClick={() => handleAbsent(0, master.to_date)}>
                                                <CancelIcon /></h6>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip
                                                    id="button-tooltip-2"
                                                    style={{ position: "absolute", top: "-30px" }}
                                                >
                                                    Half Day
                                                </Tooltip>
                                            }
                                        >
                                            <h6 onClick={() => handleHalf(1, master.to_date)}><HourglassBottomOutlinedIcon /></h6>
                                        </OverlayTrigger>
                                    </Col>

                                    {/* Present details */}
                                    <Modal show={openModal} centered={true}>
                                        <Row >
                                            <Col className="col-12">
                                                <div className="modal-header">
                                                    <div><h5> Present Staff</h5></div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setOpenModal(false)
                                                        }}
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>

                                                <Table
                                                    style={{ textAlign: "center" }}
                                                    className="table table-bordered dataTable"
                                                >
                                                    <TableHead>
                                                        <TableRow style={{ textAlign: "center" }}>

                                                            <TableCell
                                                                style={{ width: "30px", textAlign: "center" }}
                                                            >
                                                                #
                                                            </TableCell>

                                                            <TableCell
                                                                style={{ width: "210px", textAlign: "center" }}
                                                            >
                                                                Name
                                                            </TableCell>

                                                            <TableCell
                                                                style={{ width: "100px", textAlign: "center" }}
                                                            >
                                                                Mobile
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {detailsChart.data &&
                                                            detailsChart.data.map((item, index) => (


                                                                <TableRow style={{ verticalAlign: "middle" }}>
                                                                    <TableCell
                                                                        component="td"
                                                                        scope="row"
                                                                        style={{ textAlign: "center", paddingLeft: "0rem", paddingRight: "0rem", width: "0px" }}
                                                                    >
                                                                        {index + 1}
                                                                    </TableCell>

                                                                    <TableCell style={{ textAlign: "left" }}>
                                                                        {item?.firstName + " " + item?.lastName}
                                                                    </TableCell>

                                                                    <TableCell style={{ textAlign: "center" }}>
                                                                        {item?.mobile}
                                                                    </TableCell>

                                                                </TableRow>
                                                            ))}
                                                    </TableBody>

                                                </Table>
                                            </Col>

                                        </Row>

                                    </Modal>

                                    {/* Absent details */}
                                    <Modal show={absentModal} centered={true}>
                                        <Row >
                                            <Col className="col-12">
                                                <div className="modal-header">
                                                    <div><h5> Absent Staff</h5></div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setAbsentModal(false)
                                                        }}
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>

                                                <Table
                                                    style={{ textAlign: "center" }}
                                                    className="table table-bordered dataTable"
                                                >
                                                    <TableHead>
                                                        <TableRow style={{ textAlign: "center" }}>

                                                            <TableCell
                                                                style={{ width: "30px", textAlign: "center" }}
                                                            >
                                                                #
                                                            </TableCell>

                                                            <TableCell
                                                                style={{ width: "210px", textAlign: "center" }}
                                                            >
                                                                Name
                                                            </TableCell>

                                                            <TableCell
                                                                style={{ width: "100px", textAlign: "center" }}
                                                            >
                                                                Mobile
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {absentChart.users &&
                                                            absentChart.users.map((item, index) => (

                                                                <TableRow style={{ verticalAlign: "middle" }}>
                                                                    <TableCell
                                                                        component="td"
                                                                        scope="row"
                                                                        style={{ textAlign: "center", paddingLeft: "0rem", paddingRight: "0rem", width: "0px" }}
                                                                    >
                                                                        {index + 1}
                                                                    </TableCell>

                                                                    <TableCell style={{ textAlign: "left" }}>
                                                                        {item?.firstName + " " + item?.lastName}
                                                                    </TableCell>

                                                                    <TableCell style={{ textAlign: "center" }}>
                                                                        {item?.mobile}
                                                                    </TableCell>

                                                                </TableRow>
                                                            ))}
                                                    </TableBody>

                                                </Table>
                                            </Col>

                                        </Row>

                                    </Modal>

                                    {/* Half details */}
                                    <Modal show={halfModal} centered={true}>
                                        <Row >
                                            <Col className="col-12">
                                                <div className="modal-header">
                                                    <div><h5> Half day </h5></div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setHalfModal(false)
                                                        }}
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>

                                                <Table
                                                    style={{ textAlign: "center" }}
                                                    className="table table-bordered dataTable"
                                                >
                                                    <TableHead>
                                                        <TableRow style={{ textAlign: "center" }}>

                                                            <TableCell
                                                                style={{ width: "30px", textAlign: "center" }}
                                                            >
                                                                #
                                                            </TableCell>

                                                            <TableCell
                                                                style={{ width: "210px", textAlign: "center" }}
                                                            >
                                                                Name
                                                            </TableCell>

                                                            <TableCell
                                                                style={{ width: "100px", textAlign: "center" }}
                                                            >
                                                                Mobile
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {halfChart &&
                                                            halfChart.map((item, index) => {
                                                                return (
                                                                    <TableRow style={{ verticalAlign: "middle" }}>
                                                                        <TableCell
                                                                            component="td"
                                                                            scope="row"
                                                                            style={{ textAlign: "center", paddingLeft: "0rem", paddingRight: "0rem", width: "0px" }}
                                                                        >
                                                                            {index + 1}
                                                                        </TableCell>

                                                                        <TableCell style={{ textAlign: "left" }}>
                                                                            {item?.firstName + " " + item?.lastName}
                                                                        </TableCell>

                                                                        <TableCell style={{ textAlign: "center" }}>
                                                                            {item?.mobile}
                                                                        </TableCell>

                                                                    </TableRow>

                                                                )
                                                            }

                                                            )}
                                                    </TableBody>

                                                </Table>
                                            </Col>

                                        </Row>

                                    </Modal>

                                </CardBody>
                            </Card>



                        </Col>

                        <Col md="6">
                            <Row>
                                <Col style={{ marginBottom: "10px" }} md="6">
                                    <Card style={{ marginBottom: "0px", height: "100%" }}>
                                        <CardBody>
                                            <h6>
                                                Customer:{" "}
                                                <span style={{ fontSize: "13px" }}>
                                                    <b>{details?.total_customers}</b>
                                                </span>
                                            </h6>
                                            <Doughnut width={474} height={180} data={data} />
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col style={{ marginBottom: "10px" }} md="6">
                                    <Card style={{ marginBottom: "0px", height: "100%" }}>
                                        <CardBody>
                                            <h6>
                                                Waste Collection:{" "}
                                                <span style={{ fontSize: "13px" }}>
                                                    <b>{details?.waste_collect_customer}</b>
                                                </span>
                                            </h6>

                                            <Doughnut width={474} height={180} data={data2} />
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md="6">
                                    <Card style={{ marginBottom: "0px", height: "100%" }}>
                                        <CardBody>
                                            <h6>
                                                Paid:{" "}
                                                <span style={{ fontSize: "13px" }}>
                                                    <b>{details?.total_paid_customer}</b>
                                                </span>
                                            </h6>
                                            <Doughnut width={474} height={180} data={data3} />
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md="6">
                                    <Card style={{ marginBottom: "0px", height: "100%" }}>
                                        <CardBody>
                                            <h6>
                                                Unpaid:{" "}
                                                <span style={{ fontSize: "13px" }}>
                                                    <b>{details?.total_unpaid_customer}</b>
                                                </span>
                                            </h6>
                                            <Doughnut width={474} height={180} data={data4} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h6>Total Visit Summary</h6>

                                    <Bar
                                        width={474}
                                        height={200}
                                        data={data5}
                                        options={option5}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="6">
                                            <h5>UnPaid Splitup</h5>
                                        </Col>
                                        <Col
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                            md="6"
                                        >
                                            <AvForm>
                                                <AvField
                                                    name="to_date"
                                                    type="date"
                                                    value={followup?.to_date || currentDate}
                                                    className="form-control"
                                                    onChange={handleStaffChange}
                                                />
                                            </AvForm>
                                        </Col>
                                    </Row>

                                    <ReactApexChart
                                        options={optionsunpaid}
                                        series={unpaidDetails}
                                        type="pie"
                                        height="200"
                                        className="apex-charts"
                                    />
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </>
    );
}

export default DashboardChart;