import React, { useState, useEffect } from "react";
import Select from "react-select";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./UserBlock.scss";
import {
  getLocalbody,
  setMyObject,
  getMyObject,
} from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";

const DatatableTables = () => {
  const [searchData, setSearchData] = useState({});
  const [details, setDetails] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
    setMyObject(null);
  }, [currentLocalbody]);

  const handleTableData = (userID = "", firstname = "", blockstatus = "") => {
    axios
      .get(
        `${API_URL}security/userblock?localbody_id=` +
          currentLocalbody +
          "&userId=" +
          userID +
          "&firstname=" +
          firstname +
          "&block_status=" +
          blockstatus,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            if (item.status === 0) {
              item.action = (
                <>
                  <LockOpenIcon
                    style={{
                      color: "green",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                    onClick={() => updateStatus(item)}
                  />
                </>
              );
            } else if (item.status === 2) {
              item.action = (
                <>
                  <LockOpenIcon
                    style={{
                      color: "red",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                    onClick={() => updateStatus(item)}
                  />
                </>
              );
            }
          });
        setDetails(result);
      });
  };

  const updateStatus = (item) => {
    axios
      .put(`${API_URL}security/userblock?user_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          let searchData = getMyObject();
          let obj = JSON.parse(searchData);
          if (res.data.data.status === 0) {
            toastr.success("User unblocked successfully");
          } else toastr.success("User blocked successfully");
          if (searchData !== null) {
            handleTableData(obj?.userId, obj?.username, obj?.blockStatus);
          } else {
            handleTableData();
          }
        } else {
          toastr.error("Failed");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const handleInputChange = (e, name) => {
    let userId = searchData?.userID ? searchData.userID : "";
    let username = searchData?.userName ? searchData.userName : "";
    let blockStatus = searchData?.blockStatus ? searchData.blockStatus : "";
    switch (name) {
      case "userId":
        setSearchData({
          ...searchData,
          [e.target.name]: e.target.value,
        });
        let myObject = {
          userId: e.target.value,
          username: username,
          blockStatus: blockStatus,
        };
        setMyObject(myObject);
        handleTableData(e.target.value, username, blockStatus);
        break;
      case "username":
        setSearchData({
          ...searchData,
          [e.target.name]: e.target.value,
        });
        let myObj = {
          userId: userId,
          username: e.target.value,
          blockStatus: blockStatus,
        };
        setMyObject(myObj);
        handleTableData(userId, e.target.value, blockStatus);
        break;
      default:
        break;
    }
    // setSearchData({
    //   ...searchData,
    //   [e.target.name]: e.target.value,
    // });
  };
  const handleSelectChange = (selected) => {
    let userId = searchData?.userID ? searchData.userID : "";
    let username = searchData?.userName ? searchData.userName : "";
    let myObject = {
      userId: userId,
      username: username,
      blockStatus: selected.value,
    };
    setMyObject(myObject);
    setSelectedStatus(selected);
    setSearchData({
      ...searchData,
      blockStatus: selected.value,
    });
    handleTableData(userId, username, selected.value);
  };
  const reset = () => {
    setSearchData({});
    setSelectedStatus(null);
    setMyObject(null);
    handleTableData();
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "User ID",
        field: "userId",
        sort: "asc",
        width: 270,
      },
      {
        label: "Username",
        field: "firstName",
        sort: "asc",
        width: 270,
      },

      {
        label: "Blacklist Status",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="User Block" />

          {/*Text field 1*/}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>User ID</Label>
                          <AvField
                            name="userID"
                            placeholder="User ID"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={searchData?.userID}
                            onChange={(e) => handleInputChange(e, "userId")}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Username</Label>
                          <AvField
                            name="userName"
                            placeholder="Username"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={searchData?.userName}
                            onChange={(e) => handleInputChange(e, "username")}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Blacklist Status</Label>
                          <Select
                            name="_id"
                            value={selectedStatus}
                            onChange={(selected) => {
                              handleSelectChange(selected);
                            }}
                            options={[
                              {
                                label: "Blocked",
                                value: 2,
                              },
                              {
                                label: "Unblocked",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col
                        md="1"
                        className="mt-4"
                        style={{ paddingTop: "5px" }}
                      >
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="userblockTableId"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
