import React, { useState } from "react"
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Row, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "react-select"
import { MDBDataTable } from "mdbreact";



function CollectWaste() {


    const [values, setValues] = useState([
        {
            item_name: "",
            collected_amount: "",
            user_collect_amount: "",
            status: ""
        },
    ]);


    const addMore = () => {
        setValues([...values, {}]);
    };

    const handleRemove = (index) => {
        const list = [...values];
        list.splice(index, 1);

        setValues(list);
    };

    const data = {
        columns: [
            {
                label: "#",
                field: 'id',
                width: 50,
            },
            {
                label: "Name",
                field: 'name',
                width: 50,
            },
            {
                label: "Collected Amount",
                field: 'collected_amount',
                width: 50,
            },
            {
                label: "User Amount",
                field: 'user_amount',
                width: 50,
            },
            {
                label: "Status",
                field: 'status',
                width: 50,
            },
            {
                label: "Action",
                field: 'action',
                width: 50,
            },
        ],
        rows: [
            {
                id: "1",
                name: "Mannya",
                collected_amount: "150",
                user_amount: "100",
                status: "Collect Waste",
                action: (
                    <div style={{}}></div>
                )
            }
        ]
    }


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Collect Waste" />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm className="needs-validation">

                                        <Row>
                                            <Col xl="12">
                                                <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                                                    <TableHead>
                                                        <TableRow style={{ textAlign: "center" }}>
                                                            <TableCell
                                                                style={{
                                                                    width: "0px",
                                                                    textAlign: "center",
                                                                    fontWeight: "normal",
                                                                    paddingLeft: "0rem",
                                                                    paddingRight: "0rem",
                                                                    fontWeight: "500",
                                                                    fontSize: "12.819px",
                                                                    fontFamily: "IBM Plex Sans, sans-serif",
                                                                    color: "#495057",
                                                                }}>
                                                                No.
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    // width: "230px",
                                                                    textAlign: "center",
                                                                    fontWeight: "500",
                                                                    fontSize: "12.819px",
                                                                    fontFamily: "IBM Plex Sans, sans-serif",
                                                                    color: "#495057",
                                                                }}>
                                                                Item Name
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    width: "150px",
                                                                    textAlign: "center",
                                                                    fontWeight: "500",
                                                                    fontSize: "12.819px",
                                                                    fontFamily: "IBM Plex Sans, sans-serif",
                                                                    color: "#495057",
                                                                }}>
                                                                Quantity
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    width: "150px",
                                                                    textAlign: "center",
                                                                    fontWeight: "500",
                                                                    fontSize: "12.819px",
                                                                    fontFamily: "IBM Plex Sans, sans-serif",
                                                                    color: "#495057",
                                                                }}>
                                                                Weight
                                                            </TableCell>

                                                            <TableCell
                                                                style={{
                                                                    width: "150px",
                                                                    textAlign: "center",
                                                                    fontWeight: "500",
                                                                    fontSize: "12.819px",
                                                                    fontFamily: "IBM Plex Sans, sans-serif",
                                                                    color: "#495057",
                                                                }}>
                                                                Rate
                                                            </TableCell>

                                                            <TableCell
                                                                style={{
                                                                    width: "150px",
                                                                    textAlign: "center",
                                                                    fontWeight: "500",
                                                                    fontSize: "12.819px",
                                                                    fontFamily: "IBM Plex Sans, sans-serif",
                                                                    color: "#495057",
                                                                }}>
                                                                Amount
                                                            </TableCell>
                                                            {values.length !== 1 ? (
                                                                <TableCell
                                                                    style={{
                                                                        width: "100px",
                                                                        textAlign: "center",
                                                                        fontWeight: "500",
                                                                        paddingLeft: "0rem",
                                                                        paddingRight: "0rem",
                                                                        fontSize: "12.819px",
                                                                        fontFamily: "IBM Plex Sans, sans-serif",
                                                                        color: "#495057",
                                                                    }}>
                                                                    Action
                                                                </TableCell>
                                                            ) : null}
                                                        </TableRow>
                                                    </TableHead>
                                                    
                                                    <TableBody>
                                                        {values &&
                                                            values.map((item, index) => (
                                                                <TableRow>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        style={{
                                                                            textAlign: "center",
                                                                            fontSize: "12.819px",
                                                                            fontFamily: "IBM Plex Sans, sans-serif",
                                                                            color: "#495057",
                                                                            fontWeight: "500",
                                                                        }}>
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{
                                                                            textAlign: "left",
                                                                        }}>
                                                                        <Select
                                                                            name="item_name"
                                                                            //value={selectedMedicine}
                                                                            // value={{
                                                                            //     label: item.medicine_name,
                                                                            //     value: item.medicine,
                                                                            // }}
                                                                            // onChange={(selected) => {
                                                                            //     handleChangeSelectItem(selected, index);
                                                                            // }}
                                                                            // options={medicineOptions}
                                                                            // isDisabled={isAdmin ? false : !selectedPatient?.editable}
                                                                            classNamePrefix="select2-selection"
                                                                            options={[
                                                                                { label: "Thermocol" },
                                                                                { label: "Plastic Covers" },
                                                                                { label: "Paper / Cardboard" },
                                                                                { label: "Food Waste" },
                                                                                { label: "E Waste" },
                                                                                { label: "Glass" },
                                                                                { label: "Cloth" },
                                                                                { label: "Bags Rexine" },
                                                                                { label: "Chappal" }
                                                                            ]}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell style={{ textAlign: "center" }}>
                                                                        <AvField
                                                                            name="quantity"
                                                                            type="number"
                                                                            min={0}
                                                                            className="form-control"
                                                                            placeholder="Quantity"
                                                                            id="validationCustom05"
                                                                        // onChange={(e) => handleTableInputchange(e, index)}
                                                                        // value={item.frq_night}
                                                                        // onWheel={(e) => e.target.blur()}
                                                                        // disabled={isAdmin ? false : !selectedPatient?.editable}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell style={{ textAlign: "center" }}>
                                                                        <AvField
                                                                            name="weight"
                                                                            placeholder="Weight"
                                                                            type="Number"
                                                                            min={0}
                                                                            className="form-control"
                                                                            // validate={{ required: { value: true } }}
                                                                            id="validationCustom05"
                                                                        // onChange={(e) => handleTableInputchange(e, index)}
                                                                        // value={item.days}
                                                                        // onWheel={(e) => e.target.blur()}
                                                                        // disabled={isAdmin ? false : !selectedPatient?.editable}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <AvField
                                                                            name="rate"
                                                                            placeholder="Rate"
                                                                            type="number"
                                                                            min={0}
                                                                            className="form-control"
                                                                            id="validationCustom05"
                                                                        // onChange={(e) => handleTableInputchange(e, index)}
                                                                        // value={item.remarks}
                                                                        // disabled={isAdmin ? false : !selectedPatient?.editable}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <AvField
                                                                            name="amount"
                                                                            placeholder="Amount"
                                                                            type="number"
                                                                            min={0}
                                                                            className="form-control"
                                                                            id="validationCustom05"
                                                                        // onChange={(e) => handleTableInputchange(e, index)}
                                                                        // value={item.remarks}
                                                                        // disabled={isAdmin ? false : !selectedPatient?.editable}
                                                                        />
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        {values.length !== 1 && (
                                                                            <i
                                                                                className="fas fa-trash"
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    justifyContent: "center",
                                                                                }}
                                                                                onClick={() => handleRemove(index)}
                                                                            ></i>
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>

                                                <span
                                                    onClick={() => addMore()}
                                                    style={{
                                                        width: "190px",
                                                        cursor: "pointer",
                                                        fontSize: "12.819px",
                                                        fontFamily: "IBM Plex Sans, sans-serif",
                                                        color: "#495057",
                                                        fontWeight: "500",
                                                    }}>
                                                    {" "}
                                                    + Add Item{" "}
                                                </span>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="3" style={{ marginTop: "13px" }}>
                                                <Button color="primary" type="submit">Submit</Button>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="wasteTableId"
                                        responsive
                                        bordered
                                        data={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )


}

export default CollectWaste;