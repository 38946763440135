import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr, { options } from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  FormGroup,
  Input,
  FormText,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getWards,
  addWard,
  deleteWard,
  apiError,
  updateWard,
  getLocalBody,
  getLocalbodyOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody } from "../../../helpers/globalFunctions";
import "./ward.scss";

const Wards = (props) => {
  const [selectedPrivilage, setSelectedPrivilage] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [wardObject, setWardObject] = useState({});
  const [wardIdTobeUpdated, setWardIdToBeUpdated] = useState(null);
  const [wardIdToBeDeleted, setWardIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [wardsForTable, setWardsForTable] = useState([]);
  const [localbodyOptions, setlocalbodyOptions] = useState([]);
  const [wardname, setWardname] = useState("");
  const [localbodyname, setLocalbodyname] = useState(null);
  const [wardshortcode, setwardShortcode] = useState("");
  const [residenceName, setResidenceName] = useState("");
  const [commercialName, setCommercialName] = useState("");
  const [totalHouseBuilding, setTotalHouseBuilding] = useState("");
  const [wardno, setwardno] = useState(null);
  const [values, setValues] = useState({});
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [passwordObject, setPasswordObject] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    localbodyError: "",
    wardNoError: "",
  });
  const {
    wards,
    addingWard,
    addWardResponse,
    deleteWardResponse,
    updateWardResponse,
    error,
  } = useSelector((state) => state.wards);
  const formRef = useRef();
  const { localbody } = useSelector((state) => state.localbodies);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const wardnumbers = () => {
    const options = [];

    for (let i = 1; i <= 100; i++) {
      options.push({ label: i, value: i });
    }
    return options;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(getWards());
    handleTableData();
    fetch_localbody(currentLocalbody);
    dispatch(getLocalbodyOptions());
  }, [currentLocalbody]);

  function handleTableData() {
    var url = `${API_URL}ward/list?localbody_id=` + currentLocalbody;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let wardData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateWard(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setWardIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          item.time = moment(item.ward_time, "HHmmss").format("hh:mm a"); // 24hours
          if (item.localbody_name_id != null)
            item.localbody_name = item.localbody_name_id.localbody_name;
          // if (item.ward_addedby) item.wardaddedby = item.ward_addedby.firstName;

          if (item?.ward_updatedby) {
            item.wardaddedby = item.ward_updatedby.firstName;
          }
          else {
            if (item.ward_addedby != null) {
              item.wardaddedby = item.ward_addedby.firstName;
            }
          }
          item.date = moment(item.ward_date).format("DD-MM-YYYY");
          wardData.push(item);
        });
        setWardsForTable(wardData);
      });
  }
  const fetch_localbody = (id) => {
    axios
      .get(`${API_URL}api/staff/localbodies?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var localbodies =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.localbody_name,
              value: item._id,
              shortCode: item.short_code,
            };
          });
        setlocalbodyOptions([{ options: localbodies }]);
      });
  };
  useEffect(() => {
    if (addWardResponse.type === "success") {
      //dispatch(getWards());
      toastr.success(addWardResponse.message);
      handleTableData();
      setSelectedPrivilage({});
      setSelectedCompany(null);
      setSelectedBranch(null);
    } else if (addWardResponse.type === "failure") {
      toastr.error(error.data.message, addWardResponse.message);
    }
  }, [addWardResponse]);

  useEffect(() => {
    if (deleteWardResponse.type === "success") {
      //dispatch(getWards());
      toastr.success(deleteWardResponse.message);
      handleTableData();
      setWardIdToBeDeleted(null);
    } else if (deleteWardResponse.type === "failure") {
      toastr.error(error.data.message, deleteWardResponse.message);
    }
  }, [deleteWardResponse]);

  useEffect(() => {
    if (updateWardResponse.type === "success") {
      //dispatch(getWards());
      setShowModal(false);
      setWardIdToBeUpdated(null);
      setPasswordObject({});
      handleTableData();
      toastr.success(updateWardResponse.message);
    } else if (updateWardResponse.type === "failure") {
      toastr.error(error.data.message, updateWardResponse.message);
    }
  }, [updateWardResponse]);

  let preUpdateWard = (item) => {
    setWardIdToBeUpdated(item._id);

    if (item.localbody_name_id) {
      let localbody = {
        label: item.localbody_name_id.localbody_name,
        value: item.localbody_name_id._id,
      };
      let localbodyValue = {
        name: item.localbody_name_id.localbody_name,
        _id: item.localbody_name_id._id,
      };
      item.localbody = localbodyValue;
      handelChangeLocalbody(localbody);
    }
    if (item.ward_no) {
      let wardNo = {
        name: item.ward_no,
        _id: item.ward_no,
      };
      let wardno = {
        label: item.ward_no,
        value: item.ward_no,
      };
      item.wardno = wardNo;
      handleChangeWardno(wardno);
    }
    if (item.ward_name) {
      let ward = item.ward_name.split("/");
      setWardname(ward[1]);
    }

    setwardShortcode(item.ward_name);
    setCommercialName(item.total_commercial);
    setResidenceName(item.total_residence);

    // Calculate total_building and set it in the desired format
    const totalBuilding = item.total_residence + item.total_commercial;
    setTotalHouseBuilding({ total_building: totalBuilding });

    // handleSelectedlocalbodyname(localbodyname);
    setWardObject({ ...item, password: null });
  };

  let preUpdateWardPassword = (item) => {
    setWardIdToBeUpdated(item._id);
    setShowModal(true);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  // useEffect(() => {
  //   let wardData = [];

  //   wards.map((item, index) => {
  //     item.action = (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <i
  //           className="uil-edit-alt"
  //           style={{
  //             fontSize: "1.2em",
  //             cursor: "pointer",
  //             marginLeft: "0.5rem",
  //             marginRight: "0.5rem",
  //           }}
  //           onClick={() => {
  //             toTop();
  //             preUpdateWard(item);
  //           }}
  //         ></i>
  //         <i
  //           className="uil-trash-alt"
  //           style={{ fontSize: "1.2em", cursor: "pointer" }}
  //           onClick={() => {
  //             setWardIdToBeDeleted(item._id);
  //             setConfirmDeleteAlert(true);
  //           }}
  //         ></i>
  //       </div>
  //     );
  //     item.id = index + 1;
  //     item.time = moment(item.ward_time, "HHmmss").format("hh:mm a"); // 24hours
  //     if (item.localbody_name_id != null)
  //       item.localbody_name = item.localbody_name_id.localbody_name;
  //     if (item.ward_addedby) item.wardaddedby = item.ward_addedby.username;

  //     item.date = moment(item.ward_date).format("DD-MM-YYYY");
  //     wardData.push(item);
  //   });
  //   setWardsForTable(wardData);
  // }, [wards]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Localbody ",
        field: "localbody_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Ward Number",
        field: "ward_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Ward Name",
        field: "ward_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Staff",
        field: "wardaddedby",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: wardsForTable,
  };

  /* function code(){
    let code;
    if(localbody==={} || wardno==={} ||wardname==="")
    code=""
    else
    code= `${localbody.short_code}${wardno.value}/${wardname}`;
   // setWardObject({ ...wardObject, ward_name:code})
    return code;
  }*/

  function handleChangeWard(e) {
    let name = e.target.name;
    let value = e.target.value;
    setWardname(value);
    let shortname = `${localbody?.short_code ? localbody.short_code : ""}${wardno?.value ? wardno.value : ""
      }/${value}`;

    setwardShortcode(shortname);

    setWardObject({ ...wardObject, ward_name: shortname });
  }

  function handleChangeWardname(value) {
  }

  const handleChangeResidence = (e) => {
    // const value = parseInt(e.target.value, 10) || 0; 
    const value = parseInt(e.target.value);// Ensure the value is a number
    setResidenceName(value);
    updateTotalBuilding(value, wardObject.total_commercial);
  };

  const handleChangeCommercial = (e) => {
    // const value = parseInt(e.target.value, 10) || 0; 
    const value = parseInt(e.target.value);// Ensure the value is a number
    setCommercialName(value);
    updateTotalBuilding(wardObject.total_residence, value);
  };

  const updateTotalBuilding = (residenceCount, commercialCount) => {
    // const totalBuilding = residenceCount + commercialCount;

    let totalBuilding;

    if (residenceCount && commercialCount) {
      // Both counts are present, so calculate the sum
      totalBuilding = residenceCount + commercialCount;
    } else if (residenceCount) {
      // Only residenceCount is present, so assign its value to totalBuilding
      totalBuilding = residenceCount;
    } else if (commercialCount) {
      // Only commercialCount is present, so assign its value to totalBuilding
      totalBuilding = commercialCount;
    } else {
      // Handle the case where neither count is present
      totalBuilding = 0; // You can set it to 0 or any other default value
    }


    setWardObject({
      ...wardObject,
      total_residence: residenceCount,
      total_commercial: commercialCount,
      total_building: totalBuilding,
    });

    setTotalHouseBuilding({ total_building: totalBuilding })
  };


  function handleChangeWardno(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setwardno(value);
    setErrors({
      ...errors,
      ["wardNoError"]: "",
    });

    let shortname = `${localbody?.short_code ? localbody.short_code : ""}${value.value
      }/${wardname}`;

    setwardShortcode(shortname);

    if (wardIdTobeUpdated)
      setWardObject({
        ...wardObject,
        ward_no: value.value,
        ward_name: shortname,
      });
    else
      setWardObject({ ...wardObject, wardno: newValue, ward_name: shortname });
    // setValues({ ...values, [name]: value })
  }
  function handelChangeLocalbody(values) {
    dispatch(getLocalBody(values.value));

    let newValue = {
      name: values.label,
      _id: values.value,
    };

    setLocalbodyname(values);
    setErrors({
      ...errors,
      ["localbodyError"]: "",
    });

    let shortname = `${values.shortCode}${wardno?.value ? wardno.value : ""
      }/${wardname}`;

    setwardShortcode(shortname);
    setWardObject({ ...wardObject, localbody: newValue, ward_name: shortname });
  }

  function handleSelectedlocalbodyname(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setLocalbodyname(value);
    setWardObject({ ...wardObject, privilage: newValue });
  }

  function handleSelectedCompany(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setSelectedCompany(value);
    setWardObject({ ...wardObject, company: newValue });
  }
  function handleSelectedBranch(value) {
    let newValue = {
      name: value.value,
    };
    setSelectedBranch(value);
    setWardObject({ ...wardObject, localbody_name: newValue });
  }

  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }

  const handleValidSubmit = (event, values) => {
    if (localbodyname === null || wardno === null) {
      let errorVal = errors;
      if (localbodyname === null) {
        errorVal.localbodyError = "Please select localbody";
      }
      if (wardno === null) {
        errorVal.wardNoError = "Please select ward number";
      }
      setErrors({
        ...errorVal,
      });
    } else {
      if (wardIdTobeUpdated) {
        axios
          .put(`${API_URL}ward` + "/" + wardIdTobeUpdated, wardObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Ward updated successfully");
              formRef.current.reset();
              setLocalbodyname(null);
              setwardno(null);
              setWardname("");
              setwardShortcode("");
              setResidenceName("");
              setCommercialName("");
              setTotalHouseBuilding("");
              setErrors({
                localbodyError: "",
                wardNoError: "",
              });
              handleTableData();
              setWardIdToBeUpdated(null);
            } else {
              toastr.error("Failed to update ward");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}ward`, wardObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Ward added successfully");
              formRef.current.reset();
              setLocalbodyname(null);
              setwardno(null);
              setWardname("");
              setwardShortcode("");
              setResidenceName("");
              setCommercialName("");
              setTotalHouseBuilding("");
              setErrors({
                localbodyError: "",
                wardNoError: "",
              });
              handleTableData();
            } else {
              toastr.error("Failed to add ward");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
      // wardIdTobeUpdated
      //   ? dispatch(updateWard(wardObject))
      //   : dispatch(addWard(wardObject));
    }
  };

  const handleValidSubmitPassword = (event, values) => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        _id: wardIdTobeUpdated,
        password: passwordObject.password,
      };
      dispatch(updateWard(item));
    } else {
      toastr.error("Passwords are not matching");
    }
  };

  let closeModal = () => {
    setShowModal(false);
    setWardIdToBeUpdated(null);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}ward` + "/" + wardIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Ward deleted successfully");

                  if (wardObject && wardObject._id === wardIdToBeDeleted) {
                    formRef.current.reset();
                    setLocalbodyname(null);
                    setwardno(null);
                    setWardname("");
                    setwardShortcode("");
                    setResidenceName("");
                    setCommercialName("");
                    setTotalHouseBuilding("");
                    setErrors({
                      localbodyError: "",
                      wardNoError: "",
                    });
                    handleTableData();
                    setWardIdToBeUpdated(null);
                  }
                  setWardIdToBeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete ward");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            // dispatch(deleteWard(wardIdToBeDeleted));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className="page-content ">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Ward" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Localbody</Label>
                          <Select
                            name="localbody_name"
                            value={localbodyname}
                            options={localbodyOptions}
                            classNamePrefix="select2-selection"
                            onChange={handelChangeLocalbody}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {localbodyname === null
                              ? errors.localbodyError
                              : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Ward Number</Label>
                          <Select
                            name="localbody_name"
                            value={wardno}
                            options={wardnumbers()}
                            classNamePrefix="select2-selection"
                            onChange={handleChangeWardno}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {wardno === null ? errors.wardNoError : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Ward Name</Label>
                          <AvField
                            name="wardname"
                            placeholder=" Ward name"
                            type="text"
                            errorMessage="Enter ward Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={wardname}
                            onChange={handleChangeWard}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <div className="col-md-10">
                            <AvField
                              readOnly
                              name="ward_name"
                              placeholder=" Ward name"
                              type="text"
                              errorMessage="Enter ward Name"
                              className="form-control"
                              //validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={wardshortcode}
                              onChange={handleChangeWardname}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>No. of Houses</Label>
                          <AvField
                            name="total_residence"
                            placeholder="No. of houses"
                            type="number"
                            min={0}
                            id="validationCustom01"
                            value={residenceName}
                            onChange={handleChangeResidence}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>No. of Buildings</Label>
                          <AvField
                            name="total_commercial"
                            type="number"
                            placeholder="No. of Buildings"
                            value={commercialName}
                            onChange={handleChangeCommercial}
                            min={0}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Total no. of houses/Buildings</Label>
                          <AvField
                            readOnly
                            name="total_building"
                            placeholder="Total no. of house/building"
                            type="number"
                            className="form-control"
                            min={0}
                            id="validationCustom01"
                            value={totalHouseBuilding.total_building}
                          />
                        </div>
                      </Col>


                      <Col style={{ marginTop: "24px" }}>
                        <div className="mb-3">
                          {wardIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingWard ? true : false}
                            >
                              {addingWard ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingWard ? true : false}
                            >
                              {addingWard ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="wardTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(Wards));

Wards.propTypes = {
  error: PropTypes.any,
  wards: PropTypes.array,
};
