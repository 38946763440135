import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Table, Container } from "reactstrap";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import { apiError } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import logoDark from "../../assets/images/logo-dark.svg";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getLocalbody, getCompany, getDate, getFirstday, formatMoney } from "../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { CSVLink } from "react-csv";
import "./Incentive.scss";
import { Pagination } from "@mui/material";

const AutoInvoice = (props) => {
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  // const [currentCompany, serCurrentCompany] = useState(getCompany());
  const [details, setDetails] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount] = useState(20);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    fetch_all_staff(currentLocalbody);
  }, [currentLocalbody]);

  useEffect(() => {
    handleTableData();
  }, [currentLocalbody, currentPage]);

  // console.log(currentPage, PerPageCount, totalPages);

  function handleTableData(from_date = "", to_date = "", staff_id = "") {
    var url = `${API_URL}incentive/all_staff_incentive?localbody_id=` + currentLocalbody;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url = url + `&fromdate=${from_date}&todate=${to_date}&staff_id=${staff_id}&page=${currentPage}&limit=${PerPageCount}`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        setTotalPages(res.data.count);
        let dataToBeExported = [];
        result.map((item, index) => {
          item.id = (currentPage - 1) * PerPageCount + index + 1;

          let exportItem = {};
          item.date = moment(item.date).format("DD-MM-YYYY");
          item.time = moment(item.time, "HH:mm:ss").format("hh:mm a");

          item.incentive_date_from = moment(item.incentive_date_from).format("DD-MM-YYYY");
          item.incentive_date_to = moment(item.incentive_date_to).format("DD-MM-YYYY");

          item.staff_inc_amount = item.staff_inc_amount.toFixed(2);
          if (item.incentive_type == 1) {
            item.incentive_type = "Daily";
          } else {
            item.incentive_type = "Monthly";
          }

          //export data
          exportItem.No = item.incentive_no;
          exportItem.Date = item.date;
          exportItem.Time = item.time;
          exportItem.From = item.incentive_date_from;
          exportItem.To = item.incentive_date_to;
          exportItem.Type = item.incentive_type;
          exportItem.Staff = item.staff_info;
          exportItem.Group = item.group_name;
          exportItem.Incentive = item.incentive_name;
          exportItem.Visit = item.total_visit_count;
          exportItem.Amount = item.staff_inc_amount;
          dataToBeExported.push(exportItem);
        });

        setDetails(result);
        setDataToBeExported(dataToBeExported);
      });
  }

  const fetch_all_staff = (id) => {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    var user_id = user_obj._id;
    axios
      .post(
        `${API_URL}api/staff/filter_staff_list`,
        { localbody_id: currentLocalbody, user_id: user_id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    // handleTableData(date1, date2, ward_id, group_id, staff_id);
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        // handleTableData(date1, date2, ward_id, group_id, selected.value);
        break;
      default:
        break;
    }
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "No",
        field: "incentive_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "From",
        field: "incentive_date_from",
        sort: "asc",
        width: 200,
      },
      {
        label: "To",
        field: "incentive_date_to",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "incentive_type",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff_info",
        sort: "asc",
        width: 200,
      },
      {
        label: "Incentive",
        field: "incentive_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "staff_inc_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Count",
        field: "total_visit_count",
        sort: "asc",
        width: 200,
      },

      // {
      //   label: "Group",
      //   field: "group_name",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: details,
  };

  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedStaff(null);
    handleTableData();
  };
  const handleSearch = () => {
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    handleTableData(date1, date2, staff_id);
  };


  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handlePaginate = (event, value) => {
    toTop()
    setCurrentPage(value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            //breadcrumbItem="Monthly Automatic Invoice Generator"
            breadcrumbItem="Incentive"
          />

          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      {/* <div className="col-md-10"> */}
                      <input className="form-control" type="date" id="from_date" name="from_date" value={searchData?.from_date} onChange={handleDate} />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        value={searchData?.to_date}
                        min={searchData?.from_date}
                        onChange={handleDate}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  {/* <Col md="2">
                    <div className="mb-3">
                      <Label>Ward</Label>
                      <Select
                        name="ward_id"
                        value={selectedWard}
                        onChange={(selected) => {
                          handleSelectChange(selected, "ward");
                        }}
                        options={wardOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Group</Label>
                      <Select
                        name="group_id"
                        value={selectedGroup}
                        onChange={(selected) => {
                          handleSelectChange(selected, "group");
                        }}
                        options={groupOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col> */}
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Staff</Label>
                      <Select
                        name="staff_id"
                        value={selectedStaff}
                        onChange={(selected) => {
                          handleSelectChange(selected, "staff");
                        }}
                        options={staffOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div style={{ display: "flex" }}>
                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                        <Button color="primary" type="submit" onClick={() => handleSearch()}>
                          {"  "}
                          Search
                        </Button>
                      </div>

                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                        <Button color="danger" type="submit" onClick={() => reset()}>
                          {"  "}
                          Reset
                        </Button>
                      </div>

                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                        <Button color="success" type="submit">
                          <CSVLink data={dataToBeExported} filename={"staff_incentive" + getDate(new Date()) + ".xls"} style={{ color: "white" }}>
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </div>
                  </Col>

                  {/* <Col md="3">
                        <div className="mb-3" style={{ paddingTop: "30px",cursor:"pointer" }}>
                          <LoopIcon onClick={handleClick}></LoopIcon>
                        </div>

                      </Col> */}
                </Row>

                <Row
                // style={{ display: searchLoader ? "none" : "block" }}
                >
                  <Col md="12">
                    <MDBDataTable
                      id="incentivelistTable"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      paging={false}
                      infoLabel={false}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                    {totalPages > 20 && (
                      <div className="pagination-div">
                        {/* Display the page details */}
                        <div className="page-details">
                          Showing {(currentPage - 1) * PerPageCount + 1} to {Math.min(currentPage * PerPageCount, totalPages)} of {totalPages} entries
                        </div>

                        <Pagination
                          color="standard"
                          shape="rounded"
                          defaultPage={1}
                          count={Math.ceil(totalPages / PerPageCount)}
                          page={currentPage}
                          onChange={handlePaginate}
                          info={true}
                          size="large"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(AutoInvoice));

AutoInvoice.propTypes = {
  error: PropTypes.any,
  invoiceList: PropTypes.array,
};
