import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import Select from "react-select";
import $ from "jquery";
import axios from "axios";
import {
  getFirstday,
  getLocalbody,
  getDate,
  isSuperAdmin,
} from "../../../helpers/globalFunctions";
import { CSVLink } from "react-csv";
import { MDBDataTable } from "mdbreact";
import Pagination from "@mui/material/Pagination";
import "./style.scss";

function NoVisitReport() {
  const [masterObject, setmasterObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

  const [selectedWard, setSelectedWard] = useState(null);
  const [wardOptions, setWardOptions] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);

  const [selectedBillingType, setSelectedBillingType] = useState(null);
  const [selectedCustType, setSelectedCustType] = useState(null);

  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [visitDetails, setVisitDetails] = useState([]);
  const [details, setDetails] = useState([]);

  // pagination
  const [searchValue, setSearchValue] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
    currentPage * PerPageCount
  );
  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
    indexOfLastTransfer - PerPageCount
  );

  const fromdate = masterObject?.from_date;
  const todate = masterObject?.to_date;
  const ward_id = masterObject?.ward;
  const group_id = masterObject?.group_id;
  const billing_type = masterObject?.billingType;
  const type = masterObject?.customerType;

  const paginate = (event, value) => {
    setCurrentPage(value);
    handleTableData(
      fromdate,
      todate,
      ward_id,
      group_id,
      billing_type,
      type,
      value,
      PerPageCount
    );
  };

  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfFirstTransfer = 0;
    const indexOfLastTransfer = 20;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  };

  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => func(...args), delay);
    };
  };

  useEffect(() => {
    if (details?.length === 0 && indexOfFirstTransfer !== 0) {
      resetPaginate();
      handleTableData(
        searchValue,
        currentLocalbody,
        fromdate,
        todate,
        ward_id,
        group_id,
        billing_type,
        type,
        currentPage,
        PerPageCount
      );
    }
  }, [details]);

  useEffect(() => {
    fetch_ward();
    handleTableData();
  }, []);

  function fetch_ward() {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + currentLocalbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([{ options: ward_data }]);
      });
  }

  function handleDate(e) {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setmasterObject({
        ...masterObject,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setmasterObject({
        ...masterObject,
        [e.target.name]: e.target.value,
      });
    }
  }

  function handleSelectChange(selected, name) {
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSelectedGroup(null);
        setmasterObject({
          ...masterObject,
          ["ward"]: selected.value,
          ["group"]: "",
        });
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
              currentLocalbody +
              "&ward_id=" +
              selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            var group_data =
              res.data.data &&
              res.data.data.map((el) => {
                return {
                  label: el.group_name,
                  value: el._id,
                };
              });

            setGroupOptions([{ options: group_data }]);
          });
        break;

      case "group":
        setSelectedGroup(selected);
        setmasterObject({
          ...masterObject,
          ["group"]: selected.value,
        });
        break;

      case "billingType":
        setSelectedBillingType(selected);
        setmasterObject({
          ...masterObject,
          ["billingType"]: selected.value,
        });
        break;

      case "customerType":
        setSelectedCustType(selected);
        setmasterObject({
          ...masterObject,
          ["customerType"]: selected.value,
        });
        break;

      default:
        break;
    }
  }

  function reset() {
    resetPaginate();
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedBillingType(null);
    setSelectedCustType(null);
    setmasterObject({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    handleTableData(searchValue);
  }

  function handleTableData(
    from_date = "",
    to_date = "",
    ward = "",
    group = "",
    billingType = "",
    customerType = "",
    page = 1,
    limit = 20
  ) {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      userId = obj._id;
    }

    var url =
      `${API_URL}api/staff/novisit/report?localbody_id=` +
      currentLocalbody +
      "&user_id=" +
      userId;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url =
      url +
      "&fromdate=" +
      from_date +
      "&todate=" +
      to_date +
      "&ward_id=" +
      ward +
      "&group_id=" +
      group +
      "&billing_type=" +
      billingType +
      "&type=" +
      customerType +
      "&page=" +
      page +
      "&limit=" +
      limit;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let Data = [];
        let dataToBeExported = [];

        setTotalPages(res.data.count);
        let result = res.data.data;

        let id = (page - 1) * limit;

        result.map((item, index) => {
          id++;
          item.id = id;

          // Export
          let exportItem = {};

          exportItem.Id = item.id;
          exportItem.Reg_no = item.cust_reg_no;
          exportItem.Customer = item.cust_name;
          exportItem.Address = item.cust_address;
          exportItem.Designation = item.designations;
          exportItem.Ward = item.ward;
          exportItem.Group = item.group;
          exportItem.Package = item.package;
          exportItem.Billing_type = item.billingtypes;

          dataToBeExported.push(exportItem);

          Data.push(item);
        });

        setVisitDetails(Data);
        setDataToBeExported(dataToBeExported);

        setDetails(result);
      });
  }

  function handleSearch(value) {
    setSearchValue(value);
    resetPaginate();
    let date1 = masterObject?.from_date ? masterObject.from_date : "";
    let date2 = masterObject?.to_date ? masterObject.to_date : "";
    let ward_id = masterObject?.ward ? masterObject.ward : "";
    let group_id = masterObject?.group ? masterObject.group : "";
    let billing_type = masterObject?.billingType
      ? masterObject.billingType
      : "";
    let type = masterObject?.customerType ? masterObject.customerType : "";
    handleTableData(date1, date2, ward_id, group_id, billing_type, type);
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Reg No.",
        field: "cust_reg_no",
        sort: "asc",
        width: 100,
      },
      {
        label: "Customer",
        field: "cust_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Address",
        field: "cust_address",
        sort: "asc",
        width: 100,
      },
      {
        label: "Designation",
        field: "designations",
        sort: "asc",
        width: 100,
      },
      {
        label: "Ward",
        field: "ward",
        sort: "asc",
        width: 100,
      },
      {
        label: "Group",
        field: "group",
        sort: "asc",
        width: 100,
      },
      {
        label: "Package",
        field: "package",
        sort: "asc",
        width: 100,
      },
      {
        label: "Billing Type",
        field: "billingtypes",
        sort: "asc",
        width: 100,
      },
    ],
    rows: visitDetails,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="No Visit Report" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <Label>From</Label>
                      <input
                        name="from_date"
                        type="date"
                        className="form-control"
                        value={masterObject?.from_date}
                        onChange={handleDate}
                      />
                    </Col>

                    <Col md="2">
                      <Label>To</Label>
                      <input
                        name="to_date"
                        type="date"
                        className="form-control"
                        value={masterObject?.to_date}
                        onChange={handleDate}
                        min={masterObject?.from_date}
                      />
                    </Col>

                    <Col md="2">
                      <Label>Ward</Label>
                      <Select
                        name="ward"
                        value={selectedWard}
                        classNamePrefix="select2-selection"
                        options={wardOptions}
                        onChange={(selected) => {
                          handleSelectChange(selected, "ward");
                        }}
                      />
                    </Col>

                    <Col md="2">
                      <Label>Group</Label>
                      <Select
                        name="group"
                        classNamePrefix="select2-selection"
                        value={selectedGroup}
                        options={groupOptions}
                        onChange={(selected) => {
                          handleSelectChange(selected, "group");
                        }}
                      />
                    </Col>

                    <Col md="2">
                      <Label>Billing Type</Label>
                      <Select
                        name="billingType"
                        value={selectedBillingType}
                        classNamePrefix="select2-selection"
                        options={[
                          {
                            label: "Monthly",
                            value: "61f775c6aef04ceabc89eae7",
                          },
                          { label: "Spot", value: "61f77607aef04ceabc89eae9" },
                        ]}
                        onChange={(selected) => {
                          handleSelectChange(selected, "billingType");
                        }}
                      />
                    </Col>

                    <Col md="2">
                      <Label>Customer Type</Label>
                      <Select
                        name="customerType"
                        classNamePrefix="select2-selection"
                        value={selectedCustType}
                        options={[
                          { label: "Residential", value: 1 },
                          { label: "Commercial", value: 2 },
                        ]}
                        onChange={(selected) => {
                          handleSelectChange(selected, "customerType");
                        }}
                      />
                    </Col>

                    <Col md="3">
                      <div style={{ display: "flex" }}>
                        <div style={{ marginTop: "29px" }}>
                          <Button
                            type="submit"
                            color="primary"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </Button>
                        </div>

                        <div style={{ marginTop: "29px", marginLeft: "15px" }}>
                          <Button
                            type="submit"
                            color="danger"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                        {isSuperAdmin() ? (
                          <div
                            style={{ marginTop: "29px", marginLeft: "15px" }}
                          >
                            <Button type="submit" color="success">
                              <CSVLink
                                data={dataToBeExported}
                                filename={
                                  "No_visit_report_" +
                                  getDate(new Date()) +
                                  ".xls"
                                }
                                style={{ color: "white" }}
                              >
                                Export
                              </CSVLink>
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="noVisitReportTableId"
                    responsive
                    bordered
                    info={false}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    paging={false}
                    data={data}
                    onSearch={debounce(handleSearch, 500)}
                    entries={PerPageCount}
                  />
                  {totalPages > 20 && (
                    <div className="pagination-div">
                      <div className="page-details">
                        Showing {(currentPage - 1) * PerPageCount + 1} to{" "}
                        {Math.min(currentPage * PerPageCount, totalPages)} of{" "}
                        {totalPages} entries
                      </div>
                      <Pagination
                        color="standard"
                        shape="roundered"
                        defaultPage={1}
                        count={Math.ceil(totalPages / PerPageCount)}
                        page={currentPage}
                        onChange={paginate}
                        info={true}
                        size="large"
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NoVisitReport;
