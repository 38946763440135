import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./style.scss";
import _ from "lodash";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  LoadScript,
  Polyline,
} from "@react-google-maps/api";
import { getLocalbody } from "../../helpers/globalFunctions";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

const DashboardScreen2 = (props) => {
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [currentIndex, setCurrentIndex] = useState(0);
  const [directionsdata, setDirectionsData] = useState({});
  const [datalist, setDataList] = useState([]);
  const [polylines, setPolylines] = useState([]);
  const accessToken = props.match.params.accessToken;
  const userId = props.match.params.user_id;
  const latitude = 11.8862283;
  const longitude = 75.3717828;

  useEffect(() => {
    handleData(userId);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.href = "/tv_screen2/" + accessToken + "/" + userId;
    }, 190000);
  }, [userId]);

  const handleData = (userId) => {
    const requestData = {
      user_id: "64d9b337c6b10bdbf52bf710",
      localbody_id: "623ac35612d1deb71fd177ea",
      latitude: 11.8862283,
      longitude: 75.3717828,
    };

    axios
      .post(`${API_URL}api/driver/map_lat_long`, requestData, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let datas = [];
        let list = res?.data?.data;
        list.forEach((item) => {
          datas.push({
            lat: parseFloat(item?.helper_drop_latitude),
            lng: parseFloat(item?.helper_drop_longitude),
            // location: item?.helper_drop_location,
          });
        });

        setDataList(datas || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const lat = 11.8862283;
  const lng = 75.3717828;

  const data = { lat: parseFloat(lat), lng: parseFloat(lng) };

  const datas = [
    { lat: parseFloat(11.9187117), lng: parseFloat(75.3567035) },
    { lat: parseFloat(11.9176416), lng: parseFloat(75.3560241) },
    { lat: parseFloat(11.9176552), lng: parseFloat(75.3559497) },
  ];

  // useEffect(() => {
  //   const waypoints = datas.slice(1, datas.length - 1).map(destination => ({
  //     location: { lat: destination.lat, lng: destination.lng },
  //     stopover: true,
  //   }));
  //   const newDirections = [];
  //   const DirectionsService = new window.google.maps.DirectionsService();
  //   const calculateRoutes = () => {
  //     datas?.map((destination) => {
  //     DirectionsService.route(
  //       {
  //         origin: data,
  //         destination: waypoints[waypoints.length - 1].location,
  //          waypoints,
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //       },
  //       (result, status) => {
  //         if (status === window.google.maps.DirectionsStatus.OK) {
  //           newDirections.push(result);
  //           setDirectionsData(newDirections);
  //         }
  //       }
  //     );
  //      });
  //   };

  //   calculateRoutes();
  // }, [data, datalist]);

  // useEffect(() => {
  //   const DirectionsService = new window.google.maps.DirectionsService();
  //   const calculateRoutes = async () => {
  //     const MAX_WAYPOINTS_PER_REQUEST = 5;
  //     const waypointsChunks = [];
  //     for (let i = 0; i < datalist.length; i += MAX_WAYPOINTS_PER_REQUEST) {
  //       const waypointsChunk = datalist.slice(i, i + MAX_WAYPOINTS_PER_REQUEST);
  //       waypointsChunks.push(waypointsChunk);
  //     }
  //     const newDirections = [];
  //     for (const waypointsChunk of waypointsChunks) {
  //       const waypoints = waypointsChunk.map((waypoint) => ({
  //         location: { lat: waypoint.lat, lng: waypoint.lng },
  //         stopover: true,
  //       }));

  //       DirectionsService.route(
  //         {
  //           origin: data,
  //           destination: waypoints[waypoints.length - 1].location,
  //           waypoints,
  //           travelMode: window.google.maps.TravelMode.DRIVING,
  //           optimizeWaypoints: true,
  //         },
  //         (result, status) => {
  //           if (status === window.google.maps.DirectionsStatus.OK) {
  //             newDirections.push(result);
  //             if (newDirections.length === waypointsChunks.length) {
  //               setDirectionsData(newDirections);
  //             }
  //           }
  //         }
  //       );
  //     }
  //   };

  //   calculateRoutes();
  // }, [data, datas]);


  const MAX_WAYPOINTS_PER_REQUEST =35;

  const calculateRoutes = async () => {
    const start = currentIndex * MAX_WAYPOINTS_PER_REQUEST;
    const end = start + MAX_WAYPOINTS_PER_REQUEST;
    const waypointsChunk = datalist.slice(start, end);

    const newDirections = [];

    const DirectionsService = new window.google.maps.DirectionsService();
    const requests = waypointsChunk.map(waypoint =>
      new Promise(resolve =>
        DirectionsService.route(
          {
            origin: data,
            destination: { lat: waypoint.lat, lng: waypoint.lng },
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              newDirections.push(result);
              resolve();
            }
          }
        )
      )
    );

    await Promise.all(requests);
    setDirectionsData(newDirections);
  };

  useEffect(() => {
    if (currentIndex * MAX_WAYPOINTS_PER_REQUEST < datalist.length) {
      calculateRoutes();
      setCurrentIndex(currentIndex + 1);
    }
  }, [currentIndex, datalist, directionsdata]);



  

  const directionsRenderers = useMemo(
    () =>
      Array.isArray(directionsdata) &&
      directionsdata.map((direction, index) => (
        <DirectionsRenderer key={index} directions={direction} />
      )),
    [directionsdata]
  );

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ paddingTop: "10px", paddingBottom: "0px", overflowAnchor:"none" }}
      >
        {/* <div id="map" style={{ width: "100%", height: "100vh" }} /> */}
        
        <GoogleMap
          center={data}
          defaultZoom={12}
          // zoom={11}
          mapContainerStyle={{ width: "100%", height: "100vh" }}
        >
          {/* {directionsdata && (
            <DirectionsRenderer directions={directionsdata} origin={data} />
          )} */}

          {directionsRenderers}
        </GoogleMap>
      </div>
    </React.Fragment>
  );
};
export default DashboardScreen2;
