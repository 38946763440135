import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
//import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getDate,
  getFirstday,
  getLocalbody,
} from "../../../helpers/globalFunctions";
import moment from "moment";
import "./statusLog.scss";
import { CSVLink } from "react-csv";
import { getCustomerType } from "../../../store/actions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const FoodWasteStatusLog = (props) => {
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [searchData, setSearchData] = useState({
    // from_date: getFirstday(new Date()),
    // to_date: getDate(new Date()),
  });
  const [details, setDetails] = useState([]);
  // const [wasteItems, setWasteItems] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [selectedProcessingMethod, setSelectedProcessingMethod] =useState(null);
  const [processingMethodOptions, setProcessingMethodOptions] = useState([]);
  const [customerType, setCustomerType] = useState([]);
  const dispatch = useDispatch();
  const { customerTypes } = useSelector((state) => state.customers);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
    fetch_all_wards(currentLocalbody);
    // fetch_waste_items();
    fetch_all_staff(currentLocalbody);
    dispatch(getCustomerType());
    fetch_all_category();
    //fetch_all_customers();
    fetch_all_methods();
  }, [currentLocalbody]);

  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);
  const handleCustomerSearch = (query) => {
    let data = {
      keyword: query,
      localbody_id: currentLocalbody,
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const fetch_all_staff = (localbody,query) => {
    let data = {
        keyword: query,
        localbody_id: [localbody],
      };
    axios
      .post(`${API_URL}customers/option/list` ,data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setStaffOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const updateStatus = (item) => {
    axios
      .put(`${API_URL}workrequest/follow_ups/` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          handleTableData();
        }
        // } else {
        //   toastr.error(res.data.message);
        //   return;
        // }
      });
  };

  const fetch_all_customers = (localbody, query) => {
    let data = {
      keyword: query,
      localbody_id: [localbody],
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

 



  function handleTableData(
    from_date = "",
     to_date = "",
     ward_id = "", 
    group_id = "",
      method_id = ""
      ) {let user_id = "";
   

      if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        user_id = obj._id;
      }

      var url =
      `${API_URL}foodwaste/status?localbody_id=` +
      currentLocalbody +
      "&user_id=" +
      user_id;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date :"";
      to_date = to_date ? to_date : "";
    }
    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&method_id=" +
      method_id
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })

 
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.date = moment(item.foodwaste_status_date).format("DD-MM-YYYY");
          
            item.time = moment(item?.foodwaste_status_time, "hhmmss").format(
              "hh:mm a"
            );
          
            item.group &&
            item.group.map((group) => {
              item.group_name = group?.group_name;
            });
            item.ward &&
            item.ward.map((ward) => {
              item.ward_name = ward?.ward_name;
            });
            item.customer &&
            item.customer.map((customer) => {
              item.customer_name = customer?.cust_name;
            });
            item.user &&
            item.user.map((user) => {
              item.staff_name = user?.firstName;
            });
            // item.action = (
            //   <div style={{ display: "flex", justifyContent: "center" }}>
            //     {/* <i
            //         className="fas fa-eye"
            //         style={{
            //           fontSize: "1em",
            //           cursor: "pointer",
            //           marginLeft: "0.2em",
            //           marginRight: "0.5em",
            //         }}
            //         // onClick={() => {
            //         // }}
            //       ></i> */}
            //     <i
            //       className="far fa-trash-alt"
            //       style={{ fontSize: "1em", cursor: "pointer" }}
            //       onClick={() => {
            //         // setWorkRequestIdToBeDeleted(item._id);
            //         // setConfirmDeleteAlert(true);
            //       }}
            //     ></i>
            //   </div>
            // );
          });
        setDetails(result);
      });
  }
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  function fetch_all_category() {
    axios
      .get(`${API_URL}workrequest/categorylist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var category_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.category_name,
              value: el._id,
            };
          });
          setCategoryOptions([
          {
            options: category_data,
          },
        ]);
      });
  }
 
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Customer",
        field: "customer_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ward",
        field: "ward_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Group",
        field: "group_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Method",
        field: "method",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff_name",
        sort: "asc",
        width: 200,
      },
      //   {
      //     label: "Action",
      //     field: "action",
      //     sort: "asc",
      //     width: 200,
      //   },
    ],
    rows: details,
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let category_id = searchData?.category_id ? searchData.category_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const fetch_all_methods = () => {
    axios
      .get(`${API_URL}foodwaste/methodlist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let method_data =
          res.data.data &&
          res.data.data.map((method) => {
            return {
              label: method?.method_name,
              value: method?._id,
            };
          });
        setProcessingMethodOptions([
          {
            options: method_data,
          },
        ]);
      });
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
          group_id: "",
        });
        setSelectedGroup(null);
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
              currentLocalbody +
              "&ward_id=" +
              selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        // handleTableData(
        //   date1,
        //   date2,
        //   selected.value,
        //   "",
        //   staff_id,
        //   category_id
        // );
        break;
      case "group":
        setSelectedGroup(selected);
        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   selected.value,
        //   staff_id,
        //   category_id
        // );
        break;
      // case "customer":
      //   setSelectedStaff(selected);
      //   setSearchData({
      //     ...searchData,
      //     ["customer_id"]: selected.value,
      //   });
       
      //   break;
        
      case "processingMethod":
        setSelectedProcessingMethod(selected);
        setSearchData({
          ...searchData,
          ["method_id"]: selected.value,
        });
        
    
        break;
      default:
        break;
    }
  };
  const reset = () => {
    setSearchData({
      
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedStaff(null);
    setSelectedProcessingMethod(null);
    setSelectedCategory(null);
    setGroupOptions([]);
    handleTableData();
  };
  const handleSearch = () => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let method_id = searchData?.method_id ? searchData.method_id : "";
    handleTableData(date1, date2, ward_id, group_id,method_id);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Food Waste Status Log" />
          <Row>
            <Col className="col-12">
              {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {"19.00"}</h5>
              </Row> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Group</Label>
                        <Select
                          name="group_id"
                          value={selectedGroup}
                          onChange={(selected) => {
                            handleSelectChange(selected, "group");
                          }}
                          options={groupOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label>Customer</Label>
                        <Select
                          name="customer_id"
                          value={selectedCustomer}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customer");
                          }}
                          options={customerOptions}
                          onInputChange={(input) => handleCustomerSearch(input)}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Processing Method</Label>
                        <Select
                          name="method_id"
                          value={selectedProcessingMethod}
                          onChange={(selected) => {
                            handleSelectChange(selected, "processingMethod");
                          }}
                          options={processingMethodOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label>Customer</Label>
                        <Select
                          name="customer_id"
                          value={selectedStaff}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customer");
                          }}
                          options={staffOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}
                    </Row>
                 <Row>
                    <Col md="1" style={{ paddingTop: "10px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="primary"
                          type="submit"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </div>
                    </Col>
                    {/* <Col md="1" style={{ paddingTop: "14px" }}>
                      <div className="mt-2 mb-3">
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Waste_request_" +
                              getDate(new Date()) +
                              ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col> */}
                    <Col md="1" style={{ paddingTop: "10px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <MDBDataTable
                    id="foodWasteStatusLogID"
                    responsive
                    // striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default FoodWasteStatusLog;
