import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  FormGroup,
  Input,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getLocalbodies,
  addLocalbody,
  deleteLocalbody,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getCompaniesOptionsSuccess,
  getDistrictsSuccess,
  getBranchesOptions,
  getDistrictOptions,
  updateLocalbody,
  getLocalbodyTypes,
  formreset,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import { getLocalbody } from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./localbody.scss";

const Localbodies = (props) => {
  const [selectedPrivilage, setSelectedPrivilage] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [localbodyObject, setLocalbodyObject] = useState({
    driver_option: 1,
  });
  const [localbodyIdTobeUpdated, setLocalbodyIdToBeUpdated] = useState(null);
  const [localbodyIdToBeDeleted, setLocalbodyIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [localbodysForTable, setLocalbodiesForTable] = useState([]);
  const [companyOptions, setcompanyOptions] = useState([]);
  const [companyName, setCompanyname] = useState(null);
  const [districtname, setDistrictname] = useState(null);
  const [districtoptions, setDistrictOptions] = useState([]);
  const [localbodytype, setlocalbodytype] = useState(null);
  const [localbodyname, setlocalbodyname] = useState("");
  const [shortcode, setshortcode] = useState("");
  const [localbody_receipt_footer, setlocalbody_receipt_footer] = useState("");
  const [passwordObject, setPasswordObject] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    districtError: "",
    companyError: "",
    localbodyTypeError: "",
  });
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const {
    localbodies,
    addingLocalbody,
    addLocalbodyResponse,
    deleteLocalbodyResponse,
    updateLocalbodyResponse,
    error,
    localbodyTypes,
  } = useSelector((state) => state.localbodies);

  // const { companiesOptions, companyname } = useSelector(
  //   (state) => state.companies
  // );
  const { districtOptions } = useSelector((state) => state.districts);
  const dispatch = useDispatch();
  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    /* 
      if (localStorage.getItem('Company')) {
        var companydata = localStorage.getItem('Company');
        var company = JSON.parse(companydata)
        setSelectedCompany(company)
      }*/
    //dispatch(getLocalbodies());
    handleTableData();
    dispatch(getCompaniesOptions());

    dispatch(getDistrictOptions());
    // fetch_district(currentLocalbody);
    dispatch(getLocalbodyTypes());
  }, [currentLocalbody]);

  useEffect(() => {
    if (addLocalbodyResponse.type === "success") {
      //dispatch(getLocalbodies());
      handleTableData();
      toastr.success(addLocalbodyResponse.message);
      setSelectedPrivilage({});
      setSelectedCompany(null);
      setSelectedBranch(null);
    } else if (addLocalbodyResponse.type === "failure") {
      toastr.error(error.data.message, addLocalbodyResponse.message);
    }
  }, [addLocalbodyResponse]);

  useEffect(() => {
    if (deleteLocalbodyResponse.type === "success") {
      //dispatch(getLocalbodies());
      handleTableData();
      toastr.success(deleteLocalbodyResponse.message);
      setLocalbodyIdToBeDeleted(null);
    } else if (deleteLocalbodyResponse.type === "failure") {
      toastr.error(error.data.message, deleteLocalbodyResponse.message);
    }
  }, [deleteLocalbodyResponse]);

  useEffect(() => {
    if (updateLocalbodyResponse.type === "success") {
      //dispatch(getLocalbodies());
      handleTableData();
      setShowModal(false);
      setLocalbodyIdToBeUpdated(null);
      setPasswordObject({});
      toastr.success(updateLocalbodyResponse.message);
    } else if (updateLocalbodyResponse.type === "failure") {
      toastr.error(error.data.message, updateLocalbodyResponse.message);
    }
  }, [updateLocalbodyResponse]);

  useEffect(() => {
    var data =
      districtOptions &&
      districtOptions.map((el) => {
        return {
          label: el.district_name,
          value: el._id,
        };
      });

    setDistrictOptions([
      {
        options: data,
      },
    ]);
  }, [districtOptions]);

  function handleTableData() {
    var url = `${API_URL}localbody/list?localbody_id=` + currentLocalbody;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let localbodyData = [];

        result &&
          result.map((item, index) => {
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="uil-edit-alt"
                  style={{
                    fontSize: "1.2em",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateLocalbody(item);
                  }}
                ></i>

                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  onClick={() => {
                    setLocalbodyIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
            item.id = index + 1;

            if (item?.localbody_updatedby) {
              item.localbodyaddedby = item.localbody_updatedby.firstName;
            } else {
              if (item.localbody_addedby != null) {
                item.localbodyaddedby = item.localbody_addedby.firstName;
              }
            }

            // if (item.localbody_addedby != null) {
            //   item.localbodyaddedby = item.localbody_addedby.firstName;
            // }
            if (item.localbody_company != null) {
              item.company_name = item.localbody_company.company_name;
            }
            if (item.dist_id != null) {
              item.district_name = item.dist_id.district_name;
            }
            if (item.local_body_id) {
              item.localbody_type = item.local_body_id.localbody_type_name;
            }

            // item.localbody_date = moment(item?.localbody_date).format("DD-MM-YYYY")
            item.date = moment(item.localbody_date).format("DD-MM-YYYY");
            item.time = moment(item.localbody_time, "HHmmss").format("hh:mm a"); // 24hours

            localbodyData.push(item);
          });
        setLocalbodiesForTable(localbodyData);
      });
  }
  // const fetch_district = (id) => {
  //   axios
  //     .get(`${API_URL}api/staff/districts?localbody_id=` + id, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       let result = res.data.data;
  //       if (res.data && res.data.success === true) {
  //         var district_data =
  //           res.data.data &&
  //           res.data.data.map((el) => {
  //             return {
  //               label: el.district_name,
  //               value: el._id,
  //             };
  //           });
  //       } else {
  //         district_data = [];
  //       }

  //       setDistrictOptions([
  //         {
  //           options: district_data,
  //         },
  //       ]);
  //     });
  // };
  let preUpdateLocalbody = (item) => {
    if (item.localbody_company) {
      let company = {
        label: item.localbody_company.company_name,
        value: item.localbody_company._id,
      };
      let companyValue = {
        name: item.localbody_company.company_name,
        _id: item.localbody_company._id,
      };
      item.company = companyValue;
      handleChangeCompany(company);
    }
    if (item.dist_id) {
      let district = {
        label: item.dist_id.district_name,
        value: item.dist_id._id,
      };
      let districtValue = {
        name: item.dist_id.district_name,
        _id: item.dist_id._id,
      };
      item.district = districtValue;
      // handleChangeDistrictName(district);
      fetch_all_company(district?.value);
      setDistrictname(district);
    }

    if (item.local_body_id) {
      let type = {
        label: item.local_body_id.localbody_type_name,
        value: item.local_body_id._id,
      };
      let localbodyType = {
        name: item.local_body_id.localbody_type_name,
        _id: item.local_body_id._id,
      };
      item.localbodytype = localbodyType;
      handleChangeLocalbodyType(type);
    }

    setlocalbodyname(item.localbody_name);
    setshortcode(item.short_code);
    setlocalbody_receipt_footer(item.localbody_receipt_footer);
    setLocalbodyIdToBeUpdated(item._id);
    setLocalbodyObject({ ...item, password: null });
  };

  let preUpdateLocalbodyPassword = (item) => {
    setLocalbodyIdToBeUpdated(item._id);
    setShowModal(true);
  };
  //let preUpdateUser = (item) => {
  //     if (item.privilage) {
  //       let privilage = {
  //         label: item.privilage.name,
  //         value: item.privilage._id,
  //       };
  //       handleSelectedPrivilage(privilage);
  //     }
  //     if (item.company) {
  //       let company = {
  //         label: item.company.name,
  //         value: item.company._id,
  //       };
  //       handleSelectedCompany(company);
  //     }
  //     if (item.branch) {
  //       let branch = {
  //         label: item.branch.name,
  //         value: item.branch._id,
  //       };
  //       handleSelectedBranch(branch);
  //     }

  //     setUserIdToBeUpdated(item._id);
  //     setUserObject({ ...item, password: null });
  //   };

  //   let preUpdateUserPassword = (item) => {
  //     setUserIdToBeUpdated(item._id);
  //     setShowModal(true);
  //   };

  // useEffect(() => {
  //   let localbodyData = [];

  //   localbodies.map((item, index) => {
  //     item.action = (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <i
  //           className="uil-edit-alt"
  //           style={{
  //             fontSize: "1.2em",
  //             cursor: "pointer",
  //             marginLeft: "0.5rem",
  //             marginRight: "0.5rem",
  //           }}
  //           onClick={() => {
  //             toTop();
  //             preUpdateLocalbody(item);
  //           }}
  //         ></i>

  //         <i
  //           className="uil-trash-alt"
  //           style={{ fontSize: "1.2em", cursor: "pointer" }}
  //           onClick={() => {
  //             setLocalbodyIdToBeDeleted(item._id);
  //             setConfirmDeleteAlert(true);
  //           }}
  //         ></i>
  //       </div>
  //     );
  //     item.id = index + 1;
  //     if (item.localbody_addedby != null) {
  //       item.localbodyaddedby = item.localbody_addedby.username;
  //     }
  //     if (item.localbody_company != null) {
  //       item.company_name = item.localbody_company.company_name;
  //     }
  //     if (item.dist_id != null) {
  //       item.district_name = item.dist_id.district_name;
  //     }
  //     if (item.local_body_id) {
  //       item.localbody_type = item.local_body_id.localbody_type_name;
  //     }

  //     // item.localbody_date = moment(item?.localbody_date).format("DD-MM-YYYY")
  //     item.date = moment(item.localbody_date).format("DD-MM-YYYY");
  //     item.time = moment(item.localbody_time,"HHmmss").format("hh:mm a"); // 24hours

  //     localbodyData.push(item);
  //   });
  //   setLocalbodiesForTable(localbodyData);
  // }, [localbodies]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date ",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Time ",
        field: "time",
        sort: "asc",
        width: 50,
      },
      {
        label: "Company ",
        field: "company_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "District ",
        field: "district_name",
        sort: "asc",
        width: 400,
      },

      {
        label: " Type",
        field: "localbody_type",
        sort: "asc",
        width: 400,
      },
      {
        label: " Name",
        field: "localbody_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Shortcode",
        field: "short_code",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reciept Footer",
        field: "localbody_receipt_footer",
        sort: "asc",
        width: 400,
      },
      {
        label: "Staff",
        field: "localbodyaddedby",
        sort: "asc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: localbodysForTable,
  };

  function handleChangeLocalbodyname(e) {
    let name = e.target.name;
    let value = e.target.value;
    setlocalbodyname(value);
    setLocalbodyObject({ ...localbodyObject, [name]: value });
  }

  function handleChangeCompany(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setCompanyname(value);
    setErrors({
      ...errors,
      ["companyError"]: "",
    });
    if (localbodyIdTobeUpdated)
      setLocalbodyObject({ ...localbodyObject, localbody_company: newValue });
    else setLocalbodyObject({ ...localbodyObject, company: newValue });
  }
  function handleChangeDistrictName(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setDistrictname(value);
    setCompanyname(null);
    setErrors({
      ...errors,
      ["districtError"]: "",
    });
    fetch_all_company(value.value);

    if (localbodyIdTobeUpdated)
      setLocalbodyObject({ ...localbodyObject, dist_id: newValue });
    else setLocalbodyObject({ ...localbodyObject, district: newValue });
  }
  const fetch_all_company = (id) => {
    axios
      .get(`${API_URL}company/list/district?district_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var company_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.company_name,
              value: el._id,
            };
          });
        setcompanyOptions([
          {
            options: company_data,
          },
        ]);
      });
  };
  function handleChangeLocalbodyType(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setlocalbodytype(value);
    setErrors({
      ...errors,
      ["localbodyTypeError"]: "",
    });
    if (localbodyIdTobeUpdated)
      setLocalbodyObject({ ...localbodyObject, local_body_id: newValue });
    else setLocalbodyObject({ ...localbodyObject, localbodytype: newValue });
  }
  function handleChangeShortcode(e) {
    let name = e.target.name;
    let value = e.target.value;
    setshortcode(value);
    setLocalbodyObject({ ...localbodyObject, [name]: value });
  }
  function handleChangereceiptContent(e) {
    let name = e.target.name;
    let value = e.target.value;
    setlocalbody_receipt_footer(value);
    setLocalbodyObject({ ...localbodyObject, [name]: value });
  }

  /* function handleSelectedPrivilage(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setSelectedPrivilage(value);
    setLocalbodyObject({ ...localbodyObject, privilage: newValue });
  
  function handleSelectedCompany(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setSelectedCompany(value);
    setLocalbodyObject({ ...localbodyObject, company: newValue });
  }
  function handleSelectedBranch(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setSelectedBranch(value);
    setLocalbodyObject({ ...localbodyObject, branch: newValue });
  }*/
  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }
  const handleValidSubmit = (event, values) => {
    if (
      districtname === null ||
      companyName === null ||
      localbodytype === null
    ) {
      let errorVal = errors;
      if (districtname === null) {
        errorVal.districtError = "Please select district";
      }
      if (companyName === null) {
        errorVal.companyError = "Please select company";
      }
      if (localbodytype === null) {
        errorVal.localbodyTypeError = "Please select localbody type";
      }
      setErrors({
        ...errorVal,
      });
    } else {
      if (localbodyIdTobeUpdated) {
        axios
          .put(
            `${API_URL}localbody` + "/" + localbodyIdTobeUpdated,
            localbodyObject,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Localbody updated successfully");
              formRef.current.reset();
              setCompanyname(null);
              setDistrictname(null);
              setlocalbodyname("");
              setlocalbodytype(null);
              setshortcode("");
              setlocalbody_receipt_footer("");
              setErrors({
                districtError: "",
                companyError: "",
                localbodyTypeError: "",
              });
              handleTableData();
              setLocalbodyIdToBeUpdated(null);
              setcompanyOptions([]);
              setLocalbodyObject({
                driver_option: 1,
              });
            } else {
              toastr.error("Failed to update localbody");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}localbody`, localbodyObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Localbody added successfully");
              formRef.current.reset();
              setCompanyname(null);
              setDistrictname(null);
              setlocalbodyname("");
              setlocalbodytype(null);
              setshortcode("");
              setlocalbody_receipt_footer("");
              setErrors({
                districtError: "",
                companyError: "",
                localbodyTypeError: "",
              });
              handleTableData();
              setcompanyOptions([]);
              setLocalbodyObject({
                driver_option: 1,
              });
              // setSelectedPrivilage({});
              // setSelectedCompany(null);
              // setSelectedBranch(null);
            } else {
              toastr.error("Failed to add localbody");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
      // localbodyIdTobeUpdated
      //   ? dispatch(updateLocalbody(localbodyObject))
      //   : dispatch(addLocalbody(localbodyObject));
    }

    // if (localbodyIdTobeUpdated) {
    //   axios
    //     .put(
    //       `${API_URL}localbody` + "/" + localbodyIdTobeUpdated,
    //       localbodyObject,
    //       {
    //         headers: {
    //           "x-access-token": accessToken,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       if (res.data.status === 200) {
    //         toastr.success("Localbody updated successfully");
    //         formRef.current.reset();
    //         setCompanyname({});
    //         setDistrictname({});
    //         setlocalbodyname("");
    //         setlocalbodytype({});
    //         setshortcode("");
    //         handleTableData();
    //         setLocalbodyIdToBeUpdated(null);
    //       } else {
    //         toastr.success("Failed to update localbody");
    //         return;
    //       }
    //     });
    // } else {
    //   axios
    //     .post(`${API_URL}localbody`, localbodyObject, {
    //       headers: {
    //         "x-access-token": accessToken,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.data.status === 200) {
    //         toastr.success("Localbody created successfully");
    //         formRef.current.reset();
    //         setCompanyname({});
    //         setDistrictname({});
    //         setlocalbodyname("");
    //         setlocalbodytype({});
    //         setshortcode("");
    //         handleTableData();
    //       } else {
    //         toastr.success("Failed to create localbody");
    //         return;
    //       }
    //     });
    // }
  };

  const handleValidSubmitPassword = (event, values) => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        _id: localbodyIdTobeUpdated,
        password: passwordObject.password,
      };
      dispatch(updateLocalbody(item));
    } else {
      toastr.error("Passwords are not matching");
    }
  };

  let closeModal = () => {
    setShowModal(false);
    setLocalbodyIdToBeUpdated(null);
  };
  const handleCheckboxChange = (e) => {
    let value = e.target.checked === true ? 1 : 0;
    setLocalbodyObject({
      ...localbodyObject,
      [e.target.name]: value,
    });
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}localbody` + "/" + localbodyIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Localbody deleted successfully");
                  if (
                    localbodyObject &&
                    localbodyObject._id === localbodyIdToBeDeleted
                  ) {
                    formRef.current.reset();
                    setCompanyname(null);
                    setDistrictname(null);
                    setlocalbodyname("");
                    setlocalbodytype(null);
                    setshortcode("");
                    setlocalbody_receipt_footer("");
                    setErrors({
                      districtError: "",
                      companyError: "",
                      localbodyTypeError: "",
                    });
                    handleTableData();
                    setcompanyOptions([]);
                    setLocalbodyIdToBeUpdated(null);
                    setLocalbodyObject({
                      driver_option: 1,
                    });
                  }
                  setLocalbodyIdToBeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error("Failed to delete localbody");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            // dispatch(deleteLocalbody(localbodyIdToBeDeleted));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Localbody" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>

                          <Select
                            name="district_name"
                            id="exampleSelect"
                            style={{ appearance: "auto" }}
                            value={districtname}
                            onChange={handleChangeDistrictName}
                            options={districtoptions}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {districtname === null ? errors.districtError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Company</Label>
                          <Select
                            name="company_name"
                            id="exampleSelect"
                            style={{ appearance: "auto" }}
                            value={companyName}
                            onChange={handleChangeCompany}
                            options={companyOptions}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {companyName === null ? errors.companyError : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Localbody Type</Label>

                          <Select
                            name="localbody_type"
                            id="exampleSelect"
                            style={{ appearance: "auto" }}
                            value={localbodytype}
                            onChange={handleChangeLocalbodyType}
                            options={localbodyTypes?.map((item) => {
                              return {
                                label: item.localbody_type_name,
                                value: item._id,
                                key: item._id,
                              };
                            })}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {localbodytype === null
                              ? errors.localbodyTypeError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Localbody Name
                          </Label>
                          <AvField
                            name="localbody_name"
                            placeholder="Localbody Name"
                            type="text"
                            errorMessage="Enter localbody Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={localbodyname}
                            onChange={handleChangeLocalbodyname}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3 mt-2">
                          <AvField
                            name="short_code"
                            placeholder="ShortCode"
                            type="text"
                            errorMessage="Enter shortcode"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={shortcode}
                            onChange={handleChangeShortcode}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3 mt-2">
                          <AvField
                            name="localbody_receipt_footer"
                            placeholder="Receipt Footer content"
                            type="text"
                            errorMessage="Enter Content"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom01"
                            value={localbody_receipt_footer}
                            onChange={handleChangereceiptContent}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div
                          className="form-check mb-4"
                          style={{ marginTop: "14px" }}
                        >
                          <Input
                            name="driver_option"
                            type="checkbox"
                            checked={
                              localbodyObject?.driver_option === 1
                                ? true
                                : false
                            }
                            className="form-check-input"
                            id="horizontal-customCheck"
                            onChange={handleCheckboxChange}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="horizontal-customCheck"
                            style={{
                              cursor: "default",
                            }}
                          >
                            Is driver available?
                          </Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3 mt-2">
                          {localbodyIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingLocalbody ? true : false}
                            >
                              {addingLocalbody ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingLocalbody ? true : false}
                            >
                              {addingLocalbody ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="localbodyTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(Localbodies));

Localbodies.propTypes = {
  error: PropTypes.any,
  localbodys: PropTypes.array,
};
