import React, { useState, useEffect,useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody } from "../../../helpers/globalFunctions";
import $ from "jquery";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./report.scss";


const CheckinCheckout = () => {
  const [type, setType] = React.useState("");
  const [details, setDetails] = useState([]);
  const [columns , setColumns]= useState([]);
  const [month, setMonth] = useState(moment().format("YYYY-MM"));
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [date,setDate] = useState([]);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [groupOptions, setgroupOptions] = useState([]);
  const [selectedStaff, setselectedStaff] = useState(null);
  const [staffOptions, setstaffOptions] = useState([]);
  const [title, setTitle] = useState(moment().format("MMMM YYYY"));
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const handleChangeDate = (e) => {
    setMonth(e.target.value);
    setTitle(moment(e.target.value).format("MMMM YYYY"));
    var group = selectedGroup;
    if(group!=null)
    {
      group = group.value;
    }
    var staff = selectedStaff;
    if(staff!=null)
    {
      staff = staff.value;
    }
    handleTableData(e.target.value,group,staff);
  };
  function handleChangeGroup(value) {
    setselectedGroup(value);
    fetch_staff(currentLocalbody,value.value);
    setselectedStaff(null);
    handleTableData(month,value.value);
  }
  const handleChangeStaff = (e) => {
    setselectedStaff(e);
    var group = selectedGroup;
    if(group!=null)
    {
      group = group.value;
    }
    handleTableData(month,group,e.value);
  };
  useEffect(() => {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");

    fetch_district(currentLocalbody);
    fetch_staff(currentLocalbody);
    handleTableData(month)
    
  },[currentLocalbody]);

  const reset = () => {
   setMonth(moment().format("YYYY-MM"));
   setselectedGroup(null);
   setselectedStaff(null);
   fetch_staff(currentLocalbody);
   handleTableData(moment().format("YYYY-MM"));
  };
  const fetch_district = (localbody) => {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    var user_id=user_obj._id;
    axios
      .get(`${API_URL}api/staff/filter_group_list?localbody_id=` + localbody+`&user_id=`+user_id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var group_data = [];
        if (res.data.success && res.data.success === true) {
          group_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.group_name,
                value: el._id,
              };
            });
        } else {
          group_data = [];
        }

        setgroupOptions([
          {
            options: group_data,
          },
        ]);
      });
  };
  const fetch_staff = (localbody,group=null) => {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    var user_id=user_obj._id;
    var groups = [];
    if(group!=null)
    {
      groups=[group];
    }
    axios
      .post(`${API_URL}api/staff/filter_staff_list`,{localbody_id:localbody,user_id:user_id,groups:groups}, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data = [];
        if (res.data.success && res.data.success === true) {
          staff_data =
            res.data.data &&
            res.data.data.map((el) => {
                return {
                  label: el.firstName+" "+el.lastName,
                  value: el._id,
                };
              
            });
            staff_data.shift();
        } else {
          staff_data = [];
        }

        setstaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  const handleTableData = (month,group=null,staff=null) => {
    var colmns=[{
      label: "Staff",
      field: "staff",
      sort: "asc",
      width: 150,
    }];

    var year_from_month = month.split("-")[0];
    var month_from_month = month.split("-")[1];
    var month_days = new Date(year_from_month, month_from_month, 0).getDate();
    var dates = [];
    for(var i=1;i<=month_days;i++)
    {
      colmns.push({
        label: i,
        field: "d"+i,
        width: 150,
      });
      dates.push(month+"-"+i.toString().padStart(2, '0'))
    }
    setColumns(colmns);
    setDate(dates);

    let url =`${API_URL}api/staff/attendance_report`;
    axios
      .post(url,{"month":month,"localbody_id":currentLocalbody,dates:dates,group:group,staff:staff}, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
            result.map((el, index) => {
              el.staff=(
                <div>
                  {el.staff}<br></br>
                  {el.lastName}
                </div>
              )
            });
        setDetails(result);
        $("#attendance_report td").removeAttr("style");
        $("#attendance_report td:not(:first-child):contains('H')").attr("style","background-color:#77889973; color:white; vertical-align:middle");
        $("#attendance_report td:not(:first-child):contains('P')").attr("style","background-color:#abd5c5; color:white; vertical-align:middle");
        $("#attendance_report td:not(:first-child):contains('A')").attr("style","background-color:#d17a7aa6; color:white; vertical-align:middle");
        $("#attendance_report td:not(:first-child):contains('HL')").attr("style","background-color:#ffc299; color:white; vertical-align:middle");
      
      });
  };

  const data = {
    columns: columns,
    rows: details
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Attendance Report" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <input
                              className="form-control"
                              type="month"
                              value={month}
                              id="example-date-input"
                              onChange={handleChangeDate}
                              max={moment().format("YYYY-MM")}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                             <Select
                            name="group"
                            placeholder="Group"
                            value={selectedGroup}
                            options={groupOptions}
                            classNamePrefix="select2-selection"
                            onChange={handleChangeGroup}
                          />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                             <Select
                            name="staff"
                            placeholder="Staff"
                            value={selectedStaff}
                            options={staffOptions}
                            classNamePrefix="select2-selection"
                            onChange={handleChangeStaff}
                          />
                          </div>
                        </Col>
                      <Col md="1">
                        
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        
                      </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                <CardHeader><center><h5>Attendance - {title}</h5></center></CardHeader>
                  <CardBody>
                    <MDBDataTable paging={false} sortable={false} searching={false} id="attendance_report" responsive bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default CheckinCheckout;
