import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import { apiError } from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import { CSVLink } from "react-csv";
import { AvForm } from "availity-reactstrap-validation";
import ReactAudioPlayer from "react-audio-player";
import moment from "moment";
import "./helperDrop.scss";

const HelperDrop = (props) => {
  const [details, setDeatils] = useState([]);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [currentLocalbody, setCurrentLocalbody] = useState(getLocalbody());
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [helperDropItemDetails, setHelperDropItemDetails] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
  }, [currentLocalbody]);

  function handleTableData(from_date = "", to_date = "") {
    var url = `${API_URL}waste/helper_drop?localbody_id=` + currentLocalbody;

    if (from_date === "" && to_date === "") {
    from_date = from_date ? from_date : getFirstday(new Date());
    to_date = to_date ? to_date : getDate(new Date());
    }

    url = url + "&from_date=" + from_date + "&to_date=" + to_date;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let dataToBeExported = [];
        result &&
          result.map((item, index) => {
            let exportItem = {};
            item.id = index + 1;
            if (item?.staff_lastname)
              item.staff = item.staff_firstname + " " + item.staff_lastname;
            else item.staff = item.staff_firstname;
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.1em",
                  }}
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                ></i>
              </div>
            );

            //setting export data
            exportItem.id = item.id;
            exportItem.Date = item.drop_date;
            exportItem.Spot = item.helper_drop_location;
            exportItem.Total_Bags = item.helper_drop_total_bags;
            exportItem.Total_Weight = item.helper_drop_total_weight;
            exportItem.Staff = item.staff;

            dataToBeExported.push(exportItem);
          });
        setDeatils(result);
        setDataToBeExported(dataToBeExported);
      });
  }
  const handleClickOpenForm = (row) => {
    let data = {
      drop_id: row._id,
    };
    axios
      .post(`${API_URL}waste/helper_drop_view`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item) => {
            if (item?.staff_lastname)
              item.staff = item.staff_firstname + " " + item.staff_lastname;
            else item.staff = item.staff_firstname;

            let dropItems = item.drop_items;
            dropItems &&
              dropItems.map((itm, index) => {
                itm.id = index + 1;
                if (itm.helper_drop_item_paid_status === 0) itm.status = "Paid";
                else itm.status = "Unpaid";
              });
            item.warehouse = "";

            item.warehouse_info &&
              item.warehouse_info.forEach((element) => {
                item.warehouse = element.warehouse_name;
              });
            item.driverName = "";

            item.driver_info &&
              item.driver_info.forEach((driver) => {
                if (driver?.lastName)
                  item.driverName = driver.firstName + " " + driver.lastName;
                else item.driverName = driver.firstName;
              });
            setHelperDropItemDetails(dropItems);
            setPopupData(item);
          });

        setPopupView(true);
      });
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    // let fromDate = moment(date1, "DD-MM-YYYY");
    // let toDate = moment(date2, "DD-MM-YYYY");

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    // let result = toDate.diff(fromDate, "days");
    // if (Number(result) + 1 < 0) {
    //   setSearchData({
    //     ...searchData,
    //     [e.target.name]: e.target.value,
    //     ["to_date"]: "",
    //   });
    //   date2 = "";
    // } else {
    //   setSearchData({
    //     ...searchData,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    // setSearchData({
    //   ...searchData,
    //   [e.target.name]: e.target.value,
    // });

    handleTableData(date1, date2);
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    handleTableData();
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "drop_date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Drop ID",
        field: "helper_drop_id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Spot",
        field: "helper_drop_location",
        sort: "asc",
        width: 200,
      },
      {
        label: "Latitude",
        field: "helper_drop_latitude",
        sort: "asc",
        width: 200,
      },
      {
        label: "Longitude",
        field: "helper_drop_longitude",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Bags",
        field: "helper_drop_total_bags",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Weight",
        field: "helper_drop_total_weight",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "	Action	",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  };
  const helperDetailView = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Item Name",
        field: "helper_drop_items_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Bags",
        field: "helper_drop_items_bags",
        sort: "asc",
        width: 150,
      },
      {
        label: "Weight",
        field: "helper_drop_items_weight",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
    ],
    rows: helperDropItemDetails,
  };
  return (
    <React.Fragment>
      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Helper Drop Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px" }}>Date :</td>
                <td>{popupData?.drop_date}</td>
                <td>Drop ID :</td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.helper_drop_id}
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Spot :</td>
                <td>{popupData?.helper_drop_location}</td>
                <td>Total Bags :</td>
                <td style={{ textAlign: "left", width: "40px" }}>
                  {popupData?.helper_drop_total_bags}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Total Weight :</td>
                <td>{popupData?.helper_drop_total_weight}</td>
                <td>Audio :</td>
                <td>
                  {
                    <ReactAudioPlayer
                      style={{ marginLeft: "-15px" }}
                      src={`${API_URL}uploads/helper_drop_audio/${popupData.helper_drop_voice}`}
                      //autoPlay
                      controls
                    />
                  }
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>
                  {popupData?.helper_drop_current_status === 0
                    ? "Pending"
                    : popupData?.helper_drop_current_status === 2
                    ? "Drop"
                    : "Collect"}
                </td>
                <td hidden={popupData?.helper_drop_current_status !== 2}>
                  Warehouse :
                </td>
                <td
                  style={{ textAlign: "left", width: "40px" }}
                  hidden={popupData?.helper_drop_current_status !== 2}
                >
                  {popupData?.warehouse}
                </td>
                <td hidden={popupData?.helper_drop_current_status !== 1}>
                  Driver :
                </td>
                <td
                  style={{ textAlign: "left", width: "40px" }}
                  hidden={popupData?.helper_drop_current_status !== 1}
                >
                  {popupData?.driverName}
                </td>
              </tr>
            </table>
            <Row className="mt-3 mb-2">
              <h6 className="modal-title mt-0" style={{ textAlign: "center" }}>
                Helper Drop Item Details
              </h6>
            </Row>
            <Row>
              <MDBDataTable
                id="helperDropItemDetailId"
                responsive
                bordered
                data={helperDetailView}
                searching={false}
                info={false}
                paging={false}
                disableRetreatAfterSorting={true}
                //entries={10}
              />
            </Row>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Helper Drop" />
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="from_date"
                        name="from_date"
                        value={searchData?.from_date}
                        onChange={handleDate}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        value={searchData?.to_date}
                        onChange={handleDate}
                        min={searchData?.from_date}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="2" >
                    <div style={{display:"flex"}}>
                  <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                      <Button color="success" type="submit">
                        <CSVLink
                          data={dataToBeExported}
                          filename={
                            "Helper_drop_report_" + getDate(new Date()) + ".xls"
                          }
                          style={{ color: "white" }}
                        >
                          Export
                        </CSVLink>
                      </Button>
                    </div>
                  
                    <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                    </div>
                  </Col>
                </Row>
                <MDBDataTable
                  id="helperDropTableId"
                  responsive
                  bordered
                  data={data}
                  searching={true}
                  paging={true}
                  info={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(HelperDrop));

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
