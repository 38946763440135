import React, { useState, useEffect,useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { Modal } from "react-bootstrap";
import { Row, Col, Card, CardBody, Button, Label, NavItem, CardHeader } from "reactstrap";
import { AvField,AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import "./CheckinCheckoutLog.scss";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody } from "../../../helpers/globalFunctions";
import Select from "react-select";
const AttendanceSummary = () => {
  const [details, setDetails] = useState([]);
  const [popupdetails, setPopupDetails] = useState([]);
  const [filter, setFilter] = useState({from_date:moment().format("YYYY-MM-DD"),to_date:moment().format("YYYY-MM-DD")});
  const [showPopup, setShowPopup] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData(moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD"));
  }, [currentLocalbody]);
  const handleTableData = (from,to,type="",status="") => {
    let url =
      `${API_URL}api/staff/punch_log?localbody_id=` + currentLocalbody;
    url = url + "&from_date=" + from+"&to_date=" + to+"&type="+type+"&punch_status="+status;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((element, index) => {
            element.id = index + 1;
            element.punchin_date = moment(element.punchin.punch_date).format("DD-MM-YYYY")+" "+element.punchin.punch_time;
            element.punchout_date = "";
            element.punch_type = element.punchin.punch_type;
            element.punchin_loc = element.punchin.punch_location;
            element.punchout_loc = "";
            element.punch_status = (
            <span class="badge rounded-pill bg-success ">IN</span>
            );
            element.total_hours = element.punchin.punch_duration;
            if(element.punchout!="")
            {
              element.punchout_date = moment(element.punchout.punch_date).format("DD-MM-YYYY")+" "+element.punchout.punch_time;
              element.punch_type = element.punchout.punch_type;
              element.punchout_loc = element.punchout.punch_location;
              element.punch_status = (
                <span class="badge rounded-pill bg-danger">OUT</span>
                );;
              element.total_hours = element.punchout.punch_duration;
            }

            element.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenModal(element);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  };
  const handleTypeChange = (selected) =>{
    setFilter({
      ...filter,
      ["type"]:selected
    });

  }
  const handleStatusChange = (selected) =>{
    setFilter({
      ...filter,
      ["status"]:selected
    });

  }
  const handleChangeDate = (e) => {
    setFilter({
      ...filter,
      [e.target.name]:e.target.value
    })
  };
  const handleSearch = (from,to,type="",status="") => {
    handleTableData(from,to,type,status);
  };
  const reset = () => {
    setFilter({from_date:moment().format("YYYY-MM-DD"),to_date:moment().format("YYYY-MM-DD"),status:"",type:""});
    handleTableData(moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD"));
  }
  const handleClickOpenModal = (data) => {
    setModalTitle(data.punchin.staff_name+" - Log On "+moment(data.date).format("DD-MM-YYYY"))
    axios
      .get(
        `${API_URL}api/staff/staff_punch_log?staff_id=` +
          data?._id.staff_id +"&date=" +data.date,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          var attendance = [];
          let result = res.data.data;
          result &&
            result.map((element, index) => {
              element.id = index + 1;
              element.punch_date = moment(element.punch_date).format("DD-MM-YYYY");
              if(element.punch_status=="0")
              element.punch_status = (
              <span class="badge rounded-pill bg-success ">IN</span>
              );
              else
              element.punch_status = (
                <span class="badge rounded-pill bg-danger ">OUT</span>
                );
              if(result.length>1 && element.punch_duration=="")
              {
                element.punch_duration="00:00:00";
              }
          });
          setPopupDetails(result);
          setShowPopup(true);
        }
      })
  };
  const closePopup = () => {
    setShowPopup(false);
    setModalTitle("");
    setPopupDetails([]);
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "In",
        field: "punchin_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "In Location",
        field: "punchin_loc",
        sort: "asc",
        width: 150,
      },
      {
        label: "Out",
        field: "punchout_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Out Location",
        field: "punchout_loc",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "punch_type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "punch_status",
        sort: "asc",
        width: 270,
      },
      {
        label: "Duration",
        field: "total_hours",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  const popupdata = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "punch_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "punch_time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "punch_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "punch_type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Latitude",
        field: "punch_lat",
        sort: "asc",
        width: 150,
      },
      {
        label: "Longitude",
        field: "punch_long",
        sort: "asc",
        width: 270,
      },
      {
        label: "Location",
        field: "punch_location",
        sort: "asc",
        width: 270,
      },
      {
        label: "Duration",
        field: "punch_duration",
        sort: "asc",
        width: 200,
      },
    ],
    rows: popupdetails,
  };

  return (
    <>
      <React.Fragment>
        <Modal show={showPopup} size="lg" centered={true}>
          <div className="modal-header">
            <h5>{ modalTitle}</h5>
          <button
              type="button"
              onClick={() => {
                closePopup();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
                    <MDBDataTable
                      id="checkInOutPopupTable"
                      responsive
                      bordered
                      data={popupdata}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
          </div>
          

        </Modal>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Check In/Out Log" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                  <AvForm className="needs-validation">
                      <Row>
                      <Col md="2">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05"> From</Label>
                            <input
                              className="form-control"
                              type="date"
                              name="from_date"
                              value={filter.from_date}
                              defaultValue="mm/dd/yyyy"
                              id="example-date-input"
                              onChange={handleChangeDate}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">To</Label>
                            <input
                              className="form-control"
                              type="date"
                              name="to_date"
                              value={filter.to_date}
                              defaultValue="mm/dd/yyyy"
                              id="example-date-input"
                              onChange={handleChangeDate}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Status</Label>
                            <Select
                              name="customer_community_id"
                              value={filter.status}
                              onChange={(selected) => {
                                handleStatusChange(selected);
                              }}
                              options={[
                                {
                                  label: "In",
                                  value: "0",
                                },
                                { label: "Out", value: "1" },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                       
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Type</Label>
                            <Select
                              name="customer_community_id"
                              value={filter.type}
                              onChange={(selected) => {
                                handleTypeChange(selected);
                              }}
                              options={[
                                {
                                  label: "Automatic",
                                  value: "Automatic",
                                },
                                { label: "Manual", value: "Manual" },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div style={{display:"flex"}}>
                        <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>

                          
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handleSearch(filter.from_date,filter.to_date,filter.type?.value,filter.status?.value)}
                          >
                            Search
                          </Button>
                          </div>
                        
                          <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>

                            <Button
                              color="danger"
                              type="reset"
                              onClick={() => reset()}
                            >
                              Reset
                            </Button>
                            </div>
                            </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      id="checkInOutTable"
                      responsive
                      bordered
                      data={data}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default AttendanceSummary;
