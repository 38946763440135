import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import toastr from "toastr";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody,getDate,getFirstday} from "../../../helpers/globalFunctions";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  CardHeader,
} from "reactstrap";
import {
  getAllFundSource
} from "../../../store/actions";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Advance.scss";

const Advance = () => {
  const [selectedFundSource, setSelectedFundSource] = useState(null);
  const [advanceToBeDeleted, setAdvanceToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [searchSelectedStaff, setSearchSelectedStaff] = useState(null);
  const [selectedOne, setselectedOne] = useState({});
  const [searchData, setSearchData] = useState({from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),});
  const [openModal, setOpenModal] = useState(false);
  const [staffOptions, setStaffOptions] = useState([]);
  const [fundSourceOptions, setFundSourceOptions] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [master, setMaster] = useState({localbody_id:currentLocalbody});
  const [validationError, setValidationError] = useState({});
  const [details, setDetails] = useState([]);
  const dispatch = useDispatch();
  const formRef = useRef();
  const {
    fundSource
  } = useSelector((state) => state.fundTransfers);

  useEffect(() => {
    dispatch(getAllFundSource(currentLocalbody));
    handleTableData(getFirstday(new Date()),getDate(new Date()));
    fetch_all_staff();
    fetch_all_staffs(currentLocalbody)
  }, [currentLocalbody]);

  // useEffect(() => {
  //   var data =
  //     fundSource &&
  //     fundSource.map((item) => {
  //       return {
  //         label: item.acc_chart_name + " - " + item.acc_type_id.acc_type_name,
  //         value: item._id,
  //       };
  //     });

  //   setFundSourceOptions([
  //     {
  //       options: data,
  //     },
  //   ]);
  // }, [fundSource]);

  const fetch_all_staffs = (currentLocalbody) => {
    axios
      .get(`${API_URL}user/priv_options?localbody_id=` + currentLocalbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
          setFundSourceOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  const handleSearchData = (e) =>{
    setSearchData({
      ...searchData,
      [e.target.name]:e.target.value
    });
  }
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectChange = (e,name) => {

    if(name=="staff_id")
    {
      if(typeof e.value!="undefined")
      {
        $("#"+name).removeAttr('style');
      setValidationError({errStaff:""});
      }
      setSelectedStaff(e);
    }
    else
    {
      if(typeof e.value!="undefined")
      {
        $("#"+name).removeAttr('style');
      setValidationError({errFund:""});
      }
      setSelectedFundSource(e);
    }

     setMaster({
      ...master,
      [name]: e.value,
    });

  };
  const handleSearchSelectedData = (e,name) => {
      setSearchSelectedStaff(e);
      setSearchData({
        ...searchData,
        ["staff_id"]:e.value
      });
  };
  const fetch_all_staff = () => {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    var user_id=user_obj._id;
    axios
    .post(`${API_URL}api/staff/filter_staff_list`,{localbody_id:currentLocalbody,user_id:user_id}, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res) => {
      var staff_data = [];
      if (res.data.success && res.data.success === true) {
        staff_data =
          res.data.data &&
          res.data.data.map((el) => {
              return {
                label: el.firstName+" "+el.lastName,
                value: el._id,
              };
            
          });
          staff_data.shift();
      } else {
        staff_data = [];
      }

      setStaffOptions([
        {
          options: staff_data,
        },
      ]);
    });
  };
  const handleValidSubmit = (e, v) => {
    if (selectedStaff == null) {
      $("#staff_id").attr('style','border: #f46a6a thin solid');
      setValidationError({errStaff:"Staff is required"});
      return;
    }
    if (selectedFundSource == null) {
      $("#fundsource").attr('style','border: #f46a6a thin solid');
      setValidationError({errFund:"Fundsource is required"});
      return;
    }

    axios
      .post(`${API_URL}salary/add_advance`, master, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Submitted successfully");
          formRef.current.reset();
          setSelectedFundSource(null);
          setSelectedStaff(null);
          setMaster({
            ["localbody_id"]: currentLocalbody,
          });
          handleTableData(searchData.from_date,searchData.to_date,searchData.staff_id);
        } else {
          toastr.error("Failed to add fund transfer");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const reset = ()=>{
    setMaster({"localbody_id":currentLocalbody,"remarks":""
    });
    setSelectedFundSource(null);
    setSelectedStaff(null);
  }
  const resetFilter = ()=>{
    setSearchData({from_date: getFirstday(new Date()),
      to_date: getDate(new Date())});
      setSearchSelectedStaff(null);
      handleTableData(getFirstday(new Date()),getDate(new Date()));

  }
  const handleClickOpenModal = (item)=>{
    setselectedOne(item);
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleFilterData = ()=>{
    handleTableData(searchData.from_date,searchData.to_date,searchData.staff_id);
  }
  const handleTableData = (from,to,staff="") => {
    let url =
      `${API_URL}salary/advance_list?localbody_id=` + currentLocalbody+`&from_date=`+from+`&to_date=`+to+`&staff_id=`+staff;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((element, index) => {
            element.id = index + 1;
            element.date = moment(element.date).format("DD-MM-YYYY");
            element.staff = element.staff_first+" "+element.staff_last;
            element.addedby = element.added_first+" "+element.added_last;
            element.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => handleClickOpenModal(element)}
                 
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setAdvanceToBeDeleted(element._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 270,
      },
      {
        label: "Fund Source",
        field: "fundsource",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Remarks",
      //   field: "adv_remark",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Added By",
        field: "addedby",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:details,
  };

  return (
    <>
      <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}salary/advance` + "/" + advanceToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Deleted successfully");
                  setAdvanceToBeDeleted(null);
                  handleTableData(searchData.from_date,searchData.to_date,searchData.staff_id);
                } else {
                  toastr.error(res.data.message,"Failed to delete advance");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal
              show={openModal}
              toggle={handleCloseModal}
              size="lg"
              centered={true}
            >
              <div className="modal-header">
                <button
                  type="button"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <table className="table" id="popuptable">
                  <tr>
                    <th>Date</th><td>: {selectedOne.date}</td>
                    <th>Time</th><td>: {selectedOne.time}</td>
                    <th>Fund Source</th><td>: {selectedOne.fundsource}</td>
                    <th>Staff</th><td>: {selectedOne.staff}</td>
                  </tr>
                  <tr>
                    <th>Amount</th><td>: {selectedOne.amount}</td>
                    <th>Added By</th><td>: {selectedOne.addedby}</td>
                    <th>Remarks</th><td colspan="3">: {selectedOne.adv_remark}</td>
                  </tr>
                </table>
              </div>
        </Modal>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Add Advance" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      ref={formRef}
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Staff</Label>
                            <Select
                              id="staff_id"
                              name="staff_id"
                              value={selectedStaff}
                              onChange={(selected) => {
                                handleSelectChange(selected,"staff_id");
                              }}
                              options={staffOptions}
                              classNamePrefix="select2-selection"
                            />
                            <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {validationError?.errStaff}
                          </p>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Amount</Label>
                            <AvField
                              name="amount"
                              placeholder="Amount"
                              type="number"
                              errorMessage="Enter amount"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={master?.amount}
                              onChange={handleInputChange}
                              min={0}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Fund Source</Label>
                            <Select
                            name="fundsource"
                            id="fundsource"
                            value={selectedFundSource}
                            onChange={(value) => {
                              handleSelectChange(value,"fundsource");
                            }}
                            options={fundSourceOptions}
                            classNamePrefix="select2-selection"
                          />
                           <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >{validationError?.errFund}</p>
                          
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Remarks</Label>
                            <AvField
                              name="remarks"
                              rows="1"
                              placeholder="Remarks"
                              type="textarea"
                               errorMessage="Enter remark"
                              className="form-control"
                               validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={master?.remarks}
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>

                        <Col md="2">
                          <div style={{display:"flex"}} >
                        <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>

                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                       </div>
                       <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>

                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                          </div>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {/* <CardTitle>View Advance</CardTitle>
                    <hr /> */}
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={searchData?.from_date}
                            onChange={handleSearchData}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={searchData?.to_date}
                            onChange={handleSearchData}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            name="staff_id"
                            value={searchSelectedStaff}
                            onChange={(selected) => {
                              handleSearchSelectedData(selected);
                            }}
                            options={staffOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div style={{display:"flex"}} >
                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>

                          <Button color="primary" type="submit"
                          onClick={() => handleFilterData()}
                          >
                            search
                          </Button>
                       </div>
                       <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>

                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => resetFilter()}
                          >
                            Reset
                          </Button>
                          </div>
                          </div>
                        </Col>
                    </Row>
                    <MDBDataTable id="advanceTable" responsive bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default Advance;
