import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label
} from "reactstrap";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getDate,
  getFirstday,
  getArraySumByKey,
  formatMoney,
} from "../../helpers/globalFunctions";
import "./customernextvisit.scss";
import { getCustomerType } from "../../store/actions";


const Customernextvisit = (props) => {
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [details, setDetails] = useState([]);
  const [holiday, setHoliday] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [customerType, setCustomerType] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const { customerTypes } = useSelector((state) => state.customers);

  useEffect(() => {
    handleTableData();
    fetch_all_staff(currentLocalbody);
    fetch_all_wards(currentLocalbody);
    dispatch(getCustomerType());
  }, [currentLocalbody]);

  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);
  function handleTableData(
    // from_date = "",
    // to_date = "",
    ward_id = "",
    group_id = "",
    staff_id = "",
    category = ""
  ) {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      userId = obj._id;
    }
    var url =
      `${API_URL}customerWiseNextVisit`;

    axios
      .post(url, {
        "local_body": currentLocalbody,
        "user_id": userId,
        "ward_id": ward_id,
        "group_id": group_id,
        "category": category,


      }, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let holidays = res.data.allHoliDays;
        //setHoliday(holidays)

        let dataToBeExported = [];
        result &&
          result.map((item, index) => {
            let exportItem = {};
            item.id = index + 1;
            item.lastVisits = moment(item?.lastVisit).format("DD-MM-YYYY");
            item.nextVisits = moment(item?.nextVisit).format("DD-MM-YYYY");
            item.lastVisit_day = moment(item.lastVisit).format('dddd');
            item.nextVisit_day = moment(item.nextVisit).format('dddd');






            dataToBeExported.push(exportItem);
          });
        // let test = getArraySumByKey(result,"total_amount")
        // result.push({
        //   id:"",
        //   date:"",
        //   visited_houses:"",
        //   paid_houses:"",
        //   house_amt:"",
        //   visited_shops:"",
        //   paid_shops:"",
        //   shop_amt:"Total",
        //   total_amount:test,
        //   total_weight:""
        // })
        setDetails(result);
        let total = getArraySumByKey(result, "total_amount");
        setTotal(total);
        setDataToBeExported(dataToBeExported);
      });
  }
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "cust_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "cust_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "cust_phone",
        sort: "asc",
        width: 200,
      },
      //   {
      //     label: "Ward",
      //     field: "wardName",
      //     sort: "asc",
      //     width: 200,
      //   },
      {
        label: "Prev.Visit Date",
        field: "lastVisits",
        sort: "asc",
        width: 200,
      },
      {
        label: "Prev.Visit Day",
        field: "lastVisit_day",
        sort: "asc",
        width: 200,
      },
      {
        label: "NextVisit Date",
        field: "nextVisits",
        sort: "asc",
        width: 200,
      },
      {
        label: "NextVisit Day",
        field: "nextVisit_day",
        sort: "asc",
        width: 200,
      },

    ],
    rows: details,
  };
  //   const fetch_all_ward = () => {
  //     var url = `${API_URL}ward/options`;
  //     axios
  //       .get(url, {
  //         headers: {
  //           "x-access-token": accessToken,
  //         },
  //       })
  //       .then((res) => {
  //         var ward_data =
  //           res.data.data &&
  //           res.data.data.map((value) => {
  //             return {
  //               label: value.customer_name,
  //               value: value.customer_id,
  //             };
  //           });

  //         setwardOptions([
  //           {
  //             options: ward_data,
  //           },
  //         ]);
  //       });
  //   };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let category_id = searchData?.category_id ? searchData.category_id : "";

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

    // handleTableData(date1, date2, ward_id, group_id, staff_id, category_id);
  };

  const handleSelectChange = (selected, name) => {
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let date1 = searchData?.from_date ? searchData.from_date : "";
    // let date2 = searchData?.to_date ? searchData.to_date : "";
    // let category_id = searchData?.category_id ? searchData.category_id : "";

    switch (name) {
      case "ward":
        setSelectedWard(selected);

        setSelectedGroup(null);

        // let wardArray = [];
        // selected &&
        //   selected.forEach((element) => {
        //     wardArray.push(element?.value);
        //   });

        // if (wardArray && wardArray.length === 0) {
        //   setSearchData({
        //     ...searchData,
        //     ["ward_id"]: "",
        //   });
        //   handleTableData(date1, date2, "", "", staff_id);
        // } else {
        //   setSearchData({
        //     ...searchData,
        //     ["ward_id"]: [...wardArray],
        //   });
        //   handleTableData(date1, date2, [...wardArray], "", staff_id);
        // }

        // let data = {
        //   ward_id: wardArray,
        // };
        // axios
        //   .post(`${API_URL}api/staff/ward_groups`, data, {
        //     headers: {
        //       "x-access-token": accessToken,
        //     },
        //   })
        //   .then((res) => {
        //     if (res.data && res.data.success === true) {
        //       var group_data =
        //         res.data.data &&
        //         res.data.data.map((el) => {
        //           return {
        //             label: el.group_name,
        //             value: el._id,
        //           };
        //         });
        //     } else {
        //       group_data = [];
        //     }

        //     setGroupOptions([
        //       {
        //         options: group_data,
        //       },
        //     ]);
        //   });
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
          ["group_id"]: "",
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   selected.value,
        //   "",
        //   staff_id,
        //   category_id
        // );
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
            currentLocalbody +
            "&ward_id=" +
            selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });

        break;
      case "group":
        setSelectedGroup(selected);
        // let groupArray = [];
        // selected &&
        //   selected.forEach((element) => {
        //     groupArray.push(element?.value);
        //   });

        // if (groupArray && groupArray.length === 0) {
        //   setSearchData({
        //     ...searchData,
        //     ["group_id"]: "",
        //   });
        //   handleTableData(date1, date2,ward_id, "", staff_id);
        // } else {
        //   setSearchData({
        //     ...searchData,
        //     ["group_id"]: [...groupArray],
        //   });
        //   handleTableData(date1, date2, ward_id,[...groupArray], staff_id);
        // }
        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   selected.value,
        //   staff_id,
        //   category_id
        // );
        break;
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   group_id,
        //   selected.value,
        //   category_id
        // );
        break;
      case "customerType":
        setSelectedCustomerType(selected);
        setSearchData({
          ...searchData,
          category_id: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   group_id,
        //   staff_id,
        //   selected.value
        // );
        break;
      default:
        break;
    }
  };
  const reset = () => {
    setSearchData({
      //   from_date: getFirstday(new Date()),
      //   to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedStaff(null);
    setSelectedCustomerType(null);
    handleTableData();
  };
  const handleSearch = () => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let date1 = searchData?.from_date ? searchData.from_date : "";
    // let date2 = searchData?.to_date ? searchData.to_date : "";
    let category_id = searchData?.category_id ? searchData.category_id : "";
    handleTableData(ward_id, group_id, staff_id, category_id);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Customer Next Visit" />
          <Row>
            <Col className="col-12">
              {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {grandTotal}</h5>
              </Row> */}
              <Card>
                <CardBody>
                  <Row>
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                     
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                       
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                    
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                  
                      </div>
                    </Col> */}
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          //isMulti
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Group</Label>
                        <Select
                          //isMulti
                          name="group_id"
                          value={selectedGroup}
                          onChange={(selected) => {
                            handleSelectChange(selected, "group");
                          }}
                          options={groupOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          name="staff_id"
                          value={selectedStaff}
                          onChange={(selected) => {
                            handleSelectChange(selected, "staff");
                          }}
                          options={staffOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Customer Type</Label>
                        <Select
                          name="category_id"
                          value={selectedCustomerType}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customerType");
                          }}
                          options={customerType}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2" >
                      <div style={{ display: "flex" }} >

                        <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </Button>
                        </div>

                        {/* <Col md="1" style={{ paddingTop: "17px" }}>
                      <div className="mt-2 mb-3">
                        <Button color="success" type="submit">
                        
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Day_wise_report_" + getDate(new Date()) + ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col> */}
                        <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>

                    </Col>
                  </Row>
                  <MDBDataTable
                    id="customernextvisitTableId"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                  {/* <span className="grand_total_label">Total {""}</span>
                  <span className="grand_total_value">
                    {" "}
                    {formatMoney(total)}
                  </span> */}
                  {/* <span className="grand_total_value">Total  {formatMoney(total)}</span> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Customernextvisit;
