import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { connect } from "react-redux";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import { getLocalbody } from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import $ from "jquery";
const SalarySlip = (props) => {
  const [details, setDetails] = useState([]);
  const [searchData, setSearchData] = useState({});
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Salary Type",
        field: "Salarytype",
        sort: "asc",
        width: 270,
      },
      {
        label: "Basic Salary",
        field: "basicSalary",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="3">Attendance</th>
                      </tr>
                      <tr>
                        <td>P</td>
                        <td>L</td>
                        <td>H</td>
                      </tr>
                    </thead>
                  </table>
                </Row>
              </Col>
            </Row>
          </>
        ),
        field: "attendance",
        sort: "asc",
        width: 400,
      },
      {
        label: "LOP",
        field: "lop",
        sort: "asc",
        width: 150,
      },
      {
        label: "Calc. Salary",
        field: "calc.Salary",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pre. Advance",
        field: "pre.advance",
        sort: "asc",
        width: 150,
      },
      {
        label: "Adv. Due",
        field: "basicSalary",
        sort: "asc",
        width: 150,
      },
      {
        label: "Extra Payment",
        field: "extraPayment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Incentive",
        field: "incentive",
        sort: "asc",
        width: 150,
      },
      {
        label: "To Pay",
        field: "netPayment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: details,
  };
  const handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const reset = () => {
    setSearchData({
      from_date: "",
      to_date: "",
    });
  };
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Salary Slip" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    {/* <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Month</Label>
                        <input
                          className="form-control"
                          type="month"
                          id="month"
                          name="month"
                          value={searchData?.month}
                          onChange={handleMonth}
                        />
                      </div>
                    </Col> */}
                    <Col md="5">
                      <div className="mt-2" style={{ paddingTop: "18px" }}>
                        <Button
                          color="primary"
                          type="submit"
                          // onClick={() =>  handleSearch()}
                        >
                          Search
                        </Button>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                          style={{ marginLeft: "15px" }}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <MDBDataTable responsive bordered data={data} />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default SalarySlip;
