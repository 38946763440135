import React from "react";
import { MDBDataTable } from "mdbreact";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Resete from "@mui/icons-material/ResetTvRounded";
import Save from "@mui/icons-material/Save";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

//Dialogue box content's
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

//Dialogue box table content's
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./WasteSpotReport.scss";
import { Grid, Menu } from "@mui/material";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DatePicker from "@mui/lab/DatePicker";

//Dialogue table content's
function createData(name, calories, fat, carbs, protien) {
  return { name, calories, fat, carbs, protien };
}

const rows = [
  createData(
    "Check In Latitude:",
    11.9193786,
    "Check In Longitude :",
    75.4419379
  ),
  createData(
    "Check Out Date :",
    "08-11-2021",
    "Check Out Longitude:",
    75.4419379
  ),
  createData("Check Out Latitude :", 262, "Check Out Longitude :", 24),
  createData("Type :", "Automatic", "Staff : :", "SRV"),
  createData(
    "Status :",
    <InputLabel style={{ color: "green" }}>Check In</InputLabel>
  ),
];

const CheckinCheckout = () => {
  const [type, setType] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleChange = (selected) => {
    setType(selected);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [type1, setType1] = React.useState("");
  const [open1, setOpen1] = React.useState(false);
  const handleChange1 = (selected) => {
    setType1(selected);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const [type2, setType2] = React.useState("");
  const [open2, setOpen2] = React.useState(false);
  const handleChange2 = (event) => {
    setType2(event.target.value);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (newValue) => {
    setValue(newValue);
  };
  const reset = () => {
    setType(null);
    setType1(null);
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "si",
        sort: "asc",
        width: 150,
      },
      {
        label: "Spot Name",
        field: "spotname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Item",
        field: "item",
        sort: "asc",
        width: 150,
      },
      {
        label: "Today",
        field: "today",
        sort: "asc",
        width: 150,
      },
      {
        label: "Yesterday",
        field: "yesterday",
        sort: "asc",
        width: 150,
      },
      {
        label: "Week",
        field: "week",
        sort: "asc",
        width: 150,
      },
      {
        label: "Month",
        field: "month",
        sort: "asc",
        width: 150,
      },
      {
        label: "Previous Month",
        field: "PMonth",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        si:1,
        spotname: "",
        item: "1",
        today: "0",
        yesterday: "0",
        week: "0",
        month: "0",
        PMonth: "0",
      },
      {
        si:2,
        spotname: "",
        item: "1",
        today: "0",
        yesterday: "0",
        week: "0",
        month: "0",
        PMonth: "0",
      },
      {
        si:3,
        spotname: "",
        item: "1",
        today: "0",
        yesterday: "0",
        week: "0",
        month: "0",
        PMonth: "0",
      },
      {
        si:4,
        spotname: "talap",
        item: "1",
        today: "0",
        yesterday: "0",
        week: "0",
        month: "0",
        PMonth: "0",
      },
      {
        si:5,
        spotname: "",
        item: "1",
        today: "0",
        yesterday: "0",
        week: "0",
        month: "0",
        PMonth: "0",
      },
      {
        si:6,
        spotname: "",
        item: "1",
        today: "0",
        yesterday: "0",
        week: "0",
        month: "0",
        PMonth: "0",
      },
    ],
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Waste Spot Report" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row>
                      <Col md="3">
                          <div className="mb-3">
                            <Label>Spot</Label>
                            <Select
                              name="customer_community_id"
                              value={type}
                              onChange={(selected) => {
                                handleChange(selected);
                              }}
                              options={[
                                { label: "Talap", value: 1 },
                                { label: "Kakkad", value: 2 },
                                { label: "Town", value: 3 }
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Quantity</Label>
                            <Select
                              name="customer_community_id"
                              value={type1}
                              onChange={(selected) => {
                                handleChange1(selected);
                              }}
                              options={[
                                { label: "Bags(Nos)", value: 1 },
                                { label: "Weight(kg)", value: 2 }
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        

                        <Col md="3" className="mt-4">
                          <div className="mb-3 mt-2">
                            <Button color="danger" type="submit" onClick={()=>reset()}>
                              {/* <Resete></Resete> */}
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {/* <CardTitle>Report</CardTitle>
                    <hr /> */}
                    <MDBDataTable responsive bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default CheckinCheckout;
