import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getDistricts,
  addDistrict,
  deleteDistrict,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateDistrict,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import { getLocalbody } from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./district.scss";

const Districts = (props) => {
  const [selectedPrivilage, setSelectedPrivilage] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [districtObject, setDistrictObject] = useState({});
  const [districtIdTobeUpdated, setDistrictIdToBeUpdated] = useState(null);
  const [districtIdToBeDeleted, setDistrictIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [districtsForTable, setDistrictsForTable] = useState([]);
  const [districtValue, setdistrictValue] = useState("");
  const [passwordObject, setPasswordObject] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const {
    districts,
    addingDistrict,
    addDistrictResponse,
    deleteDistrictResponse,
    updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(getDistricts());
    handleTableData(currentLocalbody);
  }, [currentLocalbody]);

  function handleTableData(id) {
    var url = `${API_URL}district/list?localbody_id=` + currentLocalbody;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let districtData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateDistrict(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setDistrictIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          console.log(item, 'item');

          if (item.state_id === 19) {
            item.state_name = "Kerala";
          }

          item.date = moment(item.district_date).format("DD-MM-YYYY");
          item.time = moment(item.district_time, "HHmmss").format("hh:mm a"); // 24hours
          //item.time = item.district_time;
          if (item.district_addedby != null)
            item.district_addedby = item.district_addedby.username;

          if (item?.updated_staff_name) item.staff = item.updated_staff_name;
          else item.staff = item?.staff_name;

          districtData.push(item);
        });

        /* districtData.sort(function(a, b) {
          var keyA =new Date(a.createdAt),
            keyB = new Date(b.createdAt);
          // Compare the 2 dates
          if (keyB < keyA) return -1;
          if (keyB > keyA) return 1;
          return 0;
        })*/

        setDistrictsForTable(districtData);
      });
  }

  // useEffect(() => {
  //   if (addDistrictResponse.type === "success") {
  //     // dispatch(getDistricts())
  //     handleTableData(currentLocalbody);
  //     toastr.success(addDistrictResponse.message);
  //     setSelectedPrivilage({});
  //     setSelectedCompany(null);
  //     setSelectedBranch(null);
  //   } else if (addDistrictResponse.type === "failure") {
  //     toastr.error(error.data.message, addDistrictResponse.message);
  //   }
  // }, [addDistrictResponse]);

  // useEffect(() => {
  //   if (deleteDistrictResponse.type === "success") {
  //     // dispatch(getDistricts())
  //     handleTableData(currentLocalbody);
  //     setDistrictIdToBeDeleted(null);
  //     toastr.success(deleteDistrictResponse.message);
  //   } else if (deleteDistrictResponse.type === "failure") {
  //     toastr.error(error.data.message, deleteDistrictResponse.message, {
  //       timeOut: 2000,
  //       preventDuplicates: true,
  //     });
  //   }
  // }, [deleteDistrictResponse]);

  // useEffect(() => {
  //   if (updateDistrictResponse.type === "success") {
  //     // dispatch(getDistricts())
  //     handleTableData(currentLocalbody);
  //     setShowModal(false);
  //     setDistrictIdToBeUpdated(null);
  //     setPasswordObject({});
  //     toastr.success(updateDistrictResponse.message);
  //   } else if (updateDistrictResponse.type === "failure") {
  //     toastr.error(error.data.message, updateDistrictResponse.message);
  //   }
  // }, [updateDistrictResponse]);

  let preUpdateDistrict = (item) => {
    if (item.district_name) {
      setdistrictValue(item.district_name);
    }
    setDistrictIdToBeUpdated(item._id);
    setDistrictObject({ ...item, password: null });
  };

  let preUpdateDistrictPassword = (item) => {
    setDistrictIdToBeUpdated(item._id);
    setShowModal(true);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  // useEffect(() => {
  //   let districtData = [];

  //   districts?.map((item, index) => {
  //     item.action = (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <i
  //           className="uil-edit-alt"
  //           style={{
  //             fontSize: "1.2em",
  //             cursor: "pointer",
  //             marginLeft: "0.5rem",
  //             marginRight: "0.5rem",
  //           }}
  //           onClick={() => {
  //             toTop();
  //             preUpdateDistrict(item);
  //           }}
  //         ></i>
  //         <i
  //           className="uil-trash-alt"
  //           style={{ fontSize: "1.2em", cursor: "pointer" }}
  //           onClick={() => {
  //             setDistrictIdToBeDeleted(item._id);
  //             setConfirmDeleteAlert(true);
  //           }}
  //         ></i>
  //       </div>
  //     );
  //     item.id = index + 1;
  //     item.state_name="Kerala";
  //     item.date = moment(item.district_date).format("DD-MM-YYYY");
  //     item.time = moment(item.district_time,"HHmmss").format("hh:mm a"); // 24hours
  //     if(item.district_addedby!=null)
  //     item.district_addedby=item.district_addedby.username;
  //     districtData.push(item);
  //   });

  //  /* districtData.sort(function(a, b) {
  //     var keyA =new Date(a.createdAt),
  //       keyB = new Date(b.createdAt);
  //     // Compare the 2 dates
  //     if (keyB < keyA) return -1;
  //     if (keyB > keyA) return 1;
  //     return 0;
  //   })*/

  //   setDistrictsForTable(districtData);

  // }, [districts]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        //sort: "desc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        //sort: "desc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        //sort: "desc",
        width: 150,
      },
      {
        label: "State",
        field: "state_name",
        //sort: "desc",
        width: 150,
      },
      {
        label: "Name",
        field: "district_name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: districtsForTable,
  };

  function handleChangeDistrict(e) {
    let name = e.target.name;
    let value = e.target.value;
    setdistrictValue(value);
    setDistrictObject({ ...districtObject, [name]: value });
  }

  function handleSelectedPrivilage(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setSelectedPrivilage(value);
    setDistrictObject({ ...districtObject, privilage: newValue });
  }

  function handleSelectedCompany(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setSelectedCompany(value);
    setDistrictObject({ ...districtObject, company: newValue });
  }
  function handleSelectedBranch(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setSelectedBranch(value);
    setDistrictObject({ ...districtObject, branch: newValue });
  }

  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (districtIdTobeUpdated) {
      axios
        .put(
          `${API_URL}district` + "/" + districtIdTobeUpdated,
          districtObject,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("District updated successfully");
            setdistrictValue("");
            formRef.current.reset();
            handleTableData();
            setDistrictObject({});
            setDistrictIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update district");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}district`, districtObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("District added succesfully");
            handleTableData();
            setdistrictValue("");
            formRef.current.reset();
            setDistrictObject({});
          } else {
            toastr.error("Failed to add district");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  // districtIdTobeUpdated
  //   ? dispatch(updateDistrict(districtObject))
  //   : dispatch(addDistrict(districtObject));
  //   setdistrictValue("")
  //   formRef.current.reset();

  const handleValidSubmitPassword = (event, values) => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        _id: districtIdTobeUpdated,
        password: passwordObject.password,
      };
      dispatch(updateDistrict(item));
    } else {
      toastr.error("Passwords are not matching");
    }
  };

  let closeModal = () => {
    setShowModal(false);
    setDistrictIdToBeUpdated(null);
  };


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}district` + "/" + districtIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              }).then((res) => {
                if (res.data.success === true) {
                  toastr.success("District deleted successfully")
                  if (districtObject &&
                    districtObject._id === districtIdToBeDeleted
                  ) {
                    setdistrictValue("")
                    formRef.current.reset();
                    setDistrictIdToBeUpdated(null);
                    setDistrictObject({});
                  }
                  handleTableData()
                } else {
                  toastr.error(res.data.message, 'Failed to delete district');
                  return;
                }
              }).catch((err) => {
                toastr.error(err.response.data.message);
                return;
              })
            setConfirmDeleteAlert(false)
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >Are you sure you want to delete it?</SweetAlert>
      ) : ""}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage District" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation" ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >

                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <AvField
                            name="district"
                            type="text"
                            placeholder="District"
                            value={districtValue}
                            onchange={(e) => {
                              handleChangeDistrict(e)
                            }}
                            validate={{ required: { value: true } }}
                            errorMessage="Enter District"
                            className="form-control"
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          {districtIdTobeUpdated ? (
                            <Button color="primary" type="submit"
                              disabled={addingDistrict ? true : false}
                            >{addingDistrict ? "Updating" : "Update"}</Button>
                          ) : (
                            <Button color="primary" type="submit"
                              disabled={addingDistrict ? true : false}
                            >{addingDistrict ? "Adding" : "Submit"}</Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="districtTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>

    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(Districts));

Districts.propTypes = {
  error: PropTypes.any,
  districts: PropTypes.array,
};
