module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  PRIVILEGES : {
    SUPER_ADMIN:"61fa7275a7cd7dbee0b92b7b"
  }
}
