import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import toastr from "toastr";
import "./emi.scss";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import {
    getLocalbody,
    getDate,
    getFirstday,
} from "../../../helpers/globalFunctions";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    CardHeader,
} from "reactstrap";
import { getAllFundSource } from "../../../store/actions";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";



function AddEmi() {
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [staffOptions, setStaffOptions] = useState([]);
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
    const dispatch = useDispatch();
    const [fundSourceOptions, setFundSourceOptions] = useState([]);
    const { fundSource } = useSelector((state) => state.fundTransfers);
    const [selectedFundSource, setSelectedFundSource] = useState(null);
    const [emitIdTobeDeleted, setemitIdTobeDeleted] = useState(null);
    const [confirmDelete, setconfirmDelete] = useState(false);
    const [emilist, setemilist] = useState([]);
    const [toggleSwitch, settoggleSwitch] = useState(true);
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [emiIdTobeUpdated, setemiIdTobeUpdated] = useState(null);
    const [foundShow, setfoundShow] = useState(false);
    const [masterObj, setmasterObj] = useState({
        localbody: currentLocalbody,
        amount: 0,
        months: 0,
        emi_amount_permonth: null,
    });
    const formRef = useRef();

    const [errorS, seterrorS] = useState({ staffError: "", fundError: "" });

    function toggleActiveStatus(id, status) {
        if (status == 0) {
            const newStatus = 1;
            axios
                .put(
                    `${API_URL}salary/update_holdstatus`,
                    { _id: id, holdstatus: newStatus, localbody: currentLocalbody },
                    {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    }
                )
                .then((res) => {
                    handleTableData();
                });
        } else if (status == 1) {
            const newStatus = 0;
            axios
                .put(
                    `${API_URL}salary/update_holdstatus`,
                    { _id: id, holdstatus: 0, localbody: currentLocalbody },
                    {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    }
                )
                .then((res) => {
                    handleTableData();
                });
        }
    }

    useEffect(() => {
        dispatch(getAllFundSource(currentLocalbody));
        fetch_all_staff();
        handleTableData();
    }, [currentLocalbody]);

    useEffect(() => {
        var data =
            fundSource &&
            fundSource.map((item) => {
                return {
                    label: item.acc_chart_name + " - " + item.acc_type_id.acc_type_name,
                    value: item._id,
                    balacnce: item?.acc_chart_balance

                };
            });
        setFundSourceOptions([
            {
                options: data,
            },
        ]);
    }, [fundSource]);

    const fetch_all_staff = () => {
        var data = localStorage.getItem("authUser");
        var user_obj = JSON.parse(data);
        var user_id = user_obj._id;
        axios
            .post(
                `${API_URL}api/staff/filter_staff_list`,
                { localbody_id: currentLocalbody, user_id: user_id },
                {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }
            )
            .then((res) => {
                var staff_data = [];
                if (res.data.success && res.data.success === true) {
                    staff_data =
                        res.data.data &&
                        res.data.data.map((el) => {
                            return {
                                label: el.firstName + " " + el.lastName,
                                value: el._id,
                            };
                        });
                    staff_data.shift();
                } else {
                    staff_data = [];
                }

                setStaffOptions([
                    {
                        options: staff_data,
                    },
                ]);
            });
    };

    function handleSelectChange(selected, name) {
        switch (name) {
            case "staff_id":
                setSelectedStaff(selected);
                setmasterObj({ ...masterObj, [name]: selected.value });
                break;

            case "fundsource":
                setSelectedFundSource(selected);
                setmasterObj({ ...masterObj, [name]: selected.value });
                break;


            default:
                break;
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Ensure the values are numbers before performing the calculation
        const amount = name === "amount" ? parseFloat(value) : masterObj?.amount;
        const months = name === "months" ? parseFloat(value) : masterObj?.months;
        // Calculate and set the result in masterObj
        const emi_amount_permonth = months === 0 ? 0 : (amount / months).toFixed(2);
        setmasterObj({ ...masterObj, amount, months, emi_amount_permonth });
    };

    function handleInputChangeRemarks(e) {
        let { name, value } = e.target;
        setmasterObj({ ...masterObj, [name]: value });
    }

    function handleValidSubmit(e, v) {

        if (selectedStaff === null || selectedFundSource === null) {
            let errorVal = errorS;
            if (selectedStaff === null) {
                errorVal.staffError = "Please select staff"
            }
            if (selectedFundSource === null) {
                errorVal.fundError = "Please select fund source"
            }
            seterrorS({
                ...errorVal
            })
        } else {
            if (emiIdTobeUpdated) {
                axios
                    .put(`${API_URL}salary/update_emi`, masterObj, {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    })
                    .then((res) => {
                        if (res.data.status === 200) {
                            toastr.success("Emi updated successfully");
                            formRef.current.reset();
                            handleTableData();
                            setmasterObj({ localbody: currentLocalbody });
                            setSelectedFundSource(null);
                            setSelectedStaff(null);
                            seterrorS({
                                ...errorS,
                                staffError: "",
                                fundError: "",
                            })
                        } else {
                            toastr.error("Falied to update");
                            return;
                        }
                    })
                    .catch((err) => {
                        toastr.err(err.response.data.message);
                        return;
                    });
            } else {
                axios
                    .post(`${API_URL}salary/add_emi`, masterObj, {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    })
                    .then((res) => {
                        if (res.data.status == 200) {
                            toastr.success("Emi added successfully");
                            handleTableData();
                            setmasterObj({ localbody: currentLocalbody });
                            setSelectedFundSource(null);
                            setSelectedStaff(null);
                            formRef.current.reset();
                            seterrorS({
                                ...errorS,
                                fundError: "",
                                staffError: ""
                            })
                        } else {
                            toastr.error("Faild to add emi");
                            return;
                        }
                    })
                    .catch((err) => {
                        toastr.err(err.response.data.message);
                        return;
                    });
            }

        }
    }


    function handleTableData() {
        axios
            .get(`${API_URL}salary/emilist?localbody_id=${currentLocalbody}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                let result = res.data.data;
                result &&
                    result.map((item, index) => {
                        item.id = index + 1;
                        item.date1 = moment(item.date).format("DD-MM-YYYY");

                        const isStatusChecked = item.hold_status === 1;
                        item.status1 = (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                className="form-check form-switch"
                            >
                                <input
                                    style={{ cursor: "pointer" }}
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`customSwitchsizesm${index}`}
                                    checked={isStatusChecked}
                                    onChange={(e) => {
                                        toggleActiveStatus(item._id, item?.hold_status);
                                        settoggleSwitch(!toggleSwitch);
                                    }}
                                />
                            </div>
                        );

                        item.action = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <i
                                    className="fas fa-edit"
                                    style={{
                                        fontSize: "1em",
                                        cursor: "pointer",
                                        marginLeft: "0.2em",
                                        marginRight: "0.5em",
                                    }}
                                    onClick={() => {
                                        toTop();
                                        setemiIdTobeUpdated(item?._id);
                                        preupdateEmi(item);
                                    }}
                                ></i>
                                <i
                                    className="far fa-trash-alt"
                                    style={{ fontSize: "1em", cursor: "pointer" }}
                                    onClick={() => {
                                        setemitIdTobeDeleted(item._id);
                                        setconfirmDelete(true);
                                    }}
                                ></i>
                            </div>
                        );
                    });
                setemilist(result);
            });
    }

    function reset() {
        formRef.current.reset();
        setmasterObj({
            localbody: currentLocalbody,
        });
        setSelectedFundSource(null);
        setSelectedStaff(null);
    }

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date1",
                sort: "asc",
                width: 150,
            },

            {
                label: "Staff",
                field: "staff_name",
                sort: "asc",
                width: 150,
            },

            {
                label: "EMI Amount",
                field: "amount",
                sort: "asc",
                width: 150,
            },
            {
                label: "Months",
                field: "months",
                sort: "asc",
                width: 150,
            },
            {
                label: "Emi/months (Rs)",
                field: "emi_amount_permonth",
                sort: "asc",
                width: 150,
            },
            {
                label: "Remarks",
                field: "remarks",
                sort: "asc",
                width: 150,
            },

            {
                label: "Status",
                field: "status1",
                sort: "asc",
                width: 150,
            },

            {
                label: "Months Remaining",
                field: "months_remaining",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: emilist,
    };

    function preupdateEmi(item) {
        item.fundsource = item?.fund?._id;
        item.staff_id = item?.staff?._id;
        item.localbody = currentLocalbody;
        setemiIdTobeUpdated(item?._id);
        let stOption = {
            label: item?.staff?.firstName + item?.staff?.lastName,
            value: item?.staff?._id,
        };
        setSelectedStaff(stOption);

        let fundop = {
            label: item?.fund?.acc_chart_name,
            value: item?.fund?._id,
            balacnce: item?.fund?.acc_chart_balance

        };
        setSelectedFundSource(fundop);

        setmasterObj(item);
    }

    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            {confirmDelete ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}salary/emi_delete/${emitIdTobeDeleted}`, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                if (res.data.status == 200) {
                                    toastr.success("Emi Deleted successfully");
                                    formRef.current.reset();
                                    setmasterObj({});
                                    setSelectedStaff(null);
                                    setSelectedFundSource(null);
                                    handleTableData();
                                    setemiIdTobeUpdated(null);
                                } else {
                                    toastr.error("Faild to delete");
                                    handleTableData();
                                }
                            })
                            .catch((err) => {
                                toastr.err(err.response.data.message);
                                return;
                            });
                        setconfirmDelete(false);
                    }}
                >
                    Are you sure you want to delete ?
                </SweetAlert>
            ) : null}


            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Add EMI" />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm
                                        className="needs-validation"
                                        ref={formRef}
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v);
                                        }}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Staff</Label>
                                                    <Select
                                                        id="staff_id"
                                                        name="staff_id"
                                                        classNamePrefix="select2-selection"
                                                        value={selectedStaff}
                                                        onChange={(selected) => {
                                                            handleSelectChange(selected, "staff_id");
                                                        }}
                                                        options={staffOptions}
                                                        isDisabled={emiIdTobeUpdated}
                                                    />
                                                    <p
                                                        className="text-danger"
                                                        style={{ fontSize: "11px" }}
                                                    >{selectedStaff === null ? errorS?.staffError : ""}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom03">Amount</Label>
                                                    <AvField
                                                        name="amount"
                                                        placeholder="Amount"
                                                        type="number" // Set the type to "number"
                                                        className="form-control amont-avfield"
                                                        id="validationCustom03"
                                                        onChange={handleInputChange}
                                                        value={masterObj?.amount}
                                                        onWheel={(e) => e.target.blur()}
                                                        errorMessage="Enter amount"
                                                        validate={{ required: { value: true } }}
                                                        min={0}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label>Months</Label>
                                                    <AvField
                                                        name="months"
                                                        placeholder="Months"
                                                        type="number" // Set the type to "number"
                                                        className="form-control month-avfield"
                                                        id="validationCustom03"
                                                        min={1} // Set the minimum value to 1
                                                        max={24} // Set the maximum value to 24
                                                        setp={1}
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => {
                                                            // Prevent negative sign (-) and decimal point (.) input
                                                            if (e.key === "-" || e.key === ".") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        validate={{
                                                            required: { value: true },
                                                            min: {
                                                                value: 1,
                                                                errorMessage: "Enter a number between 1 and 24",
                                                            },
                                                            max: {
                                                                value: 24,
                                                                errorMessage: "Enter a number between 1 and 24",
                                                            },
                                                        }}
                                                        value={masterObj?.months}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label>Emi per months</Label>
                                                    <AvField
                                                        style={{ backgroundColor: "aliceblue" }}
                                                        name="emi_month"
                                                        readOnly={true}
                                                        value={masterObj?.emi_amount_permonth || 0}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>
                                                        Fund Source{" "}
                                                        {selectedFundSource && <span style={{ fontSize: "11px" }} className="ms-4">Available :&#8377; {selectedFundSource?.balacnce}</span>}
                                                    </Label>
                                                    <Select
                                                        name="fundsource"
                                                        id="fundsource"
                                                        classNamePrefix="select2-selection"
                                                        onChange={(value) => {
                                                            handleSelectChange(value, "fundsource");
                                                        }}
                                                        value={selectedFundSource}
                                                        options={fundSourceOptions}
                                                    />
                                                    <p
                                                        className="text-danger"
                                                        style={{ fontSize: "11px" }}
                                                    >
                                                        {selectedFundSource === null ? errorS?.fundError : ""}
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom03">Remarks</Label>
                                                    <AvField
                                                        name="remarks"
                                                        rows="1"
                                                        placeholder="Remarks"
                                                        type="textarea"
                                                        errorMessage="Enter remark"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom03"
                                                        onChange={handleInputChangeRemarks}
                                                        value={masterObj?.remarks || ""}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div style={{ display: "flex" }}>
                                                    <div
                                                        className="mt-4 mb-3 mx-1"
                                                        style={{ paddingTop: "5px" }}
                                                    >
                                                        {emiIdTobeUpdated ? (
                                                            <Button color="primary" type="update">
                                                                Update
                                                            </Button>
                                                        ) : (
                                                            <Button color="primary" type="submit">
                                                                Submit
                                                            </Button>
                                                        )}
                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="emiaddTable"
                                        responsive
                                        bordered
                                        data={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default AddEmi;
