import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import Resete from "@mui/icons-material/ResetTvRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./PvtAgencyMonthlyReport.scss";
import { Grid } from "@mui/material";

const DatatableTables = () => {
  const data = {
    columns: [
      {
        label: "#",
        field: "si",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ward",
        field: "ward",
        sort: "asc",
        width: 270,
      },
      {
        label: "House Visited",
        field: "HVisited",
        sort: "asc",
        width: 150,
      },
      {
        label: "House Paid",
        field: "HPaid",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Organization Visited",
        field: "OVisited",
        sort: "asc",
        width: 200,
      },
      {
        label: "Paid Fee(#)",
        field: "PFee",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount2",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total",
        field: "total",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        si: "1",
        date: "2011/04/25",
        ward: "ward 1",
        HVisited: "12",
        HPaid: "10",
        amount1: "500",
        OVisited: "8",
        PFee: "8",
        amount2: "800",
        total: "1300",
      },
      {
        si: "2",
        date: "2011/04/25",
        ward: "ward 2",
        HVisited: "10",
        HPaid: "10",
        amount1: "500",
        OVisited: "5",
        PFee: "4",
        amount2: "400",
        total: "900",
      },
      {
        si: "3",
        date: "2011/04/25",
        ward: "ward 3",
        HVisited: "15",
        HPaid: "12",
        amount1: "600",
        OVisited: "10",
        PFee: "5",
        amount2: "500",
        total: "1100",
      },
    ],
  };

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [value1, setValue1] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChange1 = (newValue1) => {
    setValue1(newValue1);
  };

  const [district, setDistrict] = React.useState("");

  const handleChangeDistrict = (event) => {
    setDistrict(event.target.value);
  };

  const [localbody, setLocalbody] = React.useState("");

  const handleChangeLocalbody = (event) => {
    setLocalbody(event.target.value);
  };

  const [ward, setWard] = React.useState("");

  const handleChangeWard = (event) => {
    setWard(event.target.value);
  };

  const [staff, setStaff] = React.useState("");

  const handleChangeStaff = (event) => {
    setStaff(event.target.value);
  };

  // const [searchData, setSearchData] = useState({});
  // let handleDate = (e) => {
  //   var date1 = $("#from_date").val();
  //   var date2 = $("#to_date").val();

  //   let ward_id = searchData?.ward_id ? searchData.ward_id : "";
  //   setSearchData({
  //     ...searchData,
  //     [e.target.name]: e.target.value,
  //   });
  //   // handleTableData(date1, date2, ward_id);
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem="PVT Agency Monthly Report"
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            // onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            // onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Localbody</Label>
                          <Select
                            name="ward_id"
                            // value={}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected);
                            // }}
                            options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="ward_id"
                            // value={}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected);
                            // }}
                            options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            name="ward_id"
                            // value={}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected);
                            // }}
                            options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ marginTop: "7px" }}>
                        <div className="mt-4">
                          <Button color="danger" type="submit">
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View PVT Agency Monthly Report</CardTitle> */}
                  <hr />
                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
