import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getMonth,
  getFirstday,
  getDate,
  isSuperAdmin,
} from "../../../helpers/globalFunctions";
import moment from "moment";
import "./localbodyReport.scss";

const LocalbodyReport = (props) => {
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [searchData, setSearchData] = useState({
    // from_date: getMonth(new Date()),
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [details, setDetails] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
    fetch_all_staff(currentLocalbody);
    fetch_all_wards(currentLocalbody);
  }, [currentLocalbody]);

  function handleTableData(
    from_date = "",
    to_date = "",
    ward_id = "",
    staff_id = ""
  ) {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      userId = obj._id;
    }
    var url =
      `${API_URL}reports/localbody_report?localbody_id=` +
      currentLocalbody +
      "&user_id=" +
      userId;

    // from_date = from_date ? from_date : getMonth(new Date());
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&staff_id=" +
      staff_id;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let dataToBeExported = [];
        let vistHouse = 0;
        let paidHouse = 0;
        let houseAmt = 0;
        let vistShop = 0;
        let paidShop = 0;
        let ShopAmt = 0;
        let totalAmount = 0;
        let totalWeight = 0;
        let totalHouse = 0;
        let totalShop = 0;
        let houseClosed = 0;
        let totalBuilding = 0;
        let paymentPendingHouses = 0;
        let paymentPendingShops = 0;
        let totalVisited = 0;
        result &&
          result.map((item, index) => {
            let exportItem = {};
            item.id = index + 1;

            item.total_weight = item.total_weight.toFixed(2);

            //export data
            exportItem.id = item.id;
            exportItem.Visited_Houses = item.visited_houses;
            exportItem.Paid_Houses = item.paid_houses;
            exportItem.House_Amt = item.house_amt;
            exportItem.Visited_Shops = item.visited_shops;
            exportItem.Paid_Shops = item.paid_shops;
            exportItem.Shop_Amt = item.shop_amt;
            exportItem.Weight = item.total_weight;
            exportItem.Ward_Name = item.ward_name;
            exportItem.Ward_No = item.ward_no;
            exportItem.Toatal_Houses = item.Total_houses;
            exportItem.Toatal_Shops = item.Total_shops;
            exportItem.House_Closed = item.House_closed;
            exportItem.Toatal_Amount = item.total_amt;
            exportItem.Toatal_Building = item.Total_building;
            exportItem.Payment_Pending_Houses = item.payment_pending_houses;
            exportItem.Payment_Pending_Shops = item.payment_pending_shops;
            exportItem.Total_Visited = item.Total_visited;

            // Calculate the total summary
            totalAmount += item?.total_amount;
            vistHouse += item?.visited_houses;
            paidHouse += item?.paid_houses;
            houseAmt += item?.house_amt;
            vistShop += item?.visited_shops;
            paidShop += item?.paid_shops;
            ShopAmt += item?.shop_amt;
            totalHouse += item?.Total_houses;
            totalShop += item?.Total_shops;
            houseClosed += item.House_closed;
            totalBuilding += item.Total_building;
            paymentPendingHouses += item.payment_pending_houses;
            paymentPendingShops += item.payment_pending_shops;
            totalVisited += item.Total_visited;
            totalWeight += parseFloat(item?.total_weight);

            dataToBeExported.push(exportItem);
          });

        // Add Total Summary row
        if (result.length !== 0) {
          result.push({
            ward_no: "",
            ward_name: <b>Total Summary</b>,
            visited_houses: <b>{vistHouse}</b>,
            paid_houses: <b>{paidHouse}</b>,
            house_amt: <b>{houseAmt}</b>,
            visited_shops: <b>{vistShop}</b>,
            paid_shops: <b>{paidShop}</b>,
            shop_amt: <b>{ShopAmt}</b>,
            total_amount: <b>{totalAmount}</b>,
            Total_houses: <b>{totalHouse}</b>,
            Total_shops: <b>{totalShop}</b>,
            House_closed: <b>{houseClosed}</b>,
            Total_building: <b>{totalBuilding}</b>,
            payment_pending_houses: <b>{paymentPendingHouses}</b>,
            payment_pending_shops: <b>{paymentPendingShops}</b>,
            Total_visited: <b>{totalVisited}</b>,
            total_weight: <b>{totalWeight.toFixed(2)}</b>,
          });
        }
        setDetails(result);
        setDataToBeExported(dataToBeExported);
      });
  }
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };

  const data = {
    columns: [
      {
        label: "Ward No",
        field: "ward_no",
        sort: "asc",
      },
      {
        label: "Ward",
        field: "ward_name",
        sort: "asc",
      },
      {
        label: "Total Houses",
        field: "Total_houses",
        sort: "asc",
      },
      {
        label: "Total Shops",
        field: "Total_shops",
        sort: "asc",
      },
      {
        label: "Closed Houses",
        field: "House_closed",
        sort: "asc",
      },
      {
        label: "Total Building",
        field: "Total_building",
        sort: "asc",
      },

      {
        label: "Visited Houses",
        field: "visited_houses",
        sort: "asc",
      },
      {
        label: "Visited Shops",
        field: "visited_shops",
        sort: "asc",
      },
      {
        label: "Paid Houses",
        field: "paid_houses",
        sort: "asc",
      },
      {
        label: "Paid Shops",
        field: "paid_shops",
        sort: "asc",
      },
      {
        label: "Pending Houses",
        field: "payment_pending_houses",
        sort: "asc",
      },
      {
        label: "Pending Shops",
        field: "payment_pending_shops",
        sort: "asc",
      },
      {
        label: "₹ House",
        field: "house_amt",
        sort: "asc",
      },
      {
        label: "₹ Shop",
        field: "shop_amt",
        sort: "asc",
      },
      {
        label: "Weight(Kg)",
        field: "total_weight",
        sort: "asc",
      },
      {
        label: "Total Visited",
        field: "Total_visited",
        sort: "asc",
      },
    ],
    rows: details,
  };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    // handleTableData(date1, date2, ward_id, staff_id);
  };

  const handleSelectChange = (selected, name) => {
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let date1 = searchData?.from_date ? searchData.from_date : "";
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
        });

        // handleTableData(date1, selected.value, staff_id);
        break;
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        // handleTableData(date1, ward_id, selected.value);
        break;
      default:
        break;
    }
  };
  const reset = () => {
    setSearchData({
      // from_date: getMonth(new Date()),
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedStaff(null);
    handleTableData();
  };
  const handleSearch = () => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    handleTableData(date1, date2, ward_id, staff_id);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Localbody Report" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="month"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col> */}
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          name="staff_id"
                          value={selectedStaff}
                          onChange={(selected) => {
                            handleSelectChange(selected, "staff");
                          }}
                          options={staffOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3" >
                      <div style={{ display: "flex" }} >
                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "23px" }}>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </Button>
                        </div>
                        {isSuperAdmin() ? (
                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "23px" }}>
                          <Button color="success" type="submit">
                            <CSVLink
                              data={dataToBeExported}
                              filename={
                                "Localbody_report_" +
                                getMonth(new Date()) +
                                ".xls"
                              }
                              style={{ color: "white" }}
                            >
                              Export
                            </CSVLink>
                          </Button>
                        </div>
        ) : null}
                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "23px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="localbodyreport"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LocalbodyReport;
