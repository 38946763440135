import React, { useState, useEffect, useRef } from "react";
import { apiError } from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody } from "../../../helpers/globalFunctions";

import QRCode from "qrcode.react";

const ViewQrCode = (props) => {
  const { qrcodeId } = props.match.params;

  const [details, setDetails] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody);
  const API_URL = process.env.REACT_APP_APIURL;

  useEffect(() => {
    if (qrcodeId) {
      handleDocument(qrcodeId);
    }
  }, [currentLocalbody]);
  useEffect(() => {
    if (details && details.length > 0) window.print();
  }, [details]);

  function handleDocument(id) {
    axios
      .get(`${API_URL}qrcode/pdf?qrcode_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setDetails(result);
      });
  }
  return (
    details &&
    details.map((item) => {
      return (
        <QRCode
          size={50}
          value={item?.qp_path}
          style={{ marginLeft: "15px", marginTop: "10px",marginRight: "15px" }}
        />
      );
    })
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(ViewQrCode));
