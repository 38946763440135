import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Table,
} from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import { formatMoney } from "../../../helpers/generalFunctions";
// import { getDate } from "../../../helpers/globalFunctions";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getDate,
  getFirstday,
  isSuperAdmin,
} from "../../../helpers/globalFunctions";
import "./wardwiseReport.scss";
import moment from "moment";
import { getCustomerType } from "../../../store/actions";
const WardwiseReport = (props) => {
  const [details, setDetails] = useState([]);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  //   const [grandTotal, setGrandTotal] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [customerType, setCustomerType] = useState([]);
  const dispatch = useDispatch();
  const { customerTypes } = useSelector((state) => state.customers);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
    fetch_all_wards(currentLocalbody);
    fetch_all_staff(currentLocalbody);
    // dispatch(getCustomerType());
  }, [currentLocalbody]);

  useEffect(() => {
    if (selectedCategory != null) {
      dispatch(getCustomerType(selectedCategory.value))
    }
  }, [selectedCategory])

  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ward",
        field: "ward_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Visited Houses",
        field: "visited_houses",
        sort: "asc",
        width: 200,
      },
      {
        label: "Paid Houses",
        field: "paid_houses",
        sort: "asc",
        width: 200,
      },
      {
        label: "House ₹",
        field: "house_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Visited Shops",
        field: "visited_shops",
        sort: "asc",
        width: 200,
      },
      {
        label: "Paid Shops",
        field: "paid_shops",
        sort: "asc",
        width: 200,
      },
      {
        label: "Shop ₹",
        field: "shop_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total ₹",
        field: "total_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Weight(Kg)",
        field: "total_weight",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };

  function handleTableData(
    from_date = "",
    to_date = "",
    ward_id = "",
    staff_id = "",
    category_type = "",
    category_id = ""
  ) {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      userId = obj._id;
    }
    var url =
      `${API_URL}reports/wardwise_report?localbody_id=` +
      currentLocalbody +
      "&user_id=" +
      userId;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&staff_id=" +
      staff_id +
      "&category_type=" +
      category_type +
      "&category_id=" +
      category_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let reportData = [];
        let dataToBeExported = [];
        let vistHouse = 0;
        let paidHouse = 0;
        let houseAmt = 0;
        let vistShop = 0;
        let paidShop = 0;
        let ShopAmt = 0;
        let totalAmount = 0;
        let totalWeight = 0;

        result.map((item, index) => {
          let exportItem = {};
          item.id = index + 1;
          item.total_weight = item.total_weight.toFixed(2);

          //export data
          exportItem.id = item.id;
          exportItem.Ward = item.ward_name;
          exportItem.Visited_Houses = item.visited_houses;
          exportItem.Paid_Houses = item.paid_houses;
          exportItem.House_Amt = item.house_amt;
          exportItem.Visited_Shops = item.visited_shops;
          exportItem.Paid_Shops = item.paid_shops;
          exportItem.Shop_Amt = item.shop_amt;
          exportItem.Total = item.total_amt;
          exportItem.Weight = item.total_weight;

          reportData.push(item);
          dataToBeExported.push(exportItem);
          // Calculate the total summary
          totalAmount += item?.total_amt;
          vistHouse += item?.visited_houses;
          paidHouse += item?.paid_houses;
          houseAmt += item?.house_amt;
          vistShop += item?.visited_shops;
          paidShop += item?.paid_shops;
          ShopAmt += item?.shop_amt;
          totalWeight += parseFloat(item?.total_weight);
        });

        if (reportData.length !== 0) {
          reportData.push({
            id: "",
            ward_name: <b>Total Summary</b>,
            visited_houses: <b>{vistHouse}</b>,
            paid_houses: <b>{paidHouse}</b>,
            house_amt: <b>{houseAmt}</b>,
            visited_shops: <b>{vistShop}</b>,
            paid_shops: <b>{paidShop}</b>,
            shop_amt: <b>{ShopAmt}</b>,
            total_amt: <b>{totalAmount}</b>,
            total_weight: <b>{totalWeight.toFixed(2)}</b>,
          });
        }
        setDetails(reportData);
        setDataToBeExported(dataToBeExported);
      });
  }

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let category_id = searchData?.category_id ? searchData.category_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

  };

  const handleSelectChange = (selected, name) => {

    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
        });
        // handleTableData(date1, date2, selected.value, staff_id, category_id);
        break;
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        // handleTableData(date1, date2, ward_id, selected.value, category_id);
        break;
      case "category_type":
        setSelectedCategory(selected);
        setSelectedCustomerType(null);
        setSearchData({
          ...searchData,
          ["category_type"]: selected.value,
          ["category_id"]: "",
        });
        break;
      case "customerType":
        setSelectedCustomerType(selected);
        setSearchData({
          ...searchData,
          ["category_id"]: selected.value,
        });
        // handleTableData(date1, date2, ward_id, staff_id, selected.value);
        break;
      default:
        break;
    }
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedStaff(null);
    setSelectedCustomerType(null);
    setSelectedCategory(null);
    handleTableData();
  };
  const handleSearch = () => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let category_id = searchData?.category_id ? searchData.category_id : "";
    let category_type = searchData?.category_type ? searchData.category_type : "";
    handleTableData(date1, date2, ward_id, staff_id, category_type, category_id);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Ward Wise Report" />
          <Row>
            <Col className="col-12">
              {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {"19.00"}</h5>
              </Row> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          name="staff_id"
                          value={selectedStaff}
                          onChange={(selected) => {
                            handleSelectChange(selected, "staff");
                          }}
                          options={staffOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Category Type</Label>
                        <Select
                          name="category_type"
                          value={selectedCategory}
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "Residential", value: 1 },
                            { label: "Commercial", value: 2 }
                          ]}
                          onChange={(selected) => {
                            handleSelectChange(selected, "category_type")
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Customer Type</Label>
                        <Select
                          name="category_id"
                          value={selectedCustomerType}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customerType");
                          }}
                          options={customerType}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    {/* <Row> */}
                    <Col md="3">
                      <div style={{ display: "flex" }} >
                        <div className="mt-1 mb-3 mx-1">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </Button>
                        </div>
                        {isSuperAdmin() ? (
                        <div className="mt-1 mb-3 mx-1">
                          <Button color="success" type="submit">
                            {/* <i className="mdi mdi-microsoft-excel"></i> */}
                            <CSVLink
                              data={dataToBeExported}
                              filename={
                                "Ward_wise_report_" + getDate(new Date()) + ".xls"
                              }
                              style={{ color: "white" }}
                            >
                              Export
                            </CSVLink>
                          </Button>
                        </div>
        ) : null}
                        <div className="mt-1 mb-3 mx-1">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Col>
                    {/* </Row> */}
                  </Row>

                  <MDBDataTable
                    id="wardwiseReportTableId"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WardwiseReport;
